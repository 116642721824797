import React, { useEffect, useState } from 'react';
import { addMonths, subMonths, format, addDays, startOfWeek, startOfMonth, endOfMonth, endOfWeek, isSameMonth, isSameDay, parse, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Wrapper, Header, AfterMonth, BeforeMonth, BigDay, CalendarHeader, CellDay, CurrentMonth, Day, Week, WeekDays, CardEventWrapper, EventType, EventTitle, CalendarWrapper, CalendarTitle, WrapperInput, SpotEvent, NoContent } from './style';
import { arrowDown, arrowSliderL, arrowSliderR } from 'assets';
import { ButtonOutline } from 'pages/Products/Components/components';
import IEvents from 'services/events/models/events-interface';
import { getAllEvents, getEventsByFilter } from 'services/events';
import getErrorMessage from 'helpers/errorMessages';
import IEvent from 'pages/Events/Detail/models/event-interface';
import { mapPin, watch } from 'assets';
import { formatEventDate, formatTitleDate } from 'helpers/eventDate';
import parseISO from 'date-fns/parseISO';
import { Button, LinkButton, Select } from 'components';
import { abrvMonths } from 'helpers/months';
import { Desktop, Mobile } from 'utils/responsiveRules';

// import { Container } from './styles';

const CardEvent = ({ events }: any) => {
  return (
    <>
      {
        events?.map((event: IEvent, index: number) => {
          return (
            <CardEventWrapper key={`event-${event?.event_id}-${index}`}>
              <Desktop>
                <EventType>{event?.title}</EventType>
              </Desktop>
              <EventTitle>{event?.subtitle}</EventTitle>
              <Desktop>
                <p>{event?.description}</p>
              </Desktop>
              <div>
                <div>
                  {event?.speakers?.map((speaker, index) => {
                    return speaker.name + ' ';
                  })}
                </div>
                <div>
                  <img src={watch} alt="" />
                  {formatEventDate(parseISO(event?.start_date))}
                </div>
                <Desktop>
                  <LinkButton variant="DefaultDark" to={`/eventos/${event?.event_id}`}>
                    Saiba mais
                  </LinkButton>
                </Desktop>
              </div>
            </CardEventWrapper>
          )
        })
      }
    </>
  )
}


const Calendar: React.FC = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDayOfMonth, setSelectedDayOfMonth] = useState<any>();
  const [selectedWeek, setSelectedWeek] = useState<any>();
  const [eventsOfDay, setEventsOfDay] = useState<any>();
  const [eventsOfMonth, setEventsOfMonth] = useState<any>();
  const [selectedMonth, setSelectedMonth] = useState<any>();
  const [currentDay, setCurrentDay] = useState();
  const [currentYear, setCurrentYear] = useState<any>();
  const [event, setEvent] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);


  const [showDayView, setShowDayView] = useState(false);
  const [showMonthView, setShowMonthView] = useState(true);
  const [showYearView, setShowYearView] = useState(false);
  const [seletecView, setSelectedView] = useState('Por mês');

  const dayEvent = (day: any) => {
    return (
      <>
        {
          eventsOfMonth?.map((event: any, i: number) => {
            if (isSameDay(parseISO(event?.start_date), day)) {
              return (
                <SpotEvent>{event?.subtitle}</SpotEvent>
              )
            }
          })
        }
      </>
    )
  }



  const nextMonth = () => {
    setSelectedDayOfMonth(false)
    setCurrentMonth(
      addMonths(currentMonth, 1)
    );
    setSelectedDayOfMonth(null);
    setSelectedWeek(null);

  };

  const prevMonth = () => {
    setSelectedDayOfMonth(false)
    setCurrentMonth(
      subMonths(currentMonth, 1)
    );
    setSelectedDayOfMonth(null);
    setSelectedWeek(null);
  };

  const renderHeader = () => {
    const dateFormat = "MMMM yyyy";
    return (
      <CalendarHeader>
        <Desktop>
          {showYearView && (
            <BeforeMonth>
              <div className="icon" onClick={() => {
                setCurrentYear(currentYear - 1)
              }}>
                <img src={arrowSliderL} alt="" />
              </div>
              <span>{currentYear - 1}</span>
            </BeforeMonth>
          )}
          {selectedDayOfMonth && selectedWeek && !showYearView && (
            <BeforeMonth>
              <div className="icon" onClick={() => {
                getWeekOfDay(subDays(selectedWeek[0], 1))
              }}>
                <img src={arrowSliderL} alt="" />
              </div>
              <span>Anterior</span>
            </BeforeMonth>
          )}

          {!selectedDayOfMonth && !selectedWeek && !showYearView && (
            <BeforeMonth>
              <div className="icon" onClick={prevMonth}>
                <img src={arrowSliderL} alt="" />
              </div>
              <span>{format(subMonths(currentMonth, 1), dateFormat, { locale: ptBR })}</span>
            </BeforeMonth>
          )}

          <CurrentMonth>
            {selectedDayOfMonth && selectedWeek && !showYearView && (
              <span>
                Semana {format(selectedWeek[0], 'dd', { locale: ptBR })}
                {' '}- {format(selectedWeek[6], 'dd', { locale: ptBR })}
                {' '}{format(selectedWeek[6], 'MMMM', { locale: ptBR })}
              </span>
            )}
            {!selectedDayOfMonth && !selectedWeek && !showYearView && (
              <span>{format(currentMonth, dateFormat, { locale: ptBR })}</span>
            )}
            {showYearView && (
              <span>{currentYear}</span>
            )}
          </CurrentMonth>
          {selectedDayOfMonth && selectedWeek && !showYearView && (
            <AfterMonth>
              <span>Próxima</span>
              <div className="icon" onClick={() => {
                getWeekOfDay(addDays(selectedWeek[6], 1))
              }}>
                <img src={arrowSliderR} alt="" />
              </div>
            </AfterMonth>
          )}
          {!selectedDayOfMonth && !selectedWeek && !showYearView && (
            <AfterMonth>
              <span>{format(addMonths(currentMonth, 1), dateFormat, { locale: ptBR })}</span>
              <div className="icon" onClick={nextMonth}>
                <img src={arrowSliderR} alt="" />
              </div>
            </AfterMonth>
          )}
          {showYearView && (
            <AfterMonth>
              <span>{currentYear + 1}</span>
              <div className="icon" onClick={() => {
                setCurrentYear(currentYear + 1)
              }}>
                <img src={arrowSliderR} alt="" />
              </div>
            </AfterMonth>
          )}
        </Desktop>
        <Mobile>
          <CurrentMonth>
            {selectedDayOfMonth && selectedWeek && (
              <span>
                {format(selectedWeek[0], 'dd', { locale: ptBR })}
                {' '}- {format(selectedWeek[6], 'dd', { locale: ptBR })}
                {' '}{format(selectedWeek[6], 'MMMM', { locale: ptBR })}
              </span>
            )}
            {!selectedDayOfMonth && !selectedWeek && (
              <span>{format(currentMonth, dateFormat, { locale: ptBR })}</span>
            )}

          </CurrentMonth>
          {!selectedDayOfMonth && !selectedWeek && (
            <BeforeMonth>
              <div className="icon" onClick={prevMonth}>
                <img src={arrowSliderL} alt="" />
              </div>
            </BeforeMonth>
          )}
          {!selectedDayOfMonth && !selectedWeek && (
            <AfterMonth>
              <div className="icon" onClick={nextMonth}>
                <img src={arrowSliderR} alt="" />
              </div>
            </AfterMonth>
          )}

          {selectedDayOfMonth && selectedWeek && (
            <BeforeMonth>
              <div className="icon" onClick={() => {
                getWeekOfDay(subDays(selectedWeek[0], 1))
              }}>
                <img src={arrowSliderL} alt="" />
              </div>
            </BeforeMonth>
          )}

          {selectedDayOfMonth && selectedWeek && (
            <AfterMonth>
              <div className="icon" onClick={() => {
                getWeekOfDay(addDays(selectedWeek[6], 1))
              }}>
                <img src={arrowSliderR} alt="" />
              </div>
            </AfterMonth>
          )}
        </Mobile>

      </CalendarHeader>
    )
  }

  const renderDays = () => {
    const dateFormat = "EEEEEE";
    const days = [];

    let startDate = startOfWeek(currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <Day key={i}>
          {format(addDays(startDate, i), dateFormat, { locale: ptBR })}
          {selectedDayOfMonth && selectedWeek &&
            <BigDay
              onClick={() => {
                setSelectedDayOfMonth(selectedWeek[i])
                setShowDayView(true)
              }}
              active={selectedWeek[i] === selectedDayOfMonth}
            >
              <span className="number">{format(selectedWeek[i], 'dd', { locale: ptBR })}</span>
              <Desktop>
                <small>{format(selectedWeek[i], 'MMMM', { locale: ptBR })}</small>
              </Desktop>
            </BigDay>
          }
        </Day>
      );
    }

    return <WeekDays>{days}</WeekDays>;
  }

  const renderMonths = () => {
    const dateFormat = "EEEEEE";
    const days = [];

    for (let i = 0; i < 12; i++) {
      days.push(
        <Day key={i}>
          {abrvMonths &&
            <BigDay
              onClick={() => setSelectedMonth(abrvMonths[i].number)}
              active={abrvMonths[i].number === selectedMonth}
            >
              <span className="number">{abrvMonths[i].name}</span>
              <small></small>
            </BigDay>
          }
        </Day>
      );
    }

    return <WeekDays>{days}</WeekDays>;
  }

  const renderCells = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const dateFormat = "dd";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat, { locale: ptBR });
        let cloneDay = day;
        days.push(
          <CellDay
            className={`col cell ${!isSameMonth(day, monthStart)
              ? "disabled"
              : isSameDay(day, selectedDate) ? "selected" : ""
              }`}
            key={`${day}-${i}`}
            onClick={() => getWeekOfDay(cloneDay)}
          >
            <span className="number">{formattedDate}</span>

            <Desktop>
              <small>{format(day, 'MMMM', { locale: ptBR })}</small>
              {dayEvent(day)}
            </Desktop>
          </CellDay>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <Week key={`${day}`}>
          {days}
        </Week>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }

  const getWeekOfDay = (aDate: any) => {
    let date = aDate;
    let day = date.getDay();
    setSelectedDayOfMonth(aDate);
    setShowDayView(true);
    setSelectedView('Por dia');
    let array = []
    for (var i = 0; i < 7; i++) {
      if (i - day != 0) {
        var days = i - day;
        var newDate = new Date(date.getTime() + (days * 24 * 60 * 60 * 1000));

        array.push(newDate);
      }
      else
        array.push(date);
    }
    setSelectedWeek(array)
  }

  const renderEvents = () => {

    if (eventsOfMonth?.length <= 0 || eventsOfDay?.length <= 0) {
      return (
        <NoContent>
          <p>Nenhum evento encontrado.</p>
        </NoContent>
      )
    }

    if (eventsOfMonth && !eventsOfDay) {
      return (
        <CardEvent events={eventsOfMonth} />

      )
    }
    if (eventsOfDay) {
      return (
        <CardEvent events={eventsOfDay} />
      )
    }
    if (eventsOfMonth && !eventsOfDay) {
      return (
        <NoContent>
          <p>Nenhum evento encontrado para o dia.</p>
        </NoContent>
      )
    }


  }

  const getEventsOfDay = async (filter?: string, date?: string) => {
    const eventsOfDay = await getEventsByFilter(filter, date);
    setEventsOfDay(eventsOfDay)
  }

  const getEventsOfMonth = async (filter?: string, date?: string) => {
    const eventsOfMonth = await getEventsByFilter(filter, date);
    setEventsOfMonth(eventsOfMonth)
  }

  useEffect(() => {
    if (selectedDayOfMonth) {
      const date = format(selectedDayOfMonth, 'yyyy-MM-dd', { locale: ptBR });
      getEventsOfDay('DAY', date);
    }
  }, [selectedDayOfMonth])

  useEffect(() => {
    if (selectedMonth) {
      const date = `${currentYear}-${selectedMonth}-01`;
      getEventsOfMonth('MONTH', date);
    }
  }, [selectedMonth, currentYear])

  useEffect(() => {
    if (selectedMonth) {
      const date = `${currentYear}-${selectedMonth}-01`;
      getEventsOfMonth('MONTH', date);
    }
  }, [selectedMonth])


  useEffect(() => {
    const getEvents = async () => {
      setCurrentYear(new Date().getFullYear());
      try {
        setError(false);
        setLoading(true);
        const date = `${new Date().getFullYear()}-${format(currentMonth, 'MM')}-01`;
        const events = await getEventsOfMonth('MONTH', date);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    getEvents();
  }, [currentMonth])

  const [select, setSelect] = useState(false);
  const showSelect = () => setSelect(true);
  const onClose = () => setSelect(false);
  const today = new Date();


  const setDay = () => {
    setSelectedDayOfMonth(today)
    getWeekOfDay(today)
    setShowDayView(true)
    setShowYearView(false)
    setSelectedView('Por dia');
    onClose()
  }
  const setMonth = () => {
    setSelectedDayOfMonth(false)
    setSelectedWeek(false)
    setShowDayView(false)
    setShowYearView(false)
    setSelectedMonth(undefined)
    setSelectedView('Por mês');
    setEventsOfDay(undefined)
    onClose()

  }
  const setYear = () => {
    setShowYearView(true)
    setShowDayView(false)
    setShowMonthView(false)
    setSelectedView('Por ano');
    onClose()
  }
  return (
    <Wrapper>
      <Header>
        <CalendarTitle>Veja calendário</CalendarTitle>
        <div>
          <WrapperInput onClick={showSelect}>
            <p>Mostrar: {seletecView}</p>
            <img src={arrowDown} alt="" />
          </WrapperInput>
          <Select onClose={onClose} select={select}>
            <p onClick={setDay}>Por dia</p>
            <p onClick={setMonth}>Por mês</p>
            <p onClick={setYear}>Por ano</p>
          </Select>
        </div>
      </Header>
      <CalendarWrapper>
        {renderHeader()}
        {!showYearView && renderDays()}
        <Desktop>
          {showYearView && renderMonths()}
        </Desktop>
        {!selectedDayOfMonth && !selectedMonth && !showYearView && renderCells()}
        <Desktop>
          <>{(showYearView || showDayView) && renderEvents()}</>
        </Desktop>

        <Mobile>
          {!showDayView && <p>Eventos {eventsOfDay ? 'do dia' : 'do mês'}</p>}
          <>
            {renderEvents()}
          </>
        </Mobile>
      </CalendarWrapper>
    </Wrapper>
  );
}

export default Calendar;