import React from 'react';
import styled from 'styled-components';
import { Icon } from 'components';

interface IMessage {
  content: string;
}

const Message = styled.h2`
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 50px 10px;
  align-items: center;

  div {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
`;

const MessageError: React.FC<IMessage> = ({ content }) => {
  return (
    <Message>
      {content}
      <div>
        <Icon name="close" size={40} />
      </div>
    </Message>
  );
};

export default MessageError;
