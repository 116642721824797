import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import LadingpageComponente from './Components';

smoothscroll.polyfill();

const Ladingpage: React.FC = () => {
  return (
    <LadingpageComponente/>
  );
}

export default Ladingpage;