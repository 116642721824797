import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { exeltis_grey, logotipo_white } from 'assets';
import { ContainerGlobal } from 'components';
import { Footer } from './style';
import {
  NEWS,
  PODCAST,
  SCHEDULE,
  PRODUCTS,
  HOME,
  FAQ,
  CONTACT,
  TERMS_OF_USE,
  PRIVACY_POLICY,
  getCategoryRoute,
  EVENTS,
} from 'routes/Constants';
import { getCategories } from 'services/categories';
import { Category } from 'services/categories/models/category-interface';
import { getValue } from 'utils/storage';

const Logged: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>();
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const getAllCategories = async () => {
      const user = getValue<string>({ prop: 'exeltis-user' });
      if (user) {
        const response = await getCategories();
        setCategories(response);
      }
    };
    getAllCategories();
  }, []);

  return (
    <Footer>
      <ContainerGlobal>
        <div className="footer-container">
          <Link onClick={scrollTop} to={`${HOME}`}>
            <img className="brand" src={logotipo_white} alt="Exeltis ON" />
          </Link>
          <nav>
            <ul>
              {categories?.map((category, key) => (
                <li key={`${category.title}-${key}`}>
                  <Link
                    onClick={scrollTop}
                    to={getCategoryRoute(category.category_id)}
                  >
                    {category.title}
                  </Link>
                </li>
              ))}
              <li>
                <Link onClick={scrollTop} to={`${EVENTS}`}>
                  Agenda
                </Link>
              </li>
              <li>
                <Link onClick={scrollTop} to={`${NEWS}`}>
                  Notícias
                </Link>
              </li>
              <li>
                <Link onClick={scrollTop} to={`${PODCAST}`}>
                  Podcast
                </Link>
              </li>
              <li>
                <Link onClick={scrollTop} to={`${PRODUCTS}`}>
                  Produtos
                </Link>
              </li>
            </ul>
          </nav>
          <div className="divisor" />
          <a
            href="https://exeltis.com.br/"
            target="_blank"
            className="tooltip"
            rel="noreferrer"
          >
            <span className="tooltiptext">
              Você será redirecionado para o site da Exeltis
            </span>
            <img className="exeltis" src={exeltis_grey} alt="Exeltis" />
          </a>
        </div>
        <div className="footer-container my column-reverse">
          <p>
            © {new Date().getFullYear()} Todos os direitos Reservados - Exeltis
            Laboratório Farmacêutico Ltda.
          </p>
          <a href="https://exeltis.com.br/" target="_blank" rel="noreferrer">
            <img className="exeltis_m" src={exeltis_grey} alt="Exeltis" />
          </a>
          <nav>
            <ul>
              <li>
                <Link
                  className="footer-anchor"
                  onClick={scrollTop}
                  to={`${FAQ}`}
                >
                  FAQ
                </Link>
              </li>
              <li>
                <Link
                  className="footer-anchor"
                  onClick={scrollTop}
                  to={`${CONTACT}`}
                >
                  Contato
                </Link>
              </li>
              <li>
                <Link
                  className="footer-anchor"
                  onClick={scrollTop}
                  to={`${TERMS_OF_USE}`}
                >
                  Termos de Uso
                </Link>
              </li>
              <li style={{ marginRight: '0px' }}>
                <Link
                  className="footer-anchor"
                  onClick={scrollTop}
                  to={`${PRIVACY_POLICY}`}
                >
                  Políticas de Privacidade
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </ContainerGlobal>
    </Footer>
  );
};

export default Logged;
