import React, { useState } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { signup } from 'services/signup';
import { Button, Back, BackText, Loading, Error } from 'components';
import { logotipo } from 'assets';
import {
  LOGIN,
  PRIVACY_POLICY,
  TERMS_OF_USE,
  VERIFY_CODE,
} from 'routes/Constants';
import * as Style from '../../style';
import './checkbox.css';
import { Content, TitleDesktop, TitleMobile, ButtonContainer } from './style';

interface LocationState {
  data: {
    email: string;
    password: string;
    name: string;
    telephone: string;
    crm: string;
    crm_uf: string;
  };
}

const SignupTerms: React.FC = () => {
  const history = useHistory();
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { state } = useLocation<LocationState>();

  const handleFinishSignup = () => {

    setError(false);
    setLoading(true);
    signup(state.data)
      .then(response => {
        if (response) {
          toast.warn(
            'Verifique seu email para receber um código de segurança',
            { delay: 4000, hideProgressBar: false });
          history.push(`${VERIFY_CODE}`, {
            email: state.data.email,
            type: 'signup',
          });
        }
      })
      .catch(() => {
        toast.error(
          '⚠ Algo deu errado ao concluir seu cadastro. Tente novamente.',
        );
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    <Loading />;
  }
  if (error) {
    <Error type="LoadFail" />;
  }

  return (
    <Style.Wrapper>
      <Style.BackContainer>
        <Back />
      </Style.BackContainer>
      <Style.BackContainerMobile>
        <BackText title="Voltar" />
      </Style.BackContainerMobile>
      <Style.LogoContainer>
        <div className="img-dash-desktop">
          <img
            src={logotipo}
            alt="Logo"
          />
        </div>
      </Style.LogoContainer>
      <Style.LoginContainer>
        <Link to={`${LOGIN}`}>Já tem uma conta?</Link>
      </Style.LoginContainer>
      <Style.Container>
        <Style.Block>
          <TitleDesktop>CADASTRO - políticas de privacidade.</TitleDesktop>
          <TitleMobile>
            Cadastro <p>políticas de privacidade.</p>
          </TitleMobile>
          <Style.Form style={{ marginTop: '50px' }}>
            <Content>
              <div className="container-check">
                <input
                  type="checkbox"
                  checked={checked1}
                  onChange={() => setChecked1(!checked1)}
                  className="regular-checkbox"
                />
              </div>
              <span>
                Tenho ciência de que este Site é voltado exclusivamente para
                médicos, e, por isso, declaro, sob as penas da lei, que as
                informações por mim fornecidas aqui são exatas e verídicas.
                Estou ciente, ainda, que devo usar o material, disponibilizado
                através do Site, com responsabilidade e não replicar, repassar
                ou compartilhar o material com quaisquer meios de comunicação ou
                com terceiros.
              </span>
            </Content>
            <Content style={{ marginTop: '40px' }}>
              <div className="container-check">
                <input
                  type="checkbox"
                  checked={checked2}
                  onChange={() => setChecked2(!checked2)}
                  className="regular-checkbox"
                />
              </div>

              <span>
                Ao concluir o seu cadastro, você declara que está de acordo com
                os nossos{' '}
                <Link to={`${TERMS_OF_USE}`}>Termos e Condições de Uso</Link> e
                nossa
                <Link to={`${PRIVACY_POLICY}`}>Política de Privacidade</Link>
              </span>
            </Content>
            <ButtonContainer>
              <div>
                <Button
                  variant="DefaultDark"
                  onClick={handleFinishSignup}
                  disabled={!(checked1 && checked2)}
                >
                  Finalizar Cadastro
                </Button>
              </div>
            </ButtonContainer>
          </Style.Form>
          <Style.LoginContainerMobile>
            <div className="content-login-link">
              <Link to={`${LOGIN}`}>Já tem uma conta?</Link>
            </div>
          </Style.LoginContainerMobile>
        </Style.Block>
      </Style.Container>
    </Style.Wrapper>
  );
};

export default SignupTerms;
