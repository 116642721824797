import httpClient from 'http-client';
import { Category } from './models/category-interface';

const getCategories = async () : Promise<Array<Category>> => {
  const categories: Category[] = (await httpClient.get(`/categories`)).data;
  return categories;
}


const getCategoryInfo = async (id: string): Promise<Category> => {
  const category: Category = (await httpClient.get(`/categories/${id}`)).data;
  return category;
};

const getContentByCategoryType = async (categoryId: any, type: string) : Promise<any> => {
  if(!categoryId){
    const content: any = (await httpClient.get(`/contents?&is_library=true&type=${type}`)).data;
    return content;
  }
  else{
    const content: any = (await httpClient.get(`/contents?category_id=${categoryId}&is_library=true&type=${type}`)).data;
    return content;
  }
  
} 

const getContentByCategoryTypeTag = async (categoryId: any, type: string, tag: string) : Promise<any> => {
  const content: any = (await httpClient.get(`/contents?&is_library=true&type=${type}&tag_id=${tag}`)).data;
  return content;
}


export {
  getCategories,
  getCategoryInfo,
  getContentByCategoryType,
  getContentByCategoryTypeTag
};
