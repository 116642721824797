import styled, { css } from 'styled-components';

interface InputProps {
  height?: {
    desktop?: string;
    mobile?: string;
  };
}

export const InputWrapper = styled.div<InputProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  position: relative;

  label {
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 8px;
    color: var(--grey-darkness);
  }

  p {
    margin-top: 10px;
    font-size: var(--font-normal);
    color: var(--third-color);
    font-weight: 600;
    cursor: pointer;
  }

  div {
    display: flex;
    justify-content: center;
    border-radius: 6px;
    padding: 0 6px;
    border: 1px solid rgba(155, 153, 175, 0.31);
    outline: none;
    color: blue;

    height: ${props => props.height?.desktop};

    @media (max-width: 768px) {
      height: ${props => props.height?.mobile};
    }
  }

  .required {
    color: var(--color-error);
    font-size: 16px;
    font-weight: 800;
    padding-left: 5px;
    padding-top: 0px;
  }

  .error-border {
    border: 1px solid var(--color-error);
  }

  input {
    height: 45px;
    outline: none;
    border-radius: 6px;
    padding: 0 6px;
    border: none;
    width: 100%;
    color: #25282b;
    font-weight: 600;
    font-size: 16px;
    background: transparent;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }

  span {
    padding-top: 5px;
    padding-left: 5px;
    color: var(--color-error);
    font-size: 14px;
  }

  .eye-icon {
    width: 30px;
    margin-right: 20px;
    cursor: pointer;
  }

  .adjust {
    top: 70%;

    @media (max-width: 768px) {
      top: 75%;
    }
  }

  .alert {
    color: var(--blue-light);
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 12px;
    line-height: 20px;
  }
`;
