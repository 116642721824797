import styled from 'styled-components';

export const BackgroundHeader = styled.div`
  background-color: var(--bg-page);
`;

export const Container = styled.div`
  background-color: var(--bg-page);
  margin-top: -152px;
  padding-top: 150px;
  padding-bottom: 84px;
  padding-left: 40px;
  padding-right: 40px;

  @media only screen and (max-width: 800px) {
    padding-left: 0px;
    padding-right: 0px;
  }

  @media only screen and (max-width: 500px) {
    padding-bottom: 40px;
  }
`;

export const Wrapper = styled.div`
  .content-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 70px;

    h2 {
      color: var(--blue-medium);
      font-size: 70px;
      font-weight: 600;
    }

    h3 {
      color: var(--black-grey);
      font-size: 28px;
      margin: 40px 0;
    }
  }

  .container-btn {
    margin: 10px 0;
    width: 100%;
    max-width: 500px;
  }

  @media only screen and (max-width: 800px) {
    .content-page {
      text-align: center;

      h2 {
        font-size: 32px;
      }

      h3 {
        max-width: 360px;
        line-height: 40px;
        margin: 20px 0;
      }
    }

    .container-btn {
      width: 86%;
    }
  }

  @media only screen and (max-width: 376px) {
    .content-page {
      margin: 10% 0;
    }
    padding-right: 0px;
    padding-left: 0px;

    .content-page {
      text-align: center;

      h2 {
        font-size: 28px;
      }

      h3 {
        max-width: 360px;
        line-height: 40px;
        margin: 20px 0;
        font-size: 22px;
      }
    }

    .container-btn {
      width: 86%;
    }
  }
`;
