import { HOME, LADINGPAGE, PERMISSION } from './Constants';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAuth } from 'providers/auth/AuthProvider';

export const PrivateRouter = (props: RouteProps) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {

    return <Route {...props} />;
  }


  return (
    <>
      <Route path={props.path}>
        <Redirect
          to={{
            pathname: props.location?.pathname === HOME ? LADINGPAGE : PERMISSION,
            state: { prevPath: props.location?.pathname }
          }
          }
        />
      </Route>
      <Route path={HOME} exact>
        <Redirect path={LADINGPAGE} exact to={HOME} />
      </Route>
    </>
  );
};

export default PrivateRouter;
