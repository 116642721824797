import React, { useEffect, useMemo, useState } from 'react';
import { Header, Comment, Wrapper, FormComment, ResponseComment, ResponseButton, WrapperButtons, DeleteButton } from './style';
import { iconDelete, profile_man, response as response_icon} from 'assets';
import { deleteCommentByCommentId, getContentComment, postComment } from 'services/comments';
import { Comment as CommentInterface } from 'services/comments/models/comment-interface';
import { Button, Icon } from 'components';
import Responses from './Responses';
import FormResponse from './FormResponse';

interface IComments{
  contentId?: string
}
const FormPostComment = ({ handleSubmit, data, showButtons, comment, setComment, setShowButtons }:any) => {
  return(
  <FormComment method="POST" onSubmit={e => handleSubmit(e)}>
      <div className="form">
        <img src={data?.info?.avatar} alt=""/>
        <textarea
          placeholder="Escreva aqui seu comentário..."
          value={comment}
          onChange={e => setComment(e.target.value)}
          onFocus={() => setShowButtons(true)}
        />
      </div>
      {showButtons && (
        <WrapperButtons>
          <button onClick={() => setShowButtons(false)}>
            Cancelar
          </button>
          <button onClick={e => handleSubmit(e)}>
            Enviar
          </button>
        </WrapperButtons>
      )}
    </FormComment>
  )
}

const Comments: React.FC<IComments> = ({ contentId }) => {
  const [comment, setComment] = useState('');
  const [showResponse, setShowResponse] = useState<boolean>();
  const [comments, setComments] = useState<any>();
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [commentId, setCommentId] = useState('');
  const [deleteComment, setDeleteComment] = useState<String[]>([]);


  const user = JSON.parse(localStorage.getItem('exeltis-user') as string);

  useEffect(()=> {
    const getComments = async () => {
      if(contentId){
        const comments = await getContentComment(contentId);
        setComments(comments)
      }
    }
    getComments();
  },[contentId])


  const handlePostComment = async (e: React.FormEvent ) => {
    e.preventDefault();
    if(contentId){
      const data = {
        description: comment,
        reference_id: contentId
      }
      await postComment(data).then((comment) => {
        setComments([comment, ...comments])
        setComment('')
      });
    }
  }

  const handleDeleteComment = async (comment_id: string) => {
    setDeleteComment([comment_id, ...deleteComment]);
    await deleteCommentByCommentId(comment_id);
  };

  const isAdmin = user?.roles?.includes('Admin');
  const numberOfComments = comments?.length - deleteComment?.length;
  return(
    <>
      <Header>
        <Icon name="comment" color="#081D3D" size={30} />
        <h2>{comments && numberOfComments > 1
          ? ' Comentários'
          : ' Comentário'} ({`${numberOfComments}`}) </h2>
      </Header>
      <FormPostComment 
        handleSubmit={handlePostComment} 
        data={user}
        showButtons={showButtons}
        setShowButtons={setShowButtons}
        comment={comment}
        setComment={setComment}
      />
      <Wrapper>
        {comments?.map((comment: CommentInterface, key: any) => {
          if (deleteComment.includes(comment?.comment_id)) {
            return null;
          }else{
            return(
              <>
                <Comment key={`comment-${key}`}>
                  <div>
                    <img src={comment?.user_avatar_url} alt=""/>
                    <strong>{comment?.username}</strong>
                    {isAdmin && 
                      <DeleteButton onClick={() =>
                        handleDeleteComment(comment?.comment_id)
                      }>
                        <img src={iconDelete} />
                      </DeleteButton>
                    }
                  </div>
                  <p>{comment?.description}</p>
                  <ResponseButton onClick={() =>{
                    setShowResponse(!showResponse)
                    setCommentId(comment?.comment_id)
                  }}>
                    <img src={response_icon} alt=""/>
                    Responder
                  </ResponseButton>
                  { showResponse && 
                    <FormResponse data={user}  contentId={contentId} comment_id={commentId} setShowResponse={setShowResponse}/>
                  }
                </Comment>
                {comment?.responses?.map((comment: CommentInterface, key: any) =>(
                  <Responses comment={comment} key={`response-${key}-${comment?.comment_id}`}/>
                ))}
              </>
            )
          }
        })}
      </Wrapper>
    </>
  );
}

export default Comments;