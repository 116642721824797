import styled from 'styled-components';

export const SectionForm = styled.section`
  margin: 64px 0;

  h2 {
    font-size: 40px;
    line-height: 35px;
    font-weight: 700;
  }

  .contact-subtitle {
    margin-top: 15px;
    font-size: 20px;
    line-height: 30px;
    color: #828282;
  }

  @media only screen and (max-width: 768px) {
    margin: 0;

    h2,
    .contact-subtitle,
    .contact-features {
      margin: 10px 20px;
    }

    h2 {
      font-size: 28px;
      line-height: 32px;
    }

    .contact-subtitle {
      font-size: 16px;
      line-height: 22px;
    }
    .contact-form {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 64px;
  margin-left: -20px;
  margin-right: -20px;

  .contact-features {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .contact-features-container {
    max-width: 400px;
  }

  @media only screen and (min-width: 320px) and (max-width: 1024px) {
    flex-wrap: wrap;
    flex: 1;
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

export const SectionContact = styled.section`
  margin: 64px 0;
  border-top: 1px solid #828282;
  padding: 40px 0;

  h2 {
    font-size: 40px;
    line-height: 35px;
    font-weight: 700;
  }

  .contact-subtitle {
    margin-top: 15px;
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 30px;
    color: #828282;
  }

  .contact-container-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(383px, 1fr));
    grid-gap: 32px;
    align-items: stretch;
  }

  @media only screen and (max-width: 600px) {
    margin: 0;
    .contact-container-cards {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      grid-gap: 20px;
    }
  }

  @media only screen and (max-width: 768px) {
    border-top: none;

    h2 {
      font-size: 28px;
      line-height: 32px;
    }

    .contact-subtitle {
      font-size: 16px;
      line-height: 22px;
    }
  }
`;

export const MessageError = styled.h2`
  font-size: 25px;
  margin-top: 20px;
  line-height: 32px;
`;
