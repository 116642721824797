import React from 'react';
import HeaderDesktop from './Auth';
import HeaderMobile from './Mobile';

const Header: React.FC = () => {
  return (
    <>
      <HeaderDesktop />
      <HeaderMobile />
    </>
  );
};

export default Header;
