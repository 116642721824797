import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from 'components';

const Container = styled.div`
cursor: pointer;
`;

const Back: React.FC = () => {
  const history = useHistory();
  const handleBack = () => {
    history.goBack();
  };

  return (
    <Container onClick={handleBack}>
      <Icon name="back" size={35} />
    </Container>
  );
};

export default Back;
