import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getValue } from 'utils/storage';
import { getInfoByType } from 'services/info';
import { formattedDate } from 'helpers/formatDate';
import Info from 'services/info/models/info-interface';
import {
  Template,
  BreadCrumb,
  LoadingBg,
  Error,
  MessageError,
} from 'components';
import { Link } from 'react-router-dom';
import { d_technology, m_technology } from 'assets';
import { LADINGPAGE } from 'routes/Constants';
import smoothscroll from 'smoothscroll-polyfill';
import getErrorMessage from 'helpers/errorMessages';
import { Container } from './style';

smoothscroll.polyfill();

const PrivacyPolicy: React.FC = () => {
  const [politic, setPolitic] = useState<Array<Info>>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const getPrivacyPolicy = async () => {
      try {
        setError(false);
        setLoading(true);
        let politic;
        const user = getValue<string>({ prop: 'exeltis-user' });

        if (user) {
          politic = await getInfoByType('POLITIC_MEDIC');
        } else {
          const token = 'Bearer ' + process.env.REACT_APP_UNLOGGED_TOKEN;
          politic = await getInfoByType('POLITIC_MEDIC', {
            Authorization: token,
          });
        }
        setPolitic(politic);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        toast.error(errorMessage);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    getPrivacyPolicy();
  }, []);

  if (loading) return <LoadingBg />;
  if (error) return <Error type="LoadFail" />;

  return (
    <Template
      banner={{
        title: 'Política de privacidade',
        imgDesktop: d_technology,
        imgMobile: m_technology,
      }}
      breadcrumb={
        <BreadCrumb
          crumbs={[
            <Link to={`${LADINGPAGE}`}>HOME</Link>,
            <span>Política de privacidade</span>,
          ]}
        />
      }
    >
      {politic && politic.length ? (
        <Container>
          <h2>Política de privacidade</h2>
          <h3>
            Esta Política foi modificada pela última vez em{' '}
            {formattedDate(new Date(politic[0].date))}.
          </h3>
          <p dangerouslySetInnerHTML={{ __html: politic[0].text }} />
        </Container>
      ) : (
        <Container>
          <MessageError content="Sem conteúdo no momento." />
        </Container>
      )}
    </Template>
  );
};

export default PrivacyPolicy;
