import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Button } from 'components';

interface PaginationProps {
  pages?: number;
  actualPage: number;
  changePage: Dispatch<SetStateAction<number>>;
}

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 32px;

  button {
    border-radius: 50%;
    width: 25px;
    height: 25px;
  }
`;

const Pagination: React.FC<PaginationProps> = ({
  pages,
  actualPage,
  changePage,
}) => {
  const numberOfPagesToShowBehindAndForward = 3;

  const handleChangePage = (pageNumber: number) => {
    changePage(pageNumber);
  };

  return pages ? (
    <PaginationContainer>
      {/*   <button
        disabled={actualPage - 1 < 0}
        onClick={() => handleChangePage(actualPage - 1)}
      >
        {'<'}
  </button>*/}
      <Button
        variant="White"
        onClick={() => handleChangePage(actualPage - 1)}
        disabled={actualPage - 1 < 0}
      >
        {'<'}
      </Button>

      {Array(pages)
        .fill('')
        .map((_, index) => index)
        .filter(index => {
          const minimalPage = actualPage - numberOfPagesToShowBehindAndForward;
          const maximalPage = actualPage + numberOfPagesToShowBehindAndForward;

          return index >= minimalPage && index < maximalPage && index <= pages;
        })
        .map(index => {
          const pageNumber = index + 1;

          return (
            <>
              <Button
                key={index}
                variant="White"
                onClick={() => handleChangePage(pageNumber - 1)}
                active={pageNumber - 1 === actualPage}
              >
                {pageNumber}
              </Button>
              {/*
                <button
                  key={index}
                  disabled={pageNumber - 1 === actualPage}
                  onClick={() => handleChangePage(pageNumber - 1)}
                >
                  {pageNumber}
                </button>
              */}
            </>
          );
        })}
      {/*  <button
        disabled={actualPage + 1 >= pages}
        onClick={() => handleChangePage(actualPage + 1)}
      >
        {'>'}
  </button>*/}
      <Button
        variant="White"
        onClick={() => handleChangePage(actualPage + 1)}
        disabled={actualPage + 1 >= pages}
      >
        {'>'}
      </Button>
    </PaginationContainer>
  ) : (
    <></>
  );
};

export default Pagination;
