import httpClient from 'http-client';
import Tags from './models/tags-interface';

const getTags = async (filters?: object): Promise<Tags[]> => {
  const tags: Tags[] = (
    await httpClient.get('/tags', {
      params: filters,
    })
  ).data;
  return tags;
};


export {
  getTags
}