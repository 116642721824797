import React from 'react';
import { TagContent } from './style';
interface Tag{
  type?: string;
  theme?: string;
}

const Tag: React.FC<Tag> = ({type, theme}) => {
  const tagTitle = type?.replace('_', ' ')
  return(
    <TagContent theme={theme}>
      #{tagTitle?.toUpperCase()}
    </TagContent>
  );
}

export default Tag;