import styled from 'styled-components';
import { px2rem } from 'styles/globals';

const Card = styled.div`
  min-width: ${px2rem(502)};
  max-width: 45%;
  padding: ${px2rem(32)};
  background: #fff;
  display: flex;
  gap: ${px2rem(24)};
  flex: 1;

  .profile-wrapper {
    flex: 1;

    .profile-inner-wrapper {
      border-radius: 50%;
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        position: absolute;
      }
    }
  }

  .name-description {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: ${px2rem(16)};

    .name {
      font-size: ${px2rem(34)};
      font-weight: 700;
      line-height: ${px2rem(42)};
      color: var(--blue-medium);
    }

    .description {
      font-size: ${px2rem(16)};
      line-height: ${px2rem(24)};
      letter-spacing: ${px2rem(0.25)};
      color: var(--black-clear);
    }
  }

  @media screen and (max-width: 998px){
    flex-direction: column;
    min-width: 70%;
    max-width: 100%;
    padding: ${px2rem(24)};

    .name-description {
      display: content;
      text-align: center;

      .name {
        font-size: ${px2rem(24)};
        font-weight: 700;
        line-height: ${px2rem(32.68)};
        color: var(--blue-medium);
      }

      .description {
        font-size: ${px2rem(14)};
        line-height: ${px2rem(18)};
      }
    }

    .profile-wrapper, .name, .description {
      align-self: center;
    }

    .profile-wrapper {
      width: ${px2rem(64)};
    }
  }
`

export { Card };
