import React from 'react';
import { Container } from './style';

interface ISpeaker {
  name: string;
  avatar: string;
}

const Speaker: React.FC<ISpeaker> = ({ name, avatar }) => {
  return (
    <Container avatar={avatar}>
      <div className="avatar-wrapper" />
      <span className="speaker-name">{name}</span>
    </Container >
  );
};

export default Speaker;
