import styled, { css } from 'styled-components';
import { px2rem } from 'styles/globals';

interface IMessage {
  theme: 'Response' | 'Normal';
}

styled.div`back-button {
  position: absolute;
  background: var(--blue - darkness);
  top: 40px;
  left: 10px;
  height: 48px;
  width: 48px;
  border - radius: 50 %;
  cursor: pointer;
  z - index: 10;
  border: none;
  transition: ease -in -out .2s;

    :hover {
    transform: scale(1.1);
    transition: ease -in -out .2s;
  }
}`;

const Container = styled.div`
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding: ${px2rem(50)} 0 ${px2rem(120)} 0;

  @media screen and (max-width: 768px) {
    // height: 42vh;
    // position: fixed;
    z-index: 1;
  }
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(---white-glass);
  width: ${px2rem(490)};
  position: relative;

  @media screen and (max-width: 1000px) {
    width: 100%;
    min-height: 0;

    @media screen and (orientation: landscape) {
      display: none;
    }
  }
`;

const Title = styled.div`
  padding: 18px 20px;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  position: absolute;
  width: ${px2rem(460)};
  backdrop-filter: blur(8px);
  z-index: 2;
  transform: translate3d(0,0,0);
  left: 0;
  top: 0;
  @media screen and (max-width: 768px) {
    width: 90%;
  }

  h1 {
    font-size: var(--font-largest);
    text-transform: uppercase;
    color: var(--blue-darker);
    font-weight: 700;
  }

  svg {
    padding: 8px;
    cursor: pointer;
    margin-left: auto;
  }
`;

const Messages = styled.div<IMessage>`
  display: flex;
  align-items: center;
  width: ${px2rem(442)};
  margin-top: ${px2rem(15)};
  padding: 10px 0 0 16px;

  ${props =>
    props.theme === 'Response' &&
    css`
      background-color: var(--blue-medium);
      margin: 20px 10px 0 10px;
      border-radius: 6px 6px 0 0;
    `}

  @media screen and (max-width: 768px) {
    width: 90%;
    padding: 0 8px;
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
  }

  .content-wrapper {
    width: ${px2rem(395)};
    display: flex;

    @media screen and (max-width: 1450px) {
      width: ${px2rem(360)};
    }

    svg {
      padding: 8px;
      cursor: pointer;
    }
  }

  .content-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: ${px2rem(340)};

    @media screen and (max-width: 768px) {
      width: ${px2rem(265)};
    }

    @media screen and (max-width: 380px) {
      width: ${px2rem(230)};
    }

    @media screen and (max-width: 320px) {
      width: ${px2rem(195)};
    }
    strong {
      font-size: var(--font-large);
      color: var(--blue-darkness);
      font-weight: 700;

      ${props =>
    props.theme === 'Response' &&
    css`
          color: #3898d0;
        `}
    }

    span {
      color: #676767;
      align-self: center;
      justify-self: center;
      font-size: var(--font-normal);

      ${props =>
    props.theme === 'Response' &&
    css`
          color: #cacaca; ;
        `}
    }
  }
`;

const Text = styled.div<IMessage>`
  margin-top: 5px;
  height: 100%;
  width: 260px;
  background-color: var(--white);
  padding: 6px;
  border-radius: 4px;

  ${props =>
    props.theme === 'Response' &&
    css`
      background-color: var(--blue-medium);
      margin-top: 0px;
    `}

  @media screen and (max-width: 768px) {
    width: ${px2rem(195)};
  }

  div {
    width: 2px;
    border: 2px solid transparent;
    border-top: 1px solid transparent;
    border-bottom: 14px solid transparent;
    border-right: 12px solid var(--white);
    position: relative;
    left: -20px;
    z-index: -2;
  }

  p {
    margin-top: -10px;
    color: #676767;
    word-break: break-word;
    font-size: var(--font-normal);
    line-height: 19px;

    ${props =>
    props.theme === 'Response' &&
    css`
        color: var(--white);
        font-weight: 400;
      `}
  }
`;

const FooterChat = styled.form`
  margin-top: 15px;
  background-color: var(--white);
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: relative;

  @media screen and (max-width: 768px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 20px 0;
  }

  div {
    width: 70%;
    margin: 0;
    border-bottom: 3px solid rgba(112, 124, 151, 0.15);
  }

  svg {
    width: 25px;
    height: 25px;
    color: #707c9780;
    cursor: pointer;
    position: relative;
  }

  input {
    width: 88%;
    border: none;
    outline: none;
    color: #676767;
    font-size: var(--font-large);

    &::placeholder {
      color: #707c9780;
    }
  }
`;

const UserDelayMessage = styled.div`
  position: absolute;
  top: 7px;
  left: 0;
  padding:  0 30px;
  border-bottom: none !important;
  display: flex;
  flex-direction: column;
  p{
    font-size: 13px;
    margin-top: 3px;
    color:#676767;
  }
  span.progress{
    width: calc(100% - 6px);
    height: 3px;
    background: #e0e0e0;
    padding: 1px;
    border-radius: 5px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
  }
  span.fill{
    display: block;
    height: 3px;
    background: #659cef;
    border-radius: 3px;
    transition: width 5.5s ease-in;
    width: 0;
    &.active{
      width: 100%;
      transition: width 5.5s ease-in;
    }
  }
`;


const Response = styled.form`
  display: flex;
  margin: 0px 10px 20px 10px;
  background-color: var(--blue-medium);
  border-radius: 0 0 6px 6px;
  padding: 14px 8px 8px 8px;
  width: ${px2rem(442)};

  @media screen and (max-width: 768px) {
    width: 90%;
  }

  p {
    margin-top: 4px;
    color: var(--white);
    font-weight: 400;
    font-size: var(--font-normal);
    line-height: 20px;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
    margin-left: 15px;
  }

  .content-title {
    strong {
      color: #3898d0;
      font-weight: 700;
    }
  }

  input {
    border: none;
    outline: none;
    border-radius: 6px;
    padding: 5px;
    width: 300px;
    font-size: var(--font-medium);
  }
`;

const MessageInfo = styled.div`
  position: relative;
`;

const AdminOptions = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
  background-color: var(--white);
  border-radius: 4px;
  margin-top: 5px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;

  &:hover {
    background-color: var(--blue-clear);
  }

  span {
    font-size: var(--font-normal);
  }
  svg {
    width: 12px;
    height: 12px;
  }
`;

const UserBlockContainer = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;

  h1 {
    color: var(--blue-medium);
    font-weight: 700;
    font-size: var(--font-larger);
    padding-bottom: 10px;
  }
  p {
    font-size: var(--font-medium);
  }
`;

const UserBlockList = styled.div`
  padding: 0px 30px;
  display: flex;
  align-items: center;
  margin-top: 20px;

  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }

  span {
    margin-left: 20px;
    font-size: var(--font-large);
  }

  button {
    width: 150px;
    margin-left: auto;
  }
`;

const ButtonChat = styled.button`
  border: none;
  margin-left: 25px;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  background-color: #0076bc;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  svg {
    width: 20px;
    height: 20px;
    padding: 6px 2px 0 0;
  }
`;

export {
  ChatContainer,
  Title,
  Messages,
  Text,
  FooterChat,
  Container,
  Response,
  AdminOptions,
  Option,
  MessageInfo,
  UserBlockContainer,
  UserBlockList,
  ButtonChat,
  UserDelayMessage
};
