import styled from 'styled-components';

export const BreadCrumbContainer = styled.div`
  display: flex;
  align-items: center;

  & > :not(:first-child) {
    &::before {
      content: '>';
      margin: 0 10px;
      color: var(--blue-medium);
      font-size: 18px;
      font-weight: 500;
    }
  }

  a {
    font-size: 16px;
    color: var(--blue-medium);
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 900;
  }

  span {
    display: flex;
    font-size: 16px;
    color: #362c2c;
    text-decoration: none;

    div {
      max-width: 221px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  strong {
    display: flex;
    font-size: 16px;
    color: #362c2c;
    text-decoration: none;

    div {
      max-width: 221px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @media only screen and (max-width: 500px) {
      display: none;
    }
  }

  @media only screen and (max-width: 500px) {
    span,
    a {
      font-size: 15px;
      align-items: center;
    }
  }
`;
