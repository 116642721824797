import axios from 'axios';

interface UserSignup {
  email: string;
  password: string;
  name: string;
  telephone: string;
  crm: string;
  crm_uf: string;
}

interface IVerifyCode {
  email: string;
  code?: string;
  is_medic?: boolean;
}

interface IExportedSignUp {
  email: string;
  password: string
}

const signup = async (data: UserSignup): Promise<boolean> => {
  const signupResponse: boolean = (
    await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/cognito/signup/medic`,
      data,
    )
  ).data;

  return signupResponse;
};

export const signupExported = async (data: IExportedSignUp): Promise<boolean> => {

  const confirm = (
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/cognito/signup/medic/exported`, {
      email: data.email,
      password: data.password,
    })
  ).data;

  return confirm;
}

const verifyCode = async (data: IVerifyCode): Promise<boolean> => {
  const signupResponse: boolean = (
    await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/cognito/verify-code`,
      data,
    )
  ).data;

  return signupResponse;
};

const resendCode = async (data: IVerifyCode): Promise<boolean> => {
  const signupResponse: boolean = (
    await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/cognito/resend-code`,
      data,
    )
  ).data;

  return signupResponse;
};

export { signup, verifyCode, resendCode };
