import React from 'react';
import { article_card_placeholder } from 'assets';
import { Article,
  Wrapper,
   ArticleDescription,
    ArticleLink,
     ArticleResume,
      ArticleTitle,
       ExternalLink } from './style';
import Tag from 'components/Tags';

interface IArticleCard{
  data?: any;
  path?: string
  theme?: string;
  tag?: string
  themeTag?: string
  article?: boolean
  onClick?: () => void

}

//const getTypeResolver = (type: string) => {
//  switch(type)
//}

const ArticleCard: React.FC<IArticleCard> = ({data, path ='', theme, tag, onClick, themeTag, article = false }) => {
  const id = data?.content_id || data?.news_id
  const link = data?.news_id ? `/noticias/${id}` : ``;
  if(article){
    return(
      <ExternalLink onClick={onClick} href={path} target="_blank" rel="noreferrer" download>
        <Article color={theme}>
          <Wrapper>
            <img src={data?.thumbnail ? data?.thumbnail :  article_card_placeholder} alt=""/>
            {tag &&  <Tag type={tag} theme={themeTag || theme}/>}
          </Wrapper>
          <ArticleDescription>
            <ArticleTitle>{data?.title}</ArticleTitle>
            <ArticleResume>{data?.description}</ArticleResume>
          </ArticleDescription>
        </Article>
      </ExternalLink>
    );
  }else{
    return(
      <ArticleLink onClick={onClick} to={link || path} title="">
        <Article color={theme}>
          <Wrapper>
            <img src={data?.thumbnail ? data?.thumbnail :  article_card_placeholder} alt=""/>
            {tag &&  <Tag type={tag} theme={themeTag || theme}/>}
          </Wrapper>
          <ArticleDescription>
            <ArticleTitle>{data?.title}</ArticleTitle>
            <ArticleResume>{data?.description}</ArticleResume>
          </ArticleDescription>
        </Article>
      </ArticleLink>
    );
  }
}

export default ArticleCard;
