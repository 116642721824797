import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import TemplateCategories from 'components/Template/TemplateCategories';
import { BreadCrumb, Template } from 'components';
import { Link } from 'react-router-dom';
import { LADINGPAGE } from 'routes/Constants';
import { d_podcast, m_technology } from 'assets';
import CategoriesList from './components/CategoriesList';
import SpotContent from './components/SpotContent';

smoothscroll.polyfill();

const Podcasts: React.FC = () => {
  return (
    <>
     <Template
        banner={{
          title: 'Podcast',
          imgDesktop: d_podcast,
          imgMobile: d_podcast,
        }}
        breadcrumb={
          <BreadCrumb
            crumbs={[
              <Link to={`${LADINGPAGE}`}>HOME</Link>,
              <span>Podcast</span>,
            ]}
          />
        }
      >
      <SpotContent/>
      <CategoriesList/>
     </Template>
    </>
  );
};

export default Podcasts;
