import { ReactComponent as union } from 'assets/icon/union.svg';
import { ReactComponent as check } from 'assets/icon/check-solid.svg';
import { ReactComponent as arrow_down } from 'assets/icon/arrow_down.svg';
import { ReactComponent as more } from 'assets/icon/more.svg';
import { ReactComponent as search_icon } from 'assets/icon/search_icon.svg';
import { ReactComponent as profile_icon } from 'assets/icon/profile.svg';
import { ReactComponent as SignOut } from 'assets/icon/SignOut.svg';
import { ReactComponent as bar } from 'assets/icon/bar.svg';
import { ReactComponent as close } from 'assets/icon/close.svg';
import { ReactComponent as close_black } from 'assets/icon/close_black.svg';
import { ReactComponent as search_glass } from 'assets/icon/search_glass.svg';
import { ReactComponent as toggle } from 'assets/icon/toggle.svg';
import { ReactComponent as back } from 'assets/icon/arrow_left.svg';
import { ReactComponent as open_eyes } from 'assets/icon/eye-active-icon.svg';
import { ReactComponent as medicine } from 'assets/icon/medicine.svg';
import { ReactComponent as thermometer } from 'assets/icon/thermometer.svg';
import { ReactComponent as atom_blue_dark } from 'assets/icon/Atom.svg';
import { ReactComponent as atom_blue_light } from 'assets/icon/atom_blue_light.svg';
import { ReactComponent as circle_negative } from 'assets/icon/circle-negative.svg';
import { ReactComponent as plus_circle } from 'assets/icon/plus-circle.svg';
import { ReactComponent as  comment} from 'assets/icon/comment.svg';

export const ICONS = {
  union,
  plus_circle,
  circle_negative,
  medicine,
  atom_blue_light,
  check,
  arrow_down,
  more,
  thermometer,
  search_icon,
  profile_icon,
  SignOut,
  bar,
  search_glass,
  close,
  toggle,
  close_black,
  back,
  open_eyes,
  atom_blue_dark,
  comment
};

export type IconsTypes = keyof typeof ICONS;
