import styled from 'styled-components';

export const Footer = styled.footer`
  padding-bottom: 40px;
  padding-top: 40px;
  position: relative;
  z-index: 3;
  
  .brand {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 180px;
      height: auto;
    }
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 40px auto;

    p {
      color: var(--blue-darkness);
      text-transform: uppercase;
      margin-bottom: 62px;
      margin-top: 22px;
      font-size: 16px;
      text-align: center;
      line-height: 30px;
      padding-left: 10px;
      padding-right: 10px;
    }

    nav ul {
      display: flex;
      justify-content: center;
    }

    li {
      margin: 0 10px;
      a {
        color: var(--blue-medium);
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;

        :hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    border-top: 1px solid #cfd2d4;
    padding-bottom: 20px;
    padding-top: 3px;

    div {
      flex-direction: column-reverse;

      p {
        margin-top: 30px;
        font-size: 15px;
        margin-bottom: 0;
      }

      nav ul {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        align-items: center;
      }

      li {
        margin: 20px auto;

        a {
          font-size: 18px;
          text-transform: uppercase;
        }
      }
    }
  }
`;
