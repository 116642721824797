import styled from 'styled-components';

export const Section = styled.section`
  margin: 47px 0;
  display: flex;
  justify-content: center;

  .medicine-thumbs {
    flex: 1;
    max-width: 400px;
    width: 100%;

    img {
      max-width: 400px;
      width: 100%;
    }
  }

  .medicine-content {
    margin-left: 40px;
    flex: 1;

    p {
      color: var(--grey-medium-darker);
      font-size: 16px;
      line-height: 34px;
    }

    .medicine-product-mobile {
      display: none;
    }
  }

  .medicine-recommendations {
    margin: 40px 0;

    .medicine-info {
      margin: 20px 0;
    }
  }

  .medicine-info-btn {
    display: flex;
    width: 100%;
    justify-content: flex-start;

    > a {
      margin-right: 18px;
    }

    @media only screen and (max-width: 320px) {
      > a {
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    flex-direction: column;

    .medicine-thumbs {
      max-width: 100%;
      width: initial;
      padding: 0 20px;

      img {
        display: none;
      }
    }

    h2 {
      font-size: 38px;
      display: flex;
      justify-content: center;
    }
    h3 {
      margin-top: 0;
    }

    .medicine-content {
      margin-left: 0;

      .medicine-content-product-mobile {
        display: block;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;

        h3 {
          margin: 10px auto;
        }
      }

      .medicine-product-mobile {
        display: block;
        max-width: 200px;
        width: 100%;
        margin: 25px auto;
      }
    }

    .medicine-info-btn {
      flex-direction: column;
      width: initial;
      align-items: center;
      padding: 0 20px;

      > a {
        margin-right: 0;
        margin: 10px 0;
      }
    }
  }
`;

export const Title = styled.h2`
  font-size: 50px;
  line-height: 40px;
  color: var(--blue-medium);
  font-weight: 800;
  text-transform: uppercase;
`;

export const Description = styled.h3`
  color: var(--grey-medium-darker);
  font-size: 18px;
  line-height: 28px;
  margin: 32px 0 42px 0;
`;
