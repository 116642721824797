import styled, { css } from 'styled-components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slider from 'react-slick';
import { px2rem } from 'styles/globals';

interface ISlider {
  faded: boolean;
}
export const Container = styled.div`
  padding: 0 40px;
  margin-bottom: 150px;

  @media (min-width: 601px) and (max-width: 1026px) {
    margin-bottom: 150px;

    .slick-dots li.slick-active button:before {
      border: 3px solid var(--blue-medium);
      width: 13px;
      height: 13px;
      color: white;
      border-radius: 50px;
      margin-top: 14px;
    }

    .slick-dots li button:before {
      border: 3px solid #444444;
      width: 13px;
      height: 13px;
      color: white;
      border-radius: 50px;
      margin-top: 14px;

      :first-child() {
        margin-right: 10px;
      }

      :last-child() {
        margin-left: 10px;
      }

      :hover {
        border: 3px solid var(--blue-medium);
      }
    }
  }

  @media (max-width: 600px) {
    padding: 0;
    overflow-x: clip;
    margin-top: 30px;
    margin-bottom: 55px;

    .slick-dots li.slick-active button:before {
      border: 3px solid var(--blue-medium);
      width: 13px;
      height: 13px;
      color: white;
      border-radius: 50px;
      margin-top: 14px;
    }

    .slick-dots li button:before {
      border: 3px solid #444444;
      width: 13px;
      height: 13px;
      color: white;
      border-radius: 50px;
      margin-top: 14px;

      :first-child() {
        margin-right: 10px;
      }

      :last-child() {
        margin-left: 10px;
      }

      :hover {
        border: 3px solid var(--blue-medium);
      }
    }
  }
`;

export const SlickWrapper = styled(Slider) <ISlider>`
  ${({ faded }) => css`
    position: relative;

    width: 100%;
    z-index: 0;

    .slick-track {
      display: flex !important;
    }

    &:after {
      content: ${faded ? "''" : 'none'};
      z-index: 1;
      background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );

      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
    }

    &:before {
      content: ${faded ? "''" : 'none'};
      z-index: 1;
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 40px;
      opacity: 0.5;
    }

    .slick-list .slick-center {
      padding: 0 ${px2rem(40)};
    }

    .slick-arrow,
    .slick-prev:before,
    .slick-prev,
    .slick-next,
    .slick-next:before {
      display: none;
    }

    .slick-slide img{
      width: 100%;
    }

    @media (max-width: 600px) {
      .slick-initialized .slick-slide .slick-list .slick-center {
        padding: 0 ${px2rem(40)};
      }
    }
  `}

  .slick-dots li button:hover, .slick-dots li button:focus {
    border: none;
    outline: none;
  }

  .slick-dots li.slick-active button:before {
    border: 4px solid var(--blue-medium);
    width: 14px;
    height: 14px;
    color: white;
    border-radius: 50px;
    margin-top: 14px;
  }

  .slick-dots li button:before {
    border: 4px solid #444444;
    width: 14px;
    height: 14px;
    color: white;
    border-radius: 50px;
    margin-top: 14px;

    :first-child() {
      margin-right: 10px;
    }

    :last-child() {
      margin-left: 10px;
    }

    :hover {
      border: 3px solid var(--blue-medium);
    }
  }
`;
