import React, { useEffect, useState } from 'react';
import { getCategories } from 'services/categories';
import { Category } from 'services/categories/models/category-interface';
import { Wrapper, Header, SectionTitle } from './style';
import ArticleList from './ArticleList';
import { LinkButton } from 'components';

const CategoryArticles: React.FC = () => {
  const [categories, setCategories] = useState<Array<Category>>();
  const getData = async () => {
    const categories = await getCategories();
    setCategories(categories);
  }

  useEffect(() => {
    getData()
  },[])

  return(
    <>
    {categories?.map((category: Category, key: number) => {
        const item = (key + 1) % 2 == 0;
        return(
          <Wrapper key={`news-${key}`}>
            <Header>
              <SectionTitle color={category?.info?.color}>{category.title}</SectionTitle>
              <LinkButton to="/artigos/artigos-comentados/listagem" variant="TextLink">
                Ver mais</LinkButton>
            </Header>
            <ArticleList reverse={item} theme={category?.info?.color} categoryId={category?.category_id} />
          </Wrapper>
        )
    })}
  </>
  );
}

export default CategoryArticles;

