import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getInfoByType } from 'services/info';
import Info from 'services/info/models/info-interface';
import getErrorMessage from 'helpers/errorMessages';
import { getValue } from 'utils/storage';
import { logotipo } from 'assets';
import SliderBullet from 'components/Slider/common/SliderBullet';

import {
  LinkButton,
  PlayerSambaVideos,
  MessageError,
  LoadingBg,
  Error,
} from 'components';
import Footer from 'components/Footer/Guest';
import Feature from './Feature';
import { SIGNUP } from 'routes/Constants';
import {
  articleNyTimes,
  atom,
  calendar,
  microphone,
  monitorPlay,
  newspaper,
  vetor_bg,
} from 'assets';
import {
  Container,
  About,
  VectorForm,
  Features,
  FooterContainer,
} from './style';
import { Link } from 'react-router-dom';

const Components: React.FC = () => {
  const [welcomeVideo, setWelcomeVideo] = useState<Array<Info>>();
  const [homeInfo, setHomeInfo] = useState<Info[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  var settings = {
    dots: true,
    infinite: true,
    speed: 1600,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    className: 'center',
    centerPadding: '0',
    autoplay: true,
    autoplaySpeed: 10000,
    arrow: false,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          dots: true,
          infinite: true,
          speed: 1600,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          className: 'center',
          centerPadding: '20px',
          autoplay: true,
          autoplaySpeed: 10000,
          arrow: false,
        },
      },
    ],
  };

  useEffect(() => {
    const getLadingpageContent = async () => {
      try {
        setError(false);
        setLoading(true);
        let homeInfos;
        let welcomeVideo
        const user = getValue<string>({ prop: 'exeltis-user' });
        if (user) {
          homeInfos = await getInfoByType('landing_medic');
          welcomeVideo = await getInfoByType('WELCOME_MEDIC')
        } else {
          const token = 'Bearer ' + process.env.REACT_APP_UNLOGGED_TOKEN;
          homeInfos = await getInfoByType('landing_medic', {
            Authorization: token,
            is_active: true,
          });
          welcomeVideo = await getInfoByType('WELCOME_MEDIC', {
            Authorization: token,
            is_active: true,
          });
        }

        setHomeInfo(homeInfos);
        setWelcomeVideo(welcomeVideo)
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        toast.error(errorMessage);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    getLadingpageContent();
  }, []);

  if (loading) return <LoadingBg />;
  if (error) return <Error type="LoadFail" />;

  const renderSlide = (banners: Info[] | undefined) => {
    if (!banners)
      return (
        <MessageError content="Sem imagens cadastradas no momento ou o serviço está indisponível, tente mais tarde." />
      );
    return banners.map((banner, key: number) => {
      const regex = /www.exeltison.com.br\/(noticias|podcast|videos|categoria|artigos|produtos)/i;
      const internal = banner?.info?.link?.match(regex);
      if (banner.info.link) {
        if (internal) {
          return (
            <a href={banner.info.link}>
              <img src={banner.info.image} alt="" key={`banner-${key}`} />
            </a>
          )
        } else {
          return (
            <a target="_blank" href={banner.info.link} rel="noreferrer">
              <img src={banner.info.image} alt="" key={`banner-${key}`} />
            </a>
          )
        }
      }
      return <img src={banner.info.image} alt="banner" key={key} />;
    });
  };

  return (
    <Container>
      <SliderBullet settings={settings}>{renderSlide(homeInfo)}</SliderBullet>
      <About>
        <div className="about-container">
          {welcomeVideo && welcomeVideo.length ? (
            <div className="samba-video">
              <PlayerSambaVideos
                contentReference={welcomeVideo[0].info.reference}
                controlsToEnable={[
                  'play',
                  'pause',
                  'quality',
                  'fullscreen',
                  'time',
                  'volume',
                ]}
                getEventListeners={() => { }}
              />
            </div>
          ) : (
            <>
              <div className="video-unavailable">
                <h3>Vídeo Indisponível</h3>
              </div>
            </>
          )}

          <div className="about-content-info">
            <h2>
              Sobre a <img src={logotipo} alt="Exeltis ON" />
            </h2>
            <p>
              <strong>Doutor(a)</strong>, a <strong>Exeltis On</strong> é uma
              plataforma criada especialmente para você, ginecologista e
              obstetra! <br />
              <br /> Aqui você irá encontrar aulas médicas realizadas com
              professores renomados, atualizações na ginecologia e obstetrícia,
              artigos comentados e científicos, podcasts e aulas de marketing
              digital focadas na sua especialidade, além dos lançamentos da{' '}
              <strong>Exeltis</strong> focados na saúde feminina.
            </p>
            <div className="containerButton">
              <LinkButton variant="Default" to={`${SIGNUP}`}>
                Inscreva-se
              </LinkButton>
            </div>
          </div>
        </div>
      </About>
      <VectorForm>
        <img src={vetor_bg} alt="vector" />
      </VectorForm>
      <Features>
        <div className="features-container">
          <div className="title">
            <h2>
              <strong>Conteúdos em diversos formatos</strong> para aprofundar
              seu conhecimento!
            </h2>
            <div className="container-btn">
              <LinkButton variant="White" to={`${SIGNUP}`}>
                Faça o seu cadastro e seja bem-vindo(a)!
              </LinkButton>
            </div>
          </div>
          <div className="features-content">
            <div className="feature-content-wrapper">
              <div className="feature-item">
                <Feature img={monitorPlay} title="Aulas médicas" />
              </div>
              <div className="feature-item">
                <Feature img={microphone} title="Podcast" />
              </div>
            </div>
            <div className="feature-content-wrapper">
              <div className="feature-item">
                <Feature img={articleNyTimes} title="Artigos comentados" />
              </div>
              <div className="feature-item">
                <Feature img={atom} title="Artigos científicos" />
              </div>
            </div>
            <div className="feature-content-wrapper">
              <div className="feature-item">
                <Feature img={newspaper} title="Notícias" />
              </div>
              <div className="feature-item">
                <Feature img={calendar} title="Agenda de eventos" />
              </div>
            </div>
          </div>
          <h3 className="signup-text">
            Faça o seu cadastro e seja bem-vindo(a)!
          </h3>
          <div className="container-btn-mobile">
            <LinkButton variant="White" to={`${SIGNUP}`}>
              Cadastrar
            </LinkButton>
          </div>
        </div>
      </Features>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </Container>
  );
};

export default Components;
