import React from 'react';
import { Spotlight, SpotlightImage, SpotlightDesc, SpotlightDescription, SpotlightTitle } from './style';
import { card_placeholder } from 'assets';
import { Link } from 'react-router-dom';
import Tag from 'components/Tags';
interface ISpolightCard{
  theme?: string
  data?: any
  to?: string
  external?: string
  tag?: string
}
const SpolightCard: React.FC<ISpolightCard> = ({ theme, data, to, external, tag }) => { 

  if(external){
    return(
      <Spotlight>
        <a href={external} title={data?.title} target="_blank" rel="noreferrer">
          <img src={data?.thumbnail } alt=""/>
          <SpotlightImage color={theme}>
            <SpotlightDesc>
              <SpotlightTitle>{data?.title}</SpotlightTitle>
              <SpotlightDescription>{data?.description}</SpotlightDescription>
            </SpotlightDesc>
          </SpotlightImage>
        </a>
      </Spotlight>  
    )
  }else{
    return(
      <Spotlight>
        <Link to={to ? to : '' } title={data?.title}>
          <img src={data?.thumbnail } alt=""/>
          <SpotlightImage color={theme}>
            <SpotlightDesc>
              {tag &&  <Tag type={tag} theme={theme}/>}
              <SpotlightTitle>{data?.title}</SpotlightTitle>
              <SpotlightDescription>{data?.description}</SpotlightDescription>
            </SpotlightDesc>
          </SpotlightImage>
        </Link>
      </Spotlight>  
    )
  }
}

export default SpolightCard