import styled, { css } from 'styled-components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slider from 'react-slick';
import { px2rem } from 'styles/globals';

interface ISlider {
  faded: boolean;
}
export const Container = styled.div`
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (width: 1024px) {
    max-width: 40%;
  }

  @media (min-width: 601px) and (max-width: 1026px) {
    margin-bottom: 100px;
    width: 100%;
    .slick-dots li.slick-active button:before {
      border: 3px solid var(--blue-medium);
      width: 13px;
      height: 13px;
      color: white;
      border-radius: 50px;
      margin-top: 14px;
    }

    .slick-dots li button:before {
      border: 3px solid #444444;
      width: 13px;
      height: 13px;
      color: white;
      border-radius: 50px;
      margin-top: 14px;

      :hover {
        border: 3px solid var(--blue-medium);
      }
    }
  }

  @media (max-width: 600px) {
    padding: 0;
    overflow-x: clip;
    margin-top: 30px;
    margin-bottom: 55px;

    .slick-dots li.slick-active button:before {
      border: 3px solid var(--blue-medium);
      width: 13px;
      height: 13px;
      color: white;
      border-radius: 50px;
      margin-top: 14px;
    }

    .slick-dots li button:before {
      border: 3px solid #444444;
      width: 13px;
      height: 13px;
      color: white;
      border-radius: 50px;
      margin-top: 14px;

      :first-child() {
        margin-right: 10px;
      }

      :last-child() {
        margin-left: 10px;
      }

      :hover {
        border: 3px solid var(--blue-medium);
      }
    }
  }
`;

export const SlickWrapper = styled(Slider)<ISlider>`
  ${({ faded }) => css`
    position: relative;
    width: 100%;
    z-index: 0;

    .slick-track {
      display: flex !important;
    }

    .slick-arrow,
    .slick-prev:before,
    .slick-prev,
    .slick-next,
    .slick-next:before {
      display: none;
      cursor: auto;

      @media screen and (max-width: 997px) {
        display: none !important;
      }
    }

    @media (max-width: 600px) {
      .slick-initialized .slick-slide .slick-list .slick-center {
        padding: 0 ${px2rem(40)};
      }
    }
  `}

  .slick-dots li button:hover, .slick-dots li button:focus {
    border: none;
    outline: none;
  }

  .slick-dots li.slick-active button:before {
    border: 4px solid var(--blue-medium);
    width: 14px;
    height: 14px;
    color: white;
    border-radius: 50px;
    margin-top: 14px;
  }

  .slick-dots li button:before {
    border: 4px solid #444444;
    width: 14px;
    height: 14px;
    color: white;
    border-radius: 50px;
    margin-top: 14px;

    :first-child() {
      margin-right: 10px;
    }

    :last-child() {
      margin-left: 10px;
    }

    :hover {
      border: 3px solid var(--blue-medium);
    }
  }
`;
