import axios from 'axios';
import { User } from 'services/user/models/user-interface';

export interface LoginData {
  email: string;
  password: string;
}

interface IResponseLogin {
  user: User;
  api_token: string;
  ip: string;
}

interface IResponseIP {
  ip?: string;
}

const login = async (data: LoginData): Promise<IResponseLogin> => {
  const loginResponse = (
    await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/cognito/login`,
      data,
    )
  ).data;

  return loginResponse;
};

const getIp = async (): Promise<IResponseIP> => {
  const response = (await axios.get('https://api.ipify.org/?format=json')).data;
  return response;
};

export { login, getIp };
