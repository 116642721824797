import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin-top: 40px;
  @media (max-width: 320px) {
    margin: 20px 10px 0 10px;
  }

  .container-btns {
    display: flex;
    align-items: center;
    gap: 40px;
    justify-content: flex-end;
    margin-top: 80px;

    @media (max-width: 500px) {
      margin-top: 50px;
    }
  }
`;

export const CodeInput = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 16px;

  input[type='number'] {
    text-align: center;
    width: 64px;
    height: 80px;
    border: 1.5px solid var(--grey-medium);
    color: var(--grey-darker);
    outline: none;
    border-radius: 8px;
    position: relative;
    z-index: 5;
    @media (max-width: 280px) {
      width: 20px !important;
      height: 40px !important;
    }

    :focus {
      border: 2px solid var(--blue-medium);
    }
    @media (min-width: 998px){
      font-size: 38px;
    }
  }

  @media (max-width: 500px) {
    justify-content: space-around;
    gap: 10px;
    input[type='number'] {
      width: 30px;
      height: 40px;
    }
  }

  /* Chrome, Safari, Edge, Opera */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const Subtitle = styled.h3`
  font-size: 1.5rem;
  margin-top: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: var(--blue-darkness);

  @media (max-width: 500px) {
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 22px;
    color: var(--blue-darkness);
    line-height: 32px;
  }
`;

export const Description = styled.p`
  color: var(--black-medium);
  font-size: 16px;
  line-height: 27px;
  font-weight: 600;
  margin-top: 40px;

  span {
    font-size: 16px;
    font-weight: 800;
    margin-left: 4px;
  }
`;
