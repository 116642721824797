import httpClient from 'http-client';
import ISearchType from './models/search-type-interface';
import ISearchValue from './models/search-value-interface ';

const getSearch = async (
  searchValue: string,
  categoryId?: string | '',
): Promise<ISearchValue> => {
  const search = (
    await httpClient.get(
      `/home-info/search?search=${searchValue}${categoryId ? `&category_id=${categoryId}` : ``}`,
    )
  ).data;

  return search;
};

const getSearchByType = async (
  type: string,
  value?: string,
): Promise<ISearchType[]> => {
  const info = (
    await httpClient.get(
      `/home-info/type?type=${type}${value && `&search=${value}`}`,
    )
  ).data;

  return info;
};

export { getSearchByType, getSearch };
