import { AuthProvider } from './auth/AuthProvider';
import { ProductProvider } from './products/ProductProvider';

const AppProvider = ({ children }: { children: JSX.Element }) => (
  <AuthProvider>
    <ProductProvider>{children}</ProductProvider>
  </AuthProvider>
);

export default AppProvider;
