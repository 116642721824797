import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useProductContext } from 'providers/products/ProductProvider';
import { BreadCrumb, Template, Error, LoadingBg } from 'components';
import { m_products, d_products } from 'assets';
import { HOME } from 'routes/Constants';
import IProduct, {
  ProductType,
} from 'services/product/models/product-interface';
import Supplement from './Supplement';
import Medicine from './Medicine';
import { Container, Section } from './style';
import _ from 'lodash';

interface SectionProductData {
  type: ProductType;
  data: IProduct[];
  title: string;
}

type TypeTitle = Record<ProductType, string>;
const getTitle = (type: ProductType) => {
  const typeTitle: TypeTitle = {
    supplement: 'Suplementos',
    medicine: 'Medicamentos',
  };

  return typeTitle[type] ?? '';
};

const grupProductByType = (products: IProduct[]): SectionProductData[] =>
  _(products)
    .groupBy(product => product.type)
    .map((value, key) => {
      const type = key as ProductType;
      return {
        type,
        title: getTitle(type),
        data: value,
      };
    })
    .value();

const Products: React.FC = () => {
  const { products, isLoading, hasError, getProducts } = useProductContext();
  useEffect(() => {
    async function getAllProducts() {
      await getProducts();
    }

    getAllProducts();
  }, []);

  const renderSections = (sectionProductItem: SectionProductData) => (
    <Section>
      <h2>{sectionProductItem.title}</h2>
      <div className="product-content">
        {sectionProductItem.data.map(product => renderProducts(product))}
      </div>
    </Section>
  );

  const renderProducts = (product: IProduct) =>
    product.type === 'supplement' ? (
      <Supplement product={product} />
    ) : (
      <Medicine product={product} />
    );

  if (isLoading) return <LoadingBg />;
  if (hasError) return <Error type="LoadFail" />;
  return (
    <Template
      banner={{
        title: 'Produtos',
        imgDesktop: d_products,
        imgMobile: d_products,
      }}
      breadcrumb={
        <BreadCrumb
          crumbs={[<Link to={`${HOME}`}>HOME</Link>, <span>Produtos</span>]}
        />
      }
    >
      <Container>
        {grupProductByType(products).map(item => renderSections(item))}
      </Container>
    </Template>
  );
};

export default Products;
