import httpClient from 'http-client';
import Contact from './models/contact-interface';

const getAllContacts = async (
  filters?: object,
  headers?: object,
): Promise<Contact[]> => {
  const response = await httpClient.get<Contact[]>('/contacts', {
    params: filters,
    headers,
  });

  return (response || { data: [] }).data;
};

const getContact = async (contact_id: string): Promise<Contact> => {
  const contact = await httpClient.get<Contact>(`/contacts/${contact_id}`);

  return contact.data;
};

export { getAllContacts, getContact };
