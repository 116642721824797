import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, PlayerSambaVideos } from 'components';
import httpClient from 'http-client';

import { Previous } from 'assets';

import LiveChat from './components/LiveChat';
import LiveFooter from './components/LiveFooter';

import IEvents from 'services/events/models/events-interface';

import { GlobalStyle, Container, NoVideo } from './style';

const Live: React.FC = () => {
  const [liveReference, setLiveReference] = useState<string>('');
  const [liveData, setLiveData] = useState<IEvents | undefined>();
  const [liveStatus, setLiveStatus] = useState<any>();
  const history = useHistory();

  const { live_id } = useParams<{
    live_id: string;
  }>();

  const getLiveData = async (): Promise<IEvents> => {
    const data = (await httpClient.get(`/events/${live_id}`)).data;

    const liveReference = data.live_id
      ? await httpClient.get(`/lives/${data.live_id}`)
      : null;
    setLiveReference(liveReference?.data.channel_sv_id);
    setLiveStatus(liveReference?.data)
    setLiveData(data);
    return data;
  };

  useEffect(() => {
    if (live_id) {
      getLiveData();
    }
  }, [live_id]);
  return (
    <Container>
      <GlobalStyle />
      {/* <Previous onClick={() => history.goBack()} className="back-button" /> */}
      <div className="player-footer">
        {(liveReference && liveStatus?.status !== 'STOPPED') && (
          <div className="video">
            <PlayerSambaVideos
              contentReference={`${process.env.REACT_APP_PLAYER_HASH}/live/${liveReference}`}
              controlsToEnable={[
                'play',
                'pause',
                'quality',
                'fullscreen',
                'time',
                'volume',
              ]}
              getEventListeners={() => { }}
            />
          </div>
        )}
        {liveStatus?.status === 'STOPPED' && (
          <NoVideo>
            <img src={liveData?.thumbnail} alt="thumbnail" />
            <div>
              <p>Transmissão encerrada.</p>
              <Button variant="DefaultDark" onClick={() => history.push(`/eventos/${liveData?.event_id}`)}>
                Voltar para Agenda
              </Button>
            </div>
          </NoVideo>
        )}
        {liveData && (
          <LiveFooter
            title={liveData.title}
            subtitle={liveData.subtitle}
            speakers={liveData.speakers}
          />
        )}
      </div>
      <LiveChat live_id={liveData?.live_id} />
    </Container>
  );
};

export default Live;
