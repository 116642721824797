import React from 'react';
import { Card } from './style';

interface SpeakerType {
  speaker: {
    name: string;
    bio: string;
    avatar: string;
  }
}

const SpeakerCard: React.FC<SpeakerType> = ({ speaker }) => {

  return (
    <Card>
      <div className="profile-wrapper">
        <div className="profile-inner-wrapper">
          <img src={speaker.avatar} alt="Profile" />
        </div>
      </div>
      <div className="name-description">
        <span className="name">{speaker.name}</span>
        <span className="description">{speaker.bio}</span>
      </div>
    </Card>
  );
}

export default SpeakerCard;
