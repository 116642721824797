import React from 'react';
import Product from 'services/product/models/product-interface';
import { getProductSupplementItemRoute } from 'routes/Constants';
import { Container } from './style';
import { LinkButton } from 'components';

type Props = {
  product: Product;
};

const SupplementCard: React.FC<Props> = ({ product }) => {
  const { product_id, info, title, subtitle, thumbnail } = product;

  return (
    <Container>
      <>
        <div>
          <img src={thumbnail} alt="produto regenesis" />
          <div className="card-product-content">
            <h3 style={{ color: info.color ?? 'black' }}>{title}</h3>
            <p>{subtitle}</p>
          </div>
        </div>
        <div className="card-product-button">
          <LinkButton
            to={getProductSupplementItemRoute(product_id)}
            variant="Outline"
          >
            Saiba mais
          </LinkButton>
        </div>
      </>
    </Container>
  );
};

export default SupplementCard;
