import React from 'react';
import { createPortal } from 'react-dom';
import { Icon } from 'components';
import { Body, ICloseOut, ICloseIn, BarModal } from './style';

type VariantPosition = 'Top' | 'Botton' | 'Center'

export type IModal = {
  modalOpen: boolean;
  closeModal(): void;
  positionClose: 'Out' | 'In';
  IconTheme: 'light' | 'dark';
  width?: {
    desktop: string;
    tablet: string;
  };
  position?: VariantPosition;
  withoutClose?: boolean
};

const Modal: React.FC<IModal> = ({
  modalOpen,
  children,
  closeModal,
  positionClose = 'Out',
  IconTheme = 'dark',
  width = { desktop: '100%', tablet: '100%' },
  position = "Center",
  withoutClose
}) => {
  if (!modalOpen) return null;

  return createPortal(
    <Body width={(width.desktop, width.tablet)} position={position}>
      <div className="closeOutStyled">
        {!withoutClose && positionClose === 'Out' && (
          <ICloseOut onClick={closeModal}>
            {IconTheme === 'dark' ? (
              <Icon name="close_black" size={20} />
            ) : (
              <Icon name="close" size={20} />
            )}
          </ICloseOut>
        )}
      </div>
      <div className="container-content-modal">
        <div className="content">
          {positionClose === 'In' && (
            <ICloseIn>
              {IconTheme === 'dark' ? (
                <div className="containerIcon" onClick={closeModal}>
                  <Icon name="close_black" size={20} />
                </div>
              ) : (
                <div className="containerIcon" onClick={closeModal}>
                  <Icon name="close" size={15} />
                </div>
              )}
            </ICloseIn>
          )}
          {children}
        </div>
      </div>
    </Body>,
    document.body,
  );
};

export default Modal;
