import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { Link } from 'react-router-dom';

type VariantThemes =
  | 'Outline'
  | 'White'
  | 'DefaultDark'
  | 'Default'
  | 'TextLink'
  | 'TextLinkBrown'
  | 'TextLinkPinkLight'
  | 'TextLinkPinkDark';

export interface IButtonStyle {
  variant: VariantThemes;
  reverseIcon?: boolean;
  active?: boolean;
}

export const ButtonUI = styled.button<IButtonStyle>`
  ${props =>
    props.reverseIcon === true &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row-reverse;
    `}

  ${props =>
    props.variant === 'White' &&
    css`
      background-color: #f5f8fa;
      color: var(--blue-medium);
      border: 2px solid var(--blue-medium);
      font-weight: bolder;
      text-decoration: none;
      width: 100%;
      border-radius: 50px;
      outline: none;
      padding: 15px;
      font-size: var(--font-medium);
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: ${lighten(0.1, '#12428A')};
        transition: 0.4s;
        color: white;
      }
    `}

  ${props =>
    props.variant === 'Default' &&
    css`
      background-color: var(--blue-medium);
      color: white;
      font-weight: bold;
      text-decoration: none;
      width: 100%;
      border-radius: 50px;
      outline: none;
      border: none;
      padding: 15px;
      font-size: var(--font-medium);
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: ${lighten(0.1, '#12428A')};
        transition: 0.4s;
      }
    `}

  ${props =>
    props.variant === 'DefaultDark' &&
    css`
      background-color: var(--blue-darkness);
      color: white;
      font-weight: 900;
      text-decoration: none;
      width: 100%;
      border-radius: 50px;
      outline: none;
      border: none;
      padding: 15px;
      font-size: var(--font-medium);
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: ${lighten(0.1, '#081D3D')};
        transition: 0.4s;
      }
    `}

  ${props =>
    props.variant === 'Outline' &&
    css`
      border: 1px solid var(--blue-darkness);
      background-color: transparent;
      color: var(--blue-darkness);
      font-weight: bold;
      text-decoration: none;
      padding: 15px;
      font-size: var(--font-medium);
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      width: 100%;
      border-radius: 50px;

      &:hover {
        background-color: var(--blue-darkness);
        color: white;
        transition: 0.4s;
      }
    `}

    img {
    margin-left: 10px;
    margin-right: 10px;
    max-width: 22px;
    width: 100%;
    height: auto;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: var(--blue-medium);
      color: white;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: grey;
      color: white;
      border-color: grey;

      :hover {
        background-color: grey;
        color: white;
        cursor: not-allowed;
      }
    `}
`;

export const ButtonLink = styled(Link)<IButtonStyle>`
  ${props =>
    props.reverseIcon === true &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row-reverse;
    `}

  ${props =>
    props.variant === 'White' &&
    css`
      background-color: #f5f8fa;
      color: var(--blue-medium);
      border: 2px solid var(--blue-medium);
      font-weight: bolder;
      text-decoration: none;
      width: 100%;
      border-radius: 50px;
      outline: none;
      padding: 15px;
      font-size: var(--font-medium);
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: ${lighten(0.1, '#12428A')};
        transition: 0.4s;
        color: white;
      }
    `}

  ${props =>
    props.variant === 'Default' &&
    css`
      background-color: var(--blue-medium);
      color: white;
      font-weight: bold;
      text-decoration: none;
      width: 100%;
      border-radius: 50px;
      outline: none;
      border: none;
      padding: 15px;
      font-size: var(--font-medium);
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: ${lighten(0.1, '#12428A')};
        transition: 0.4s;
      }
    `}

  ${props =>
    props.variant === 'DefaultDark' &&
    css`
      background-color: var(--blue-darkness);
      color: white;
      font-weight: 900;
      text-decoration: none;
      width: 100%;
      border-radius: 50px;
      outline: none;
      border: none;
      padding: 15px;
      font-size: var(--font-medium);
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: ${lighten(0.1, '#081D3D')};
        transition: 0.4s;
      }
    `}

  ${props =>
    props.variant === 'Outline' &&
    css`
      border: 1px solid var(--blue-darkness);
      background-color: transparent;
      color: var(--blue-darkness);
      font-weight: bold;
      text-decoration: none;
      padding: 15px;
      font-size: var(--font-medium);
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      width: 100%;
      border-radius: 50px;

      &:hover {
        background-color: var(--blue-darkness);
        color: white;
        transition: 0.4s;
      }
    `}

      ${props =>
    props.variant === 'TextLink' &&
    css`
      padding-bottom: 10px;
      color: var(--blue-medium);
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      border-bottom: 2px solid var(--blue-medium);

      &:hover {
        color: ${lighten(0.1, '#081D3D')};
        transition: 0.4s;
        border-bottom: 2px solid ${lighten(0.1, '#081D3D')};
      }
    `}


     ${props =>
    props.variant === 'TextLinkBrown' &&
    css`
      padding-bottom: 10px;
      color: var(--brown-medium);
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      border-bottom: 2px solid var(--brown-medium);

      &:hover {
        color: ${lighten(0.1, '#674E15')};
        transition: 0.4s;
        border-bottom: 2px solid ${lighten(0.1, '#674E15')};
      }
    `}


    ${props =>
    props.variant === 'TextLinkPinkLight' &&
    css`
      padding-bottom: 10px;
      color: var(--pink-dark);
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      border-bottom: 2px solid var(--pink-dark);

      &:hover {
        color: ${lighten(0.1, '#A84D66')};
        transition: 0.4s;
        border-bottom: 2px solid ${lighten(0.1, '#A84D66')};
      }
    `}

     ${props =>
    props.variant === 'TextLinkPinkDark' &&
    css`
      padding-bottom: 10px;
      color: var(--pink-darker);
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      border-bottom: 2px solid var(--pink-darker);

      &:hover {
        color: ${lighten(0.1, '#990054')};
        transition: 0.4s;
        border-bottom: 2px solid ${lighten(0.1, '#990054')};
      }
    `}

    img {
    margin-left: 10px;
    margin-right: 10px;
    max-width: 22px;
    width: 100%;
    height: auto;
  }
`;
