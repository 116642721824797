import { useSelectedProductById } from 'hooks/useSelectedProductById';
import { useParams } from 'react-router-dom';
import { Error, LoadingBg } from 'components';
import MedicineContent from './Components';

const MedicinePage: React.FC = () => {
  const { medicineId } = useParams<{
    medicineId: string;
  }>();

  const { isLoading, hasError, product } = useSelectedProductById(medicineId);

  if (isLoading) return <LoadingBg />;
  if (hasError) return <Error type="LoadFail" />;
  if (product === null) return <Error type="LoadFail" />;

  return <MedicineContent product={product} />;
};

export default MedicinePage;
