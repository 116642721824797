import React from 'react';
import Contact from 'services/contact/models/contact-interface';
import {
  Template,
  BreadCrumb,
  Error,
  LoadingBg,
  MessageError,
} from 'components';
import { useContacts, useCardContact } from 'hooks';
import { Link } from 'react-router-dom';
import { d_contact, m_contact } from 'assets';
import { LADINGPAGE } from 'routes/Constants';
import { Form, Feature } from './components';
import { SectionForm, SectionContact, Container } from './style';
import Card from './Card/';

const Component: React.FC = () => {
  const [loadingContact, errorContact, contacts] = useContacts();

  const [loadingContent, errorContent, cardContact] = useCardContact();

  const loading = loadingContact || loadingContent;
  const error = errorContact || errorContent;

  const renderCardsContacts = (items: Contact[]) => {
    if (items.length === 0)
      return (
        <MessageError content="Sem contatos cadastrados da equipe no momento." />
      );

    return items.map(item => (
      <Card key={item.contact_id.toString()} card_contact={item} />
    ));
  };

  if (loading) return <LoadingBg />;
  if (error) return <Error type="LoadFail" />;

  return (
    <Template
      banner={{
        title: 'Contato',
        imgDesktop: d_contact,
        imgMobile: m_contact,
      }}
      breadcrumb={
        <BreadCrumb
          crumbs={[
            <Link to={`${LADINGPAGE}`}>HOME</Link>,
            <span>Contato</span>,
          ]}
        />
      }
    >
      <SectionForm>
        <h2>Entre em contato</h2>
        <p className="contact-subtitle">
          Para dúvidas gerais ou relatos de reações adversas com o uso dos produtos da Exeltis, entre em contato com o SAC abaixo.
        </p>
        <Container>
          <div className="contact-features">
            <div className="contact-features-container">
              {contacts.map(item => (
                <Feature key={item.text.toString()} contact={item} />
              ))}
            </div>
          </div>
          <div className="contact-form">
            <Form />
          </div>
        </Container>
      </SectionForm>
      <SectionContact>
        <h2>Fale com nossa equipe</h2>
        <p className="contact-subtitle">
          Você pode entrar em contato direto com nossa equipe de campo.
        </p>
        <div className="contact-container-cards">
          {renderCardsContacts(cardContact)}
        </div>
      </SectionContact>
    </Template>
  );
};

export default Component;
