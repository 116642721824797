import styled, { css } from 'styled-components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slider from 'react-slick';
import { px2rem } from 'styles/globals';

interface ISlider {
  faded: boolean;
}

export const SlickWrapper = styled(Slider)<ISlider>`
  ${({ faded }) => css`
    position: relative;

    width: ${faded ? '105%' : '100%'};
    z-index: 0;

    .slick-track {
      display: flex !important;
    }

    &:after {
      content: ${faded ? "''" : 'none'};
      z-index: 1;
      background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );

      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 20%;
    }

    &:before {
      content: ${faded ? "''" : 'none'};
      z-index: 1;
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 40px;
      opacity: 0.5;
    }

    .sliderNav {
      position: absolute;
      display: flex;
      align-items: center;
      top: 50%;
      transform: translateY(-50%);
      width: 15px;
      z-index: 2;
      cursor: pointer;

      svg {
        font-size: 3rem;
      }

      &--prev {
        left: 1rem;
      }
      &--next {
        left: 97%;
      }
    }

    .slick-list .slick-center {
      padding: 0 ${px2rem(40)};
    }

    @media (max-width: 480px) and (orientation: portrait) {
      width: 100%;

      &:before {
        content: none;
      }
    }
  `}
`;
