import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Comments,
  Icon,
  LinkButton,
  LoadingBg,
  Modal,
  PlayerSambaVideos,
} from 'components';
import {
  article_card_placeholder,
  card_placeholder,
  comment,
  heart,
  monitorPlay,
} from 'assets';
import { useModal } from 'hooks';
import ArticleSession from '../ArticleSession';
import { SpolightHeader, Content, WrapperImage } from './styles';
import { Section, Header, ModalHeader } from '../../styles';
import {
  getContentByCategoryType,
  getContentByCategoryTypeTag,
} from 'services/categories';
import { useParams } from 'react-router';
import { Row } from '../../styles';
import {
  Article,
  ArticleDescription,
  ArticleResume,
  ArticleTitle,
} from 'components/Cards/ArticleCard/style';
import ITags from 'services/tags/models/tags-interface';
import { engagementRecord } from 'services/user';
import {
  updateProgressSingleContent,
  finishSingleContent as finishSingleContentService,
  startSingleContent as startSingleContentService,
  getContentInfo,
} from 'services/contents';

interface IHighlightCarrousel {
  theme?: string;
  type: string;
  category: string;
  title?: string;
  tag?: ITags | null;
}

interface PlayerEventListener {
  event: string;
  eventParam?: any;
  duration?: any;
}

interface ExtendedWindow extends Window {
  hasStartedContent: boolean;
  hasFinishedContent: boolean;
}

declare let window: ExtendedWindow;

const HighlightCarrousel: React.FC<IHighlightCarrousel> = ({
  theme,
  type,
  title,
  category,
  tag,
}) => {
  const [modalOpen, openModal, closeModal] = useModal();
  const [content, setContent] = useState<any>();
  const [selectedContent, setSelectedContent] = useState<any>();
  const [currentContent, setCurrentContent] = useState<any>();
  const [progress, setProgress] = useState<number>(0);
  const [lastWatchedTime, setLastWatchedTime] = useState<number>(0);
  const { categoryId } = useParams<{
    categoryId: string;
  }>();
  const getData = useCallback(async () => {
    if (!tag) {
      const contents = await getContentByCategoryType(categoryId, type);
      setContent(contents);
      setSelectedContent(contents[0]?.content_id);
    } else {
      const contents = await getContentByCategoryTypeTag(
        categoryId,
        type,
        tag?.tag_id,
      );
      setContent(contents);
      setSelectedContent(contents[0]?.content_id);
    }
  }, [categoryId, tag, type]);

  const getRowContent = (data: any) => {
    return (
      <>
        {data?.slice(0, 4)?.map((item: any, key: number) => (
          <Article
            key={`article-${key}`}
            onClick={() => setSelectedContent(item?.content_id)}
            isActive={selectedContent === item?.content_id}
            color={item?.categories[0]?.info?.color}
          >
            <WrapperImage>
              <img
                src={
                  item?.thumbnail ? item?.thumbnail : article_card_placeholder
                }
                alt=""
              />
            </WrapperImage>
            <ArticleDescription>
              <ArticleTitle>{item?.title}</ArticleTitle>
              <ArticleResume>{item?.description}</ArticleResume>
            </ArticleDescription>
          </Article>
        ))}
      </>
    );
  };

  useEffect(() => {
    window.hasStartedContent = false;
    window.hasFinishedContent = false;
    getData();
  }, [categoryId, tag]);

  useEffect(() => {
    const getCurrentContent = async () => {
      if (selectedContent) {
        const content = await getContentInfo(selectedContent);

        setCurrentContent(content);
      }
    };
    getCurrentContent();
  }, [selectedContent]);

  const currentSpotContent =
    content &&
    content
      ?.filter((content: any) => content?.content_id === selectedContent)
      .shift();

  const getEventListeners = async (player: PlayerEventListener) => {
    if (
      currentContent &&
      player.event === 'onStart' &&
      currentContent.type === 'PODCAST'
    ) {
      await engagementRecord(currentContent.content_id, 'PODCAST');
    }

    if (currentContent && currentContent.content_id) {
      switch (player.event) {
        case 'onProgress':
          if (hasReachedCompletionTime(player.duration, player.eventParam)) {
            await finishContent();
          }
          updateWatchTime(player);
          break;

        case 'onStart':
          startContent();
          break;

        case 'onFinish':
          goToNextContent();
          break;

        default:
          break;
      }
    }
  };

  const updateContentProgress = useCallback(
    async (time: number | null) => {
      if (time && time > 0) {
        await updateProgressSingleContent({
          content_id: selectedContent,
          content_view: time,
        });
      }
    },
    [selectedContent],
  );

  const resumeTime = useMemo(() => {
    setProgress(0);
    if (!currentContent) {
      return false;
    }
    if (currentContent?.content_user) {
      setLastWatchedTime(Number(currentContent.content_user.content_view));
      return Number(currentContent?.content_user.content_view);
    }

    return false;
  }, [currentContent]);

  useEffect(() => {
    if (progress >= lastWatchedTime + 30) {
      setLastWatchedTime(progress);
      updateContentProgress(progress);
      return;
    }
    if (progress < lastWatchedTime) {
      if (progress > 1) {
        setLastWatchedTime(progress);
        updateContentProgress(progress);
        return;
      }
      setLastWatchedTime(0);
      updateContentProgress(null);
    }
  }, [lastWatchedTime, progress, updateContentProgress]);

  const hasReachedCompletionTime = (duration: number, currentTime: number) => {
    const totalDurationInSecs = duration;
    const completionRate = 0.9;

    const completionTime = totalDurationInSecs * completionRate;
    return currentTime >= completionTime;
  };

  const finishContent = async () => {
    if (currentContent) {
      if (
        !window.hasFinishedContent &&
        !currentContent.content_user.finish_at
      ) {
        try {
          window.hasFinishedContent = true;
          await finishSingleContentService(currentContent.content_id);
          currentContent.content_user.finish_at = true;
        } catch {
          window.hasFinishedContent = false;
        }
      }
    }
  };

  const updateWatchTime = useCallback((player: PlayerEventListener) => {
    const { event, eventParam } = player;
    if (event === 'onProgress') {
      setProgress(eventParam);
    }
    return null;
  }, []);

  const startContent = async () => {
    if (currentContent) {
      if (!window.hasStartedContent && !currentContent.content_user) {
        try {
          window.hasStartedContent = true;
          currentContent.alreadyStarted = true;
          await startSingleContentService(currentContent.content_id);
        } catch {
          window.hasStartedContent = false;
          currentContent.alreadyStarted = false;
        }
      }
    }
  };

  const goToNextContent = async () => {};

  if (!content) return null;

  if (content?.length <= 0) return null;

  return (
    <Section>
      <Modal
        modalOpen={modalOpen}
        closeModal={closeModal}
        positionClose="Out"
        IconTheme="light"
      >
        <ModalHeader>
          <Icon name="comment" color="#081D3D" size={30} />
          <h2>Comentários</h2>
        </ModalHeader>
        <div>
          <Comments contentId={selectedContent} />
        </div>
      </Modal>
      <div>
        <Header>
          <img src={monitorPlay} alt="" />
          <h3>
            {title} {content && `(${content?.length})`}
          </h3>
        </Header>
        <Content>
          <div className="video">
            <PlayerSambaVideos
              contentReference={currentContent?.reference}
              controlsToEnable={[
                'play',
                'pause',
                'quality',
                'fullscreen',
                'time',
                'volume',
              ]}
              getEventListeners={getEventListeners}
              resume={resumeTime}
            />
          </div>
          <div>
            <SpolightHeader>
              <div>
                <h3>{currentContent?.title}</h3>
                <p>{currentContent?.description}</p>
              </div>
              <button type="button" onClick={openModal}>
                <span className="mobile">Comentários</span>
                <img src={comment} alt="" />
              </button>
              {/* <button>
                  <img src={heart} alt=""/>
              </button>*/}
            </SpolightHeader>
            <Row>{getRowContent(content)}</Row>
          </div>
          {content.length > 4 && (
            <LinkButton
              //style={content.length > 4 ? {} : {}}
              variant="Outline"
              to={`/${title?.toLowerCase()}/listagem/${categoryId}`}
              typeIcon="more"
              reverseIcon={true}
            >
              Ver mais
            </LinkButton>
          )}
        </Content>
      </div>
    </Section>
  );
};

export default HighlightCarrousel;
