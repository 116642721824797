import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Back, BackText, Loading, Error, Input, LoadingBg, Modal } from 'components';
import { logotipo } from 'assets';
import * as Style from '../../style';
import { FormContainer, CodeInput, Subtitle, Description } from './style';
import { toast } from 'react-toastify';
import smoothscroll from 'smoothscroll-polyfill';
import {
  setForgotPassword as forgotPasswordService,
  setConfirmPassword as confirmPasswordService,
  verifyUser,
} from 'services/user';
import { LADINGPAGE, LOGIN } from 'routes/Constants';
import { EditInfo } from 'pages/Profile/EditPassword/style';
import { useModal } from 'hooks';

smoothscroll.polyfill();

const ResetPassword: React.FC = () => {
  const history = useHistory();
  const [verifyCode, setVerifyCode] = useState<string>('');
  const [showPasswordInput, setShowPasswordInput] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [errorPassword, setErrorPassword] = useState<string>('');
  const [errorRepeatPassword, setErrorRepeatPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error] = useState<boolean>(false);
  const [isModalExported, openModalExported, closeModalExported] =
    useModal();

  const handleChangeInputCode = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ): void => {
    if (event.target.value === '') {
      const removeChar = verifyCode.slice(0, index);

      setVerifyCode(removeChar);
      return;
    }

    setVerifyCode(verifyCode + event.target.value);
    document.getElementById(`input-verify-code-number-${index + 1}`)?.focus();

    if (index === 5) {
      document.getElementById(`input-verify-code-number-${index}`)?.blur();
    }
  };

  const handlePasswordReset = async (): Promise<void> => {
    if (newPassword !== repeatPassword) {
      setErrorPassword('As senhas não se correspondem, informe senhas iguais.');
      setErrorRepeatPassword(
        'As senhas não se correspondem, informe senhas iguais.',
      );

      return;
    }

    if (newPassword.length < 8) {
      setErrorPassword('A senha deve ter no mínimo 8 caracteres.');
      setErrorRepeatPassword('A senha deve ter no mínimo 8 caracteres.');

      return;
    }

    if (!verifyCode) {
      toast.error('Preencha o código de verificação');
      return;
    }

    const data = {
      email,
      password: newPassword,
      code: verifyCode
    };

    await confirmPasswordService(data)
      .then(response => {
        if (response) {
          toast.success('Senha alterada com sucesso');
          history.push(`${LOGIN}`);
        }
      })
      .catch(() => {
        toast.error(
          '⚠ Algo deu errado ao salvar sua nova senha. Tente novamente.',
        );
      });
  };

  const sendVerifyCodeToEmail = async (): Promise<void> => {
    if (!email || !email.replace(/[!@#$%¨&()_+=~^<>*|"]/g, '').trim()) {
      toast.error('Preencha o campo Email');
      return;
    }

    const { email_exists, is_exported, had_logged, is_confirmed } = await verifyUser(email);
    if (email_exists) {
      if (email_exists && is_exported && had_logged && !is_confirmed) {
        openModalExported();
      } else {
        (await forgotPasswordService(email).then(response => {
          if (response) {
            setShowPasswordInput(true);
          }
        }))
      }
    } else {
      toast.error('Email inválido!');
    }
  };

  if (loading) {
    return <LoadingBg />;
  }

  if (error) {
    return <Error type="LoadFail" />;
  }

  function renderHandlePasswordReset() {
    return (
      <>
        <Style.Title>Atualize sua senha</Style.Title>
        <Subtitle>Código de verificação</Subtitle>
        <FormContainer>
          <CodeInput>
            {Array(6)
              .fill(6)
              .map((_, index) => (
                <input
                  key={index}
                  type="number"
                  name=""
                  id={`input-verify-code-number-${index}`}
                  value={verifyCode.charAt(index)}
                  onChange={event => handleChangeInputCode(event, index)}
                />
              ))}
          </CodeInput>
        </FormContainer>
        <Style.Form>
          <Style.Label style={{ marginTop: '30px' }}>
            <Input
              label={'Nova senha'}
              type="password"
              onChange={event => setNewPassword(event.target.value)}
              onFocus={() => setErrorPassword('')}
              value={newPassword}
              id={'new-password-input'}
              alertMessage="A senha precisa conter no mínimo 8 caracteres, incluindo letras maíusculas, minúsculas e números"
            >
              {errorPassword && <span>{errorPassword}</span>}
            </Input>

            <Input
              label={'Confirme a nova senha'}
              type="password"
              onChange={event => setRepeatPassword(event.target.value)}
              onFocus={() => setErrorRepeatPassword('')}
              value={repeatPassword}
              id={'repeat-password-input'}
            >
              {errorRepeatPassword && <span>{errorRepeatPassword}</span>}
            </Input>
            <div className="container-btns">
              <Button variant="Default" onClick={handlePasswordReset}>
                Enviar
              </Button>
            </div>
          </Style.Label>
        </Style.Form>
      </>
    );
  }

  function renderSendVerifyCodeToEmail() {
    return (
      <>
        <Style.Title>Recuperação de senha</Style.Title>
        <Description>
          Insira seu email para receber o código de verificação. Verifique o
          código também na sua caixa de spam.
        </Description>
        <Style.Form>
          <Style.Label>
            <Input
              type="email"
              id="email-input"
              value={email}
              onChange={event => setEmail(event.target.value)}
              label="E-mail"
            />
            <div className="container-btns">
              <Button variant="Default" onClick={sendVerifyCodeToEmail}>
                Enviar
              </Button>
            </div>
          </Style.Label>
        </Style.Form>
      </>
    );
  }

  return (
    <Style.Wrapper>
      <Style.BackContainer>
        <Back />
      </Style.BackContainer>
      <Style.BackContainerMobile>
        <BackText title="Voltar" />
      </Style.BackContainerMobile>
      <Style.LogoContainer>
        <div className="img-dash-desktop pointer">
          <img
            src={logotipo}
            alt="Logo"
            onClick={() => {
              history.push(`${LADINGPAGE}`);
            }}
          />
        </div>
      </Style.LogoContainer>
      <Style.Container>
        <Style.Block>
          {showPasswordInput ? (
            <>{renderHandlePasswordReset()}</>
          ) : (
            <>{renderSendVerifyCodeToEmail()}</>
          )}
        </Style.Block>
      </Style.Container>
      <Modal
        closeModal={closeModalExported}
        positionClose="In"
        IconTheme="dark"
        modalOpen={isModalExported}
        width={{ desktop: '700px', tablet: '650px' }}
      >
        <EditInfo>
          <div className="modal-wrapper">
            <h2>Recuperar Senha</h2>
            <div className="content-email-user">
              <h3>
                Para recuperar sua senha contate o administrador através do email: <strong>contato@exeltison.com.br</strong>.
              </h3>
            </div>
          </div>
        </EditInfo>
      </Modal>
    </Style.Wrapper>
  );
};

export default ResetPassword;
