import styled from 'styled-components';
import { px2rem } from 'styles/globals';

const Section = styled.section`
  padding-top: ${px2rem(30)};

  a[type="button"]{
    padding: 0 15px;
    height: ${px2rem(36)};
    margin: ${px2rem(40)} auto 0;
    width: ${px2rem(160)};
    div svg{
      width: ${px2rem(20)};
    }
  }

  @media screen and (min-width: 998px){
    max-width: 1424px;
    margin: 0 auto;
  }
  @media screen and (max-width: 997px){
    padding: 50px 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  img{
    width: ${px2rem(39)};
    margin-right: ${px2rem(8)};
  }
  h3{
    font-size: ${px2rem(24)};
    color: var(--blue-darker);
    font-weight: 800;
  }
`;

const SpolightHeader = styled.div`
  display: flex;
  div{
    h3{
      font-size: var(--font-larger);
      color: var(--blue-darknes);
      margin-bottom: 10px;
    }
    p{
      font-size: var(--font-normal);
      margin-bottom: 10px;
    }
  }
  button{
    border: none;
    background: transparent;
  }
 
  @media screen and (min-width: 998px){
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.75px solid rgba(135, 135, 135, 0.25);
    padding: 32px 0;
    button .mobile{
      display: none;
    }
    div{
      width: 93%;
    }
  }
  @media screen and (max-width: 997px){
    width: 100%;
    flex-direction: column;
    margin: 15px 0;
    button{
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;
      span{
        font-size: var(--font-large);
        color: var(--blue-medium);
        font-family: 'Open Sans';
        font-weight: 600;
        padding-left: 10px;
      }
    }
  }
`

const Content = styled.div`
  margin-top: 25px;
  > div.video{
    position: relative;
    padding-bottom: 43%;
   >div{
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      iframe{
        width: 100%;
        height: 100%;
      }
    }
  }
`
export {
  Section, 
  Header,
  SpolightHeader,
  Content
}