import React from 'react';
import styled from 'styled-components';
import Desktop from './Desktop';

const IDesktop = styled.div`
  @media (max-width: 1279px) {
    display: none;
  }
`;

const Guest: React.FC = () => {
  return (
    <>
      <IDesktop>
        <Desktop />
      </IDesktop>
    </>
  );
};

export default Guest;
