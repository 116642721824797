import styled from 'styled-components';

export const Footer = styled.footer`
  padding: 40px 4rem;
  position: relative;
  z-index: 3;
  background-color: var(--blue-darkness);

  .brand {
    max-width: 200px;
    width: 100%;
  }

  .tooltiptext {
    visibility: hidden;
    width: 150px;
    font-size: 14px;
    font-weight: 500;
    background-color: white;
    color: #12428a;
    text-align: center;
    border-radius: 6px;
    padding: 2px 0;
    position: absolute;
    z-index: 1;
    top: 10px;

  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .exeltis {
    max-width: 150px;
    width: 100%;
  }

  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    nav {
      margin: 30px 0;
      display: flex;
      justify-content: center;
      width: 100%;

      :last-child {
        width: initial;
      }

      ul {
        display: flex;

        li {
          margin: 10px 20px;

          a {
            white-space: nowrap;
            display: block;
            position: relative;
            font-weight: 700;
            font-size: var(--font-medium);
            text-decoration: none;
            color: white;

            &::before {
              content: '';
              background-color: white;
              width: 4px;
              opacity: 0;
              height: 3px;
              display: block;
              border-radius: 0;
              position: absolute;
              bottom: -8px;
              left: 50%;
              transform: scaleX(1) translateX(-300%);
              transition: all 0.3s ease;
              border-radius: 0.7px;
            }

            :hover {
              &::before {
                transform: scaleX(12) translateX(-10%);
                opacity: 1;
              }
            }

            &.active {
              &::before {
                transform: scaleX(12) translateX(-10%);
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .footer-anchor {
      font-weight: 500;
      text-transform: initial;

      :before {
        display: none;
      }

      :hover {
        text-decoration: underline;
      }
    }

    p {
      font-size: var(--font-medium);
      font-weight: 500;
      color: white;
    }

    .exeltis_m {
      display: none;
    }
  }

  @media only screen and (max-width: 1200px) {
    .brand {
      max-width: 170px;
      width: 100%;
    }

    .footer-container {
      flex-direction: column;
      align-items: flex-start;

      nav {
        width: initial;

        ul {
          flex-direction: column;

          li {
            margin: 15px 0px;

            a {
              :before {
                display: none;
              }
            }
          }
        }
      }
    }

    .exeltis {
      display: none;
    }

    .exeltis_m {
      display: block !important;
      max-width: 140px;
      width: 100%;
      margin-bottom: 50px;
    }

    .divisor {
      width: 100%;
      background-color: white;
      height: 1px;
    }

    .column-reverse {
      flex-direction: column-reverse;
    }

    p {
      text-transform: uppercase;
      line-height: 29px;
      font-size: 14px !important;
    }
  }

  @media only screen and (max-width: 700px) {
    padding: 40px 2rem;
  }
`;
