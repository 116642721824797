import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  position: relative;

  label {
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 8px;
    color: var(--grey-darkness);
  }

  p {
    margin-top: 10px;
    font-size: var(--font-normal);
    color: var(--third-color);
    font-weight: 600;
    cursor: pointer;
  }

  .required {
    color: var(--color-error);
    font-size: 16px;
    font-weight: 800;
    padding-left: 5px;
    padding-top: 0px;
  }

  .error-border {
    border: 1px solid var(--color-error);
  }

  input {
    height: 45px;
    outline: none;
    border-radius: 6px;
    padding: 0 6px;
    border: none;
    width: 100%;
    color: #25282b;
    font-weight: 600;
    font-size: 16px;
    background: transparent;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }

  span {
    padding-top: 5px;
    padding-left: 5px;
    color: var(--color-error);
    font-size: 14px;
  }

  .alert {
    color: var(--blue-light);
    margin-left: -6px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 12px;
    line-height: 20px;
  }
`;

export const TextArea = styled.textarea`
  outline: none;
  background-color: white;
  display: flex;
  align-items: center;
  outline: none;
  border-radius: 6px;
  padding: 0 6px;
  border: 1px solid rgba(155, 153, 175, 0.31);
  width: 100%;
  color: #25282b;
  padding-top: 10px;

  textarea:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }

  @media only screen and (max-width: 500px) {
    width: 96%;
    max-width: 100%;
  }
`;

export const IDesktop = styled.div`
  @media only screen and (min-width: 501px) {
    display: block;
  }
  @media only screen and (max-width: 500px) {
    display: none;
  }
`;

export const IMobile = styled.div`
  @media only screen and (max-width: 500px) {
    display: block;
  }
  @media only screen and (min-width: 501px) {
    display: none;
  }
`;
