import React, { useState } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Back, BackText, Input } from 'components';
import { logotipo } from 'assets';
import {
    LOGIN,
    PRIVACY_POLICY,
    TERMS_OF_USE, VERIFY_CODE,
} from 'routes/Constants';
import { resendCode, signupExported } from 'services/signup';
import * as Style from '../../style';
import './checkbox.css';
import { Content, TitleDesktop, TitleMobile, ButtonContainer } from './style';

interface LocationState {
    data: {
        email: string;
    };
}

interface IState {
    email: string;
}

const SignupExportedUser: React.FC = () => {
    const history = useHistory();
    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [passwordConfirmed, setPasswordConfirmed] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');
    const [passwordErrorConfirmed, setPasswordErrorConfirmed] = useState<string>('');
    const { state } = useLocation<LocationState>();

    const handleNewPassword = async () => {
        if (password !== passwordConfirmed) {
            setPasswordError(
                'As senhas não se correspondem, informe senhas iguais.',
            );
            return;
        }
        const data = {
            email: state.data.email,
            password: password,
            is_medic: true
        }

        await signupExported(data)
            .then(async (response) => {
                if (response) {
                    toast.warn(
                        'Verifique seu email para receber um código de segurança',
                        { delay: 4000, hideProgressBar: false });
                    history.push(`${VERIFY_CODE}`, {
                        email: state.data.email,
                        type: 'signup',
                    });

                }
            })
            .catch(() => {
                toast.error(
                    '⚠ Algo deu errado ao salvar sua nova senha. Tente novamente.',
                );
            });
    }

    const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.target.id) {

            case 'password-input':
                setPassword(e.target.value);
                break;

            case 'passwordConfirmed-input':
                setPasswordConfirmed(e.target.value);
                break;

            default:
                break;
        }
    };

    return (
        <Style.Wrapper>
            <Style.BackContainer>
                <Back />
            </Style.BackContainer>
            <Style.BackContainerMobile>
                <BackText title="Voltar" />
            </Style.BackContainerMobile>
            <Style.LogoContainer>
                <div className="img-dash-desktop">
                    <img
                        src={logotipo}
                        alt="Logo"
                    />
                </div>
            </Style.LogoContainer>
            <Style.Container>

                <Style.Block>
                    <div>
                        <TitleDesktop style={{ marginTop: '50px' }}>renove sua
                            senha.</TitleDesktop>
                        <Style.Form>
                            <Style.Label>

                                <Input
                                    type="password"
                                    id="password-input"
                                    value={password}
                                    onChange={handleChangeInput}
                                    onFocus={() => setPasswordError('')}
                                    label="Nova Senha"
                                    hasError={!!passwordError}
                                    alertMessage="A senha precisa conter no mínimo 8 caracteres, incluindo letras maiúsculas, minúsculas e números"

                                >
                                    {passwordError && <span>{passwordError}</span>}
                                </Input>
                                <Input
                                    type="password"
                                    id="passwordConfirmed-input"
                                    value={passwordConfirmed}
                                    onChange={handleChangeInput}
                                    onFocus={() => setPasswordErrorConfirmed('')}
                                    label="Confirmar Senha"
                                    hasError={!!passwordErrorConfirmed}
                                >
                                    {passwordErrorConfirmed && <span>{passwordErrorConfirmed}</span>}
                                </Input>

                            </Style.Label>
                        </Style.Form>

                    </div>
                    <TitleDesktop>CADASTRO - políticas de privacidade.</TitleDesktop>
                    <TitleMobile>
                        Cadastro <p>políticas de privacidade.</p>
                    </TitleMobile>
                    <Style.Form style={{ marginTop: '50px' }}>
                        <Content>
                            <div className="container-check">
                                <input
                                    type="checkbox"
                                    checked={checked1}
                                    onChange={() => setChecked1(!checked1)}
                                    className="regular-checkbox"
                                />
                            </div>
                            <span>
                                Tenho ciência de que este Site é voltado exclusivamente para
                                médicos, e, por isso, declaro, sob as penas da lei, que as
                                informações por mim fornecidas aqui são exatas e verídicas.
                                Estou ciente, ainda, que devo usar o material, disponibilizado
                                através do Site, com responsabilidade e não replicar, repassar
                                ou compartilhar o material com quaisquer meios de comunicação ou
                                com terceiros.
                            </span>
                        </Content>
                        <Content style={{ marginTop: '40px' }}>
                            <div className="container-check">
                                <input
                                    type="checkbox"
                                    checked={checked2}
                                    onChange={() => setChecked2(!checked2)}
                                    className="regular-checkbox"
                                />
                            </div>

                            <span>
                                Ao concluir o seu cadastro, você declara que está de acordo com
                                os nossos{' '}
                                <Link to={`${TERMS_OF_USE}`}>Termos e Condições de Uso</Link> e
                                nossa
                                <Link to={`${PRIVACY_POLICY}`}>Política de Privacidade</Link>
                            </span>

                        </Content>

                        <ButtonContainer>
                            <div>
                                <Button
                                    variant="DefaultDark"
                                    onClick={handleNewPassword}
                                    disabled={!(checked1 && checked2 && password && passwordConfirmed)}
                                >
                                    Finalizar Cadastro
                                </Button>
                            </div>
                        </ButtonContainer>
                    </Style.Form>
                    <Style.LoginContainerMobile>
                        <div className="content-login-link">
                            <Link to={`${LOGIN}`}>Já tem uma conta?</Link>
                        </div>
                    </Style.LoginContainerMobile>
                </Style.Block>
            </Style.Container>
        </Style.Wrapper>
    );
};

export default SignupExportedUser;
