import { arrowDown } from 'assets';
import { Select } from 'components';
import React, { useEffect, useState } from 'react';
import { getTags } from 'services/tags';
import ITags from 'services/tags/models/tags-interface';
 import { Wrapper, WrapperSelect } from './style';

 interface ISelect{
   selected?: ITags | null;
   setSelectedItem: (value?: string | null ) => void
 }

const SelectTags: React.FC<ISelect> = ({ selected, setSelectedItem }) => {
  const [select, setSelect] = useState(false);
  const [items, setItems] = useState<ITags[]>();

  const showSelect = () => setSelect(true);
  const onClose = () => setSelect(false);

  const handleItemClick = (id: any) => {
    selected === id ? setSelectedItem(null) : setSelectedItem(id);
    setSelect(false)
  };

  const getAllTags = async () => {
    const tags = await getTags();
    setItems(tags);
  }

  useEffect(() => {
    getAllTags();
  }, [])

  return(
    <Wrapper>
     <WrapperSelect onClick={showSelect}>
        <p>{selected ? selected?.tag :  `Filtre por tag`}</p>
        <img src={arrowDown} alt=""/>
      </WrapperSelect>
      <Select onClose={onClose} select={select}>
        {items?.map(item => (
          <p
            onClick={e => {
            handleItemClick(item);
          }} 
          key={item?.tag_id}
          className={item?.tag_id === selected?.tag_id ? `selected` : ``}
          >{item?.tag}</p>
        ))}
      </Select>
    </Wrapper>
  );
}

export default SelectTags;