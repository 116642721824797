import { d_events, m_events } from 'assets';
import {
  BreadCrumb,
  LoadingBg,
  Error,
  MessageError,
  ScheduleCard,
  Template,
} from 'components';
import SliderEvent from 'components/Slider/SliderEvent';
import getErrorMessage from 'helpers/errorMessages';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LADINGPAGE } from 'routes/Constants';
import { getAllEvents } from 'services/events';
import IEvents from 'services/events/models/events-interface';
import moment from 'moment';
import Calendar from 'components/Calendar';
import { formatEventDate } from 'helpers/eventDate';
import { Desktop, Mobile } from 'utils/responsiveRules';

const Events: React.FC = () => {
  const [event, setEvent] = useState<IEvents[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const getEvents = async () => {
      try {
        setError(false);
        setLoading(true);
        const events = await getAllEvents({ filter_type: 'NEXT' });

        setEvent(events);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        toast.error(errorMessage);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    getEvents();
  }, []);

  function compare(a: any, b: any) {
    const eventA = new Date(a?.start_date).getTime()
    const eventB = new Date(b?.start_date).getTime()
    if (eventA < eventB) {
      return -1;
    }
    if (eventA > eventB) {
      return 1;
    }
    return 0;
  }

  const renderSlide = (item: IEvents[]) => {
    if (item.length === 0) {
      return <MessageError content="Sem eventos no momento" />;
    }

    return event?.sort(compare)
      .map(item => {
        const startDate = new Date(item?.start_date).getTime();
        const endDate = new Date(item?.end_date).getTime()
        const actualDateTime = new Date().getTime();
        const isLive = actualDateTime >= startDate && actualDateTime <= endDate;
        if (actualDateTime > endDate) {
          return <></>;
        } else {
          return (
            <ScheduleCard
              key={item.event_id}
              subtitle={item.subtitle}
              event_id={item.event_id}
              size={'double'}
              title={item.title}
              speakers={item.speakers}
              start_date={item.start_date}
              local={item.local}
              isOnline={!!item.live_id}
              end_date={item.end_date}
              is_active={item.is_active}
              is_live={isLive}
            />
          )
        }
      })
      ?.filter(item => item.props.is_active);
  };

  if (loading) return <LoadingBg />;
  if (error) return <Error type="LoadFail" />;

  var settings = {
    initialSlide: 1,
    dots: false,
    speed: 1000,
    slidesToShow: event.length === 1 ? 1 : 2,
    slidesToScroll: 1,
    centerMode: true,
    className: 'center',
    centerPadding: '0',
    autoplay: false,
    autoplaySpeed: 2000,
    arrow: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          dots: true,
          infinite: true,
          speed: 1400,
          slidesToShow: event.length === 1 ? 1 : 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 998,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Template
        banner={{
          title: 'Agenda de Lives e eventos',
          imgDesktop: d_events,
          imgMobile: d_events,
        }}
        breadcrumb={
          <BreadCrumb
            crumbs={[
              <Link to={`${LADINGPAGE}`}>HOME</Link>,
              <span>Agenda</span>,
            ]}
          />
        }
      >
        <SliderEvent settings={settings}>{renderSlide(event)}</SliderEvent>
        <Mobile>
          <Calendar />
        </Mobile>
      </Template>
      <Desktop>
        <Calendar />
      </Desktop>
    </>
  );
};

export default Events;
