import React from 'react';
import { ICONS, IconsTypes } from './catalogue';
import styled, { DefaultTheme } from 'styled-components';

type StyledProps = {
  size: number;
  opacity?: number;
  colorHover?: string;
  colorBorder?: string;
};

type Props = StyledProps & {
  name?: IconsTypes;
  color?: string;
  colorBorder?: string
  colorHover?: string;
  onClick?(props: any): void;
  cursor?: string;
  theme?: DefaultTheme;
};

const Icon = styled.svg<StyledProps>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  opacity: ${props => props.opacity || 1};

  .iconColor {
    fill: ${props => props.color};
    transition: all 0.4s;
    stroke: ${props => props.colorBorder};
  }

  :hover {
    .iconColor {
      fill: ${props => props.colorHover};
    }
  }
`;

const Component: React.FC<Props> = props => {
  if (!props.name) return null;
  const selectedIcon = ICONS[props.name];
  if (!selectedIcon) return null;

  const rest = { ...props };

  return (
    <Icon
      {...rest}
      as={selectedIcon}
      size={props.size}
      opacity={props.opacity}
      color={props.color}
      colorHover={props.colorHover}
      onClick={props.onClick || undefined}
      cursor={props.onClick && 'pointer'}
    />
  );
};

Component.displayName = 'Icon';

Component.defaultProps = {
  size: 24,
  opacity: 1,
};

export default Component;
