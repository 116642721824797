import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import TemplateCategories from 'components/Template/TemplateCategories';
smoothscroll.polyfill();

const Category: React.FC = () => {
  return (
    <>
      <TemplateCategories/>
    </>
  );
};

export default Category;
