import styled, { css, keyframes } from 'styled-components';

interface ISidebar {
  open: boolean;
}

export const slideRight = keyframes`
	 0% {
      width: 0;
      left: -50%;
    }
    100% {
      left: 0;
    }
`;

export const slideLeft = keyframes`
	 0% {
      width: 0;
      left: -50%;
    }
    100% {
      left: 0;
    }
`;

export const HeaderMobile = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #cfd2d4;
  padding: 18px;

  .brand {
    max-width: 150px;
    width: 100%;
    height: auto;
  }

  .navigation {
    display: flex;
  }

  .SearchModal {
    margin-right: 20px;
    margin-top: 6px;
  }

  .contentNav {
    div {
      margin-top: 6px;
    }
  }
`;

export const Sidebar = styled.header<ISidebar>`
  ${({ open }) => css`
    display: ${open ? 'flex' : 'none'};
    animation: ${open ? 'slide' : 'none'};
    animation-duration: 1s;
  `}

  @keyframes slide {
    0% {
      width: 0;
      left: -50%;
    }
    100% {
      width: 74%;
      left: 0;
    }
  }

  width: 100%;
  background-color: black;
  position: relative;
  z-index: 6;

  .overflow {
    background: #0000009e;
    height: 100vh;
    width: 100vw;
    top: 0;
    position: fixed;
    right: 0;
  }

  .sidebar-content {
    ${({ open }) => css`
      display: ${open ? 'flex' : 'none'};
      width: ${open ? '74%' : '0'};
      animation: ${open ? 'slide' : 'none'};
      animation-duration: 1s;
    `}
    z-index: 5;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: white;
    flex-direction: column;
    -webkit-box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);
    -moz-box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);
    box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);
    display: flex;
    justify-content: space-evenly;

    @media (min-width: 768px) {
      ${({ open }) => css`
        width: ${open ? '41%' : '0'};
      `}

      @keyframes slide {
        0% {
          width: 0;
          left: -50%;
        }
        100% {
          width: 41%;
          left: 0;
        }
      }
    }
  }

  flex-direction: column;
  align-items: center;
  justify-content: center;

  .brand-sidebar {
    display: flex;
    justify-content: center;
  }

  .isLogged-style {
    margin-left: 0rem;
  }

  .isGuest-style {
    margin-left: -2.4rem;
  }

  nav {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    li {
      margin: 30px 0;
      line-height: 23px;

      a {
        color: var(--grey-darker);
        text-decoration: none;
        font-size: var(--font-large);
      }
    }
  }

  .wrapper-logout {
    padding: 37px 0;
  }

  .content-logout {
    display: flex;
    align-items: center;
  }

  .dropdown-articles {
    color: var(--grey-darker);
    text-decoration: none;
    font-size: var(--font-large);
    display: flex;
    align-items: center;
  }

  .dropdown-arrow {
    margin: 0 10px;
    transform: rotate(-90deg);
  }

  .dropdown-arrow-reverse {
    margin-right: 10px;
    margin-left: -10px;
    transform: rotate(90deg);
  }

  @media only screen and (min-width: 400px) and (max-width: 600px) {
    .brand {
      margin-top: 50px;
    }
    nav {
      padding-top: 0;
    }
  }

  @media only screen and (max-width: 375px) {
    .sidebar-wrapper-content {
      overflow: auto;
      padding: 40px 0;
    }
  }
`;

export const ButtonClose = styled.div`
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 0;
  bottom: 31px;
  transform: translatex(74px);

  .container-btn-close {
    background-color: white;
    border-radius: 50px;
    padding: 18px;
    -webkit-box-shadow: -1px 1px 10px 0px rgb(179 179 179 / 45%);
    -moz-box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);
    box-shadow: -1px 1px 10px 0px rgb(179 179 179 / 45%);
  }
`;
