import styled, { css } from 'styled-components';

export const Category = styled.p`
  font-size: 16px;
  font-weight: 800;
  ${props => props.color && css`
    color: ${props.color} 
  `}
`

export const Container = styled.div`
  max-width: 75%;
  margin: 0 auto;

  .category {
 
  }

  h2 {
    color: var(--gray-darker);
    font-weight: 700;
    font-size: 46px;
    line-height: 56px;
    margin: 16px 0;
  }

  h3 {
    color: #757575;
    font-size: 21px;
    line-height: 28px;
    margin-bottom: 20px;
  }

  h4 {
    color: #757575;
    font-size: 16px;
    line-height: 24px;
  }

  .principal-image {
    margin: 40px 0;
    width: 100%;
  }

  .text-news {
    font-size: 18px;
    line-height: 33px;
    color: var(--gray-darker);
    border-bottom: 1px solid #878787;
    padding-bottom: 60px;
  }

  .more-news {
    color: var(--blue-darkness);
    font-size: 24px;
    font-weight: 800;
    line-height: 40px;
    padding-top: 60px;
  }

  .container-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 16px;
    align-items: stretch;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    h2 {
      font-size: 28px;
      line-height: 32px;
    }

    h3 {
      font-size: 18px;
      line-height: 24px;
    }

    h4 {
      font-size: 12px;
      line-height: 18px;
    }

    .principal-image {
      margin: 28px 0;
    }

    .text-news {
      font-size: 18px;
      line-height: 28px;
    }

    .container-cards {
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
  }
`;
