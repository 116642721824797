import React from 'react';
import { Container } from './style';
import Speaker from '../Speaker';

interface ILiveFooter {
  title: string;
  subtitle: string;
  speakers?: [{
    name: string;
    bio: string;
    avatar: string;
  }];
}

const LiveFooter: React.FC<ILiveFooter> = ({ title, subtitle, speakers }) => {
  return (
    <Container>
      <div className="text">
        <span className="title">{title}</span>
        <span className="subtitle">{subtitle}</span>
      </div>
      <hr />
      <div className="speakers">
        {speakers &&
          speakers.map(speaker => <Speaker key={speaker.name} name={speaker.name} avatar={speaker.avatar} />)
        }
      </div>
      <div className="shadow-overlay" />
    </Container >
  );
};

export default LiveFooter;
