import React, {
  ButtonHTMLAttributes,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import Template from './TemplateSearch/Template';
import BreadCrumb from './TemplateSearch/BreadCrumb';
import { d_search } from 'assets';
import { HOME } from 'routes/Constants';
import {
  Section,
  Grid,
  Button,
  ButtonContainer,
  Content,
  NotFoundContent,
  PaginationContent,
  SearchIcon,
  MobileResultText,
} from './style';
import _ from 'lodash';
import { LoadingBg, MessageError } from 'components';
import ItemCard from './ItemCard';
import ISearchType from 'services/search/models/search-type-interface';
import ISearchValue from 'services/search/models/search-value-interface ';
import { Category } from 'services/categories/models/category-interface';
import { Icon } from 'components';
import { getSearchByType, getSearch } from 'services/search';
import { getCategories } from 'services/categories';
import Pagination from 'components/Pagination';

import { toast } from 'react-toastify';

const Search: React.FC = () => {
  const searchValue = window.location.pathname.split('/')[2];
  const [searchResult, setSearchResult] = useState<ISearchValue>();
  const [searchType, setSearchType] = useState<ISearchType[]>();
  const [searchCategory, setSearchCategory] = useState<ISearchValue>();
  const [searchMethod, setSearchMethod] = useState('DEFAULT');
  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<Category[]>();

  const possibleTypes = [
    { id: 1, type: 'VIDEO', title: 'Video' },
    { id: 2, type: 'PODCAST', title: 'Podcast' },
    { id: 3, type: 'NOTICIA', title: 'Notícia' },
    { id: 4, type: 'ARTIGO_CIENTIFICO', title: 'Artigos Científico' },
    { id: 5, type: 'ARTIGO_COMENTADO', title: 'Artigos Comentados' },
    { id: 6, type: 'MONOGRAFIA', title: 'Monografias' },
  ];

  // paginação
  const [page, setPage] = useState(0);
  const number_of_items = 18;

  const number_of_pages = useMemo(() => {
    switch (searchMethod) {
      case 'DEFAULT':
        return (
          searchResult && Math.ceil(searchResult.title.length / number_of_items)
        );
      case 'TYPE':
        return searchType && Math.ceil(searchType.length / number_of_items);
      case 'CATEGORY':
        return (
          searchCategory &&
          Math.ceil(searchCategory.title.length / number_of_items)
        );
      default:
        break;
    }
  }, [searchCategory, searchMethod, searchResult, searchType]);

  const value_search = useMemo(() => {
    if (searchResult){
      return [...searchResult.title].splice(
        page * number_of_items,
        number_of_items,
      );
    }
  }, [searchResult, page, number_of_items]);

  const type_search = useMemo(() => {
    if (searchType)
      return [...searchType].splice(page * number_of_items, number_of_items);
  }, [searchType, page, number_of_items]);

  const category_search = useMemo(() => {
    if (searchCategory)
      return [...searchCategory.title].splice(
        page * number_of_items,
        number_of_items,
      );
  }, [searchCategory, page, number_of_items]);
  //

  const searchByType = async (event: string) => {
    try {
      await getSearchByType(event, searchValue).then(value => {
        setSearchType(value);
        setSearchMethod('TYPE');
        setPage(0);
      });
    } catch (error) {
      toast.error('error');
    }
  };

  const searchByCategory = async (categoryId: string) => {
    try {
      await getSearch(searchValue, categoryId).then(value => {
        setSearchCategory(value);
        setSearchMethod('CATEGORY');
        setPage(0);
      });
    } catch (error) {
      toast.error('error');
    }
  };

  const existingCategories = useMemo(() => {
    const result = searchResult?.title
      .map(item => item.categories?.map(cat => cat.category_id))
      .flat();
    return result;
  }, [searchResult?.title]);

  const existingTypes = useMemo(() => {
    const finalResult = searchResult?.title.map(item => item.type);
    return finalResult;
  }, [searchResult?.title]);

  useEffect(() => {
    (async () => {
      try {
        await getSearch(searchValue).then(value => {
          value && setSearchResult(value);
          setLoading(true);
        });
        await getCategories().then(value => {
          setCategories(value);
          setLoading(true);
        });
        setSearchMethod('DEFAULT');
      } catch (error) {}
    })();
  }, [searchValue]);

  const ArticleTypes = ['ARTIGO_CIENTIFICO', 'ARTIGO_COMENTADO', 'MONOGRAFIA']

  const GridItems: React.FC = () => {
    return (
      <Grid>
        {searchMethod === 'DEFAULT'
          ? value_search &&
            value_search.map((data, id) => {
              const theme = data?.categories[0]?.info?.color;
              const isArticle = ArticleTypes.includes(data?.type);
              return (
                <ItemCard
                  data={data}
                  key={id}
                  path={data.reference}
                  themeTag={theme}
                  tag={data.type}
                  article={isArticle}
                />
              );
            })
          : searchMethod === 'TYPE'
          ? type_search?.map((data, id) => {
              const theme = data?.categories[0]?.info?.color;
              const isArticle = ArticleTypes.includes(data?.type);
              return (
                <ItemCard
                  data={data}
                  key={id}
                  path={data.reference}
                  themeTag={theme}
                  tag={data.type}
                  article={isArticle}
                />
              );
            })
          : category_search?.map((data, id) => {
              const theme = data?.categories[0]?.info?.color;
              const isArticle = ArticleTypes.includes(data?.type);
              return (
                <ItemCard
                  data={data}
                  key={id}
                  path={data.reference}
                  themeTag={theme}
                  tag={data.type}
                  article={isArticle}
                />
              );
            })}
      </Grid>
    );
  };


  return (
    <>
      {loading ? (
        <Template
          banner={{
            title: 'Pesquisa',
            imgDesktop: d_search,
          }}
          breadcrumb={
            <BreadCrumb
              crumbs={[
                <Link to={`${HOME}`}>HOME</Link>,
                <span>Pesquisa</span>,
                <span>{decodeURIComponent(searchValue)}</span>,
              ]}
            />
          }
        >
          <Section>
            <div className="content">
              <div className="side-menu">
                <h2>Categorias</h2>
                <ButtonContainer>
                  {categories &&
                    categories
                      .filter(cat =>
                        existingCategories?.includes(cat.category_id),
                      )
                      .map((category, id) => (
                        <Button
                          key={id}
                          onClick={() => searchByCategory(category.category_id)}
                          check={true}
                        >
                          {category.title}
                        </Button>
                      ))}
                </ButtonContainer>
                <h2>Conteúdo</h2>
                <ButtonContainer>
                  {possibleTypes.map(type =>
                    existingTypes && existingTypes.includes(type.type) ? (
                      <Button
                        key={type.id}
                        onClick={() => searchByType(type.type)}
                      >
                        {type.title}
                      </Button>
                    ) : (
                      ''
                    ),
                  )}
                </ButtonContainer>
                <ButtonContainer>
                  <h2>Sem filtro</h2>
                  <Button onClick={() => setSearchMethod('DEFAULT')}>
                    Exibir todos os resultados
                  </Button>
                </ButtonContainer>
              </div>
              <Content>
                {searchResult && searchResult.title.length > 0 ? (
                  <>
                    <MobileResultText>
                      <h1>
                        Sua busca por{' '}
                        <strong>"{searchValue.replaceAll('%20', ' ')}"</strong>{' '}
                        retornou{' '}
                        {searchResult.title.length > 1
                          ? `${searchResult.title.length} resultados`
                          : `${searchResult.title.length} resultado`}
                        :
                      </h1>
                    </MobileResultText>
                    <GridItems />
                    <PaginationContent>
                      <Pagination
                        actualPage={page}
                        pages={number_of_pages}
                        changePage={setPage}
                      />
                    </PaginationContent>
                  </>
                ) : (
                  <NotFoundContent>
                    <h1>
                      Sua busca por{' '}
                      <strong>"{decodeURIComponent(searchValue)}"</strong>{' '}
                      não retornou resultado
                    </h1>
                    <p>
                      Verifique a ortografia ou tente palavras-chave diferentes.
                    </p>
                    <SearchIcon />
                  </NotFoundContent>
                )}
              </Content>
            </div>
          </Section>
        </Template>
      ) : (
        <LoadingBg />
      )}
    </>
  );
};

export default Search;
