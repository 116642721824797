import React from 'react';
import styled from 'styled-components';

export interface IFeature {
  img: string;
  title: string;
}

const Component = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 85px;
  height: 85px;

  h3 {
    margin-top: 10px;
    font-size: 16px;
    color: var(--black-lighter);
    font-weight: 500;
    line-height: 22px;
    display: flex;
    text-align: center;
    justify-content: center;
  }
`;

const Feature: React.FC<IFeature> = ({ img, title }) => {
  return (
    <Component>
      <img src={img} alt="imagem" />
      <h3>{title}</h3>
    </Component>
  );
};

export default Feature;
