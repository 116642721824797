import styled, { css } from 'styled-components'
import { px2rem } from 'styles/globals';

interface IRow{
  reverse?: boolean
}

const Wrapper = styled.div`
  margin-bottom: 60px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${px2rem(24)};
`;

const SectionTitle = styled.h1`
  font-weight: 800;
  font-size: 20px;
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
  @media screen and (min-width: 998px){
    font-size: var(--font-larger);
  }
`;

const Row = styled.div<IRow>`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: 998px){
    justify-content: space-between;
    flex-direction: row;
    gap: 15px;
    >div:first-of-type{
      width: 50%;
    }
    ${props => props.reverse ?  
      `
        flex-direction: row-reverse;
      ` 
      : `
        flex-direction: row;
      ` 
    }
  }
  @media screen and (max-width: 997px){
    align-items: center;
  }
`;
const Grid = styled.div`
  width: 100%;
  @media screen and (min-width: 998px){
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${px2rem(25)};
    grid-template-rows: repeat(2, 1fr);
    width: 50%;
  }
  @media screen and (max-width: 997px){
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


  }
`;

export{
  Wrapper,
  Header,
  Grid, 
  Row,
  SectionTitle
}