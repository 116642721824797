import styled from 'styled-components';

export const HeaderDesktop = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 4rem;
  margin: 30px auto;
  max-width: 1320px;

  .brand {
    max-width: 142px;
    width: 100%;
  }

  nav {
    display: flex;
    justify-content: center;
  }

  ul {
    display: flex;
    align-items: center;
  }

  li {
    margin: 0px 15px;

    a {
      white-space: nowrap;
      display: block;
      position: relative;
      font-weight: bold;
      font-size: var(--font-medium);
      text-decoration: none;
      color: var(--blue-medium);

      &::before {
        content: '';
        background-color: var(--blue-medium);
        width: 4px;
        opacity: 0;
        height: 3px;
        display: block;
        border-radius: 0;
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: scaleX(1) translateX(-300%);
        transition: all 0.3s ease;
        border-radius: 0.7px;
      }

      :hover {
        &::before {
          transform: scaleX(12) translateX(-10%);
          opacity: 1;
        }
      }

      &.active {
        &::before {
          transform: scaleX(12) translateX(-10%);
          opacity: 1;
        }
      }
    }
  }

  button {
    font-weight: bold;
    font-size: var(--font-medium);
    text-decoration: none;
    color: var(--blue-medium);
    white-space: nowrap;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    outline: none;

    img {
      margin-left: 15px;
    }
  }

  .profile-header {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      font-weight: 400;
      font-size: var(--font-medium);
      text-decoration: none;
      color: var(--blue-medium);
      margin-right: 10px;
    }

    img {
      width: 27px;
      height: auto;
    }
  }

  .drop-menu {
    position: absolute;
    width: 100%;
    transform: translate(-101px, 46px);

    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      a {
        display: flex;
        align-items: center;
        font-weight: bolder;
        color: black;
      }

      .signout {
        position: initial;
        top: auto;
      }
    }
  }

  .logoutStyle {
    text-decoration: none;
    color: var(--grey-darker);
    font-weight: bold;
    padding-top: 23px;
    border: none;
    cursor: pointer;

    &::before {
      content: none;
    }
  }
  .select-position-articles {
    transform: translate(-121px, 18px);
  }

  .select-position-articles a {
    &::before {
      content: none;
    }
  }
  .select-prosition-profile {
    transform: translate(-117px, 18px);

    a {
      font-size: 14px;
    }
  }

  .container-logout {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    img {
      margin-top: 10px;
      margin-right: 10px;
    }

    span {
      text-decoration: none;
      color: var(--grey-darker);
      font-weight: bold;
      border: none;
      cursor: pointer;
      font-size: 14px;
      margin-left: 10px;
    }
  }

  @media (min-width: 1320px) {
    padding: 40px 0;
  }
`;
