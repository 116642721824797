import styled, { css } from 'styled-components';

const Spotlight = styled.div`
  position: relative;
  border-radius: 4px;
  width: 100%;
  img {
    width: 100%;
    height: 700px;
    object-fit: cover;
  }

  @media screen and (min-width: 998px) {
    min-height: 427px;
  }

  @media screen and (max-width: 997px) {
    margin-bottom: 16px;
    height: 327px;
  }
`;

const SpotlightImage = styled.div`
  background: linear-gradient(180deg, rgba(18, 66, 138, 0) 0%, #12428a 91.67%);

  ${props =>
    props.color &&
    css`
      background-color: var(--blue-medium);
      background: linear-gradient(
        180deg,
        rgba(18, 66, 138, 0) 0%,
        ${props.color} 91.67%
      );
    `}
  background-repeat: no-repeat;
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  @media screen and (max-width: 997px) {
    height: 105%;
  }
`;

const SpotlightDesc = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px 16px;
  @media screen and (min-width: 998px) {
    padding: 52px;
  }
`;

const TagNews = styled.div`
  position: absolute;
  left: 30px;
  bottom: 130px;
`;

const SpotlightTitle = styled.h1`
  text-transform: uppercase;
  color: var(--white);
  font-weight: 700;
  letter-spacing: 0.18px;
  font-size: 20px;
  @media screen and (min-width: 998px) {
    font-size: var(--font-largest);
  }
`;

const SpotlightDescription = styled.p`
  color: var(--white);
  letter-spacing: 0.25px;
  font-weight: 400;
  margin-top: 16px;
  line-height: 24px;
  font-size: 14px;
  @media screen and (min-width: 998px) {
    font-size: var(--font-medium);
  }
  @media screen and (max-width: 998px) {
    line-height: 17px;
    margin-top: 10px;
  }
`;

export {
  Spotlight,
  SpotlightImage,
  SpotlightDesc,
  SpotlightTitle,
  SpotlightDescription,
  TagNews,
};
