import { useState } from 'react';
import { Select, Loading, LoadingBg } from 'components';
import smoothscroll from 'smoothscroll-polyfill';
import { Link } from 'react-router-dom';
import { Title, TitleComponents } from './style';
import {
  Button,
  LinkButton,
  Icon,
  Modal,
  BreadCrumb,
  Banner,
  MessageError,
} from 'components';
import { useModal } from 'hooks';
import { LADINGPAGE, NEWS } from 'routes/Constants';
import { d_planning, m_attention } from 'assets';

smoothscroll.polyfill();

/** ITENS RELACIONADOS AO SELECT - INICIO **/
const data: DropDownField[] = [
  {
    id: 0,
    label: 'Istanbul, TR (AHL)',
    link: 'https://github.com/sparksuite/react-accessible-dropdown-menu-hook',
  },
  {
    id: 1,
    label: 'Paris, FR (CDG)',
    link: 'https://github.com/sparksuite/react-accessible-dropdown-menu-hook',
  },

  {
    id: 2,
    label: 'Paris, FR (CDG)',
    link: 'https://github.com/sparksuite/react-accessible-dropdown-menu-hook',
  },
  {
    id: 3,
    label: 'Paris, FR (CDG)',
    link: 'https://github.com/sparksuite/react-accessible-dropdown-menu-hook',
  },
  {
    id: 4,
    label: 'Paris, FR (CDG)',
    link: 'https://github.com/sparksuite/react-accessible-dropdown-menu-hook',
  },
  {
    id: 5,
    label: 'Paris, FR (CDG)',
    link: 'https://github.com/sparksuite/react-accessible-dropdown-menu-hook',
  },
  {
    id: 6,
    label: 'Paris, FR (CDG)',
    link: 'https://github.com/sparksuite/react-accessible-dropdown-menu-hook',
  },
];

export interface DropDownField {
  id: number;
  label: string;
  link: string;
}
/** ITENS RELACIONADOS AO SELECT - FIM **/

const Components: React.FC = () => {
  /** ITENS RELACIONADOS AO SELECT - INICIO **/
  const [select, setSelect] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [items] = useState(data);
  const showSelect = () => setSelect(true);
  const onClose = () => setSelect(false);
  const handleItemClick = (id: any) => {
    selectedItem === id ? setSelectedItem(null) : setSelectedItem(id);
  };

  const [modalOpen, openModal, closeModal] = useModal();

  /** ITENS RELACIONADOS AO SELECT - FIM **/

  return (
    <>
      <Title>DESIGN SYSTEM COMPONENTS EXELTIS MÉDICOS</Title>
      <TitleComponents>COMPONENTE SELECT</TitleComponents>
      {/*COMPONENT SELECT*/}
      <p>
        O componente já está estilizado para receber um map com link da forma
        como está representado abaixo
      </p>
      <button onClick={showSelect}>Select</button>
      <Select onClose={onClose} select={select}>
        {items.map(item => (
          <Link
            key={item.id.toString()}
            id={item.id.toString()}
            to={item.link}
            onClick={e => {
              handleItemClick(item.id);
            }}
            className={`dropdown-item-dot ${
              item.id === selectedItem && 'selected'
            }`}
          >
            {item.label}
          </Link>
        ))}
      </Select>
      <TitleComponents>
        COMPONENTE BUTTON TIPO <b>SUBMIT</b> OU <b>LINK</b>
      </TitleComponents>
      <Button variant="DefaultDark" typeIcon="more" reverseIcon={true}>
        Entrar
      </Button>
      <br />
      <LinkButton to="/" variant="Default">
        Entrar
      </LinkButton>
      <TitleComponents>COMPONENTE DE ICONE</TitleComponents>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <div>
          <p>Icone</p>
          <Icon name="union" color="black" colorHover="#913b3b" size={40} />
        </div>
        <div>
          <p>Icone com cor diferente e opção de hover</p>
          <Icon name="union" color="blue" colorHover="#913b3b" size={40} />
        </div>
      </div>
      <TitleComponents>Modal</TitleComponents>
      <Modal
        modalOpen={modalOpen}
        closeModal={closeModal}
        positionClose="In"
        IconTheme="light"
      >
        <h2 style={{ color: 'black' }}>texto</h2>
      </Modal>
      <button type="button" onClick={openModal}>
        Open Modal
      </button>

      <TitleComponents>BreadCrumb</TitleComponents>
      <BreadCrumb
        crumbs={[
          <Link to={`${LADINGPAGE}`}>HOME</Link>,
          <Link to={`${NEWS}`}>Notícias</Link>,
          <span>Profissionais</span>,
        ]}
      />

      <TitleComponents>Banner</TitleComponents>
      <Banner
        imgDesktop={d_planning}
        imgMobile={m_attention}
        theme="BlueDark"
        title="VÍDEO"
      />
      {/* <Loading /> */}
      <MessageError content="O conteúdo não está disponível no momento." />
    </>
  );
};

export default Components;
