import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  -webkit-box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);
  -moz-box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);
  box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);

  img {
    width: 85%;
    height: 180px;
    object-fit:  contain;
  }

  div {
    display: flex;
    align-items: center;
    flex-direction: column;

    .card-product-content {
      flex-direction: column;
      align-items: flex-start;
      h3 {
        font-size: 40px;
        line-height: 51px;
        max-width: 267px;
        font-weight: 700;
        text-transform: uppercase;
      }

      p {
        text-transform: uppercase;
        font-size: 16px;
        line-height: 26px;
      }
    }
  }

  .card-product-button {
    margin-top: 25px;
    width: 100%;
  }

  @media only screen and (max-width: 500px) {
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .card-product-content {
        margin-left: 10px;
        h3 {
          font-size: 28px;
          line-height: 38px;
        }

        p {
          text-transform: uppercase;
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
  }
`;
