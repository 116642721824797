import styled from 'styled-components';

export const PlayerSambaVideosContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  & iframe {
    @media (max-width: 550px) {
      height: 165px;
      width: auto;
    }
  }
} 
`;
