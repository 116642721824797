import styled from 'styled-components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slider from 'react-slick';

export const SlickWrapper = styled(Slider)`
  margin-bottom: 150px;
  display: flex;

  .slick-track {
    display: flex !important;
  }

  .slick-dots li.slick-active button:before,
  .slick-dots li button:before {
    display: none;
  }

  @media (max-width: 1100px) {
    .slick-dots{
      position: inherit;
      margin: 20px 0;
    }
    .slick-dots li.slick-active button:before {
      display: block;
      border: 2px solid var(--blue-medium);
      width: 13px;
      height: 13px;
      color: white;
      border-radius: 50px;
      margin-top: 14px;
    }

    .slick-dots li button:before {
      display: block;
      border: 2px solid #444444;
      width: 13px;
      height: 13px;
      color: white;
      border-radius: 50px;
      margin-top: 14px;

      :first-child() {
        margin-right: 10px;
      }

      :last-child() {
        margin-left: 10px;
      }
    }
  }

  @media (max-width: 600px) {
    padding: 0;
    overflow-x: clip;
    margin-top: 30px;
    margin-bottom: 55px;
    display: flex;
    flex-direction: column;
  }

  .sliderNav {
    position: absolute;
    display: flex;
    align-items: center;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;
    padding-bottom: 60px;

    &--prev {
      left: 96%;
    }
    &--next {
      left: 90%;
    }

    @media (max-width: 1100px) {
      &--prev {
        display: none;
      }
      &--next {
        display: none;
      }
    }
  }

  .slick-list {
    width: 100%;
  }

  .slick-center {
    margin-left: 10px;
  }

  @media (max-width: 480px) and (orientation: portrait) {
    width: 100%;

    &:before {
      content: none;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
`;
