import styled from 'styled-components';

export const Container = styled.section`
  max-width: 70%;
  margin: 0 auto;

  .container-header {
    display: flex;
    align-items: center;
  }

  .wrapper-header {
    padding-left: 30px;
  }

  .img-container {
    border-radius: 50%;
    position: relative;
    width: 150px;
    height: 150px;
    object-fit: cover;
  }

  .icon {
    position: absolute;
    cursor: pointer;
    margin: 60px 0 0 100px;
  }

  h2 {
    font-size: 50px;
    color: var(--blue-medium);
    font-weight: 700;
    line-height: 56px;
  }

  p {
    font-size: 25px;
    line-height: 50px;
    font-weight: 400;
    color: #4e5153;
  }

  .wrapper-header > p {
    color: var(--gray-darker);
  }

  h3 {
    font-size: 26px;
    line-height: 32px;
    font-weight: 700;
    color: var(--gray-darker);
    margin: 30px 0 5px 0;
  }

  .phone {
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    padding-top: 30px;
  }

  .container-info > p {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    padding-bottom: 30px;
  }

  p:last-child {
    border-bottom: none;
  }

  .container-info > div {
    display: flex;
    justify-content: space-between;
    max-width: 50%;
  }

  button {
    width: 175px;
    height: 40px;
  }

  .security > h2 {
    margin-top: 40px;
    padding: 30px 0 10px 0;
    color: var(--gray-darker);
    border-top: 1px solid rgba(0, 0, 0, 0.5);
  }

  .security > p {
    visibility: hidden;
  }

  .security > h3 {
    margin-bottom: -45px;
  }

  .security > p::after {
    content: '•••••••••••••••';
    visibility: visible;
    display: block;
    padding-bottom: 30px;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;

    .container-header {
      flex-direction: column;
      text-align: center;
    }

    .img-container {
      width: 114px;
      height: 114px;
      object-fit: cover;
    }

    .wrapper-header {
      margin-top: 10px;
    }

    .icon {
      margin: 80px 0 0 37px;
    }

    h2 {
      font-size: 32px;
      line-height: 42px;
    }

    p {
      font-size: 20px;
      line-height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .container-info > div {
      max-width: 100%;
    }

    h3 {
      font-size: 18px;
      line-height: 24px;
    }

    .security > h3 {
      margin: 10px 0 -30px 0;
    }

    button {
      width: 100%;
    }
  }
  #input-file-user-avatar {
    display: none;
  }
`;
