import styled from 'styled-components';


const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  >div:nth-of-type(2){
    top: 70px;
    z-index: 4;
  }
  @media screen and (max-width: 997px){
    >div:nth-of-type(2){
      right: 40px;
    }
  }
`; 
const WrapperSelect = styled.div`
  border: 1px solid var(--blue-darkness);
  justify-content: space-between;
  height: 36px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  border-radius: 18px;
  cursor: pointer;
  margin-left: auto;
  width: 193px;
  p{
    color: var(--blue-darkness);
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    &.active{
      background-color: #cfd2d4;
    }
  }
`;

export{
  Wrapper,
  WrapperSelect
}