import React, { useEffect, useState } from 'react';
import { ArticleCard, LinkButton, LoadingBg, SpolightCard } from 'components';
import { Header, SectionTitle,Row, Grid, Wrapper } from './styles';
import { getCategories } from 'services/categories';
import { Category } from 'services/categories/models/category-interface';
import { getAllNews } from 'services/news';
import NewList from './NewsList';

const CategoryNews: React.FC = () => {
  const [categories, setCategories] = useState<Array<Category>>();
  const [isLoading, setLoading] = useState<boolean>(true);

  const getData = async () => {
    const categories = await getCategories();
    setCategories(categories);
  }

  useEffect(() => {
    getData();
    setTimeout(() => {
      setLoading(false)
    }, 750)
  },[])

  if (isLoading)
   return <LoadingBg />;
  return(
    <>
      {categories?.map((category: Category, key: number) => {
        const item = (key + 1) % 2 == 0;
          return(
            <Wrapper key={`news-${key}`}>
              <NewList title={category.title} reverse={item} theme={category?.info?.color} categoryId={category?.category_id} />
            </Wrapper>
          )
      })}
    </>
  );
}

export default CategoryNews;