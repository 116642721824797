import React from 'react';
import { article_card_placeholder } from 'assets';
import {
  Article,
  ArticleDescription,
  ArticleLink,
  ArticleResume,
  ArticleTitle,
  Wrapper,
} from './style';
import Tag from 'components/Tags';
import { ExternalLink } from 'components/Cards/ArticleCard/style';

interface IArticleCard {
  data?: any;
  path?: string;
  theme?: string;
  tag?: string;
  themeTag?: string;
  article?: boolean;
}
const ItemCard: React.FC<IArticleCard> = ({
  data,
  path,
  themeTag,
  theme,
  tag,
  article
}) => {
  const tagTitle = tag?.replace('_', ' ')
  const pathResolver = () => {
    switch(tag){
      case 'VIDEO':
        return `/video/play/${data?.content_id}`
      case 'PODCAST':
        return `/podcast/play/${data?.content_id}`  
      case 'NOTICIA':
        return `/noticias/${data?.news_id}`  
      default: 
        return ''   
    }
  }

  if(article){
    return(
      <ExternalLink href={path} target="_blank" rel="noreferrer" download>
        <Article color={theme}>
          <Wrapper>
            <img src={data?.thumbnail ? data?.thumbnail :  article_card_placeholder} alt=""/>
            {tag &&  <Tag type={tag} theme={themeTag || theme}/>}
          </Wrapper>
          <ArticleDescription>
            <ArticleTitle>{data?.title}</ArticleTitle>
            <ArticleResume>{data?.description}</ArticleResume>
          </ArticleDescription>
        </Article>
      </ExternalLink>
    );
  }else{
    return (
      <ArticleLink to={pathResolver} title="">
        <Article>
          <Wrapper>
            <img
              src={data?.thumbnail ? data?.thumbnail : article_card_placeholder}
              alt=""
            />
            {tag && <Tag type={tagTitle} theme={themeTag || theme} />}
          </Wrapper>
          <ArticleDescription>
            <ArticleTitle>{data?.title}</ArticleTitle>
            <ArticleResume>
              {data?.description || data.info.description}
            </ArticleResume>
          </ArticleDescription>
        </Article>
      </ArticleLink>
    );
  }
  
};

export default ItemCard;
