import styled, { keyframes } from 'styled-components';

const dropdown = keyframes`
	z-index: 10;
	0% {
		opacity: 0;
		transform: translate(0px, -30px);
	}
	100% {
		opacity: 1;
		transform: translate(0px, 0px);
	}
`;

export const Container = styled.div`
  position: absolute;
  z-index: 3;
  margin-left: 9px;
  z-index: 1;
  .triangle {
    width: 19%;
    position: absolute;
    right: -20px;
    top: -7%;
  }

  animation: ${dropdown} 0.6s;
`;

export const SelectContainer = styled.div`
  width: 100%;
  background-color: white;
  -webkit-box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);
  -moz-box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);
  box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);
  padding: 15px 26px;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  a, p {
    text-decoration: none;
    color: var(--grey-darker);
    font-weight: bold;
    padding: 23px 0px;
    border-bottom: 1px solid var(--grey-dark);
    font-size: 14px !important;
    cursor: pointer;

    :last-child {
      border-bottom: 1px solid transparent;
      padding-bottom: 10px;
    }
    
  }

  .selected {
    background-color: #cfd2d4;
    margin-left: -26px;
    margin-right: -26px;
    margin-top: -2px;
    padding-left: 20px;

    :first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      margin-top: -15px;
      margin-left: -26px;
      margin-right: -26px;
    }
    :last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      margin-left: -26px;
      margin-right: -26px;
      margin-top: -3px;
      padding-bottom: 20px !important;
      margin-bottom: -15px;
      padding-left: 20px;
    }
  }
`;
