import { Button, Input } from 'components';
import React, { useState } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import {
  setForgotPassword as forgotPasswordService,
  setConfirmPassword as confirmPasswordService,
} from 'services/user';
import { toast } from 'react-toastify';
import { EditInfo, CodeInput } from './style';
import { User } from 'services/user/models/user-interface';
import { checkPassword } from 'helpers/validation';

type Prop = {
  user: User;
  closeModal: () => void;
};

smoothscroll.polyfill();

const EditPassword: React.FC<Prop> = ({ user, closeModal }) => {
  const [presentPassword, setPresentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [verifyCode, setVerifyCode] = useState<string>('');
  const [showPasswordInput, setShowPasswordInput] = useState<boolean>(false);
  const [errorRepeatPassword, setErrorRepeatPassword] = useState<string>('');

  const handleChangeInputCode = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ): void => {
    if (event.target.value === '') {
      const removeChar = verifyCode.slice(0, index);

      setVerifyCode(removeChar);
      return;
    }

    setVerifyCode(verifyCode + event.target.value);
    document.getElementById(`input-verify-code-number-${index + 1}`)?.focus();

    if (index === 5) {
      document.getElementById(`input-verify-code-number-${index}`)?.blur();
    }
  };

  const sendVerifyCodeToEmail = async (): Promise<void> => {
    await forgotPasswordService(user.email).then(response => {
      if (response) {
        setShowPasswordInput(true);
      }
    });
  };

  const handlePasswordReset = async (): Promise<void> => {
    if (newPassword !== repeatPassword) {
      setPasswordError('As senhas não se correspondem, informe senhas iguais.');
      setErrorRepeatPassword(
        'As senhas não se correspondem, informe senhas iguais.',
      );
      return;
    }

    if (newPassword.length < 8) {
      setPasswordError('A senha deve ter no mínimo 8 caracteres.');
      setErrorRepeatPassword('A senha deve ter no mínimo 8 caracteres.');

      return;
    }

    if (newPassword === repeatPassword && !checkPassword(newPassword)) {
      setPasswordError(
        'A senha precisa conter no mínimo 8 caracteres, incluindo letras maíusculas, minúsculas e números',
      );
      return;
    }

    if (!verifyCode) {
      toast.error('Preencha o código de verificação');
      return;
    }

    const data = {
      email: user.email,
      password: newPassword,
      code: verifyCode,
    };

    await confirmPasswordService(data)
      .then(response => {
        if (response) {
          toast.success('Senha alterada com sucesso');
          closeModal();
        }
      })
      .catch((err) => {
        if (passwordError !== '') {
          toast.error(
            `⚠ ${passwordError}`,
          );
        } else if (errorRepeatPassword !== '') {
          toast.error(
            `⚠ ${errorRepeatPassword}`,
          );
        }
        else {
          toast.error(
            `⚠ Erro ao mudar a senha, confirme o código de verificação`,
          );
        }
      });
  };

  function renderSendVerifyCodeToEmail() {
    return (
      <>
        <div className="modal-wrapper">
          <h2>Alterar minha senha</h2>
          <div className="content-email-user">
            <h3>
              É necessário continuar para receber o código de verificação e
              prosseguir com o processo de alteração de senha. Verifique na caixa de
              entrada do seu e-mail o código de verificação e também na sua
              caixa de spam.
            </h3>
            <h4>Seu email é: {user?.email}</h4>
          </div>
          <div className="buttons">
            <Button variant="Outline" onClick={closeModal}>
              Cancelar
            </Button>
            <Button variant="DefaultDark" onClick={sendVerifyCodeToEmail}>
              Continuar
            </Button>
          </div>
        </div>
      </>
    );
  }

  function renderHandlePasswordReset() {
    return (
      <>
        <div className="modal-wrapper">
          <h2>Alterar minha senha</h2>

          <div className="form-password">
            <h5>Código de verificação</h5>
            <div
              style={{ marginBottom: '23px' }}
              className="form-input-verifyCode"
            >
              <CodeInput>
                {Array(6)
                  .fill(6)
                  .map((_, index) => (
                    <input
                      key={index}
                      type="number"
                      id={`input-verify-code-number-${index}`}
                      value={verifyCode.charAt(index)}
                      onChange={event => handleChangeInputCode(event, index)}
                    />
                  ))}
              </CodeInput>
            </div>
            <Input
              label={'Nova senha'}
              type="password"
              onChange={event => setNewPassword(event.target.value)}
              onFocus={() => setPasswordError('')}
              value={newPassword}
              id={'new-password-input'}
              alertMessage="A senha precisa conter no mínimo 8 caracteres, incluindo letras maiúsculas, minúsculas e números"
            >
              {passwordError && <span>{passwordError}</span>}
            </Input>
            <Input
              label={'Confirme a nova senha'}
              type="password"
              onChange={event => setRepeatPassword(event.target.value)}
              onFocus={() => setErrorRepeatPassword('')}
              value={repeatPassword}
              id={'repeat-password-input'}
            >
              {errorRepeatPassword && <span>{errorRepeatPassword}</span>}
            </Input>
          </div>
          <div className="buttons">
            <Button variant="Outline" onClick={closeModal}>
              Cancelar
            </Button>
            <Button variant="DefaultDark" onClick={handlePasswordReset}>
              Salvar nova senha
            </Button>
          </div>
        </div>
      </>
    );
  }

  return (
    <EditInfo>
      {showPasswordInput ? (
        <>{renderHandlePasswordReset()}</>
      ) : (
        <>{renderSendVerifyCodeToEmail()}</>
      )}
    </EditInfo>
  );
};

export default EditPassword;
