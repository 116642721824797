import React, { useContext, createContext } from 'react';
import { useProvideProduct } from './hooks/useProvideProduct';

type ProductContextType = ReturnType<typeof useProvideProduct> | null;

const ProductContext = createContext<ProductContextType>(null);

export const ProductProvider = ({ children }: { children: JSX.Element }) => {
  const product = useProvideProduct();
  return (
    <ProductContext.Provider value={product}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = () => {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error('useProductContext must be inside a Provider with a value');
  }
  return context;
};
