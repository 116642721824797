import React from 'react';
import { Link } from 'react-router-dom';
import { logotipo_black } from 'assets';
import { Footer } from './style';
import { CONTACT, PRIVACY_POLICY, TERMS_OF_USE, FAQ } from 'routes/Constants';

const Guest: React.FC = () => {

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <Footer>
        <div className="brand">
          <img src={logotipo_black} alt="Exeltis ON" />
        </div>
        <div>
          <p>
            © {new Date().getFullYear()} Todos os direitos Reservados - Exeltis
            Laboratório Farmacêutico Ltda.
          </p>
          <nav>
            <ul>
              <li>
                <Link onClick={scrollTop} to={`${CONTACT}`}>
                  Contato
                </Link>
              </li>
              <li>
                <Link onClick={scrollTop} to={`${TERMS_OF_USE}`}>
                  Termos de Uso
                </Link>
              </li>
              <li>
                <Link onClick={scrollTop} to={`${PRIVACY_POLICY}`}>
                  Política de Privacidade{' '}
                </Link>
              </li>
              <li>
                <Link onClick={scrollTop} to={`${FAQ}`}>
                  FAQ
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </Footer>
    </>
  );
};

export default Guest;
