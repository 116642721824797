import React from 'react';
import { Link } from 'react-router-dom';
import { SIGNUP, LOGIN, LADINGPAGE } from 'routes/Constants';
import { Header } from './style';
import { logotipo } from 'assets';
import { LinkButton } from 'components';

const Component: React.FC = () => {

  return (
    <Header>
      <Link to={`${LADINGPAGE}`}><img className="brand" src={logotipo} alt="Exeltis ON" /></Link>
      <nav>
        <ul>
          <li><Link to={`${LOGIN}`}>Já tenho cadastro</Link></li>
          <LinkButton className="button-header" to={`${SIGNUP}`} variant="DefaultDark">Inscreva-se</LinkButton>
        </ul>
      </nav>
    </Header>
  );
}

export default Component;
