import styled from 'styled-components';

export const Container = styled.div`
  .container-breadcrumb {
    padding: 40px 0;
    border-bottom: 1px solid #c2c4c5;
  }
  .content-template {
    margin: 0 auto;
  }

  @media only screen and (max-width: 800px) {
    padding: 0px;

    .content-template {
      display: flex;
      flex-direction: column-reverse;
    }

    .container-breadcrumb {
      padding: 5px 0px;
      margin: 16px 24px;
      border-bottom: none;
    }
  }

  @media only screen and (min-width: 800px) and (max-width: 1600px) {
    padding: 40px 4rem;
  }
`;

export const Wrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  min-height: 100vh;

  @media only screen and (max-width: 800px) {
    padding: 0 20px;
  }
`;

export const HeaderPage = styled.div`
  margin-bottom: 40px;
`;

export const Title = styled.h2`
  font-size: 40px;
  line-height: 35px;
  color: var(--grey-darker);
  font-weight: 700;

  @media only screen and (max-width: 800px) {
    font-size: 28px;
    line-height: 32px;
  }
`;

export const Subtitle = styled.h3`
  margin-top: 25px;
  font-size: 20px;
  line-height: 30px;
  color: #828282;

  @media only screen and (max-width: 800px) {
    font-size: 16px;
    line-height: 22px;
  }
`;
