import styled from 'styled-components';
import { px2rem } from 'styles/globals';

const Product = styled.div`
  max-width: ${px2rem(343)};
  margin-bottom: 16px;
  width: 100%;
  a{
    font-size: var(--font-normal);
    padding: ${px2rem(10)};
    max-width: ${px2rem(126)};
  }
  img{ 
    width: 100%;
    max-width: 374px;
    height: 164px;
    object-fit: contain;
  }
  @media screen and (min-width: 998px){
    margin: 0;
  }
`;

const ProductInfo = styled.div`

  @media screen and (max-width: 1024px){
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const ProductName = styled.strong`
  font-size: ${px2rem(20)};
  color: var(--blue-medium);
  font-weight: 800;
  display: block;
  margin-top: ${px2rem(15)};
`; 

const ProductType = styled.p`
  font-size: ${px2rem(20)};
  color: var(--grey-darker);
  margin: 8px 0 15px;
`;


export{
  Product,
  ProductInfo,
  ProductName,
  ProductType
}