import React from 'react';
import { BreadCrumb, Template } from 'components';
import {d_article, m_article, } from 'assets';
import { Link } from 'react-router-dom';
import { HOME } from 'routes/Constants';
import smoothscroll from 'smoothscroll-polyfill';
import CategoryScientificArticles from './components/CategoryScientificArticles';


smoothscroll.polyfill();

const ScientificArticles: React.FC = () => {
  return (
    <>
    <Template
        banner={{
          title: 'Artigos Cientifícos',
          imgDesktop: d_article,
          imgMobile: d_article,
        }}
        breadcrumb={
          <BreadCrumb
            crumbs={[
              <Link to={`${HOME}`}>HOME</Link>,
              <span>Artigos Cientifícos</span>,
            ]}
          />
        }
      > 
      <CategoryScientificArticles/>
      </Template>
    </>
  );
};

export default ScientificArticles;
