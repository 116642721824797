import styled from 'styled-components';


const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 30px;
  svg path{
    stroke: var(--blue-darkness);
  }
  h2{
    font-size: var(--font-larger);
    color: var(--blue-darkness);
    font-weight: 600;
    margin-left: 10px;
  }

`;

const FormComment = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  .form{
    display: flex;
    width: 100%;
    gap: 16px;
    img{
      width: 48px;
      height: 48px;
      object-fit: contain;
      border-radius: 50%;
      align-self: center;
    }
    textarea{
      resize: none;
      width: 100%;
      border: 1px solid #E1E3E6;
      font-family: 'Open Sans', sans serif;
      border-radius: 8px;
      outline: none;
      padding: 12px;
    }
  }
  
`;


const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  button{
    background-color: transparent;
    border: none;
    font-family: 'Open Sans', sans serif;
    font-size: var(--font-normal);
  }
`;

const Comment = styled.div`
  padding: 0 32px;
  margin-top: 50px;
  > div{
    display: flex;
    align-items: center;
    gap: 18px;
    position: relative;
    > img{
      width: 48px;
      height: 48px;
      border-radius: 50%;
      align-self: center;
      object-fit: cover;
    }
    strong{
      font-size: var(--font-large);
      color: var(--blue-darkness);
      display: block;
      margin-top: 10px;
    }
  }
  p{
    color: #525F7F;
    font-size: var(--font-normal);
    margin: 16px 0 24px 70px;
  }
  button{
    margin-left: 70px;
  }
`;

const ResponseComment = styled.div`
  padding: 0 32px;
  margin: 50px 0 0 70px;
  div{
    display: flex;
    align-items: center;
    gap: 18px;
    position: relative;
    > img{
      width: 48px;
      border-radius: 50%;
      align-self: center;
    }
    strong{
      font-size: var(--font-large);
      color: var(--blue-darkness);
      display: block;
      margin-top: 10px;
    }
  }
  p{
    color: #525F7F;
    font-size: var(--font-normal);
    margin: 16px 0 24px 70px;
  }
  button{
    margin-left: 70px;
  }
`;

const ResponseButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: var(--font-normal);
  color: var(--grey);
  font-weight: 600;
  font-family: 'Open Sans';
  img{
    margin-right: 5px;
  }
`;

const DeleteButton = styled.button`
  background-color: transparent;
  border: none;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  img{
    width: 100%;
  }
`;

const Wrapper = styled.div`
  overflow: auto;
`;

export{
  Header,
  FormComment,
  Wrapper, 
  Comment, 
  ResponseComment,
  ResponseButton,
  WrapperButtons,
  DeleteButton
}