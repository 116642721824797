import Tag from 'components/Tags';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Spotlight,
  SpotlightImage,
  SpotlightDesc,
  SpotlightDescription,
  SpotlightTitle,
  TagNews,
} from './style';

type Props = {
  data: any;
  to: string;
  tag?: string;
  theme?: string;
  themeTag?: string;
};

const ScheduleNews: React.FC<Props> = ({ data, to, tag, theme, themeTag }) => {
  return (
    <>
      <Spotlight>
        <Link to={to ? to : ''} title={data.title}>
          <img src={data.thumbnail} alt="" />
          <SpotlightImage color={theme}>
            <SpotlightDesc>
              <TagNews>
                {tag && <Tag type={tag} theme={themeTag || theme} />}
              </TagNews>
              <SpotlightTitle>{data.title}</SpotlightTitle>
              <SpotlightDescription>{data.description}</SpotlightDescription>
            </SpotlightDesc>
          </SpotlightImage>
        </Link>
      </Spotlight>
    </>
  );
};

export default ScheduleNews;
