import { LinkButton, ProductCard } from 'components';
import { ContainerGlobal } from 'components/ContainerGlobal/style';
import React, { useEffect, useState } from 'react';
import { getAllProducts } from 'services/product';
import { HomeWrapper, Header, SectionTitle, Row } from '../style';
import IProduct from '../../../services/product/models/product-interface';

const ProductsList: React.FC = () => {
  const [products, setProducts] = useState<Array<IProduct>>();
  const getMainProducts = async () => {
    const products = await getAllProducts({limit: 5});
    setProducts(products)
  }
  useEffect(() => {
    getMainProducts();
  }, [])
  return(
    <HomeWrapper background="#E5E5E5">
    <div>
      <ContainerGlobal>
        <Header>
          <SectionTitle>Nossos produtos</SectionTitle>
          <LinkButton to="/produtos" variant="TextLink">
            Ver mais
          </LinkButton>
        </Header>
        <Row>
          {products?.map((product: IProduct, key:number) => (
            <ProductCard product={product} key={`product-${key}`}/>
          ))}
        </Row>
      </ContainerGlobal>
    </div>
  </HomeWrapper>
  );
}

export default ProductsList;