import styled from 'styled-components';
import { px2rem } from 'styles/globals';

interface BigDayProps {
  active?: boolean
}


const Wrapper = styled.div`
  @media screen and (min-width: 1201px){
    box-sizing: border-box;
    max-width: 1424px;
    width: 100%;
    margin-inline: auto;
  }

`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1424px;
  margin: 0 auto ${px2rem(50)};

  @media screen and (min-width: 1201px){
    flex-direction: row;
    padding: 0 4rem;
  }
`;

const CalendarTitle = styled.h2`
  font-weight: 700;
  color: var(--black-clear);
  font-size: 24px;
  @media screen and (min-width: 1201px){
    font-size: ${px2rem(32)};
    margin-right: ${px2rem(30)};
  }
`;

const WrapperInput = styled.div`
  align-items: center;
  display: flex;
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid var(--blue-darker);
  justify-content: space-between;
  height: ${px2rem(32)};
  padding: 0 ${px2rem(16)};
  border-radius: ${px2rem(16)};
  cursor: pointer;
  @media screen and (min-width: 1201px){
    max-width: 225px;
    width: 100%;
  }
  @media screen and (max-width: 1200px){
    margin-top: 30px;
    max-width: 220px;
  }
`;


const NoContent = styled.div`
  padding: 20px 0;

`;

const CalendarWrapper = styled.div`
  > p{
    margin: 30px 0 10px;
    color: #081D3D;
    font-weight: 800;
    font-size: 16px;
    border-bottom: 4px solid #12428A;
    padding-bottom: 7px;
  }
  @media screen and (min-width: 1201px){
    padding: 0 4rem;
  }
`;

const CalendarHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  @media screen and (min-width: 1201px){
    padding: ${px2rem(25)} 0;
    justify-content: space-between;
  }
`;

const BeforeMonth = styled.div`
  display: flex;
  span{
    text-transform: uppercase;
    font-size: ${px2rem(24)};
  }
  img{ width: 25px; }

  @media screen and (min-width: 1201px){
    img{ width: ${px2rem(54)}; }
  }
`;

const SpotEvent = styled.p`
  text-align: left;
  font-size: ${px2rem(15)};
  font-weight: 700;
  color: #12428A;
  margin: ${px2rem(4)} 0;
  padding: 2px 0;
  overflow: hidden; // Removendo barra de rolagem
  text-overflow: ellipsis; // Adicionando "..." ao final
  display: -webkit-box;
  -webkit-line-clamp: 2; // Quantidade de linhas
  -webkit-box-orient: vertical; 
`


const CurrentMonth = styled.div`
  span{
    text-transform: uppercase;
    color: var(--blue-medium);
    font-weight: 700;
    font-size: 20px;
  }
  @media screen and (min-width: 1201px){
    span{
      font-size: ${px2rem(40)};
    }
  }
  @media screen and (max-width:1200px){
    margin-right: 20px;
  }
`;

const AfterMonth = styled.div`
  display: flex;
  span{
    text-transform: uppercase;
    font-size: ${px2rem(24)};
  }
  img{ width: 25px; }

  @media screen and (min-width: 1201px){
    img{ width: ${px2rem(54)}; }
  }
`;

const WeekDays = styled.div`
  display: flex;
  justify-content: space-around;
  @media screen and (min-width: 1201px){
    gap: ${px2rem(11)};

  }
`;

const Day = styled.span`
  text-transform: uppercase;
  color: var(--blue-darkness);
  font-weight: 600;
  padding: ${px2rem(20)} 0;
  width: ${px2rem(194)};
  text-align: center;
`;

const Week = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;

  @media screen and (min-width: 1201px){
    gap: ${px2rem(11)};
  }

`;
const CellDay = styled.div`
  text-align: center;
  cursor: pointer;
  padding: 5px;
  span{
    color: var(--blue-darkness);
    font-weight: 800;
    font-size: 24px;
  }
  small{
    text-transform: uppercase;
    font-size: ${px2rem(18)};
    margin-left: 10px;
  }
  &.disabled{
    border-color: #8A8A8A;
    background-color: #F2F2F2;
    span, small{
      color: #c3c3c3;
    }
  }

  @media screen and (min-width: 1201px){
    border-top: ${px2rem(8)} solid var(--blue-medium);
    width: ${px2rem(194)};
    height: ${px2rem(180)};
    padding: ${px2rem(12)} ${px2rem(16)};
    overflow: hidden;
    span{
      font-size: ${px2rem(36)};
    }
  }
  @media screen and (max-width: 1200px){
    &.disabled{
      border-radius: 50%;
    }
  }
`;

const BigDay = styled.p<BigDayProps>`
  border-top: ${px2rem(8)} solid var(--blue-medium);
  margin-top: ${px2rem(25)};
  padding: 5px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
  cursor: pointer;
  span{
    color: var(--blue-darkness);
    font-weight: 800;
    font-size: ${px2rem(20)};
  }
  small{
    text-transform: uppercase;
    font-size: ${px2rem(18)};
    margin-left: 10px;
  }
  ${props => props.active ? `
    background-color: #e7e7e7;
  ` : `background-color: #f2f2f2;`}

  @media screen and (min-width: 1201px){
    padding: 10px;
    span{
      font-size: ${px2rem(30)};
    }
    &:hover{
      background-color: #e7e7e7;
    }
  }
  

`;

const CardEventWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${px2rem(32)};
  border: 2px solid #F2F2F2;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), 0px 0px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: ${px2rem(25)};
  p{
    font-size: ${px2rem(14)};
    color: #362C2C;
    overflow: hidden; // Removendo barra de rolagem
    text-overflow: ellipsis; // Adicionando "..." ao final
    display: -webkit-box;
    -webkit-line-clamp: 3; // Quantidade de linhas
    -webkit-box-orient: vertical; 
  }
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
  > div{
    display: flex;
    margin-top: ${px2rem(32)};
    align-items: center;
    >div{
      font-size: ${px2rem(16)};
      color: var(--grey-darker);
      margin-right: ${px2rem(50)};
      img{
        margin-right: ${px2rem(10)};
      }
    }
    a{
      height: ${px2rem(36)};
      padding: 0;
      text-transform: uppercase;
      font-weight: 500;
      font-size: ${px2rem(14)};
      max-width: ${px2rem(196)};
      margin-left: auto;
    }
  }

  @media screen and (max-width: 1200px){
    >div{
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

`;

const EventType = styled.small`
  color: #081D3D;
  font-size: ${px2rem(24)};
  font-weight: 700;
`;

const EventTitle = styled.span`
  color: #12428A;
  font-weight: bold;
  margin: 15px 0;
  font-size: 24px;
  @media screen and (min-width: 1201px){
    font-size: ${px2rem(34)};
  }
`

export {
  Wrapper,
  NoContent,
  Header,
  CalendarTitle,
  WrapperInput,
  CalendarWrapper,
  CalendarHeader,
  BeforeMonth,
  CurrentMonth,
  AfterMonth,
  WeekDays,
  Day,
  Week,
  CellDay,
  BigDay,
  CardEventWrapper,
  EventType,
  EventTitle,
  SpotEvent
}