import React, { ButtonHTMLAttributes, useState } from 'react';
import { ButtonUI } from './style';
import { Icon } from 'components';
import { IconsTypes } from 'components/Icon/catalogue';

type VariantThemes = 'Outline' | 'White' | 'DefaultDark' | 'Default';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: VariantThemes;
  typeIcon?: IconsTypes;
  reverseIcon?: boolean;
  active?:boolean;
}

const Button: React.FC<IButtonProps> = ({
  children,
  variant = 'Default',
  typeIcon,
  reverseIcon,
  ...rest
}) => {
  const [themeHover, setThemeHover] = useState(false);

  const handleThemeOver = () => {
    setThemeHover(true);
  };
  const handleThemeOut = () => {
    setThemeHover(false);
  };

  const renderIcon = (selectedtypeIcon: IconsTypes | undefined) => {
    if (variant === 'Default' || variant === 'DefaultDark') {
      if (selectedtypeIcon) {
        return (
          <div style={{ marginLeft: '10px', marginRight: '10px' }}>
            <Icon name={selectedtypeIcon} color={'white'} size={30} />
          </div>
        );
      }

      return null;
    }

    if (variant === 'Outline' || variant === 'White') {
      if (selectedtypeIcon) {
        return (
          <div style={{ marginLeft: '10px', marginRight: '10px' }}>
            <Icon
              name={selectedtypeIcon}
              color={themeHover ? 'white' : '#081D3D'}
              size={30}
            />
          </div>
        );
      }

      return null;
    }
  };

  return (
    <ButtonUI
      {...rest}
      variant={variant}
      reverseIcon={reverseIcon}
      type="button"
      onMouseOver={handleThemeOver}
      onMouseLeave={handleThemeOut}
    >
      {children}
      {renderIcon(typeIcon)}
    </ButtonUI>
  );
};

export default Button;
