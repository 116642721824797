import { ArticleCard, LinkButton, SpolightCard } from 'components';
import { ContainerGlobal } from 'components/ContainerGlobal/style';
import React, { useEffect, useState } from 'react';
import { getInfoContent } from 'services/info';
import { engagementRecord } from 'services/user';
import { HomeWrapper, Header, SectionTitle, Row, Grid } from '../style';

const CategoriesList: React.FC = () => {
  const [content, setContent] = useState<any>();

  useEffect(() => {
    const getContent = async () =>{
      const content = await getInfoContent({
        limit: 3
      });
      setContent(content)
    }
    getContent();
  },[])

  return(
    <>
      {content?.map((content:any, key: number) => {
        const item = (key + 1) % 2 == 0;
        if(content.info.items.length <= 0) {
          return null;
        }
        const pathResolver = () => {
          switch(content?.info?.cover?.type){
            case 'VIDEO':
              return `/video/play/${content?.info?.cover?.content_id}`
            case 'PODCAST':
              return `/podcast/play/${content?.info?.cover?.content_id}`  
            default:
              return `/noticias/${content?.info?.cover?.news_id}`  
          }
        }
        const coverTag = content?.info?.cover?.type ? content?.info?.cover?.type : 'NOTÍCIA';
        return(
          <HomeWrapper background={item ? "#E5E5E5" : '#FFF'} key={`category-${key}`} >
            <div>
              <ContainerGlobal>
                <Header>
                  <SectionTitle>{content?.category?.title}</SectionTitle>
                  <LinkButton to={`/categoria/${content?.category?.category_id}`} variant="TextLink">
                    Ver mais
                  </LinkButton>
                </Header>
                <Row reverse={item}>
                  {content?.info?.cover && <SpolightCard tag={coverTag}  to={pathResolver()} data={content?.info?.cover} theme={content?.category?.info?.color}/>}
                  <Grid>
                    {content?.info?.items?.map((item: any, key:number) => {
                      const itemType = item?.type ? item?.type : 'NOTÍCIA';
                      return(
                        <ArticleCard onClick={() => engagementRecord(item?.content_id, itemType) } tag={itemType} data={item} key={`content-${item?.content_id}-${key}`} theme={content?.category?.info?.color}/>
                      )
                    })}
                  </Grid>
                </Row>
              </ContainerGlobal>
            </div>
          </HomeWrapper>
        )
      })}
      
    </>
  );
}

export default CategoriesList;