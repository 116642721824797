import httpClient from 'http-client';
import FAQ from './models/faq-interface';

interface ISendEmail {
  name: string;
  email: string;
  text: string;
  phone: string;
  subject?: string;
}

const sendEmailContactUs = async (
  data: ISendEmail,
  headers?: object,
): Promise<void> => {
  await httpClient.post('/faqs/mail', data, {
    headers,
  });
};

const getFaqData = async (
  filters?: object,
  headers?: object,
): Promise<Array<FAQ>> => {
  const faqs: FAQ[] = (
    await httpClient.get('/faqs', {
      params: filters,
      headers,
    })
  ).data;

  return faqs;
};

export { sendEmailContactUs, getFaqData };
