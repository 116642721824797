import React, { useContext, createContext } from 'react';
import { useProvideAuth } from './hooks/useProvideAuth';
import { LoginData } from 'services/login';
import { User } from 'services/user/models/user-interface';

export interface IAuthContext {
  user: User | null;
  isAuthenticated: boolean;
  signIn: (data: LoginData) => Promise<any>;
  signOut: () => void;
  token: string;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = (): IAuthContext => useContext(AuthContext);
