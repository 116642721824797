import React from 'react';
import { TextArea, InputWrapper, IDesktop, IMobile } from './style';

interface IInputTextAreaProps {
  value: string | number | readonly string[] | undefined;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: () => void;
  type: string;
  id?: string;
  label: string;
  alert?: string;
  width?: string;
  required?: boolean;
  alertMessage?: string;
}

const TextAreaUI: React.FC<IInputTextAreaProps> = ({
  children,
  onChange,
  value,
  id,
  onFocus,
  label,
  alertMessage,
  required,
}) => {
  return (
    <>
      <InputWrapper>
        <>
          <label htmlFor={id}>
            {label}
            {required && <span className="required">*</span>}
          </label>
          {alertMessage ? <span className="alert">{alertMessage}</span> : null}
          <IDesktop>
            <TextArea
              rows={14}
              id={id}
              value={value}
              onChange={onChange}
              onFocus={onFocus}
            >
              {children}
            </TextArea>
          </IDesktop>
          <IMobile>
            <TextArea
              rows={5}
              id={id}
              value={value}
              onChange={onChange}
              onFocus={onFocus}
            >
              {children}
            </TextArea>
          </IMobile>
        </>
      </InputWrapper>
    </>
  );
};

export default TextAreaUI;
