import { ArticleCard, SpolightCard } from 'components';
import React, { useEffect, useState } from 'react';
import { getAllNews } from 'services/news';
import { Header, SectionTitle,Row, Grid, Wrapper } from './style';
import News from 'services/news/models/news-interface';
import { getAllCommentArticle } from 'services/commentedArticle';
import CommentArticle from 'services/commentedArticle/models/commentArticle-interface';

interface IArticleList{
  theme?: string
  categoryId?: string
  show?: any
  reverse?: boolean
}

const ArticleList: React.FC<IArticleList> = ({ theme, categoryId, show, reverse }) => {
  const [articles, setArticles] = useState<Array<CommentArticle>>();
  const getNews = async (id :string) => {
    const articles = await getAllCommentArticle({ category_id: id, limit: 5, type: 'MONOGRAFIA' })
    setArticles(articles)
  }

  useEffect(() => {
    if(categoryId)
      getNews(categoryId)

  }, [categoryId])
  
  return(
    <Row reverse={reverse}>
    {/*getNews(category?.category_id)*/}
    <SpolightCard theme={theme} data={articles && articles[0]} external={`${articles && articles[0]?.reference}`}/>
    <Grid>
      {articles?.slice(1,5).map((content: CommentArticle, key: number) =>(
        <ArticleCard path={content?.reference} article theme={theme} data={content} tag="Artigos" key={key}/>
      ))}
    </Grid>
  </Row>
  );
}

export default ArticleList;