import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getAllContacts } from 'services/contact';
import Contact from 'services/contact/models/contact-interface';
import getErrorMessage from 'helpers/errorMessages';
import { getValue } from 'utils/storage';

type ReturnTypeUseCardContent = [boolean, boolean, Contact[]];

export const useCardContact = (): ReturnTypeUseCardContent => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [cardContact, setCardContact] = useState<Contact[]>([]);
  useEffect(() => {
    const getCardContacts = async () => {
      try {
        setError(false);
        setLoading(true);
        const user = getValue<string>({ prop: 'exeltis-user' });
        let localCardsContact;

        if (user) {
          localCardsContact = await getAllContacts({
            is_active: true,
          });
        } else {
          const token = 'Bearer ' + process.env.REACT_APP_UNLOGGED_TOKEN;
          localCardsContact = await getAllContacts(
            {
              is_active: true,
            },
            { Authorization: token },
          );
        }
        setCardContact(localCardsContact);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        toast.error(errorMessage);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    getCardContacts();
  }, []);

  return [loading, error, cardContact];
};

export default useCardContact;
