import React from 'react';
import { ArticleCard, LinkButton } from 'components';
import { Row } from './styles';
import { engagementRecord } from 'services/user';

interface IArticleSession{
  data?: any;
  origin?: string
  type: string
}
const ArticleSession: React.FC<IArticleSession> = ({ data, origin, type }) => {
  return(
    <>
      <Row>
        {data?.map((item :any, key:number) => (
          <ArticleCard onClick={() => engagementRecord(item?.content_id, type)} data={item} article path={item?.reference} key={`article-${key}`} />
        ))}
      </Row>
      <LinkButton variant="Outline" to={`/${origin}`} typeIcon="more" reverseIcon={true}>
        Ver mais
      </LinkButton>
    </>
  );
}

export default ArticleSession;