import styled, { css, keyframes } from 'styled-components';

type VariantPosition = 'Top' | 'Botton' | 'Center';

interface IModal {
  width?: string;
  position?: VariantPosition;
}

const fadeIn = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;

const transition = keyframes`
	z-index: 10;
	0% {
		opacity: 0;
		transform: translate(0px, 30px);
	}
	100% {
		opacity: 1;
		transform: translate(0px, 0px);
	}
`;

export const Body = styled.div<IModal>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 500;
  display: flex;
  align-items: center;
  background: #00000094;
  animation: ${fadeIn} 0.2s linear;

  flex-direction: column;

  ${props =>
    props.position === 'Center' &&
    css`
      justify-content: center;
    `}

  ${props =>
    props.position === 'Top' &&
    css`
      justify-content: initial;
      padding-top: 5.7rem;
    `}

    ${props =>
    props.position === 'Botton' &&
    css`
      justify-content: flex-end
    `}

  .container-content-modal {
    width: ${props => props.width};
    display: flex;
    justify-content: center;
  }

  .closeOutStyled {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    margin-bottom: 20px;
  }

  .content {
    padding: 10px;
    max-height: 100vh;
    width: 90%;
    border-radius: 10px;
    height: auto;
    background: white;
    box-shadow: 0px 24px 64px rgba(29, 31, 36, 0.13);
    animation: ${fadeIn} 0.4s linear;
    border: 2px solid #e1e3e6;

    .containerIcon {
      cursor: pointer;
    }

    @media screen and (max-width: 610px) {
      position: fixed;
      /* bottom: 0; */
      /* width: 97%; */
      top: 0;
      border-radius: 0;
      width: 100%;
      height: 100%;
      animation: ${transition} 0.4s linear;
    }
  }

  @media screen and (max-width: 610px) {
    /*position: fixed;
    top: 0;
    left: 0;
    transform: initial;
    display: flex;
    align-items: center;
    justify-content: center; */
  }
`;

export const ICloseOut = styled.div`
  border-radius: 100%;
  width: 60px;
  height: 60px;
  box-shadow: 0px 24px 64px rgba(29, 31, 36, 0.13);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 610px) {
    display: none;
  }
`;

export const ICloseIn = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-left: -13px;
  margin-top: 10px;

  @media screen and (max-width: 610px) {
    /* display: none; */
  }
`;

export const BarModal = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px auto;
  align-items: center;
  margin-top: -16px;
  margin-bottom: -16px;

  @media screen and (min-width: 611px) {
    display: none;
  }
`;
