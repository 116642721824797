import httpClient from 'http-client';
import { Comment } from './models/comment-interface';

const getContentComment = async (content_id: string) : Promise<Array<Comment>> => {
  const comment : Comment[] = (await httpClient.get(`/comments/${content_id}`)).data;
  return comment
}

const postComment = async (data: any) : Promise<any> => {
  const comment = (await httpClient.post(`/comments`, data)).data; 
  return comment;
}

const deleteCommentByCommentId = async (comment_id: string): Promise<any> => {
  const comment: any = (await httpClient.delete(`/comments/${comment_id}`))
    .data;
  return comment;
};

export{
  getContentComment,
  postComment,
  deleteCommentByCommentId
}