import styled from 'styled-components';
import { px2rem } from 'styles/globals';

const Wrapper = styled.div`

  .block {
    display: block;
  }

  .date-address-container {
    display: flex;
    flex-direction: row;
    gap: ${px2rem(60)};
    margin-top: ${px2rem(8)};
    margin-bottom: ${px2rem(25)};

    .date-address-wrapper {
      display: flex;
      flex-direction: row;
      gap: ${px2rem(10)};

      .date-address-icon{
        align-self: center;
        height: ${px2rem(16)};
        width: 20px;
      }

      .date-address-text {
        flex: 1;
        align-self: center;
        font-size: ${px2rem(16)};
        line-height: ${px2rem(16)};
        color: var(--black-clear);
        letter-spacing:  ${px2rem(1.5)};
        line-height: 16px;
        font-family: Roboto, sans serif;

        .bold {
          font-weight: 700;
        }
      }
    }
  }

  .title {
    font-size: ${px2rem(24)};
    line-height: ${px2rem(24)};
    font-weight: 700;
    color: var(--blue-darkness);
  }

  .subtitle {
    margin-top: ${px2rem(8)};
    margin-bottom: ${px2rem(25)};
    font-size: ${px2rem(34)};
    font-weight: 700;
    color: var(--blue-medium);
    line-height: ${px2rem(36)};
  }

  @media screen and (max-width: 998px){

    .title {
      font-size: ${px2rem(18)};
      line-height: ${px2rem(18)};
    }

    .subtitle {
      font-size: ${px2rem(24)};
      line-height: ${px2rem(28)};
    }

    .date-address-container {
      flex-direction: column;
      gap: ${px2rem(19)};
    }
  }
`

export { Wrapper };
