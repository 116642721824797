const meses = [
  'JAN',
  'FEV',
  'MAR',
  'ABR',
  'MAIO',
  'JUN',
  'JUL',
  'AGOS',
  'SET',
  'OUT',
  'NOV',
  'DEZ',
];

function formatEventDate(dateString: Date): string {
  const date = new Date(dateString);
  const day = adicionaZero(date.getDate());
  const month = adicionaZero(date.getMonth() + 1);
  const year = date.getFullYear();
  const hour = adicionaZero(date.getHours());
  const minute = adicionaZero(date.getMinutes());
  return `${day}/${month}/${year} - ${hour}H${minute}`;
}

function adicionaZero(numero: number) {
  if (numero <= 9) return '0' + numero;
  else return numero;
}

function formatTitleDate(dateString: Date): string {
  const date = new Date(dateString);
  const day = adicionaZero(date.getDate());
  const month = meses[date.getMonth()];

  return `${day} ${month}`;
}

export { formatEventDate, formatTitleDate };
