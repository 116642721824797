import React, { useState } from 'react';
import { postComment } from 'services/comments';
import { FormComment, WrapperButtons } from './style';

interface IFormResponse{
  comment_id?: string
  data?: any
  setShowResponse?: any
  contentId?: string
}

const FormResponse: React.FC<IFormResponse> = ({ contentId, comment_id, data, setShowResponse }) => {
  const [response, setResponse] = useState('');
  const [showButtonsResponse, setShowButtonsResponse] = useState<boolean>();

  const handlePostResponseComment = async (e: React.FormEvent ) => {
    e.preventDefault();
    const data ={
      description: response,
      reference_id: contentId,
      comment_reference_id: comment_id
    }
    await postComment(data).then(() => {
      setResponse('')
    });
  }

  return(
    <FormComment method="POST" onSubmit={e => handlePostResponseComment(e)}>
        <div className="form">
          <img src={data?.info?.avatar} alt=""/>
          <textarea
            placeholder="Escreva aqui seu comentário..."
            value={response}
            onChange={e => setResponse(e.target.value)}
            onFocus={() => setShowButtonsResponse(true)}
          />
        </div>
        {showButtonsResponse && (
          <WrapperButtons>
            <button onClick={() =>{
              setShowResponse(false)
              setShowButtonsResponse(false)
            } }>
              Cancelar
            </button>
            <button onClick={e => handlePostResponseComment(e)}>
              Enviar
            </button>
          </WrapperButtons>
        )}
      </FormComment>
    )
}

export default FormResponse;