import { broadcast, mapPin, watch } from 'assets';
import { LinkButton } from 'components';
import convertState from 'helpers/convertState';
import { formatEventDate, formatTitleDate } from 'helpers/eventDate';
import React from 'react';
import IEvents from 'services/events/models/events-interface';
import {
  ScheduleWrapper,
  ScheduleDay,
  EventInfo,
  EventTitle,
  EventDescription,
  EventSpeakers,
  EventSubtitle,
  LiveTag,
} from './style';

const ScheduleCard: React.FC<IEvents> = ({
  event_id,
  title,
  subtitle,
  description,
  start_date,
  speakers,
  local,
  size,
  isOnline,
  is_active,
  is_live
}) => {
  const speaker = speakers?.map(speaker => speaker.name).join(', ');

  return (
    <ScheduleWrapper size={size}>
      <ScheduleDay>
        {is_live && <LiveTag>Ao Vivo</LiveTag>}
        <span>
          <strong>{formatTitleDate(start_date)}</strong>
        </span>
        <span></span>
      </ScheduleDay>
      <EventInfo>
        <EventTitle>{title}</EventTitle>
        <EventSubtitle>{subtitle}</EventSubtitle>

        {description ? (
          <EventDescription>
            {description.length > 90
              ? description.substring(0, 90) + '...'
              : description}
          </EventDescription>
        ) : (
          ''
        )}

        {!speaker ? (
          ''
        ) : (
          <EventSpeakers>
            <strong>Palestrantes: </strong>
            {speaker}
          </EventSpeakers>
        )}
        <ul>
          <li>
            <img className='icon' src={watch} alt="watch" />
            {formatEventDate(start_date)}
          </li>
          {isOnline ? (
            <li>
              <img className='icon' src={broadcast} alt="" />
              <span>ONLINE</span>
            </li>
          ) : (
            <li>
              <img src={mapPin} alt="watch" />
              {local.city} / {convertState(local.state)}
            </li>
          )}
        </ul>
        <LinkButton to={`/eventos/${event_id}`} variant="Outline">
          Saiba mais
        </LinkButton>
      </EventInfo>
    </ScheduleWrapper>
  );
};

export default ScheduleCard;
