import {
  LIST_ARTICLES_COMMENTEDS,
  LIST_ARTICLES_SCIENTIFIC,
  LIST_MONOGRAPHS,
} from 'routes/Constants';


export interface DropDownField {
  id: number;
  label: string;
  link: string;
}

const dataArticles: DropDownField[] = [
   {
    id: 0,
    label: 'Artigos Comentados',
    link: `${LIST_ARTICLES_COMMENTEDS}`,
  },
  {
    id: 1,
    label: 'Artigos científicos',
    link: `${LIST_ARTICLES_SCIENTIFIC}`,
  },
  {
    id: 2,
    label: 'Monografias',
    link: `${LIST_MONOGRAPHS}`,
  },
];

export default dataArticles;