import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PlayerSambaVideos } from 'components';
import { article_card_placeholder, comment, heart, monitorPlay } from 'assets';
import { useModal } from 'hooks';
import { SpolightHeader, Content } from './style';
import { Section } from './style';
import { getContentByCategoryType } from 'services/categories';
import { useParams } from 'react-router';
import { RowSpot } from '../../style';
import { Article, ArticleDescription, ArticleResume, ArticleTitle } from 'components/Cards/ArticleCard/style';
import { engagementRecord } from 'services/user';
import { updateProgressSingleContent, finishSingleContent as finishSingleContentService, startSingleContent as startSingleContentService, getContentInfo } from 'services/contents';

interface IHighlightCarrousel {
  theme?: string;
  type?: string;
  title?: string;
}

interface PlayerEventListener {
  event: string;
  eventParam?: any;
  duration?: any;
}

interface ExtendedWindow extends Window {
  hasStartedContent: boolean;
  hasFinishedContent: boolean;
}

declare let window: ExtendedWindow;

const HighlightCarrousel: React.FC<IHighlightCarrousel> = ({
  theme,
  type,
  title
}) => {
  const [content, setContent] = useState<any>();
  const [selectedContent, setSelectedContent] = useState<any>();
  const [currentContent, setCurrentContent] = useState<any>();
  const [progress, setProgress] = useState<number>(0);
  const [lastWatchedTime, setLastWatchedTime] = useState<number>(0);
  const { categoryId } = useParams<{
    categoryId: string
  }>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getData = async () => {
    const contents = await getContentByCategoryType('', 'VIDEO');
    setContent(contents)
    setSelectedContent(contents[0]?.content_id)
  }

  const getRowContent = (data: any) => {
    return (
      <>
        {data?.slice(0, 4)?.map((item: any, key: number) => (
          <Article key={`article-${key}`} onClick={() => setSelectedContent(item?.content_id)} isActive={selectedContent === item?.content_id} color={item?.categories[0]?.info?.color}>
            <div><img src={item?.thumbnail ? item?.thumbnail : article_card_placeholder} alt="" /></div>
            <ArticleDescription>
              <ArticleTitle>{item?.title}</ArticleTitle>
              <ArticleResume>{item?.description}</ArticleResume>
            </ArticleDescription>
          </Article>
        ))}
      </>
    )
  }

  useEffect(() => {
    window.hasStartedContent = false;
    window.hasFinishedContent = false;
    getData()
  }, [categoryId])



  useEffect(() => {
    const getCurrentContent = async () => {
      if (selectedContent) {
        const content = await getContentInfo(selectedContent);
        setCurrentContent(content)
      }
    }
    getCurrentContent();
  }, [selectedContent])


  const currentSpotContent = content && content?.filter((content: any) => content?.content_id === selectedContent).shift();

  const getEventListeners = async (player: PlayerEventListener) => {
    if (currentContent && player.event === 'onStart') {
      await engagementRecord(currentContent?.content_id, 'VIDEO');
    }
    if (currentContent && currentContent.content_id) {
      switch (player.event) {
        case 'onProgress':
          if (hasReachedCompletionTime(player.duration, player.eventParam)) {
            await finishContent();
          }
          updateWatchTime(player);
          break;

        case 'onStart':
          startContent();
          break;

        case 'onFinish':
          goToNextContent();
          break;

        default:
          break;
      }
    }
  };

  const updateContentProgress = useCallback(
    async (time: number | null) => {
      if (time && time > 0) {
        await updateProgressSingleContent({ content_id: selectedContent, content_view: time });
      }
    },
    [selectedContent]
  );

  const resumeTime = useMemo(() => {
    setProgress(0)
    if (!currentContent) {
      return false;
    }
    if (currentContent?.content_user) {
      setLastWatchedTime(Number(currentContent.content_user.content_view));
      return Number(currentContent?.content_user.content_view);
    }

    return false;
  }, [currentContent]);


  useEffect(() => {
    if (progress >= lastWatchedTime + 30) {
      setLastWatchedTime(progress);
      updateContentProgress(progress);
      return;
    }
    if (progress < lastWatchedTime) {
      if (progress > 1) {
        setLastWatchedTime(progress);
        updateContentProgress(progress);
        return;
      }
      setLastWatchedTime(0);
      updateContentProgress(null);
    }
  }, [lastWatchedTime, progress, updateContentProgress]);


  const hasReachedCompletionTime = (duration: number, currentTime: number) => {
    const totalDurationInSecs = duration;
    const completionRate = 0.9;

    const completionTime = totalDurationInSecs * completionRate;
    return currentTime >= completionTime;
  };

  const finishContent = async () => {
    if (currentContent) {
      if (!window.hasFinishedContent && !currentContent.content_user.finish_at) {
        try {
          window.hasFinishedContent = true;
          await finishSingleContentService(currentContent.content_id);
          currentContent.content_user.finish_at = true;
        } catch {
          window.hasFinishedContent = false;
        }
      }
    }
  };

  const updateWatchTime = useCallback((player: PlayerEventListener) => {
    const { event, eventParam } = player;
    if (event === 'onProgress') {
      setProgress(eventParam);
    }
    return null;
  }, []);

  const startContent = async () => {
    if (currentContent) {
      if (!window.hasStartedContent && !currentContent.content_user) {
        try {
          window.hasStartedContent = true;
          currentContent.alreadyStarted = true;
          await startSingleContentService(currentContent.content_id);
        } catch {
          window.hasStartedContent = false;
          currentContent.alreadyStarted = false;
        }
      }
    }
  };

  const goToNextContent = async () => { };


  if (!content)
    return null

  if (content?.length <= 0)
    return null;

  return (
    <Section>
      <div>
        <Content>
          <div className="video">
            <PlayerSambaVideos
              contentReference={currentContent?.reference}
              controlsToEnable={[
                'play',
                'pause',
                'quality',
                'fullscreen',
                'time',
                'volume',
                'seekbar',
              ]}
              getEventListeners={getEventListeners}
              resume={resumeTime}
            />
          </div>
          <div>
            <SpolightHeader>
              <div>
                <h3>{currentContent?.title}</h3>
                <p>{currentContent?.description}</p>
              </div>
            </SpolightHeader>
            <RowSpot>
              {getRowContent(content)}
            </RowSpot>
          </div>
        </Content>
      </div>
    </Section>
  );
}

export default HighlightCarrousel;