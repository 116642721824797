import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column-reverse;
`;

export const Section = styled.section`
  margin-bottom: 50px;
  padding-bottom: 60px;
  border-bottom: 1px solid #c2c4c5;

  :first-child {
    border-bottom: none;
  }

  h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 25px;
  }

  .product-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
    grid-gap: 20px;
    align-items: stretch;
  }

  @media only screen and (max-width: 500px) {
    .product-content {
      grid-template-columns: repeat(auto-fill, minmax(221px, 1fr));
    }
  }
`;
