import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';

function compareDate(date: string): string {
  const actualDate = new Date(date);
  const responseDate = formatDistanceToNow(actualDate, {
    addSuffix: true,
    locale: ptBR,
  });

  return responseDate;
}

function formattedDate(date: Date): string {
  const dateFormat = new Intl.DateTimeFormat('pt-BR', {
    dateStyle: 'long',
  }).format(new Date(date));

  return dateFormat;
}

export { compareDate, formattedDate };
