import React, { AnchorHTMLAttributes } from 'react';
import styled from 'styled-components';

interface IButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
}

const Container = styled.a`
  width: 100%;
  background-color: transparent;
  border: 1px solid var(--blue-darker);
  color: var(--blue-darker);
  font-size: 16px;
  font-weight: 700;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    background-color: var(--blue-darkness);
    color: white;
    transition: 0.4s;
  }
`;

const ButtonOutline: React.FC<IButtonProps> = ({ children, href }) => {
  return (
    <Container href={href} target="_blank">
      {children}
    </Container>
  );
};

export default ButtonOutline;
