/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { d_video, m_video } from 'assets';
import {
  ArticleCard,
  BreadCrumb,
  Template,
  LoadingBg,
  MessageError,
  Error,
  Pagination,
} from 'components';
import { Link, useParams } from 'react-router-dom';
import { HOME, LADINGPAGE } from 'routes/Constants';
import smoothscroll from 'smoothscroll-polyfill';
import { WrapperList, GridList, A } from '../style';
import ICommentArticles from 'services/commentedArticle/models/commentArticle-interface';
import ICategories from 'services/categories/models/category-interface';
import { getAllCommentArticle } from 'services/commentedArticle';
import { getCategories, getContentByCategoryType } from 'services/categories';
import { toast } from 'react-toastify';
import getErrorMessage from 'helpers/errorMessages';

smoothscroll.polyfill();

const VideoList: React.FC = ({}) => {
  const [filteredCommentsArticles, setFilteredCommentsArticles] = useState<
    ICommentArticles[]
  >([]);
  const [allCategories, setAllCategories] = useState<ICategories[]>([]);
  const [selectedCategory, setSelectedCatogory] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const number_of_items = 12;
  const number_of_pages = useMemo(() => {
    return Math.ceil(filteredCommentsArticles?.length / number_of_items);
  }, [filteredCommentsArticles]);

  const articles_to_show = useMemo(() => {
    return [...filteredCommentsArticles].splice(
      page * number_of_items,
      number_of_items,
    );
  }, [filteredCommentsArticles, page, number_of_items]);

  const { category_id } = useParams<any>();

  const getAllCategories = useCallback(async () => {
    try {
      const all_categories = await getCategories();

      setAllCategories(all_categories);
      !selectedCategory && setSelectedCatogory(category_id);
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      toast.error(errorMessage);
    }
  }, [category_id, selectedCategory]);

  const getAllArticles = useCallback(async () => {
    try {
      setError(false);
      const category = selectedCategory || category_id || undefined;
      const all_comments_articles = await getContentByCategoryType('', 'VIDEO');

      const filteredCategory = all_comments_articles.filter(
        (item: { categories: { category_id: string | undefined }[] }) =>
          item.categories.length !== 0 &&
          item.categories[0].category_id === category,
      );

      if (category_id) setFilteredCommentsArticles(filteredCategory);
      else setFilteredCommentsArticles(all_comments_articles);
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      toast.error(errorMessage);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [category_id, selectedCategory]);

  useEffect(() => {
    getAllCategories();
  }, [getAllCategories, category_id, selectedCategory]);

  useEffect(() => {
    getAllArticles();
  }, [getAllArticles, selectedCategory]);

  if (loading) return <LoadingBg />;
  if (error) return <Error type="LoadFail" />;

  const selectCategory = (category: string) => {
    setPage(0);
    setSelectedCatogory(category);
  };
  const renderCardsAllCommentsArticles = (items: any) => {
    if (items.length === 0) {
      return <MessageError content="Sem vídeos no momento." />;
    }

    return items.map((item: any) => {
      const theme = item?.categories[0]?.info?.color;
      return (
        <ArticleCard
          path={`/video/play/${item?.content_id}`}
          key={item.content_id}
          data={item}
          themeTag={theme}
          tag="Video"
        />
      );
    });
  };

  const renderAllCategories = (items: ICategories[]) => {
    if (items.length === 0)
      return <h3>Não tem categorias cadastradas no momento.</h3>;

    return items?.map(item => (
      <Link
        style={{ textDecoration: 'none' }}
        to={`/video/listagem/${item.category_id}`}
      >
        <li
          key={item.category_id}
          onClick={() => selectCategory(item.category_id)}
          className={category_id == item?.category_id ? `active` : ''}
        >
          {item.title}
        </li>
      </Link>
    ));
  };

  return (
    <>
      <Template
        banner={{
          title: 'Video',
          imgDesktop: d_video,
          imgMobile: m_video,
        }}
        breadcrumb={
          <BreadCrumb
            crumbs={[<Link to={`${HOME}`}>HOME</Link>, <span>Video</span>]}
          />
        }
      >
        <WrapperList>
          <div className="summary">
            <p>Categorias</p>
            <ul>{renderAllCategories(allCategories)}</ul>
          </div>
          <A>
            <GridList>
              {renderCardsAllCommentsArticles(articles_to_show)}
            </GridList>

            <Pagination
              actualPage={page}
              pages={number_of_pages}
              changePage={setPage}
            />
          </A>
        </WrapperList>
      </Template>
    </>
  );
};

export default VideoList;
