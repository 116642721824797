import React, { useEffect, useState } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import { toast } from 'react-toastify';
import Slider from 'components/Slider';
import { getInfoByType, getInfoContent } from 'services/info';
import getErrorMessage from 'helpers/errorMessages';
import { getValue } from 'utils/storage';
import Info from 'services/info/models/info-interface';
import {
  MessageError,
  LoadingBg,
  Error,
} from 'components';
import CategoriesList from './components/CategoriesList';
import ProductsList from './components/ProductsList';
import ScheduleList from './components/ScheduleList/ScheduleList';
import { useHistory } from 'react-router-dom';

smoothscroll.polyfill();

const Home: React.FC = () => {
  const [banners, setBanners] = useState<Array<Info>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const history = useHistory();
  const path = localStorage.getItem('path');

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1.01,
    slidesToScroll: 1,
    centerMode: true,
    className: 'center',
    centerPadding: '85px',
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          centerPadding: '0px',
        },
      },
    ],
  };

  useEffect(() => {
    const getHomeBannerContent = async () => {
      try {
        setError(false);
        setLoading(true);
        let localBanners;
        const user = getValue<string>({ prop: 'exeltis-user' });
        if (user) {
          localBanners = await getInfoByType('home_medic', {
            is_active: true,
          });
        } else {
          localBanners = await getInfoByType('home_medic', {
            is_active: true,
          });
        }
        setBanners(localBanners);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        toast.error(errorMessage);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    if (path) {
      history.push(path)
      localStorage.removeItem('path');
      localStorage.removeItem('internalContent');
    }

    getHomeBannerContent();
  }, []);

  if (loading) return <LoadingBg />;
  if (error) return <Error type="LoadFail" />;

  const renderBanner = (items: Info[]) => {
    if (items.length === 0)
      return (
        <MessageError content="Sem imagens cadastradas no momento ou o serviço está indisponível, tente mais tarde." />
      );

    return items.map((item, key) => {
      const regex = /www.exeltison.com.br\/(noticias|podcast|videos|categoria|artigos|produtos)/i;
      const internal = item?.info?.link?.match(regex);
      if (item.info.link) {
        if (internal) {
          return (
            <a href={item.info.link}>
              <img src={item.info.image} alt="" key={`banner-${key}`} />
            </a>
          )
        } else {
          return (
            <a target="_blank" href={item.info.link} rel="noreferrer">
              <img src={item.info.image} alt="" key={`banner-${key}`} />
            </a>
          )
        }
      }
      return <img src={item.info.image} alt="" key={`banner-${key}`} />
    });
  }

  return (
    <>
      <Slider settings={settings}>{renderBanner(banners)}</Slider>
      <CategoriesList />
      <ProductsList />
      <ScheduleList />
    </>
  );
};

export default Home;
