import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ContentNewsCard = styled.div`
  max-width: 100%;
  border-radius: 4px;
  -webkit-box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);
  -moz-box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);
  box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);
  background-color: var(--white);
  a{
    text-decoration: none;
  }
  .wrapper {
    padding: 20px;
  }

  img {
    width: 100%;
  }

  .title {
    color: var(--blue-darkness);
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  p {
    color: var(--black-medium);
    font-size: 14px;
    line-height: 20px;
  }

`;

interface ICard {
  id?: number;
  img: string;
  title: string;
  subtitle: string;
  to: string
}

const Cards: React.FC<ICard> = ({ img, title, subtitle, to }) => {
  return (
    <ContentNewsCard>
      <Link to={to} title={title}>
        <img src={img} alt="imagem" />
        <div className="wrapper">
          <h3 className="title">{title}</h3>
          <p>{subtitle}</p>
        </div>
      </Link>
    </ContentNewsCard>
  );
};

export default Cards;
