import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { validateEmail } from 'helpers/validation';
import { Input, Button, Back } from 'components';
import { logotipo } from 'assets';
import { getIp } from 'services/login';
import * as Style from './../../style';
import {
    HOME,
    LADINGPAGE,
    RESET,
    SIGNUP,
    RECOVER_VERIFY_CODE, SIGNUP_EXPORTED_USER, VERIFY_CODE,
} from 'routes/Constants';
import getErrorMessage from 'helpers/errorMessages';
import { useAuth } from 'providers/auth/AuthProvider';
import { resendCode } from 'services/signup';
import { purgeStorage } from 'utils/storage';

const Login: React.FC = () => {
    const history = useHistory();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [emailError, setEmailError] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');
    const { signIn } = useAuth();

    const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.target.id) {
            case 'email-input':
                setEmail(e.target.value);
                break;

            case 'password-input':
                setPassword(e.target.value);
                break;

            default:
                break;
        }
    };

    const handleLogin = async (): Promise<void> => {
        if (!email) {
            setEmailError('Este campo é obrigatório.');
        }

        if (!password) {
            setPasswordError('Este campo é obrigatório.');
            return;
        }

        if (validateEmail(email) === false) {
            setEmailError('Informe um e-mail com formato válido.');
            return;
        }

        const { ip } = await getIp();

        const data = {
            email,
            password,
            is_medic: true,
            ip,
        };


        signIn(data)
            .then(async response => {
                const { is_confirmed, is_exported, had_logged, email } = response
                if (is_exported && !is_confirmed && !had_logged) {
                    purgeStorage({ prop: 'exeltis-user' });
                    purgeStorage({ prop: 'exeltis-api-token' });
                    history.push(`${SIGNUP_EXPORTED_USER}`, { data: response })
                    return
                }
                if (is_exported && !is_confirmed && had_logged) {
                    purgeStorage({ prop: 'exeltis-user' });
                    purgeStorage({ prop: 'exeltis-api-token' });
                    const code = async () => await resendCode({ email, is_medic: true })
                    code()
                    toast.warn(
                        'Verifique seu email para receber um código de segurança',
                        { delay: 4000, hideProgressBar: false });
                    history.push(`${VERIFY_CODE}`, {
                        email,
                        type: 'signup',
                    });

                    return
                }
                history.push(`${HOME}`);
            })
            .catch(error => {
                const errorMessage = getErrorMessage(error);
                if (errorMessage === 'Erro ao fazer login Usuário não confirmado') {
                    history.push(`${RECOVER_VERIFY_CODE}`, {
                        type: 'codeFirstStep',
                        unverified: true,
                    });
                }
                toast.error(errorMessage);
                setEmailError('');
                setPasswordError('');
            });
    };

    return (
        <Style.Wrapper>
            <Style.BackContainer>
                <Back />
            </Style.BackContainer>
            <Style.ContainerLogoGlobal style={{ height: '100vh' }}>
                <Style.LogoContainer>
                    <div className="img-dash-desktop">
                        <img
                            src={logotipo}
                            alt="Logo"
                            onClick={() => {
                                history.push(`${LADINGPAGE}`);
                            }}
                        />
                    </div>
                </Style.LogoContainer>
            </Style.ContainerLogoGlobal>
            <Style.Container>
                <Style.Block>
                    <Style.Title>Olá, Dr(a)! </Style.Title>
                    <Style.Description>
                        Bem-vindo(a) novamente à <strong>Exeltis On</strong>,<br /> a
                        plataforma médica da <strong>Exeltis</strong>.
                    </Style.Description>
                    <Style.Form>
                        <Style.Label>
                            <Input
                                type="email"
                                id="email-input"
                                value={email}
                                onChange={handleChangeInput}
                                onFocus={() => setEmailError('')}
                                label="E-mail"
                                hasError={!!emailError}
                            >
                                {emailError && <span>{emailError}</span>}
                            </Input>
                            <Input
                                type="password"
                                id="password-input"
                                value={password}
                                onChange={handleChangeInput}
                                onFocus={() => setPasswordError('')}
                                label="Senha"
                                hasError={!!passwordError}
                            >
                                {passwordError && <span>{passwordError}</span>}
                            </Input>
                            <div className="content-wrapper">
                                <p
                                    className="reset"
                                    onClick={() => history.push(`${RESET}`, {})}
                                >
                                    Recuperar minha senha
                                </p>
                                <div className="container-btn">
                                    <div>
                                        <Button variant="DefaultDark" onClick={handleLogin}>
                                            Entrar
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="content-wrapper">
                                <div className="container-signup">
                                    <p>
                                        Ainda não é cadastrado?{' '}
                                        <Link className="signup-navigation" to={`${SIGNUP}`}>
                                            Crie sua conta.
                                        </Link>
                                    </p>
                                </div>
                                <div className="container-link">
                                    <Link
                                        className="signup-navigation"
                                        to={`${RECOVER_VERIFY_CODE}`}
                                    >
                                        Já tem um código de verificação ou não recebeu o código?
                                    </Link>
                                </div>
                            </div>
                        </Style.Label>
                    </Style.Form>
                </Style.Block>
            </Style.Container>
        </Style.Wrapper>
    );
};

export default Login;
