import React from 'react';
import { Link } from 'react-router-dom';
import { BreadCrumb, Template } from 'components';
import { m_products, d_products } from 'assets';
import { HOME, PRODUCTS } from 'routes/Constants';
import { Section, Title, Description } from './style';
import {
  Info,
  ButtonOutline,
  ButtonDefault,
} from '../../Components/components';
import IProduct from 'services/product/models/product-interface';

type Props = {
  product: IProduct;
};

const Medicine: React.FC<Props> = ({ product }) => {
  return (
    <Template
      banner={{
        title: 'Produtos',
        imgDesktop: d_products,
        imgMobile: d_products,
      }}
      breadcrumb={
        <BreadCrumb
          crumbs={[
            <Link to={`${HOME}`}>HOME</Link>,
            <Link to={`${PRODUCTS}`}>PRODUTOS</Link>,
            <span>Medicamentos</span>,
          ]}
        />
      }
    >
      <Section>
        <div className="medicine-thumbs">
          <img src={product.thumbnail} alt="Medicamento" />
        </div>
        <div className="medicine-content">
          <div>
            <Title>{product.title}</Title>
            <div className="medicine-content-product-mobile">
              <Description>{product?.description}</Description> 
              <img
                className="medicine-product-mobile"
                src={product.thumbnail}
                alt="Medicamento"
              />
            </div>
          </div>
          
          <div className="medicine-recommendations">
          {product.info.active_principle && (
            <div className="medicine-info">
              <Info
                icon={{ name: 'medicine', color: 'black', size: 30 }}
                title="Princípio Ativo"
                description={`${product.info.active_principle}`}
              />
            </div>
            )}
            {product.info.presentation && (
              <div className="medicine-info">
                <Info
                  icon={{ name: 'atom_blue_light', color: 'black', size: 30 }}
                  title="APRESENTAÇÃO"
                  description={`${product.info.presentation}`}
                />
              </div>
            )}
            {product.info.usage_recomendations && (
              <div className="medicine-info">
                <Info
                  icon={{ name: 'medicine', color: 'black', size: 30 }}
                  title="recomendações de uso"
                  description={product.info.usage_recomendations}
                />
              </div>
            )}
            {product.info.conservation_care &&  (
              <div className="medicine-info">
                <Info
                  icon={{ name: 'thermometer', color: 'black', size: 30 }}
                  title="Cuidados de conservação"
                  description={product.info.conservation_care}
                />
              </div>
            )}
            
            <div className="medicine-info-btn">
              {product?.info?.specialist_link && (
                <ButtonOutline href={`${product.info.specialist_link}`}  target="_blank">
                  Fale com um representante
                </ButtonOutline>
              )}
              {product?.info?.drug_leaflet && (
                <ButtonDefault href={`${product.info.drug_leaflet}`} download target="_blank">
                  BAIXAR A BULA
                </ButtonDefault>
              )} 
            </div>
         
          </div>
        </div>
      </Section>
    </Template>
  );
};

export default Medicine;