import React, { useEffect, useState } from 'react';
import { ContainerGlobal } from 'components/ContainerGlobal/style';
import { HomeWrapper, Row } from '../../style';
import ScheduleNews from './ScheduleNews';
import { LinkButton, MessageError, ScheduleCard } from 'components';
import SliderNews from 'components/Slider/SliderNews';
import IEvents from 'services/events/models/events-interface';
import { getAllEvents } from 'services/events';
import INews from 'services/news/models/news-interface';
import { getAllNews } from 'services/news';
import { formatEventDate } from 'helpers/eventDate';
import is from 'date-fns/esm/locale/is/index.js';

const ScheduleList: React.FC = () => {
  const [cardsNews, setCardNews] = useState<INews[]>([]);
  const [events, setEvent] = useState<IEvents[]>([]);

  useEffect(() => {
    const getCardNews = async () => {
      const cards = await getAllNews();
      setCardNews(cards);
    };
    getCardNews();
  }, []);

  useEffect(() => {
    const getEvents = async () => {
      const events = await getAllEvents({ filter_type: 'NEXT' })
      setEvent(events);
    };
    getEvents();
  }, []);

  const renderSlide = (item: INews[]) => {
    if (item.length === 0) {
      return <MessageError content="Sem notícias no momento" />;
    }
    return cardsNews
      .map(card => {
        const theme = card?.category?.info?.color;
        return (
          <ScheduleNews
            tag={'notícia'}
            data={card}
            to={`/noticias/${card.news_id}`}
            themeTag={theme}
            theme={theme}
          />
        );
      })
      .slice(0, 3);
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    className: 'center',
    centerPadding: '0',
    autoplay: true,
    autoplaySpeed: 2000,
    arrow: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          dots: true,
          infinite: true,
          speed: 1400,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function compare(a: any, b: any) {
    const eventA = new Date(a?.start_date).getTime()
    const eventB = new Date(b?.start_date).getTime()
    if (eventA < eventB) {
      return -1;
    }
    if (eventA > eventB) {
      return 1;
    }
    return 0;
  }

  return (
    <HomeWrapper background="#E5E5E5">
      <ContainerGlobal>
        <Row>
          <SliderNews settings={settings}>{renderSlide(cardsNews)}</SliderNews>
          {events.sort(compare).filter(item => new Date(item.end_date).getTime() > new Date().getTime())
            .map(item => (
              <ScheduleCard
                key={item.event_id}
                subtitle={item.subtitle}
                event_id={item.event_id}
                description={item.description}
                size={'normal'}
                title={''}
                start_date={item.start_date}
                local={item.local}
                isOnline={!!item.live_id}
                end_date={item.end_date}
                is_active={item.is_active}
              />
            ))
            .slice(0, 2)
            .filter(item => item.props.is_active)}
        </Row>
        <Row reverse={true}>
          <LinkButton to="/eventos" variant="TextLink">
            Ver mais
          </LinkButton>
        </Row>
      </ContainerGlobal>
    </HomeWrapper>
  );
};

export default ScheduleList;
