import React, { useState } from 'react';

import { InputWrapper } from './style';
import EyeActive from 'assets/icon/eye-active-icon.svg';
import EyeInactive from 'assets/icon/eye-inactive-icon.svg';

interface IInputProps {
  value: string | number | readonly string[] | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  type: string;
  id?: string;
  label: string;
  alertMessage?: string;
  hasError?: boolean;
  required?: boolean;
  height?: {
    desktop?: string;
    mobile?: string;
  };
  maxlength?: number;
}

const Input: React.FC<IInputProps> = ({
  children,
  onChange,
  type,
  value,
  id,
  onFocus,
  label,
  alertMessage,
  hasError,
  required,
  height = { desktop: '45px' },
  maxlength,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [typePassword, setTypePassword] = useState<string>('password');

  return (
    <InputWrapper height={height}>
      <label htmlFor={id}>
        {label}
        {required && <span className="required">*</span>}
      </label>
      {alertMessage ? <span className="alert">{alertMessage}</span> : null}
      <div className={hasError ? 'error-border' : ''}>
        <input
          type={type === 'password' ? typePassword : type}
          id={id}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          maxLength={maxlength}
        />
        {type === 'password' ? (
          <>
            {showPassword ? (
              <img
                src={EyeActive}
                alt="eye"
                className={alertMessage ? 'eye-icon adjust' : 'eye-icon'}
                onClick={() => {
                  setShowPassword(false);
                  setTypePassword('password');
                }}
              />
            ) : (
              <img
                src={EyeInactive}
                alt="eye"
                className={alertMessage ? 'eye-icon adjust' : 'eye-icon'}
                onClick={() => {
                  setShowPassword(true);
                  setTypePassword('text');
                }}
              />
            )}
          </>
        ) : null}
      </div>

      {children}
    </InputWrapper>
  );
};

export default Input;
