import styled from 'styled-components';
import { px2rem } from 'styles/globals';

interface IWrapper {
  background?: string
}

const Wrapper = styled.section<IWrapper>`
  margin: 0 auto;
  padding: 0 0 ${px2rem(100)} 0;
  font-family: 'Open Sans', sans-serif;
  ${props => props.background ? `background: ${props.background};` : ''}

  .block {
    display: block;
  }

  .about-block {
    margin-top: ${px2rem(65)};
    margin-bottom: ${px2rem(32)};
    display: flex;
    gap: ${px2rem(13)};
    .about-icon {
      height: ${px2rem(30)};
      align-self: center;
    }
    .about {
      font-size: ${px2rem(24)};
      font-weight: 800;
      color: var(--blue-darkness);
      line-height: ${px2rem(40)};
      align-self: center;
      font-family: 'Open Sans', sans serif;
    }
  }

  .speakers {
    margin-top: ${px2rem(86)};
    display: flex;
    gap: ${px2rem(13)};

    .speakers-title {
      line-height: ${px2rem(40)};
      align-self: center;
      font-size: ${px2rem(24)};
      font-weight: 800;
    }
    .icon {
      align-self: center;
      width: ${px2rem(33.75)};
    }
  }

  @media screen and (max-width: 998px){

    padding: 0 0 ${px2rem(22)} 0;
    .about-block {
      margin-top: ${px2rem(34)};
      gap: ${px2rem(11)};
      .about-icon {
        height: ${px2rem(18)};
      }
      .about {
        font-size: ${px2rem(16)};
        line-height: ${px2rem(21.79)};
      }
    }
    .speakers {
      margin-top: ${px2rem(41)};
      display: flex;
      gap: ${px2rem(9.36)};

      .speakers-title {
        line-height: ${px2rem(21.79)};
        font-size: ${px2rem(16)};
      }
      .icon {
        width: ${px2rem(20.25)};
      }
    }
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${px2rem(28)};
  white-space: pre-wrap;

  .description {
    font-size: ${px2rem(22)};
    line-height: ${px2rem(28)};
    color: var(--black-clear);
    overflow-wrap: break-word;
  }

  @media screen and (max-width: 998px){
    .description {
      font-size: ${px2rem(18)};
      line-height: ${px2rem(28)};
    }
  }

  @media screen and (max-width: 600px){
    .description:not(:first-child) {
      display: none;
    }
  }
`

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${px2rem(32)};
  margin-top: ${px2rem(41)};
`

export {
  Wrapper,
  DescriptionContainer,
  CardsContainer,
};
