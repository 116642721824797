import styled from 'styled-components';
import arrow_select from 'assets/icon/arrow_select.svg';

export const EditInfo = styled.section`
 .modal-wrapper {
    padding: 32px;
    @media screen and (max-width: 768px){
      padding: 10px;
    }
  }

  .content-email-user {
    margin-bottom: 20px;
    margin-top: -14px;

    h3 {
      line-height: 24px;
      font-weight: 600;
    }

    h4 {
      margin-top: 30px;
      font-weight: 700;
      font-size: 16px;
    }
  }

  h2 {
    font-size: 34px;
    line-height: 36px;
    color: var(--blue-medium);
    font-weight: 700;
    padding-bottom: 32px;
  }

  h3 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 800;
    color: var(--gray-darker);
  }

  .input-container {
    display: flex;
    justify-content: space-between;
  }

  button {
    width: 250px;
    margin-top: 50px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }

  .uf-select > h3 {
    margin: 0 0 9px 0;
  }

  @media only screen and (max-width: 768px) {
    button {
      width: 100%;
      margin-top: 20px;
    }

    .buttons {
      flex-direction: column-reverse;
    }
  }

  .form-password {
    h5 {
      font-size: 16px;
      font-weight: 800;
      margin-bottom: 8px;
      color: var(--grey-darkness);
    }
  }
`;

export const Selection = styled.select`
  width: 240px;
  height: 45px;
  outline: none;
  border: 1px solid rgba(155, 153, 175, 0.31);
  background: transparent;
  border-radius: 6px;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(${arrow_select});
  background-repeat: no-repeat;
  background-position: 95% 50%;
  background-size: 5%;
  color: grey;
  font-size: 16px;
`;

export const CodeInput = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 16px;

  input[type='number'] {
    text-align: center;
    width: 64px;
    height: 80px;
    border: 1.5px solid var(--grey-medium);
    color: var(--grey-darker);
    outline: none;
    border-radius: 8px;
    position: relative;
    z-index: 5;
    @media (max-width: 280px) {
      width: 20px !important;
      height: 40px !important;
    }

    :focus {
      border: 2px solid var(--blue-medium);
    }
    @media (min-width: 998px){
      font-size: 38px;
    }
  }

  @media (max-width: 500px) {
    justify-content: space-around;
    gap: 10px;
    input[type='number'] {
      width: 30px;
      height: 40px;
    }
  }

  /* Chrome, Safari, Edge, Opera */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
