import styled, { css } from 'styled-components';

interface ITagContent{
  theme?: string
}

export const TagContent = styled.div<ITagContent>`
  position: absolute;
  bottom: 15px;
  left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  height: 30px;
  padding: 0 10px;
  color: var(--white);
  ${props =>
    props.theme && css`
      background-color: ${props.theme};
    `}
`
