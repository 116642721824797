import React, { useEffect, useState } from 'react';
import { differenceInMinutes, format } from 'date-fns';
import { info, live_banner_desktop, live_banner_mobile, profiles_icon } from 'assets';
import {
  BreadCrumb,
  Template,
} from 'components';
import EventData from './components/EventData';
import SpeakerCard from './components/SpeakerCard';
import EventBanner from './components/EventBanner';
import {
  Wrapper,
  DescriptionContainer,
  CardsContainer,
} from './style';
import { Link, useParams } from 'react-router-dom';
import { HOME, EVENTS } from 'routes/Constants';
import httpClient from 'http-client';
import IEvent from './models/event-interface';

const EventDetail: React.FC = () => {

  const [event, setEvent] = useState<IEvent | undefined>(undefined);
  const [status, setStatus] = useState<string>('no-link');
  const [liveReference, setLiveReference] = useState<string | null>(null);

  const { event_id } = useParams<{
    event_id: string;
  }>();

  const getEventData = async (): Promise<IEvent> => {
    const data: IEvent = (
      await httpClient.get(`/events/${event_id}`)
    ).data;

    const liveData = data.live_id ? await httpClient.get(`/lives/${data.live_id}`) : null;

    setLiveReference(liveData?.data.status === 'ACTIVE' ? liveData?.data.channel_sv_id : data.content_id);

    const start = new Date(data.start_date);
    const started = differenceInMinutes(start, new Date()) < 0;

    const eventStatus = liveData?.data.status === 'ACTIVE' ? 'live' :
      !started || !data.live_id ? 'no-link' :
        data.content_id ? 'over' :
          'waiting-upload';

    setStatus(eventStatus);
    setEvent({ ...data, start_date: `${format(start, "dd/LL/yyyy - HH'H'mm")}` });
    return data;
  };

  useEffect(() => {
    if (event_id) {
      getEventData();
    }
  }, [event_id])

  return (
    <>
      <Template
        banner={{
          title: event ? event.title : '',
          subtitle: event ? event.subtitle : '',
          imgDesktop: live_banner_desktop,
          imgMobile: live_banner_desktop,
          color: '#12428A'
        }}
        breadcrumb={
          <BreadCrumb
            crumbs={[
              <Link to={`${HOME}`}>HOME</Link>,
              <Link to={`${EVENTS}`}>Agenda</Link>,
              <span>{event?.title}</span>,
            ]}
          />
        }
      >
        {event &&
          <Wrapper>
            <EventBanner
              status={status}
              path={`/live/play/${event_id}`}
              uploadedBanner={event.thumbnail || ''}
              liveReference={liveReference}
            />

            <EventData
              title={event.title}
              subtitle={event.subtitle}
              date={event.start_date}
              address={event.local}
              status={status}
              isOnline={!!event.live_id}
            />

            <div className="block about-block">
              <img className="about-icon" src={info} alt="" />
              <span className="about">Sobre o evento</span>
            </div>

            <DescriptionContainer>
              <span className="description">{event.description}</span>
            </DescriptionContainer>

            <div className="speakers">
              <img className="icon" src={profiles_icon} alt="" />
              <span className="speakers-title">Palestrantes</span>
            </div>

            <CardsContainer>
              {event.speakers.map(speaker => {
                return (
                  <SpeakerCard
                    key={speaker.name}
                    speaker={speaker}
                  />
                );
              })}
            </CardsContainer>

          </Wrapper>
        }
      </Template>
    </>
  );
};

export default EventDetail;
