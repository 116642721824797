import React from 'react';
import styled from 'styled-components';
import { useAuth } from 'providers/auth/AuthProvider';
import HeaderMobile from './Mobile';

const IMobileAuthenticated = styled.div`
  position: relative;
  z-index: 10;
  @media (min-width: 1201px) {
    display: none;
  }
  @media (max-width: 1200px) {
    display: block;
  }
`;

const IMobileIsNotAuthenticated = styled.div`
  position: relative;
  z-index: 10;
  @media (min-width: 1280px) {
    display: none;
  }
  @media (max-width: 1279px) {
    display: block;
  }
`;

const Logged: React.FC = () => {
  const { isAuthenticated } = useAuth();

  function renderHeaderMobile() {
    if (isAuthenticated) {
      return (
        <>
          <IMobileAuthenticated>
            <HeaderMobile />
          </IMobileAuthenticated>
        </>
      );
    } else {
      return (
        <>
          <IMobileIsNotAuthenticated>
            <HeaderMobile />
          </IMobileIsNotAuthenticated>
        </>
      );
    }
  }
  return <>{renderHeaderMobile()}</>;
};

export default Logged;
