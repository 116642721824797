import React from 'react';
import Guest from './Guest';
import Logged from './Logged';
import { useAuth } from 'providers/auth/AuthProvider';

const Auth: React.FC = () => {
  
  const { isAuthenticated } = useAuth();
  
  if (isAuthenticated) {
    return <Logged />
  }
  return <Guest />;
};

export default Auth;
