import { keyframes } from 'styled-components';

const fadeLeft = keyframes`
	0% {
		transform: translateX(-300px);
	}
	100% {
		transform: translateX(0);
	}
`;

export default fadeLeft;
