import styled from 'styled-components';
import arrow_select from 'assets/icon/arrow_select.svg';

const EditInfo = styled.section`
  .test {
    color: red;
  }

  .modal-wrapper {
    padding: 32px;
    @media screen and (max-width: 768px){
      padding: 10px;
    }
  }

  h2 {
    font-size: 34px;
    line-height: 36px;
    color: var(--blue-medium);
    font-weight: 700;
    padding-bottom: 32px;
  }

  h3 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 800;
    color: var(--gray-darker);
  }

  .input-container {
    display: flex;
    justify-content: space-between;

    .error-styled {
      padding-top: 5px;
      padding-left: 5px;
      color: var(--color-error);
      font-size: 14px;
    }
  }

  button {
    width: 250px;
    margin-top: 50px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }

  .uf-select > h3 {
    margin: 0 0 9px 0;
  }

  @media only screen and (max-width: 768px) {
    button {
      width: 100%;
      margin-top: 20px;
    }

    .buttons {
      flex-direction: column-reverse;
    }
  }
`;

export const Selection = styled.select`
  width: 240px;
  height: 45px;
  outline: none;
  border: 1px solid rgba(155, 153, 175, 0.31);
  background: transparent;
  border-radius: 6px;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(${arrow_select});
  background-repeat: no-repeat;
  background-position: 95% 50%;
  background-size: 5%;
  color: grey;
  font-size: 16px;
`;

export default EditInfo;
