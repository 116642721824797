import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyle from './styles/globals';
import Routes from './routes';
import { Header, Footer } from './components';
import 'react-toastify/dist/ReactToastify.css';
import AppProvider from 'providers/AppProvider';

const App: React.FC = () => {
  useEffect(() => {
    if (window.navigator.onLine === false) {
      toast.error(
        'Você parece estar sem internet. Verifique sua conexão para continuar.',
      );
    }
  }, []);

  return (
    <AppProvider>
      <Router>
        <GlobalStyle />
        <Header />
        <Routes />
        <Footer />
      </Router>
    </AppProvider>
  );
};

export default App;
