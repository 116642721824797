import styled, { css } from 'styled-components';
import { px2rem } from 'styles/globals';
import { MdSearchOff } from 'react-icons/md';

export const Container = styled.div``;

type ChekedButton = true | false;

interface CheckButton {
  check?: ChekedButton;
}

export const Button = styled.button<CheckButton>`
  display: flex;
  width: 100%;
  color: var(--blue-medium);
  background: none;
  font-size: 16px;
  font-weight: 600;
  align-items: center;
  text-transform: capitalize;
  outline: none;
  border: none;
  margin: 10px 0;
  /* 
  ${props =>
    props.check &&
    css`
      color: var(--blue-lighter);
      font-weight: 800;
    `} */

  &:focus {
    color: var(--blue-lighter);
    font-weight: 800;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Section = styled.section`
  margin-bottom: 50px;
  padding-bottom: 60px;
  border-bottom: 1px solid #c2c4c5;

  :last-child {
    border-bottom: none;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 25px;
  }

  .content {
    display: flex;
    width: 100%;
    flex-direction: row;
  }

  .side-menu {
    width: 25%;
  }
  @media only screen and (max-width: 800px) {
    .side-menu {
      display: none;
    }
    .product-content {
      grid-template-columns: repeat(auto-fill, minmax(221px, 1fr));
    }
  }
`;

export const Grid = styled.div`
  /* width: 100%; */
  height: 100%;

  @media screen and (min-width: 998px) {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${px2rem(25)};
  }
  @media screen and (max-width: 997px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
`;
export const PaginationContent = styled.div`
  position: relative;
  /* top: 55rem;
  left: 50rem; */

  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

export const NotFoundContent = styled.div`
  margin-left: 10rem;

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 30px;
    color: var(--black-clear);

    strong {
      color: var(--blue-medium);
    }
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: var(--black-clear);
  }

  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

export const MobileResultText = styled.div`
  display: none;
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 30px;
    color: var(--black-clear);

    strong {
      color: var(--blue-medium);
    }
  }
  @media only screen and (max-width: 768px) {
    margin-left: 0;
    display: inline;
  }
`;

export const SearchIcon = styled(MdSearchOff)`
  padding-top: 10%;
  width: 100%;
  height: 15%;
  color: var(--blue-medium);
`;
