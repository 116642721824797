import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  -webkit-box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);
  -moz-box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);
  box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);

  img {
    width: 100%;
    max-width: 374px;
    height: 164px;
    object-fit: contain;
  }

  div {
    .card-product-content {
      h3 {
        font-size: 40px;
        line-height: 51px;
        max-width: 267px;
        font-weight: 800;
        text-transform: uppercase;
        color: var(--blue-medium);
      }

      p {
        text-transform: uppercase;
        font-size: 16px;
        line-height: 26px;
      }
    }
  }

  .card-product-button {
    margin-top: 25px;
    width: 93%;
  }

  @media only screen and (max-width: 500px) {
    div {
      .card-product-content {
        margin-left: 10px;
        h3 {
          font-size: 28px;
          line-height: 38px;
        }

        p {
          text-transform: uppercase;
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
  }
`;
