import styled from 'styled-components';
import Contact from 'services/contact/models/contact-interface';

const ContentTeam = styled.div`
  background-color: white;
  padding: 32px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .contact {
    background-color: white;
    width: 100%;
    padding: 32px;
    border-radius: 6px;
  }

  h3 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: #12428a;
  }

  h4 {
    font-size: 23px;
    line-height: 40px;
    font-weight: 600;
    color: var(--gray-darkest);
    margin-bottom: 8px;
  }

  p {
    font-size: 20px;
    line-height: 33px;
    font-weight: 400;
    color: #828282;
  }

  @media only screen and (max-width: 800px) {
    padding: 24px;

    h3 {
      font-size: 14px;
      line-height: 24px;
    }

    h4 {
      font-size: 20px;
      line-height: 24px;
    }

    p {
      font-size: 18px;
      line-height: 32px;

      @media only screen and (max-width: 540px) {
        text-overflow: ellipsis;
        white-space: pre-line;
        overflow: hidden !important;
      }
    }
  }
`;

type Props = {
  card_contact: Contact;
};

function getDefaultCardsContacts(contacts: Contact) {
  const {
    name = '-',
    email = '-',
    employee_name = '-',
    phone_number = '-',
  } = contacts;

  return { name, email, employee_name, phone_number };
}

const Card: React.FC<Props> = ({ card_contact }) => {
  const { name, email, employee_name, phone_number } =
    getDefaultCardsContacts(card_contact);

  return (
    <ContentTeam>
      <h3>{name}</h3>
      <h4>{employee_name}</h4>
      <p>{email}</p>
      <p>{phone_number}</p>
    </ContentTeam>
  );
};

export default Card;
