import styled from 'styled-components';
import { px2rem } from 'styles/globals';

interface BannerTypes {
  pointer: boolean;
  player: boolean
}

const Wrapper = styled.div<BannerTypes>`

  position: relative;
  display: block;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: ${px2rem(32)};
  background: black;
  ${props => props.player && 'padding-bottom: 43%;'}
  ${props => props.pointer && 'cursor: pointer;'}

   > .player {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    iframe{
      width: 100%;
      height: 100%;
    }
  }

  .event-image {
    position: relative;
    display: block;
    width: 100%;
  }

  .overlay {
    position: absolute;
    bottom: 0;
    display: block;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .waiting-message {
      margin: ${px2rem(40)};
    }

    &:not(.live) {
      background: linear-gradient(180deg, rgba(18, 66, 138, 0) 0%, rgba(18, 66, 138, 0.827432) 55.52%, rgba(18, 66, 138, 0.98) 91.67%);
    }

    span {
      text-align: center;
      padding: 0 ${px2rem(15)};
      color: #fff;
      font-size: ${px2rem(43)};
      font-weight: 700;
      line-height: ${px2rem(78)};
    }

    .controllers {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 196px;
      height: 156px;

      .play-icon, .play-button {
        align-self: center;
        transition: ease-out .2s;
      }

      .play-icon {
        width: 64px;
        height: 64px;
        stroke-width: 3px;
        overflow: visible;
      }

      .play-button {
        width: 196px;
        height: 36px;
        line-height: 36px;
        border-radius: 18px;
        background: #fff;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: ${px2rem(1.25)};
        color: var(--blue-darker);
      }

      &.live {
        width: 100%;
        height: 100%;
        padding: ${px2rem(40)};
        box-sizing: border-box;
        
        .live-indicator {
          align-self: start;
          background: #f00;
          color: #fff;
          font-weight: bold;
          font-size: 18px;
          width: ${px2rem(140)};
          height: ${px2rem(40)};
          border-radius: 20px;
          text-align: center;
          line-height: ${px2rem(40)};
        }

        .play-button {
          align-self: end;
        }
      }
    }
  }

  &:hover > .overlay > .controllers {
    .play-icon {
      transform: scale(1.05);
      transition: ease-out .2s;
    }

    :not(.live) > .play-button {
        transform: scale(1.05);
        transition: ease-out .2s;
      }
    }
  }

  @media screen and (max-width: 700px) {

    .overlay.waiting-upload > span {
      font-size: ${px2rem(16)};
      line-height: ${px2rem(21.79)};
      padding: 0;
    }
  }

  @media screen and (max-width: 600px) {

    ${props => props.player && 'padding-bottom: 60%;'}

    .overlay {

      .controllers {
        height: 100px;

        &.live > .live-indicator {
          height: 20px;
          width: 83px;
          font-size: ${px2rem(12)};
          line-height: 20px;
        }

        .play-icon {
          transform: scale(.5);
          stroke-width: 6px;
        }

        .play-button {
          height: 30px;
          width: 156px;
          line-height: 30px;
        }
      }

      &.live > .controllers {
        padding: ${px2rem(16)};
      }

      &:hover > .controllers > .play-icon {
        transform: scale(.55);
      }
    }
  }
`

export { Wrapper };
