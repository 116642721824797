import React, { HTMLAttributes, ReactNode } from 'react';
import { SlickWrapper, Container } from './style';

interface ISlider extends HTMLAttributes<HTMLElement> {
  children?: ReactNode;
  settings?: any;
  faded?: boolean;
}

const Slider = (props: ISlider) => {
  const { children, settings, faded, className } = props;

  return (
    <Container>
      <SlickWrapper
        {...settings}
        faded={!!faded}
        className={`sliderWrapper ${className}`}
      >
        {children}
      </SlickWrapper>
    </Container>
  );
};

export default Slider;
