import styled from 'styled-components';
import arrow_select from 'assets/icon/arrow_select.svg';

export const BackContainer = styled.div`
  position: absolute;
  top: 0;
  transform: translate(66px, 115px);
  left: 0;
  z-index: 9999;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const BackContainerMobile = styled.div`
  display: none;
  @media (max-width: 1024px) {
    margin-top: 40px;
    margin-left: 15px;
    display: block;
    margin-bottom: 13px;
  }
`;

export const Wrapper = styled.div`
  display: flex;

  @media (max-height: 750px) {
    height: initial;
  }

  @media (max-width: 1024px) {
    background-color: var(--white);
    flex-direction: column;
    height: auto;
  }
`;

export const ContainerLogoGlobal = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
`;

export const LogoContainer = styled.div`
  background-color: var(--blue-clear);
  flex: 1 0 50%;
  position: relative;
  height: 100vh;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .img-dash-desktop {
    img {
      width: 300px;
    }
  }

  .pointer {
    cursor: pointer;
  }
  .img-dash-mobile {
    display: none;
  }

  .description-admin {
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
  }

  /* @media (max-height: 750px) {
    height: initial;
  } */

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 50%;
  background: white;

  @media (max-height: 750px) {
    height: initial;
  }

  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

export const Block = styled.div`
  max-width: 650px;
  width: 100%;
  padding: 20px;

  @media (max-width: 1024px) {
    padding: 20px;
    margin-top: 20px;
  }
`;

export const Title = styled.h2`
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: 22px;
  color: var(--blue-darkness);
  line-height: 40px;
  text-transform: uppercase;
  width: 100%;
  position: relative;

  @media (max-width: 1024px) {
    font-size: 1.8rem;
    text-transform: none;
  }

  @media (max-width: 320px){
    font-size: 1.6rem;
  }
`;

export const Description = styled.p`
  font-size: 1.2rem;
  margin-bottom: 40px;
  line-height: 32px;

  strong {
    color: var(--blue-light);
    font-weight: 800;
  }

  a {
    text-decoration: none;
    color: var(--blue-light);
    font-weight: 800;
    font-size: 1.2rem;
    margin-bottom: 40px;
    line-height: 32px;

    :hover {
      color: var(--blue-lighter);
    }
  }

  @media (max-width: 800px) {
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;

    a {
      font-size: 18px;
      font-weight: 400;
      line-height: 33px;
    }
  }
`;

export const DescriptionMobile = styled(Description)`
  display: none;
  @media (max-width: 500px) {
    display: block;
    margin: 15px 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: white;

  .content-wrapper {
    display: flex;
    flex-direction: column;

    .container-link {
      margin: 15px 0;
      width: 100%;
      display: flex;
      justify-content: left;

      .verify-code-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: var(--black-lighter);
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
      }
      @media (max-width: 1024px) {
        width: 100%;
      }
    }

    .container-btn {
      margin: 15px 0;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      div {
        display: flex;
        justify-content: flex-end;
        width: 160px;
        align-items: center;

        @media (max-width: 1024px) {
          width: 100%;
        }
      }
    }
    @media (max-width: 1024px) {
      flex-wrap: wrap;
    }
  }
`;

export const Label = styled.label`
  width: 100%;
  font-weight: bold;
  font-size: 1.2rem;

  &:not(:first-child) {
    margin-top: 32px;
  }

  .reset {
    color: var(--blue-medium);
    font-size: 16px;
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;
  }

  .error-styled {
    padding-top: 5px;
    padding-left: 5px;
    color: var(--color-error);
    font-size: 14px;
  }

  .container-signup {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .container-signup p {
    color: var(--black-lighter);
    font-weight: 500;
    font-size: 18px;
  }
  .signup-navigation {
    color: var(--blue-light);
    font-size: 18px;
    font-weight: 900;
    margin-left: 10px;
    text-decoration: none;
  }

  @media (max-width: 1024px) {
    .reset {
      text-align: center;
    }

    .container-signup a {
      text-align: center;
      font-size: 16px;
    }

    .container-signup p {
      text-align: center;
      font-size: 16px;
    }
  }
`;

export const ContainerCRM = styled.div`
  display: flex;
  justify-content: space-between;

  .select-crm {
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
    margin-right: 10px;

    h3 {
      font-size: 16px;
      font-weight: 800;
      margin-bottom: 8px;
      color: var(--grey-darkness);
    }
  }

  .input-crm {
    flex: 1 1 50%;
  }
`;

export const Select = styled.select`
  height: 45px;
  outline: none;
  border: 1px solid rgba(155, 153, 175, 0.31);
  background: transparent;
  border-radius: 6px;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(${arrow_select});
  background-repeat: no-repeat;
  background-position: 95% 50%;
  background-size: 5%;
  color: grey;
  font-size: 16px;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

export const LoginContainer = styled.div`
  position: absolute;
  z-index: 9999;
  left: 0;
  bottom: 0;
  transform: translate(54px, -58px);

  a {
    color: var(--blue-medium);
    font-weight: 900;
    font-size: 20px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const LoginContainerMobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .content-login-link {
    display: none;
  }

  @media (max-width: 1024px) {
    .content-login-link {
      display: block;
      a {
        color: var(--blue-medium);
        font-weight: 900;
        font-size: 16px;
      }
    }
  }
`;
