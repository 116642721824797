import styled from 'styled-components';
import { px2rem } from 'styles/globals';

const Row = styled.div`
  margin-top: ${px2rem(24)};
  
  @media screen and (min-width: 998px){
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    >div:not(:last-of-type){
      margin-right: ${px2rem(25)};
    }
  }
  @media screen and (max-width: 997px){
    display: flex;
    align-items: center;
    flex-direction: column;
    >article{
      display: flex;
      box-shadow: none;
      padding: 5px;
      img{ width: 30%; }
      >div{
        width: 70%;
        padding: 10px;
        h1{ font-size: 18px; }
        p{ font-size: 14px; }
      }
    }
  }
`;

export{
  Row
}