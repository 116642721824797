import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import getErrorMessage from 'helpers/errorMessages';
import { Loading, LoadingBg, MessageError } from 'components';
import { getFaqData } from 'services/faq';
import { getValue } from 'utils/storage';
import FAQ from 'services/faq/models/faq-interface';
import Categories from '../Accordion/Categories';
import ContentUI from '../Accordion/Content';

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
`;

const Wrapper = styled.div`
  position: relative;
  left: 16%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const AccordionUI: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [faqs, setFaqs] = React.useState<FAQ[]>([]);
  const [faqSelected, setFaqSelected] = React.useState<FAQ>();

  useEffect(() => {
    const getFAQ = async () => {
      try {
        setError(false);
        setLoading(true);
        let localFaqs;
        const user = getValue<string>({ prop: 'exeltis-user' });

        if (user) {
          localFaqs = await getFaqData();
        } else {
          const token = 'Bearer ' + process.env.REACT_APP_UNLOGGED_TOKEN;
          localFaqs = await getFaqData(undefined, { Authorization: token });
        }
        setFaqs(localFaqs);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        toast.error(errorMessage);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    getFAQ();
  }, []);

  if (loading) return <LoadingBg />;
  if (error)
    return (
      <Wrapper>
        <MessageError content="Falha no carregamento do conteúdo. Tente mais tarde." />
      </Wrapper>
    );

  return (
    <>
      {faqs && faqs.length ? (
        <Categories faqs={faqs} setFaqSelected={setFaqSelected}>
          <ContentUI faq={faqSelected} />
        </Categories>
      ) : (
        <Container>
          <Loading />
        </Container>
      )}
    </>
  );
};

export default AccordionUI;
