import styled, { css } from 'styled-components';
import { px2rem } from 'styles/globals';

interface ICardSize {
  size?: 'double' | 'normal';
}

const ScheduleWrapper = styled.div<ICardSize>`
  box-shadow: 0 1px 4px rgba(17, 17, 17, 0.14);
  min-width: 25%;
  max-height: 700px;
  background-color: var(--white);

  @media screen and (max-width: 998px) {
    max-width: 100%;
    width: 100%;
  }

  ${props =>
    props.size === 'normal' &&
    css`
      @media screen and (min-width: 998px) {
        max-width: ${px2rem(343)};
      }
    `}
  ${props =>
    props.size === 'double' &&
    css`
      @media screen and (min-width: 998px) {
        max-width: ${px2rem(686)};
      }
    `}
      @media screen and (max-width: 998px) {
    & + div {
      margin-top: 20px;
    }
  }
`;


const ScheduleDay = styled.div`
  background-color: var(--blue-darkness);
  padding: 24px 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  height: 50px;
  text-align: center;
  position: relative;

  span {
    display: flex;
    flex-direction: row;
    color: var(--white);
    font-size: var(--font-largest);
    max-width: 50px;
    strong {
      font-weight: 800;
      margin-right: 10px;
    }
  }
  @media screen and (min-width: 998px) {
    padding: ${px2rem(80)} ${px2rem(135)};
    span {
      flex-direction: column;
      align-items: center;
      strong {
        margin: 0;
      }
    }
  }
`;

const LiveTag = styled.small`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f00;
  color: #FFF;
  font-size: 16px;
  height: 32px;
  text-align: center;
  font-weight: bold;
  border-radius: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100px;
`;

const EventInfo = styled.div<ICardSize>`
  padding: 16px 24px;
  max-height: 346px;
  ul {
    min-height: ${px2rem(15)};
    li {
      display: flex;
      align-items: center;
      margin-bottom: ${px2rem(10)};
      font-size: 10px;
      &:first-child {
        margin-top: ${px2rem(5)};
      }

      img {
        margin-right: ${px2rem(15)};
      }
    }
  }
  a {
    width: auto;
    height: ${px2rem(36)};
    padding: 0 ${px2rem(15)};
  }

  @media screen and (min-width: 998px) {
    padding: ${px2rem(50)} ${px2rem(32)} ${px2rem(32)};
    height: 47%;
    ul {
      margin-top: ${px2rem(35)};
    }
    a {
      margin-top: ${px2rem(30)};
      display: flex;
    }
  }
`;

const EventTitle = styled.h1`
  font-size: var(--font-larger);
  color: var(--blue-darkness);
  font-weight: 700;
  line-height: ${px2rem(30)};

  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 18px;
    padding-bottom: 8px;
    min-height: 20px;
  }
`;

const EventSubtitle = styled.h1`
  color: var(--blue-medium);
  font-weight: bold;
  margin-bottom: ${px2rem(5)};
  font-size: ${px2rem(28)};
  min-height: 130px;
  height: 70px;

  @media screen and (max-width: 998px) {
    height: 100%;
  }

  @media screen and (min-width: 998px) {
    font-size: var(--font-largest);
  }

  @media screen and (max-width: 768px) {
    font-size: 22px;
    line-height: 23px;
    min-height: 50px;
  }
`;

const EventDescription = styled.p`
  color: var(--grey-darker);
  font-size: var(--font-normal);
  letter-spacing: ${px2rem(0.25)};
  line-height: ${px2rem(24)};
  min-height: ${px2rem(72)};
  overflow-wrap: break-word;

  @media screen and (max-width: 998px) {
    line-height: ${px2rem(20)};
  }
`;

const EventSpeakers = styled.div`
  font-size: 15px;
  color: var(--grey-darkest);
  min-height: ${px2rem(40)};
  strong {
    font-weight: 600;
  }

  @media screen and (max-width: 1000px) {
    padding: ${px2rem(10)} ${px2rem(0)};
  }
`;

export {
  ScheduleWrapper,
  ScheduleDay,
  LiveTag,
  EventInfo,
  EventTitle,
  EventDescription,
  EventSpeakers,
  EventSubtitle,
};
