import { avatar_chat, ChatSendIcon } from 'assets';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import {
  ChatContainer,
  Title,
  Messages,
  Text,
  FooterChat,
  Container,
  Response,
  AdminOptions,
  MessageInfo,
  Option,
  UserBlockContainer,
  UserBlockList,
  ButtonChat,
  UserDelayMessage,
} from './style';
import { GrEmoji } from 'react-icons/gr';
import { IoIosArrowDown } from 'react-icons/io';
import { BsFillReplyFill, BsFillTrashFill } from 'react-icons/bs';
import { BiBlock } from 'react-icons/bi';
import Picker from 'emoji-picker-react';
import { useAuth } from 'providers/auth/AuthProvider';
import { io } from 'socket.io-client';
import { toast } from 'react-toastify';
import { convertTime } from 'helpers/date';
import { Button, MessageError, Modal } from 'components';
import { Previous } from 'assets';
import { useHistory } from 'react-router-dom';


interface IMessage {
  message: string;
  message_id: string;
  uername: string;
  user_id: string;
  live_id: string;
  chat_avatar: string;
  message_reference_id: string;
}

interface IReplyMessage {
  username: string;
  user_id: string;
  text: string;
  chat_avatar: string;
  message_reference_id: string;
  live_id: string;
}

interface IUsersBlocked {
  username: string;
  user_id: string;
  live_id: string;
  chat_avatar: string;
}

interface ILive {
  live_id?: string;
}

const LiveChat: React.FC<ILive> = ({ live_id }) => {
  const [showEmojis, setShowEmojis] = useState(false);
  const [message, setMessage] = useState('');
  const [messageReplyInput, setMessageReplyInput] = useState('');
  const { user, token } = useAuth();
  const [socket, setSocket] = useState<any>();
  const [chatMessages, setChatMessages] = useState<IMessage[] | any>([]);
  const [messageOptions, setMessageOptions] = useState(null);
  const [usersBlocked, setUsersBlocked] = useState<IUsersBlocked[] | any>([]);
  const [response, setResponse] = useState(false);
  const [replyMessage, setReplyMessage] = useState<IReplyMessage[] | any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState<boolean>(false);
  const messagesEndRef = useRef<any>(null);
  const messagesListRef = useRef<any>(null);
  const isAdmin = user?.roles?.includes('Admin');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [sendMessage, setSendMessage] = useState(false)
  const history = useHistory();


  const adjustScroll = () => {
    const scrollHeight = messagesListRef?.current?.scrollHeight;
    const scrollOfTop = messagesListRef?.current?.scrollTop;
    const messageElementHeight = 74;

    const shouldScroll =
      (scrollHeight - scrollOfTop) % messagesListRef.current.clientHeight <=
      messageElementHeight * 5;

    if (shouldScroll) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }



  const onEmojiClick = (event: any, emojiObject: any) => {
    setMessage(prevInput => prevInput + emojiObject.emoji);
    setShowEmojis(false);
  };

  const handleMessageOptions = (index: any) => {
    setMessageOptions(index);
    setOpen(!open);
  };

  const handleResponse = (index: any) => {
    setResponse(index);
    setOpen(!open);
  };

  const handleSendMessage = (e: FormEvent) => {
    e.preventDefault();

    if (!loading) {
      setSendMessage(true)
      setTimeout(() => {
        setLoading(true);
      }, 300)

      setSendMessage(true)

      setTimeout(() => {
        setSendMessage(false)
        setLoading(false)
      }, 300)

      if (!message) return;
      socket.emit('message', {
        live_id: live_id,
        user_id: user?.user_id,
        message: message,
      });
      adjustScroll();
      setMessage('');
      setResponse(false);
    }

  };

  const handleReplyMessage = (e: FormEvent, message_id: any) => {
    e.preventDefault();

    if (!messageReplyInput) return;

    socket.emit('respond_message', {
      live_id: live_id,
      user_id: user?.user_id,
      message: messageReplyInput,
      message_reference_id: message_id,
    });

    setMessageReplyInput('');
    setResponse(false);
  };

  const handleBlockUser = (userId: any) => {
    socket.emit('block_user', {
      live_id: live_id,
      user_id: userId,
    });
    toast.success('Usuário bloqueado.');
    setOpen(!open);
  };

  const handleUnblockUser = (userId: any) => {
    socket.emit('remove_user_block', {
      live_id: live_id,
      user_id: userId,
    });
    toast.info('Usuário desbloqueado.');
  };

  const handleExcludeMessage = (message_id: any) => {
    socket.emit('delete_message', {
      live_id: live_id,
      message_id: message_id,
    });
    toast.success('Mensagem deletada.');
    setOpen(!open);
  };

  useEffect(() => {
    const connection = () => {
      const SOCKET_URL = process.env.REACT_APP_SOCKET_URL as string;

      const socketIo = io(SOCKET_URL, {
        query: {
          token: token,
        },
        transports: ['websocket'],
      });

      setSocket(socketIo);

      socketIo.emit(
        'select_room',
        {
          live_id: live_id,
          user_id: user?.user_id,
          username: user?.name,
          chat_avatar: user?.info.avatar,
        },
        (data: any) => {
          setChatMessages(data.messages);
          setUsersBlocked(data.blocked_users);
        },
      );
    }

    connection()
  }, [live_id, user, token])

  useEffect(() => {
    if (socket) {
      socket.on('message', (newMessage: any) => {
        setChatMessages((messages: any) => [...messages, newMessage]);
      });

      socket.on('delete_message', (data: any) => {
        setChatMessages((messages: any) =>
          messages.filter((msg: any) => msg.message_id !== data.message_id),
        );
      });
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.on('respond_message', (newReply: any) => {
        setChatMessages((messages: any) => {
          const index = messages.findIndex(
            (message: any) => message.message_id === newReply.message_reference_id,
          );

          if (index !== -1) {
            messages[index].reply_messages = [newReply];
          }

          return [...messages];
        })
      });
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.on('block_user', (data: any) => {
        setUsersBlocked(data.blocked_users);
      });

      socket.on('remove_user_block', (data: any) => {
        setUsersBlocked(() => data.blocked_users);
      });
    }
  }, [usersBlocked]);

  useEffect(() => {
    return () => {
      if (socket) {
        socket.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    if (messagesEndRef) {
      setTimeout(() => {
        adjustScroll()
      }, 1500)
    }
  }, [messagesEndRef, messagesListRef])

  return (
    <>
      <ChatContainer>
        <Title>
          <h1>Chat</h1>
          {isAdmin && <IoIosArrowDown onClick={() => setIsOpen(!isOpen)} />}
          {isOpen && (
            <MessageInfo>
              <AdminOptions>
                <Option onClick={() => setModal(!modal)}>
                  <BiBlock style={{ width: '18px' }} />
                  <span>Usuários bloqueados</span>
                </Option>
              </AdminOptions>
            </MessageInfo>
          )}
          <Modal
            positionClose="In"
            IconTheme="dark"
            modalOpen={modal}
            closeModal={() => setModal(!modal)}
            width={{ desktop: '700px', tablet: '650px' }}
          >
            <UserBlockContainer>
              <h1>Usuários bloquados</h1>
              <p>Veja os usuários que foram bloquados.</p>
            </UserBlockContainer>
            {usersBlocked.length <= 0 ? (
              <MessageError content="Sem usuários bloqueados no momento" />
            ) : (
              usersBlocked.map((userBlocked: IUsersBlocked, key: number) => (
                <UserBlockList key={key}>
                  <img
                    src={userBlocked.chat_avatar || avatar_chat}
                    alt="Foto de perfil"
                  />
                  <span>{userBlocked.username}</span>
                  <Button
                    variant="DefaultDark"
                    onClick={() => handleUnblockUser(userBlocked.user_id)}
                  >
                    Desbloquear
                  </Button>
                </UserBlockList>
              ))
            )}
          </Modal>
        </Title>
        <Container ref={messagesListRef}>
          {chatMessages.map((messages: any, index: any) => (
            <>
              <Messages
                key={`${messages.message_id}-${index}`}
                theme={messages.reply_messages && 'Response'}
              >
                <img src={messages.chat_avatar || avatar_chat} alt="" />
                <div>
                  <div className="content-wrapper">
                    <div className="content-title">
                      <strong>{messages.username}</strong>
                      <span>{convertTime(messages.created_at)}</span>
                    </div>
                    {isAdmin && (
                      <IoIosArrowDown
                        onClick={e => handleMessageOptions(index)}
                      />
                    )}
                    {messageOptions === index && open === true && (
                      <MessageInfo>
                        <AdminOptions>
                          <Option onClick={() => handleResponse(index)}>
                            <BsFillReplyFill />
                            <span>Responder</span>
                          </Option>
                          <Option
                            onClick={e =>
                              handleExcludeMessage(messages.message_id)
                            }
                          >
                            <BsFillTrashFill />
                            <span>Apagar</span>
                          </Option>
                          <Option
                            onClick={() => handleBlockUser(messages.user_id)}
                          >
                            <BiBlock />
                            <span>Bloquear</span>
                          </Option>
                        </AdminOptions>
                      </MessageInfo>
                    )}
                  </div>
                  <Text theme={messages.reply_messages && 'Response'}>
                    <div></div>
                    <p>{messages.text}</p>
                  </Text>
                </div>
              </Messages>
              {messages.reply_messages &&
                messages.reply_messages.map((reply: any, key: number) => (
                  <Response key={key}>
                    <img src={reply.chat_avatar || avatar_chat} alt="" />
                    <div>
                      <div className="content-title">
                        <strong>{reply.username}:</strong>
                      </div>
                      <p>{reply.text}</p>
                    </div>
                  </Response>
                ))}
              {response === index && (
                <Response onSubmit={e => handleReplyMessage(e, messages.message_id)}>
                  <input
                    value={messageReplyInput}
                    type="text"
                    placeholder="Diga Algo"
                    autoFocus
                    onChange={e => setMessageReplyInput(e.target.value)}
                  />
                  <ButtonChat
                    type="submit"
                  >
                    <ChatSendIcon />
                  </ButtonChat>
                </Response>
              )}
            </>
          ))}
          <div ref={messagesEndRef} />
        </Container>
        <FooterChat onSubmit={(e) => handleSendMessage(e)}>
          {/* {!isAdmin && sendMessage && (
          <UserDelayMessage>
            <span className='progress'><span className={loading ? 'fill active' : 'fill'}></span></span>
            <p>Envie uma nova mensagem em 5 segundos</p>
          </UserDelayMessage>
        )} */}
          <div>
            <input
              value={message}
              type="text"
              placeholder="Diga Algo"
              onChange={e => setMessage(e.target.value)}
            />
            <GrEmoji onClick={() => setShowEmojis(!showEmojis)} />
            {showEmojis && (
              <Picker
                onEmojiClick={onEmojiClick}
                pickerStyle={{
                  position: 'absolute',
                  bottom: '80px',
                  width: '90%',
                }}
              />
            )}
          </div>
          <ButtonChat type="submit" >
            <ChatSendIcon />
          </ButtonChat>
        </FooterChat>
      </ChatContainer>
      <Previous onClick={() => {
        socket.emit('disconnectado')
        return history.goBack()
      }
      } className="back-button">
      </Previous>
    </>
  );
};

export default LiveChat;
