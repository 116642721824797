import React from 'react';
import { getProductMedicinesItemRoute } from 'routes/Constants';
import { Container } from './style';
import { LinkButton } from 'components';
import Product from 'services/product/models/product-interface';

type Props = {
  product: Product;
};

const MedicineCard: React.FC<Props> = ({ product }) => {
  const { product_id, title, subtitle, thumbnail } = product;

  return (
    <Container>
      <div>
        <img src={thumbnail} alt="produto medicamento" />
        <div className="card-product-content">
          <h3>{title}</h3>
          <p>{subtitle}</p>
        </div>
      </div>
      <div className="card-product-button">
        <LinkButton
          to={getProductMedicinesItemRoute(product_id)}
          variant="Outline"
        >
          Saiba mais
        </LinkButton>
      </div>
    </Container>
  );
};

export default MedicineCard;
