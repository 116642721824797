import httpClient from 'http-client';
interface ContentUpdateProgress {
  content_id: string;
  content_view: number | null;
}

const getContentInfo = async (id: string): Promise<any> => {
  const content: any = (await httpClient.get(`/contents/${id}`)).data;
  return content;
};

const updateProgressSingleContent = async ({ content_id, content_view }: ContentUpdateProgress) => {

  if (!content_id) {
    return {};
  }
  const updatedContent = await httpClient.patch('/content-users/content-view', {
    content_id,
    content_view: content_view ? content_view : 0,
  });
  return updatedContent;
};

const finishSingleContent = async (content_id: string) => {
  if (!content_id) {
    return {};
  }

  const updatedContent = httpClient.put('/content-users/finish', {
    content_id,
  });

  return updatedContent;
};

const startSingleContent = async (content_id: string) => {
  if (!content_id) {
    return {};
  }

  const updatedContent = httpClient.post('/content-users', {
    content_id,
  });

  return updatedContent;
};

export {
  getContentInfo,
  updateProgressSingleContent,
  finishSingleContent,
  startSingleContent
};
