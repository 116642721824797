import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from 'components';

type IBack = {
  title: string;
};

const Container = styled.div``;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    color: var(--blue-medium);
    font-weight: 900;
    margin-left: 15px;
    font-size: 16px;
  }
`;

const BackText: React.FC<IBack> = ({ title }) => {
  const history = useHistory();
  const handleBack = () => {
    history.goBack();
  };

  return (
    <Container onClick={handleBack}>
      <Wrapper>
        <Icon name="back" size={16} />
        <span>{title}</span>
      </Wrapper>
    </Container>
  );
};

export default BackText;
