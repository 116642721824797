import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2.6rem;
  margin: 30px auto;
  position: relative;
  z-index: 6;

  .brand {
    max-width: 200px;
    width: 100%;
  }

  ul {
    display: flex;
    align-items: center;
    width: 300px;
  }

  li {
    margin-right: 20px;

    a {
      white-space: nowrap;
      display: block;
      position: relative;
      font-weight: bold;
      font-size: var(--font-medium);
      text-decoration: none;
      color: var(--blue-medium);

      &::before {
        content: '';
        background-color: var(--blue-medium);
        width: 4px;
        opacity: 0;
        height: 3px;
        display: block;
        border-radius: 0;
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: scaleX(1) translateX(-300%);
        transition: all 0.3s ease;
        border-radius: 0.7px;
      }

      :hover {
        &::before {
          transform: scaleX(12) translateX(-10%);
          opacity: 1;
        }
      }

      &.active {
        &::before {
          transform: scaleX(12) translateX(-10%);
          opacity: 1;
        }
      }
    }
  }
`;
