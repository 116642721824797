import React from 'react';
import { Banner, ContainerGlobal } from 'components';
import { Container, Wrapper, HeaderPage, Title, Subtitle } from './style';

interface IHeaderPage {
  title: string;
  subtitle: string;
}

interface ITemplateWithBg {
  banner: {
    title: string;
    imgDesktop: string;
  };
  breadcrumb: React.ReactElement;
  headerPage?: IHeaderPage;
}

const Template: React.FC<ITemplateWithBg> = ({
  banner,
  breadcrumb,
  children,
  headerPage,
}) => {
  return (
    <ContainerGlobal>
      <Container>
        <div className="content-template">
          <Banner
            title={banner.title}
            imgDesktop={banner.imgDesktop}
            theme="BlueDark"
          />
          <div className="container-breadcrumb">{breadcrumb}</div>
        </div>
        <Wrapper>
          <HeaderPage>
            <Title>{headerPage?.title}</Title>
            <Subtitle>{headerPage?.subtitle}</Subtitle>
          </HeaderPage>
          {children}
        </Wrapper>
      </Container>
    </ContainerGlobal>
  );
};

export default Template;
