import { Dispatch, useReducer, useEffect } from 'react';
import { getProduct } from 'services/product';
import Product from 'services/product/models/product-interface';

type Action =
  | { type: 'load' }
  | { type: 'success'; payload: Product }
  | { type: 'error' };

type State = {
  isLoading: boolean;
  hasError: boolean;
  product: Product | null;
};

const initialState: State = {
  isLoading: true,
  hasError: false,
  product: null,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'load':
      return { ...state, isLoading: true, hasError: false };
    case 'success':
      return {
        ...state,
        isLoading: false,
        hasError: false,
        product: action.payload,
      };

    case 'error':
      return { ...state, isLoading: false, hasError: true };

    default:
      return { ...state };
  }
}

const getProductById = async (
  dispatch: Dispatch<Action>,
  product_id: string,
) => {
  try {
    dispatch({ type: 'load' });

    const product = await getProduct(product_id);

    dispatch({ type: 'success', payload: product });
  } catch (err) {
    console.log('err:', err);
    dispatch({ type: 'error' });
  }
};

export function useSelectedProductById(productId: string) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    async function getSelectedProduct() {
      await getProductById(dispatch, productId);
    }

    getSelectedProduct();
  }, [productId]);

  return {
    ...state,
  };
}
