import React from 'react';
import { card_produto } from 'assets';
import { LinkButton } from 'components';
import { Product, ProductInfo, ProductName, ProductType } from './style';
import IProduct, { ProductType as IProductType } from 'services/product/models/product-interface';

interface IProductCard {
  product?: IProduct
}

type TypeTitle = Record<IProductType, string>;

const getTitle = (type: IProductType) => {
  const typeTitle: TypeTitle = {
    supplement: 'Suplementos',
    medicine: 'Medicamentos',
  };

  return typeTitle[type] ?? '';
};

const ProductCard: React.FC<IProductCard> = ({ product }) => {
  if (!product)
    return null
  const link = product?.type === 'supplement' ? `/produtos/suplemento/${product?.product_id}` : `/produtos/medicamento/${product?.product_id}`;
  return (
    <Product>
      <img src={product ? product.thumbnail : card_produto} alt="" />
      <ProductInfo>
        <div>
          <ProductName>{product?.title}</ProductName>
          <ProductType>{getTitle(product?.type)}</ProductType>
        </div>
        <LinkButton to={link} variant="Outline">
          Saiba mais
        </LinkButton>
      </ProductInfo>
    </Product>
  );
}

export default ProductCard;