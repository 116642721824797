import { ArticleCard, SpolightCard } from 'components';
import React, { useEffect, useState } from 'react';
import { getAllNews } from 'services/news';
import { Header, SectionTitle,Row, Grid, Wrapper } from './styles';
import News from 'services/news/models/news-interface';

interface INewsList{
  theme?: string
  categoryId?: string
  show?: any
  reverse?: boolean
  title?: string
}

const NewList: React.FC<INewsList> = ({ theme, categoryId, show, reverse, title }) => {
  const [news, setNews] = useState<Array<News>>();
  const getNews = async (id :string) => {
    const news = await getAllNews({ category_id: id, limit: 5 })
    setNews(news)
  }

  useEffect(() => {
    if(categoryId)
      getNews(categoryId)
  }, [categoryId])

  if(news && !news[0]){
    return null;
  }
  return(
    <>
      <Header>
        <SectionTitle color={theme}>{title}</SectionTitle>
      </Header>
      <Row reverse={reverse}>
        <SpolightCard theme={theme} data={news && news[0]} to={`/noticias/${news && news[0]?.news_id}`}/>
        <Grid>
          {news?.slice(1,5).map((content: News, key: number) =>(
            <ArticleCard path="noticias" theme={theme} data={content} tag="notícias"/>
          ))}
        </Grid>
      </Row>
    </>
  );
}

export default NewList;