import React from 'react';
import { BannerWrapper } from './style';

interface IBanner {
  theme?: 'BlueDark' | 'PinkLight' | 'PinkMedium' | 'PinkDark' | "";
  title: string;
  subtitle?: string;
  imgDesktop: string;
  imgMobile?: string;
  color?: string;
}

const Banner: React.FC<IBanner> = ({
  theme,
  title,
  subtitle,
  imgDesktop,
  imgMobile,
  color
}) => {
  return (
    <BannerWrapper theme={theme} color={color}>
      <div className="content">
        <h2>{title}</h2>
        {subtitle && <h5>{subtitle}</h5>}
      </div>
      <div className="container-img">
        <div>
          {imgDesktop && (
            <img src={imgDesktop} alt="Info" className="imgBannerDesktop" />
          )}
          {imgMobile && (
            <img src={imgMobile} alt="Info" className="imgBannerMobile" />
          )}
        </div>
      </div>
    </BannerWrapper>
  );
};

export default Banner;
