import httpClient from 'http-client';
import Info from './models/info-interface';

const getInfoByType = async (
  type: string,
  headers?: object,
): Promise<Array<Info>> => {
  const info = await httpClient.get<Array<Info>>('/home-info', {
    params: {
      type,
    },
    headers,
  });

  return (info || { data: [] }).data;
};

const getInfoContent = async (filters: any): Promise<void> => {
  const content = await (await httpClient.get('/home-info/categories/contents', { params: filters })).data;
  return content;
}

export { getInfoByType, getInfoContent };
