import React from 'react';
import FAQ from 'services/faq/models/faq-interface';

type Props = {
  faq: FAQ[] | any;
};

const ContentUI: React.FC<Props> = ({ faq }) => {
  if (!faq) {
    return null;
  }
  const { answer } = faq;

  return <div dangerouslySetInnerHTML={{ __html: answer }} />;
};

export default ContentUI;
