import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { Banner, Button } from 'components';
import { d_doctor, m_doctor } from 'assets';
import { BackgroundHeader, Container, Wrapper } from './style';
import { HOME, LADINGPAGE } from 'routes/Constants';
import { useAuth } from 'providers/auth/AuthProvider';
import { ContainerGlobal } from 'components/ContainerGlobal/style';

type IError = {
  type: 'Error404' | 'Permission' | 'LoadFail';
};

const ErrorUI: React.FC<IError> = ({ type }) => {
  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const { state: prevPath } = useLocation<any>();
  const regex = /live\/play\/ || \/eventos/i;
  const regexContent = /podcast(?:\/play)?|\/artigo(?:\/.*)?||\/produtos(?:\/.*)?|\/categoria(?:\/.*)?|\/noticias(?:\/.*)?/;

  const match = prevPath?.prevPath?.match(regex)
  const internalContent = prevPath?.prevPath?.match(regexContent);

  if (internalContent) {
    localStorage.setItem('internalContent', prevPath?.prevPath)
  }

  if (match) {
    localStorage.setItem('path', prevPath?.prevPath);
    history.push('/login')
  }

  const handleBack = () => {
    history.goBack();
  };

  const handleGoHome = () => {
    if (isAuthenticated) {
      return history.push(`${HOME}`);
    }
    return history.push(`${LADINGPAGE}`);
  };

  return (
    <>
      <BackgroundHeader />
      <Container>
        <ContainerGlobal>
          <Wrapper>
            {type === 'Error404' && (
              <Banner
                title="ERRO 404"
                theme="BlueDark"
                imgDesktop={d_doctor}
                imgMobile={m_doctor}
              />
            )}
            {type === 'LoadFail' && (
              <Banner
                title="falha no carregamento"
                theme="BlueDark"
                imgDesktop={d_doctor}
                imgMobile={m_doctor}
              />
            )}
            {type === 'Permission' && (
              <Banner
                title="página não disponível"
                theme="BlueDark"
                imgDesktop={d_doctor}
                imgMobile={m_doctor}
              />
            )}
            <div className="content-page">
              <h2>TEM ALGO ERRADO</h2>
              {type === 'Error404' && (
                <h3>Erro 404: A página que você procura não existe.</h3>
              )}
              {type === 'LoadFail' && <h3>O carregamento da página falhou.</h3>}
              {type === 'Permission' && (
                <h3>A página que você procura não está disponível.</h3>
              )}
              <div className="container-btn">
                <Button variant="DefaultDark" onClick={handleBack}>
                  VOLTAR A PÁGINA ANTERIOR
                </Button>
              </div>
              <div className="container-btn">
                <Button variant="Outline" onClick={handleGoHome}>
                  IR PARA A PÁGINA INICIAL
                </Button>
              </div>
            </div>
          </Wrapper>
        </ContainerGlobal>
      </Container>
    </>
  );
};

export default ErrorUI;
