import React from 'react';
import styled from 'styled-components';
import { Icon } from 'components';
import { IconsTypes } from 'components/Icon/catalogue';

interface IInfo {
  icon: {
    name: IconsTypes;
    color: string;
    size: number;
  };
  title: string;
  description: string;
}

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .info-icon {
    display: flex;
    align-items: center;
    line-height: 24px;
  }

  .description-info {
    padding-left: 2.6rem;
  }
`;

const Title = styled.h3`
  font-size: 18px;
  color: var(--blue-medium);
  font-weight: 800;
  text-transform: uppercase;
  margin-top: 0;

  @media only screen and (max-width: 768px) {
    font-size: 17px;
    margin-top: -4px;
  }
`;

const Description = styled.p`
  color: var(--grey-medium-darker);
  font-weight: 500;
  font-size: 16px;
`;

const Info: React.FC<IInfo> = ({ title, description, icon }) => {
  return (
    <Component>
      <div className="info-icon">
        <div style={{ marginRight: '10px' }}>
          <Icon name={icon.name} size={icon.size} color={icon.color} />
        </div>
        <Title className="title-info">{title}</Title>
      </div>
      <div className="description-info">
        <Description>{description}</Description>
      </div>
    </Component>
  );
};

export default Info;
