import styled, { css } from 'styled-components';
import { px2rem } from 'styles/globals';

interface ISpolightImage {
  image?: string;
}

//TODO: Analisar o uso do max-width: ${px2rem(690)};

const Spotlight = styled.div`
  position: relative;
  /* max-width: ${px2rem(690)}; */
  border-radius: 4px;
  max-width: 690px;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (min-width: 998px){
    min-height: 427px;
  }

  @media screen and (max-width: 997px) {
    margin-bottom: 16px;
  }
`;

const SpotlightImage = styled.div`
  background: linear-gradient(
    180deg,
    rgba(203, 107, 127, 0) 0%,
    #a30067 91.67%
  );

  ${props =>
    props.color &&
    css`
      background-color: var(--pink-darker);
      background: linear-gradient(
        180deg,
        rgba(203, 107, 127, 0) 0%,
        ${props.color} 91.67%
      );
    `}
  background-repeat: no-repeat;
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const SpotlightDesc = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px 16px;
  >div{
    position: relative;
    align-self: flex-start;
  }
  @media screen and (min-width: 998px) {
    padding: 32px;
  }
`;

const SpotlightTitle = styled.h1`
  text-transform: uppercase;
  color: var(--white);
  font-weight: 800;
  letter-spacing: 0.18px;
  font-size: 20px;
  @media screen and (min-width: 998px) {
    font-size: var(--font-largest);
  }
`;

const SpotlightDescription = styled.p`
  color: var(--white);
  letter-spacing: 0.25px;
  font-weight: 700;
  margin-top: 10px;
  font-size: 14px;
  overflow: hidden; 
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; 
  -webkit-box-orient: vertical;
  padding-bottom: 5px;
  @media screen and (min-width: 998px) {
    font-size: var(--font-medium);
    max-width: ${px2rem(440)};
  }
`;

export {
  Spotlight,
  SpotlightImage,
  SpotlightDesc,
  SpotlightTitle,
  SpotlightDescription,
};
