import React from 'react';
import { mapPin, online, watch } from 'assets';

import { Wrapper } from './style';

interface IEventData {
  title: string;
  subtitle: string;
  date: string;
  address?: {
    place: string;
    street: string;
    city: string;
    state: string;
  };
  status: string;
  isOnline: boolean;
}

const EventData: React.FC<IEventData> = ({ title, subtitle, date, address, status, isOnline }) => {

  return (
    <Wrapper>
      <h4 className="title block">{title}</h4>
      <h3 className="subtitle block">{subtitle}</h3>
      <div className="date-address-container">
        <div className="date-address-wrapper">
          <img className="date-address-icon" src={watch} alt="" />

          <h5 className="date-address-text">
            {status !== 'live' && (
              <span className="date-address bold">REALIZANDO EM: </span>
            )}
            <span>{date}</span>
          </h5>
        </div>

        <div className="date-address-wrapper address">

          {isOnline && (
            <>
              <img className="date-address-icon" src={online} alt="" />

              <h5 className="date-address-text online">ONLINE</h5>
            </>
          )}
        </div>

        <div className="date-address-wrapper address">

          {address?.city && (
            <>
              <img className="date-address-icon" src={mapPin} alt="" />

              <h5 className="date-address-text">{`${address!.street}, ${address!.place} - ${address!.city}/${address!.state}`}</h5>
            </>
          )}
        </div>
      </div>
    </Wrapper>
  );
}

export default EventData;
