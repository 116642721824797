import React from 'react';
import { OutsideClick } from 'components';
import { SelectContainer, Container } from './style';
import union from 'assets/icon/union.svg';

export interface ISelect {
  select?: boolean;
  onClose(): void;
}

/**
 * Conferir a utilização em pages/components
 */

const Select: React.FC<ISelect> = ({ select, onClose, children }) => {
  return (
    <>
      {select && (
        <Container>
          <OutsideClick handleClick={onClose}>
            <img src={union} alt="triangle" className="triangle" />
            <SelectContainer>{children}</SelectContainer>
          </OutsideClick>
        </Container>
      )}
    </>
  );
};

export default Select;
