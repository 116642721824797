import { ArticleCard, LinkButton, SpolightCard } from 'components';
import React, { useEffect, useState } from 'react';
import { getAllNews } from 'services/news';
import { Header, SectionTitle,Row, Grid, Wrapper } from './style';
import News from 'services/news/models/news-interface';
import { getAllCommentArticle } from 'services/commentedArticle';
import CommentArticle from 'services/commentedArticle/models/commentArticle-interface';
import { getContentByCategoryType } from 'services/categories';

interface IArticleList{
  theme?: string
  categoryId?: string
  show?: any
  reverse?: boolean
  title?: string
}

const ArticleList: React.FC<IArticleList> = ({ theme, categoryId, show, reverse, title }) => {
  const [content, setContent] = useState<Array<any>>();
  const getNews = async (id :string) => {
    const content = await getContentByCategoryType(id,'PODCAST')
    setContent(content)
  }

  useEffect(() => {
    if(categoryId)
      getNews(categoryId)

  }, [categoryId])

  if(content && !content[0]){
    return null;
  }
  
  return(
    <>
     <Header>
        <SectionTitle color={theme}>{title}</SectionTitle>
        <LinkButton to="/podcast/listagem" variant="TextLink">
          Ver mais</LinkButton>
      </Header>
      <Row reverse={reverse}>
        <SpolightCard theme={theme} data={content && content[0]} to={`/podcast/play/${content && content[0]?.content_id}`}/>
        <Grid>
          {content?.slice(1,5).map((content: any, key: number) =>(
            <ArticleCard path={`/podcast/play/${content && content?.content_id}`} theme={theme} data={content} tag="" key={key}/>
          ))}
        </Grid>
      </Row>
    </>
  );
}

export default ArticleList;