import styled, { keyframes } from 'styled-components';
import arrow_select from 'assets/icon/arrow_select.svg';

const dropdown = keyframes`
	z-index: 10;
	0% {
		opacity: 0;
		transform: translate(0px, -30px);
	}
	100% {
		opacity: 1;
		transform: translate(0px, 0px);
	}
`;

export const SelectWrapper = styled.select`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  position: relative;

  label {
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 8px;
    color: var(--grey-darkness);
  }

  p {
    margin-top: 10px;
    font-size: var(--font-normal);
    color: var(--third-color);
    font-weight: 600;
    cursor: pointer;
  }

  .error-border {
    border: 1px solid var(--color-error);
  }

  height: 45px;
  outline: none;
  border: 1px solid rgba(155, 153, 175, 0.31);
  background: transparent;
  border-radius: 6px;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(${arrow_select});
  background-repeat: no-repeat;
  background-position: top 10% right;
  color: grey;

  span {
    padding-top: 5px;
    padding-left: 5px;
    color: var(--color-error);
    font-size: 14px;
  }

  .alert {
    color: var(--blue-light);
    margin-left: -6px;
    margin-bottom: 10px;
    margin-top: -10px;
    font-size: 12px;
  }

  .options {
    animation: ${dropdown} 0.6s;
    width: 100%;
    background-color: white;
    -webkit-box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);
    -moz-box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);
    box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);
    padding: 15px 20px;
    border-radius: 6px;
    color: var(--grey-darker);
    font-weight: bold;

    option {
      padding: 10px 20px;
    }
  }
`;
