const abrvMonths = [
  {
    name: 'Jan', number: '01'
  },
  {
    name: 'Fev', number: '02'
  },
  {
    name: 'Mar', number: '03'
  },
  {
    name: 'Abr', number: '04'
  },
  {
    name: 'Mai', number: '05'
  },
  {
    name: 'Jun', number: '06'
  },
  {
    name: 'Jul', number: '07'
  },
  {
    name: 'Ago', number: '08'
  },
  {
    name: 'Set', number: '09'
  },
  {
    name: 'Out', number: '10'
  },
  {
    name: 'Nov', number: '11'
  },
  {
    name: 'Dez', number: '12'
  },

];

export {
  abrvMonths
}