import httpClient from 'http-client';
import News from './models/news-interface';

const getAllNews = async (filters?: object): Promise<News[]> => {
  const news: News[] = (
    await httpClient.get('/news', {
      params: filters,
    })
  ).data;

  return news;
};

const getNew = async (news_id: string): Promise<News> => {
  const news = (await httpClient.get(`/news/${encodeURI(news_id)}`)).data;
  return news;
};

export { getNew, getAllNews };
