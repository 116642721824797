import React, { useEffect, useState, useMemo } from 'react';
import { article_card_placeholder, d_article, iconDownload, m_article } from 'assets';
import {
  ArticleCard,
  BreadCrumb,
  Template,
  LoadingBg,
  MessageError,
  Error,
  Pagination,
} from 'components';
import { Link } from 'react-router-dom';
import { HOME } from 'routes/Constants';
import smoothscroll from 'smoothscroll-polyfill';
import { WrapperList, Grid, A } from '../style';
import ICommentArticles from 'services/commentedArticle/models/commentArticle-interface';
import ICategories from 'services/categories/models/category-interface';
import { getAllCommentArticle } from 'services/commentedArticle';
import { getCategories } from 'services/categories';
import { toast } from 'react-toastify';
import getErrorMessage from 'helpers/errorMessages';
import { ExternalLink, Article, Wrapper, ArticleDescription, ArticleResume, ArticleTitle } from './style';
import Tag from 'components/Tags';
import { engagementRecord } from 'services/user';

smoothscroll.polyfill();

const CommentedArticles: React.FC = ({}) => {
  const [allCommentArticles, setAllCommentArticles] = useState<
    ICommentArticles[]
  >([]);
  
  const [filteredCommentsArticles, setFilteredCommentsArticles] = useState<
    ICommentArticles[]
  >([]);
  const [allCategories, setAllCategories] = useState<ICategories[]>([]);

  const [selectedCategory, setSelectedCatogory] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const number_of_items = 12;
  const number_of_pages = useMemo(() => {
    return Math.ceil(allCommentArticles?.length / number_of_items);
  }, [allCommentArticles]);

  const articles_to_show = useMemo(() => {
    return [...allCommentArticles].splice(
      page * number_of_items,
      number_of_items,
    );
  }, [allCommentArticles, page, number_of_items]);

  useEffect(() => {
    const getAllArticles = async () => {
      try {
        setError(false);
        const all_comments_articles = await getAllCommentArticle({
          category_id: selectedCategory || undefined,
          type: 'ARTIGO_CIENTIFICO'
        });
        setAllCommentArticles(all_comments_articles);
        setFilteredCommentsArticles(all_comments_articles);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        toast.error(errorMessage);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    getAllArticles();
  }, [selectedCategory]);

  useEffect(() => {
    const getAllCategories = async () => {
      try {
        const all_categories = await getCategories();
        setAllCategories(all_categories);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        toast.error(errorMessage);
      }
    };

    getAllCategories();
  }, []);

  const registerEngagement = async (id: string) => {
    await engagementRecord(id, 'ARTIGO_CIENTIFICO');
  }

  if (loading) return <LoadingBg />;
  if (error) return <Error type="LoadFail" />;

  const selectCategory = (category: string) => {
    setSelectedCatogory(category);
  };


  const renderCardsAllCommentsArticles = (items: ICommentArticles[]) => {
    if (items.length === 0)
      return <MessageError content="Sem artigos no momento." />;

    return items.map(item => {
      const theme = item?.categories[0]?.info?.color
      return(
        <>
          <ExternalLink onClick={() => registerEngagement(item?.content_id)} href={item?.reference} target="_blank" rel="noreferrer" download>
            <Article color={theme}>
              <Wrapper>
                <img className="icon" src={iconDownload} alt=""/>
                <img src={item?.thumbnail ? item?.thumbnail :  article_card_placeholder} alt=""/>
                <Tag type={'Artigo Científico'} theme={theme}/>
              </Wrapper>
              <ArticleDescription>
                <ArticleTitle>{item?.title}</ArticleTitle>
                <ArticleResume>{item?.description}</ArticleResume>
              </ArticleDescription>
            </Article>
          </ExternalLink>
        </>
      )
    });
  };

  const renderAllCategories = (items: ICategories[]) => {
    if (items.length === 0)
      return <h3>Não tem categorias cadastradas no momento.</h3>;

    return items?.map(item => (
      <li
        key={item.category_id}
        onClick={() => selectCategory(item.category_id)}
        className={selectedCategory == item?.category_id ? `active` : ''}
      >
        {item.title}
      </li>
    ));
  };

  return (
    <>
      <Template
        banner={{
          title: 'Artigos científicos',
          imgDesktop: d_article,
          imgMobile: d_article,
        }}
        breadcrumb={
          <BreadCrumb
            crumbs={[
              <Link to={`${HOME}`}>HOME</Link>,
              <span>Artigos científicos</span>,
            ]}
          />
        }
      >
        <WrapperList>
          <div className="summary">
            <p>Categorias</p>
            <ul>{renderAllCategories(allCategories)}</ul>
          </div>
          <A>
            <Grid>{renderCardsAllCommentsArticles(articles_to_show)}</Grid>
            <Pagination
              actualPage={page}
              pages={number_of_pages}
              changePage={setPage}
            />
          </A>
        </WrapperList>
      </Template>
    </>
  );
};

export default CommentedArticles;


