import React, { useCallback, useEffect, useMemo, useState } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import { useParams } from 'react-router';
import { ArticleCard, BreadCrumb, LoadingBg, PlayerSambaVideos, Template } from 'components';
import { Link } from 'react-router-dom';
import { HOME, PODCAST } from 'routes/Constants';
import { d_podcast, d_technology, m_technology } from 'assets';
import { Content, SpolightHeader } from '../components/SpotContent/style';
import { getContentInfo, updateProgressSingleContent, finishSingleContent as finishSingleContentService, startSingleContent as startSingleContentService } from 'services/contents';
import { getContentByCategoryType } from 'services/categories';
import { Row } from 'pages/Home/style';
import { Related } from '../style';
import { engagementRecord } from 'services/user';

smoothscroll.polyfill();

interface PlayerEventListener {
  event: string;
  eventParam?: any;
  duration?: any;
}

interface ExtendedWindow extends Window {
  hasStartedContent: boolean;
  hasFinishedContent: boolean;
}

declare let window: ExtendedWindow;

const DetailVideo: React.FC = () => {
  const [content, setContent] = useState<any>();
  const [relatedContent, setRelatedContent] = useState<any>();
  const [progress, setProgress] = useState<number>(0);
  const [lastWatchedTime, setLastWatchedTime] = useState<number>(0);
  const { podcast_id } = useParams<{
    podcast_id: string
  }>();


  const getContent = useCallback(
    async () => {
      const content = await getContentInfo(podcast_id);
      setContent(content);
    }, [podcast_id]
  )

  const getRelatedContent = useCallback(
    async () => {
      const category = content ? content?.categories[0]?.category_id : undefined;
      const relatedContent = await getContentByCategoryType(category, 'PODCAST');
      setRelatedContent(relatedContent.slice(0, 4));
    }, [content]
  )

  const renderRelated = (items: any) => {
    if (items?.length === 0)
      return null;

    return items.map((item: any) => {
      const theme = item?.categories[0]?.info?.color
      return (
        <ArticleCard path={`/podcast/play/${item?.content_id}`} key={item.content_id} data={item} themeTag={theme} />
      )
    });
  };

  const getEventListeners = async (player: PlayerEventListener) => {
    if (window.location.href.endsWith(`play/${podcast_id}`)) {
      if (content && content.content_id) {
        switch (player.event) {
          case 'onProgress':
            if (hasReachedCompletionTime(player.duration, player.eventParam)) {
              await finishContent();
            }
            updateWatchTime(player);
            break;

          case 'onStart':
            startContent();
            await engagementRecord(podcast_id, 'PODCAST')
            break;

          case 'onFinish':
            goToNextContent();
            break;

          default:
            break;
        }
      }
    }
  };


  const updateContentProgress = useCallback(
    async (time: number | null) => {
      await updateProgressSingleContent({ content_id: podcast_id, content_view: time });
    },
    [podcast_id]
  );

  useEffect(() => {
    window.hasStartedContent = false;
    window.hasFinishedContent = false;

    if (podcast_id) {
      getRelatedContent();
    }
  }, [getRelatedContent, podcast_id])

  useEffect(() => {
    getContent();
  }, [podcast_id])

  const resumeTime = useMemo(() => {
    if (!content) {
      return false;
    }
    if (content?.content_user) {
      setLastWatchedTime(Number(content.content_user.content_view));
      return Number(content?.content_user.content_view);
    }

    return false;
  }, [content]);

  useEffect(() => {
    if (progress >= lastWatchedTime + 30) {
      setLastWatchedTime(progress);
      updateContentProgress(progress);
      return;
    }
    if (progress < lastWatchedTime) {
      if (progress > 1) {
        setLastWatchedTime(progress);
        updateContentProgress(progress);
        return;
      }
      setLastWatchedTime(0);
      updateContentProgress(null);
    }
  }, [content, lastWatchedTime, progress, updateContentProgress]);

  const hasReachedCompletionTime = (duration: number, currentTime: number) => {
    const totalDurationInSecs = duration;
    const completionRate = 0.9;

    const completionTime = totalDurationInSecs * completionRate;
    return currentTime >= completionTime;
  };

  const finishContent = async () => {
    if (content) {
      if (!window.hasFinishedContent && !content?.content_user?.finish_at) {
        try {
          window.hasFinishedContent = true;
          await finishSingleContentService(content.content_id);
          content.content_user.finish_at = true;
        } catch {
          window.hasFinishedContent = false;
        }
      }
    }
  };

  const updateWatchTime = useCallback((player: PlayerEventListener) => {
    const { event, eventParam } = player;

    if (event === 'onProgress') {
      setProgress(eventParam);
    }
    return null;
  }, []);

  const startContent = async () => {
    if (content) {
      if (!window.hasStartedContent && !content.content_user) {
        try {
          window.hasStartedContent = true;
          await startSingleContentService(content.content_id);
        } catch {
          window.hasStartedContent = false;
        }
      }
    }
  };

  const goToNextContent = async () => { };


  if (!content || content?.length <= 0)
    return <LoadingBg />
  return (
    <>
      <Template
        banner={{
          title: 'Podcast',
          imgDesktop: d_podcast,
          imgMobile: m_technology,
        }}
        breadcrumb={
          <BreadCrumb
            crumbs={[
              <Link to={`${HOME}`}>HOME</Link>,
              <Link to={`${PODCAST}`}>Podcast</Link>,
              <Link to={`/categoria/${content?.categories[0]?.category_id}`}>{content?.categories[0]?.title}</Link>,
              <span>{content?.title}</span>,
            ]}
          />
        }
      >
        <Content>
          <div className="video">
            <PlayerSambaVideos
              contentReference={content?.reference}
              controlsToEnable={[
                'play',
                'pause',
                'quality',
                'fullscreen',
                'time',
                'volume',
                'seekbar',
              ]}
              getEventListeners={getEventListeners}
              resume={resumeTime}
            />
          </div>
          <div>
            <SpolightHeader>
              <div>
                <h3>{content?.title}</h3>
                <p>{content?.description}</p>
              </div>
            </SpolightHeader>
            <Related>
              <h3>Mais podcasts</h3>
              <Row>
                {relatedContent && renderRelated(relatedContent)}
              </Row>
            </Related>
          </div>
        </Content>
      </Template>
    </>
  );
};

export default DetailVideo;
