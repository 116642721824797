import React, { useEffect, useState } from 'react';
import { Button, Input } from 'components';
import EditInfo from './style';
import { data as states } from 'pages/Account/Signup/Components/data';
import { Selection } from './style';
import { maskOnlyNumber, maskPhone } from 'helpers/masks';
import { User } from 'services/user/models/user-interface';
import { changeUserInfo, verifyUser } from 'services/user';
import { validateName } from 'helpers/validation';
import { toast } from 'react-toastify';

type Props = {
  user: User;
  onClose: () => void;
};

const EditProfile: React.FC<Props> = ({ user, onClose }) => {
  const [newUsername, setNewUsername] = useState<string>('');
  const [newPhone, setNewPhone] = useState<string>('');
  const [newCRM, setNewCRM] = useState<string>('');
  const [newCRMSelected, setNewCRMSelected] = useState<string>('');

  const [nameError, setNameError] = useState<string>('');
  const [newCRMError, setNewCRMError] = useState<string>('');
  const [newCRMSelectedError, setNewCRMSelectedError] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');

  const findState = states.filter(
    e => e.value === user.info.crm_uf || e.label === user.info.crm_uf,
  );

  useEffect(() => {
    setNewUsername(user.name);
    setNewPhone(maskPhone(user.info.telephone));
    setNewCRM(user.info.crm);
    setNewCRMSelected(findState[0]?.label);
  }, [user]);  

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    switch (e.target.id) {
      case 'name-input':
        setNewUsername(e.target.value);
        break;

      case 'crm-input':
        setNewCRM(maskOnlyNumber(e.target.value));
        break;

      case 'crm-selected-input':
        setNewCRMSelected(e.target.value);
        break;

      case 'phone-input':
        setNewPhone(maskPhone(e.target.value));
        break;

      default:
        break;
    }
  };

  const handleChangeSelect = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    switch (e.target.id) {
      case 'crm-selected':
        setNewCRMSelected(e.target.value);
        break;

      default:
        break;
    }
  };

  const handleChangeUserInfo = async (): Promise<void> => {
    if (!newUsername) {
      setNameError('Preencha corretamente este campo');
    }
    if (!newPhone) {
      setPhoneError('Preencha corretamente este campo');
    }
    if (!newCRMSelected) {
      setNewCRMSelectedError('Selecione o UF do seu CRM');
    }
    if (!newCRM) {
      setNewCRMError('Preencha corretamente este campo');
    }
    if (validateName(newUsername) || newUsername.length < 2) {
      setNameError(
        'O nome precisa ter pelo menos duas letras e nenhum caracter especial.',
      );
      return;
    }
    if (newCRM.length < 7) {
      setNewCRMError('O crm precisa ter 7 números.');
      return;
    }
    if (
      newPhone
        .replace('(', '')
        .replace(')', '')
        .replace('-', '')
        .replace(' ', '').length < 11
    ) {
      setPhoneError('O número de telefone está inválido.');
      return;
    }
    if (!newCRMError && !newCRMSelectedError && !nameError && !phoneError) {
      try {
        const { email_exists, crm_exists } = await verifyUser(
          user.email,
          newCRM,
        );
        if (!crm_exists || user.info.crm === newCRM) {
          const data = {
            // email: newEmail,
            name: newUsername,
            info: {
              crm: newCRM,
              crm_uf: newCRMSelected,
              telephone: newPhone
                .replace('(', '')
                .replace(')', '')
                .replace('-', '')
                .replace(' ', ''),
            },
          };
          await changeUserInfo(data).then(response => {
            if (response) {
              localStorage.setItem('exeltis-user', JSON.stringify(response));
              toast.success('Usuário alterado com sucesso.');
              onClose();
            }
          });
        } else {
          if (crm_exists) {
            setNewCRMError('Este CRM já foi cadastrado');
          }
        }
      } catch (err) {
        toast.error('⚠ Houve um erro ao alterar as informações.');
      }
    } else {
      console.log('error');
    }
  };

  return (
    <EditInfo>
      <div className="modal-wrapper">
        <h2>Alterar meus dados</h2>
        <Input
          label="Nome completo"
          type="text"
          id="name-input"
          value={newUsername}
          onChange={handleChangeInput}
          onFocus={() => setNameError('')}
          required={false}
        >
          {nameError && <span>{nameError}</span>}
        </Input>
        <div className="input-container">
          <div className="uf-select">
            <h3>UF do CRM</h3>
            <Selection
              id="crm-selected"
              onChange={handleChangeSelect}
              onFocus={() => setNewCRMSelected('')}
            >
              <option>
                {newCRMSelected ? newCRMSelected : `Selecione o UF do CRM`}
              </option>
              {states.map(({ value, label }) => (
                <option value={value} key={value}>
                  {label}
                </option>
              ))}
            </Selection>
            {newCRMSelectedError && (
              <span className="error-styled">{newCRMSelectedError}</span>
            )}
          </div>
          <div>
            <Input
              label="CRM"
              type="text"
              id="crm-input"
              maxlength={7}
              value={newCRM}
              onChange={handleChangeInput}
              onFocus={() => setNewCRMError('')}
              required={false}
            >
              <>{newCRMError && <span>{newCRMError}</span>}</>
            </Input>
          </div>
        </div>
        <Input
          type="text"
          id="phone-input"
          value={newPhone}
          onChange={handleChangeInput}
          onFocus={() => setPhoneError('')}
          label="Celular"
          required={false}
        >
          {phoneError && <span>{phoneError}</span>}
        </Input>
        <div className="buttons">
          <Button variant="Outline" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="DefaultDark" onClick={() => handleChangeUserInfo()}>
            Salvar alterações
          </Button>
        </div>
      </div>
    </EditInfo>
  );
};

export default EditProfile;
