import { useMediaQuery } from 'react-responsive';

const Desktop = ({ children }: any) => {
  const isDesktop = useMediaQuery({ minWidth: 1201 })
  return isDesktop ? children : null
}
const Mobile = ({ children }: any) => {
  const isMobile = useMediaQuery({ maxWidth: 1200 })
  return isMobile ? children : null
}

export { Desktop, Mobile }