import { arrowLeft, arrowRight } from 'assets';
import React, { HTMLAttributes, ReactNode } from 'react';
import { SlickWrapper } from './style';

interface ISlider extends HTMLAttributes<HTMLElement> {
  children?: ReactNode;
  settings?: any;
  faded?: boolean;
}

const Slider = (props: ISlider) => {
  const { children, settings, faded, className } = props;

  const PrevArrow = (props:any) => {
    const { onClick } = props;

    return (
      <div onClick={onClick} className='sliderNav sliderNav--prev'>
        <img src={arrowRight} alt="" />
      </div>
    );
  };
  const NextArrow = (props:any) => {
    const { onClick } = props;

    return (
      <div onClick={onClick} className='sliderNav sliderNav--next'>
        <img src={arrowLeft} alt=""/>
      </div>
    );
  };

  return (
    <SlickWrapper
      {...settings}
      faded={!!faded}
      prevArrow={<PrevArrow />}
      nextArrow={<NextArrow />}
      className={`sliderWrapper ${className}`}
    >
      {children}
    </SlickWrapper>
  );
};

export default Slider;
