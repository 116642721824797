import React, { useEffect, useState } from 'react';
import { atom } from 'assets';
import { Section, Header } from '../../styles';
import ArticleSession from '../ArticleSession';
import { useParams } from 'react-router';
import { getContentByCategoryType, getContentByCategoryTypeTag } from 'services/categories';
import ITags from 'services/tags/models/tags-interface';

interface IScientificArticles{
  type: string;
  title?: string
  tag?: ITags | null
}
const ScientificArticles: React.FC<IScientificArticles> = ({type, title, tag}) => {
  const [content, setContent] = useState<any>();
  const { categoryId } = useParams<{
    categoryId: string
  }>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getData = async () => {
    if(!tag){
      const contents = await getContentByCategoryType(categoryId, type);
      setContent(contents)
    }else{
      const contents = await getContentByCategoryTypeTag(categoryId, type, tag?.tag_id)
      setContent(contents)
    }
  }

  useEffect(() => {
    getData()
  }, [categoryId, tag])

  if(!content || content?.length <= 0 )
    return null
  return(
    <Section>
      <Header>
        <img src={atom} alt=""/>
        <h3>{title} {content && `(${content?.length >= 5 ? (content?.length - 1) : content?.length})`}</h3>
      </Header>
      <ArticleSession type={type} origin="artigos/artigos-cientificos/listagem" data={content?.slice(0,4)}/>
    </Section>
  );
}

export default ScientificArticles;