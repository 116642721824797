import styled from 'styled-components';
import { px2rem } from 'styles/globals';

const Section = styled.section`
  padding-top: ${px2rem(88)};

  a[type="button"]{
    padding: 0 15px;
    height: ${px2rem(36)};
    margin: ${px2rem(40)} auto 0;
    width: ${px2rem(160)};
    div svg{
      width: ${px2rem(20)};
    }
  }

  @media screen and (min-width: 998px){
    max-width: 1424px;
    margin: 0 auto;
  }
  @media screen and (max-width: 997px){
    padding: 50px 0px ;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  img{
    width: ${px2rem(39)};
    margin-right: ${px2rem(8)};
  }
  h3{
    font-size: ${px2rem(24)};
    color: var(--blue-darker);
    font-weight: 800;
  }
`;

const Row = styled.div`
  margin-top: ${px2rem(24)};
  gap: 15px;
  
  @media screen and (min-width: 998px){
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    >div:not(:last-of-type){
      margin-right: ${px2rem(25)};
    }
  }
  @media screen and (max-width: 997px){
    display: flex;
    align-items: center;
    flex-direction: column;
    >article{
      display: flex;
      box-shadow: none;
      padding: 5px;
      >div{
        width: 70%;
        padding: 10px;
        height: auto;
        h1{ font-size: 18px; }
        p{ font-size: 14px; }
      }
    }
  }
    
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 30px;
  svg path{
    stroke: var(--blue-darkness);
  }
  h2{
    font-size: var(--font-larger);
    color: var(--blue-darkness);
    font-weight: 600;
    margin-left: 10px;
  }
`;


export{
  Section,
  Header, 
  Row,
  ModalHeader
}