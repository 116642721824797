import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ToastContainer } from 'react-toastify';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

ReactDOM.render(
  <React.StrictMode>
    <App />

    <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
  </React.StrictMode>,
  document.getElementById('root'),
);
