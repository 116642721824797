import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Back, BackText, Error, LoadingBg } from 'components';
import { logotipo } from 'assets';
import * as Style from '../../style';
import { FormContainer, CodeInput, Subtitle, Description } from './style';
import { toast } from 'react-toastify';
import { verifyCode as verifyCodeService } from 'services/signup';
import { LOGIN } from 'routes/Constants';

interface IState {
  email: string;
}
const ResetPassword: React.FC = () => {
  const history = useHistory();
  const [verifyCode, setVerifyCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error] = useState<boolean>(false);
  const { state } = useLocation<IState>();

  const handleChangeInputCode = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ): void => {
    if (event.target.value === '') {
      const removeChar = verifyCode.slice(0, index);

      setVerifyCode(removeChar);
      return;
    }

    setVerifyCode(verifyCode + event.target.value);
    document.getElementById(`input-verify-code-number-${index + 1}`)?.focus();

    if (index === 5) {
      document.getElementById(`input-verify-code-number-${index}`)?.blur();
    }
  };

  const handleVerifyCodeSubmit = async (): Promise<void> => {
    if (!verifyCode || !verifyCode.length) {
      toast.error('Preencha o código de verificação');
      return;
    }

    const data = {
      email: state.email,
      code: verifyCode,
      is_medic: true
    };

    setLoading(true);

    verifyCodeService(data)
      .then(() => {
        toast.success('Código verificado com sucesso!');
        history.push(LOGIN);
      })
      .catch(() => {
        toast.error('⚠ Erro ao verificar código. Tente novamente');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <LoadingBg />;
  }

  if (error) {
    return <Error type="LoadFail" />;
  }

  return (
    <Style.Wrapper>
      <Style.BackContainer>
        <Back />
      </Style.BackContainer>
      <Style.BackContainerMobile>
        <BackText title="Voltar" />
      </Style.BackContainerMobile>
      <Style.LogoContainer>
        <div className="img-dash-desktop">
          <img src={logotipo} alt="Logo" />
        </div>
      </Style.LogoContainer>
      <Style.Container>
        <Style.Block>
          <Style.Title>Cadastro</Style.Title>
          <Subtitle>Para prosseguir, é preciso validar seu e-mail</Subtitle>
          <Description>
            Um código de confirmação foi enviado para o e-mail:
            <span>{state.email}</span>
            <p style={{ marginTop: '20px' }}>
              Verifique o código também na sua caixa de spam.
            </p>
          </Description>
          <FormContainer>
            <CodeInput>
              {Array(6)
                .fill(6)
                .map((_, index) => (
                  <input
                    key={index}
                    type="number"
                    name=""
                    id={`input-verify-code-number-${index}`}
                    value={verifyCode.charAt(index)}
                    onChange={event => handleChangeInputCode(event, index)}
                  />
                ))}
            </CodeInput>
            <div className="container-btns">
              <Button variant="Default" onClick={handleVerifyCodeSubmit}>
                Confirmar
              </Button>
            </div>
          </FormContainer>
        </Style.Block>
      </Style.Container>
    </Style.Wrapper>
  );
};

export default ResetPassword;
