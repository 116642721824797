import {
  HOME,
  ERROR_404,
  COMPONENTS,
  LADINGPAGE,
  LOGIN,
  SIGNUP,
  RESET,
  VERIFY_CODE,
  TERMS_OF_USE,
  PRIVACY_POLICY,
  CONTACT,
  PRIVACY_POLICY_SIGNUP,
  PERMISSION,
  PRODUCT_SUPPLEMENT_ITEM,
  PRODUCT_MEDICINES_ITEM,
  PRODUCTS,
  PROFILE,
  FAQ,
  NEWS,
  NEW,
  ARTICLES_COMMENTEDS,
  SEARCH,
  RECOVER_VERIFY_CODE,
  PODCAST,
  CATEGORY_TEMPLATE_DETAIL,
  ARTICLES_SCIENTIFIC,
  MONOGRAPHS,
  LIST_ARTICLES_COMMENTEDS,
  LIST_MONOGRAPHS,
  LIST_ARTICLES_SCIENTIFIC,
  VIDEO,
  PODCAST_LIST,
  VIDEO_LIST,
  VIDEO_DETAIL,
  PODCAST_DETAIL,
  EVENT_DETAIL,
  EVENTS,
  LIVE,
  SIGNUP_EXPORTED_USER,
  VIDEO_LIST_CATEGORY,
  PODCAST_LIST_CATEGORY,
} from './Constants';
import Components from 'pages/PageComponents';
import Ladingpage from 'pages/Ladingpage';
import Home from 'pages/Home';
import TermsOfUse from 'pages/TermsOfUse';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import Faq from 'pages/FAQ';
import Contact from 'pages/Contact';
import Products from 'pages/Products/ProductsPage';
import Search from 'pages/Search/SearchPage';
import * as Product from 'pages/Products';
import * as Error from 'pages/Error';
import * as Account from 'pages/Account';
import CommentedArticles from 'pages/CommentedArticles';
import Profile from 'pages/Profile';
import New_page from 'pages/News/NewsPage';
import News from 'pages/News';
import Category from 'pages/Category';
import Podcasts from 'pages/Podcasts';
import ScientificArticles from 'pages/ScientificArticles';
import Monography from 'pages/Monography';
import ListCommentedArticles from 'pages/CommentedArticles/List';
import ListScientifArticles from 'pages/ScientificArticles/List';
import ListMonography from 'pages/Monography/List';
import Video from 'pages/Video';
import ListVideos from 'pages/Video/List/index';
import VideoDetail from 'pages/Video/Detail/index';
import PodcastDetail from 'pages/Podcasts/Detail/index';
import Events from 'pages/Events';
import DetailEvent from 'pages/Events/Detail/index';
import Live from 'pages/Live';
import ListPodcast from 'pages/Podcasts/List';

export interface IRoutes {
  path: string;
  name: string;
  component: React.ComponentType<any>;
  exact?: boolean;
  isProtected?: boolean;
}

const routes: IRoutes[] = [
  {
    path: HOME,
    name: 'Página Principal para pessoas logadas',
    component: Home,
    exact: true,
    isProtected: false,
  },

  {
    path: CATEGORY_TEMPLATE_DETAIL,
    name: 'Página de Categoria',
    component: Category,
    exact: true,
    isProtected: true,
  },

  {
    path: ARTICLES_COMMENTEDS,
    name: 'Artigos Comentados',
    component: CommentedArticles,
    exact: true,
    isProtected: true,
  },

  {
    path: LIST_ARTICLES_COMMENTEDS,
    name: 'Artigos Comentados',
    component: ListCommentedArticles,
    exact: true,
    isProtected: true,
  },

  {
    path: ARTICLES_SCIENTIFIC,
    name: 'Artigos Científicos',
    component: ScientificArticles,
    exact: true,
    isProtected: true,
  },

  {
    path: LIST_ARTICLES_SCIENTIFIC,
    name: 'Artigos Científicos',
    component: ListScientifArticles,
    exact: true,
    isProtected: true,
  },

  {
    path: MONOGRAPHS,
    name: 'Monografias',
    component: Monography,
    exact: true,
    isProtected: true,
  },

  {
    path: LIST_MONOGRAPHS,
    name: 'Monografias',
    component: ListMonography,
    exact: true,
    isProtected: true,
  },

  {
    path: TERMS_OF_USE,
    name: 'Página de termos de uso',
    component: TermsOfUse,
    exact: true,
  },

  {
    path: PROFILE,
    name: 'Página de meu perfil',
    component: Profile,
    exact: true,
  },

  {
    path: LOGIN,
    name: 'Página de Login do usuário',
    component: Account.Login,
    exact: true,
  },

  {
    path: SIGNUP,
    name: 'Página de cadastro do usuário',
    component: Account.Signup,
    exact: true,
  },

  {
    path: PRIVACY_POLICY_SIGNUP,
    name: 'Página de políticas de privacidade no processo de cadastro',
    component: Account.PrivacyPolicy,
    exact: true,
  },
  {
    path: SIGNUP_EXPORTED_USER,
    name: 'Página de cadastro de usuários exportados',
    component: Account.SignupExportedUser,
    exact: true,
  },

  {
    path: VERIFY_CODE,
    name: 'Página de verificação do código para confirmar cadastro',
    component: Account.VerifyCode,
    exact: true,
  },

  {
    path: RECOVER_VERIFY_CODE,
    name: 'Página de recuperação do código de verificação para confirmar cadastro',
    component: Account.RecoverVerifyCode,
    exact: true,
  },

  {
    path: RESET,
    name: 'Página de recuperar senha do usuário',
    component: Account.Reset,
    exact: true,
  },

  {
    path: LADINGPAGE,
    name: 'Página Principal para pessoas deslogadas',
    component: Ladingpage,
    exact: true,
  },

  //PRODUCTS
  {
    path: PRODUCTS,
    name: 'Página de produtos',
    component: Products,
    exact: true,
    isProtected: true,
  },

  {
    path: PRODUCT_SUPPLEMENT_ITEM,
    name: 'Página do produto relacionado a suplementos',
    component: Product.Supplement,
    exact: true,
    isProtected: true,
  },

  {
    path: PRODUCT_MEDICINES_ITEM,
    name: 'Página do produto relacionado a medicamentos',
    component: Product.Medicine,
    exact: true,
    isProtected: true,
  },

  //FAQ
  {
    path: FAQ,
    name: 'Página de dúvidas frequentes',
    component: Faq,
    exact: true,
    isProtected: false,
  },

  {
    path: COMPONENTS,
    name: 'Página de desenvolvimento de components',
    component: Components,
    exact: true,
    isProtected: true,
  },

  {
    path: PRIVACY_POLICY,
    name: 'Página de política de privacidade',
    component: PrivacyPolicy,
    exact: true,
  },

  {
    path: CONTACT,
    name: 'Página de contato',
    component: Contact,
    exact: true,
  },

  //Search
  {
    path: SEARCH,
    name: 'Página de pesquisa',
    component: Search,
    exact: true,
    isProtected: true,
  },

  {
    path: NEWS,
    name: 'Página de notícias',
    component: News,
    exact: true,
    isProtected: true,
  },

  {
    path: PODCAST,
    name: 'Página de podcasts',
    component: Podcasts,
    exact: true,
    isProtected: true,
  },

  {
    path: PODCAST_LIST,
    name: 'Página de listagem de podcasts',
    component: ListPodcast,
    exact: true,
    isProtected: true,
  },

  {
    path: PODCAST_LIST_CATEGORY,
    name: 'Página de listagem de podcasts',
    component: ListPodcast,
    exact: true,
    isProtected: true,
  },

  {
    path: PODCAST_DETAIL,
    name: 'Página detalhada de podcasts',
    component: PodcastDetail,
    exact: true,
    isProtected: true,
  },

  {
    path: VIDEO,
    name: 'Página de vídeos',
    component: Video,
    exact: true,
    isProtected: true,
  },

  {
    path: VIDEO_LIST,
    name: 'Página de listagem de vídeos',
    component: ListVideos,
    exact: true,
    isProtected: true,
  },

  {
    path: VIDEO_LIST_CATEGORY,
    name: 'Página de listagem de vídeos',
    component: ListVideos,
    exact: true,
    isProtected: true,
  },

  {
    path: VIDEO_DETAIL,
    name: 'Página de detalhada do vídeos',
    component: VideoDetail,
    exact: true,
    isProtected: true,
  },

  {
    path: EVENT_DETAIL,
    name: 'Página detalhada de eventos',
    component: DetailEvent,
    exact: true,
    isProtected: true,
  },

  {
    path: NEW,
    name: 'Página de notícias interno',
    component: New_page,
    exact: true,
    isProtected: true,
  },

  //EVENTS
  {
    path: EVENTS,
    name: 'Página de eventos',
    component: Events,
    exact: true,
    isProtected: true,
  },

  //LIVES
  {
    path: LIVE,
    name: 'Página de transmissão de live',
    component: Live,
    exact: true,
    isProtected: true,
  },

  {
    path: PERMISSION,
    name: 'Página não permitida',
    component: Error.PagePermission,
    exact: true,
  },

  // Sempre manter no final
  {
    path: ERROR_404,
    name: 'Página de error 404',
    component: Error.Page404,
    exact: true,
  },
];

export default routes;
