import httpClient from 'http-client';
import { User } from './models/user-interface';

interface IConfirmPassword {
    email: string;
    password: string;
    code: string;
}

interface INewConfirmPassword {
    email: string;
    password: string;
    is_medic: boolean;
}

interface IUserAvatarFile {
    created_at: string;
    file_id: string;
    mime_type: string;
    name: string;
    original_name: string;
    reference: string;
    type: string;
}

const getUserDetails = async (): Promise<User> => {
    const user: User = (await httpClient.get('/users/me')).data;

    return user;
};

const changeUserInfo = async (userInfo: object): Promise<User> => {
    return (await httpClient.put('/users', userInfo)).data;
};

const changeUserAvatar = async (formData: FormData): Promise<User> => {
    if (!formData) {
        throw new Error('Nenhum arquivo foi enviado!');
    }

    const { reference }: IUserAvatarFile = (
        await httpClient.post(`/files/avatar`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    ).data;

    const userAvatar: User = await (
        await httpClient.patch('/users/avatar', {
            avatar: reference,
        })
    ).data;

    return userAvatar;
};

const setForgotPassword = async (email: string): Promise<boolean> => {
    const password = (
        await httpClient.post('/cognito/forgot-password', {
            email,
            is_medic: true,
        })
    ).data;

    return password;
};

const setConfirmPassword = async (data: IConfirmPassword): Promise<boolean> => {
    const confirm = (
        await httpClient.post('/cognito/confirm-new-password', {
            email: data.email,
            password: data.password,
            code: data.code,
            is_medic: true
        })
    ).data;

    return confirm;
};


const setNewConfirmPassword = async (data: INewConfirmPassword): Promise<boolean> => {
    const confirm = (
        await httpClient.post('/cognito/confirm-new-password', {
            email: data.email,
            password: data.password,
            is_medic: true
        })
    ).data;

    return confirm;
};


const verifyUser = async (email: string, crm?: string, crm_uf?: string): Promise<{ email_exists: boolean, crm_exists: boolean, is_exported: boolean, is_confirmed: boolean, had_logged: boolean }> => {

    const confirm = (
        await httpClient.post('/users/verify-user-medic', {
            email: email,
            crm: crm,
            crm_uf: crm_uf
        })
    ).data;

    return confirm;
};


const engagementRecord = async (content_id: string, content_type: string): Promise<void> => {
    await httpClient.post('/user-view', {
        content_id,
        content_type
    })
}
export {
    changeUserInfo,
    getUserDetails,
    setForgotPassword,
    setConfirmPassword,
    verifyUser,
    changeUserAvatar,
    engagementRecord,
    setNewConfirmPassword
};
