import { useSelectedProductById } from 'hooks/useSelectedProductById';
import { useParams } from 'react-router-dom';
import { Error, LoadingBg } from 'components';
import SupplementContent from './Components';

const SupplementPage: React.FC = () => {
  const { supplementId } = useParams<{
    type: 'supplement';
    supplementId: string;
  }>();

  const { isLoading, hasError, product } = useSelectedProductById(supplementId);

  if (isLoading) return <LoadingBg />;
  if (hasError) return <Error type="LoadFail" />;
  if (product === null) return <Error type="LoadFail" />;

  return <SupplementContent product={product} />;
};

export default SupplementPage;
