export const HOME = '/home';
export const LADINGPAGE = '/';

//Authentication
export const SIGNUP = '/cadastro';
export const PRIVACY_POLICY_SIGNUP = '/cadastro/politicas-de-privacidade';
export const SIGNUP_EXPORTED_USER = '/cadastro/exportado';
export const RESET = '/recuperar-senha';
export const VERIFY_CODE = '/cadastro/codigo-de-verificacao';
export const RECOVER_VERIFY_CODE = '/cadastro/recuperar-codigo-de-verificacao';
export const LOGIN = '/login';
export const PROFILE = '/meu-perfil';

//Pages
export const CATEGORY_TEMPLATE = '/categoria/';
export const CATEGORY_TEMPLATE_DETAIL = `${CATEGORY_TEMPLATE}:categoryId`;
export const VAGINAL_INFECTIONS = '/infeccoes-vaginais';
export const FAMILY_PLANNING = '/planejamento-familiar';
export const OBSTETRICS = '/obstetria';
export const PODCAST = '/podcast';
export const PODCAST_DETAIL = '/podcast/play/:podcast_id';
export const PODCAST_LIST = '/podcast/listagem';
export const PODCAST_LIST_CATEGORY = '/podcast/listagem/:podcast_id';
export const SCHEDULE = '/agenda';
export const TERMS_OF_USE = '/termos-de-uso';
export const PRIVACY_POLICY = '/politicas-de-privacidade';
export const CONTACT = '/fale-conosco';
export const FAQ = '/duvidas-frenquentes';
export const VIDEO = '/video';
export const VIDEO_DETAIL = '/video/play/:video_id';
export const VIDEO_LIST = '/video/listagem';
export const VIDEO_LIST_CATEGORY = '/video/listagem/:category_id';

//Pages - News
export const NEWS = '/noticias';

export const NEW = `${NEWS}/:news_id`;
export const getNewItemRoute = (news_id: string) => `${NEW}/${news_id}`;

//Pages - Products
export const PRODUCTS = '/produtos';

const PRODUCT_MEDICINES = '/produtos/medicamento/';
const PRODUCT_SUPPLEMENT = '/produtos/suplemento/';

/* Pages - Product - Item */
export const PRODUCT_MEDICINES_ITEM = `${PRODUCT_MEDICINES}:medicineId`;
export const PRODUCT_SUPPLEMENT_ITEM = `${PRODUCT_SUPPLEMENT}:supplementId`;

/* Pages - Product - Item (Acess component) */
export const getProductMedicinesItemRoute = (medicineId: string) =>
  `${PRODUCT_MEDICINES}${medicineId}`;
export const getProductSupplementItemRoute = (supplementId: string) =>
  `${PRODUCT_SUPPLEMENT}${supplementId}`;

/* Pages - Categoria */
export const getCategoryRoute = (categoryId: string) =>
  `${CATEGORY_TEMPLATE}${categoryId}`;

//Pages - ARTICLES
export const ARTICLES_COMMENTEDS = '/artigos/artigos-comentados';
export const ARTICLES_SCIENTIFIC = '/artigos/artigos-cientificos';
export const MONOGRAPHS = '/artigos/monografias';

export const LIST_ARTICLES_COMMENTEDS = '/artigos/artigos-comentados/listagem';
export const LIST_ARTICLES_SCIENTIFIC = '/artigos/artigos-cientificos/listagem';
export const LIST_MONOGRAPHS = '/artigos/monografias/listagem';

//DESIGN SYSTEM COMPONENTS
export const COMPONENTS = '/componentes';

//Pages - Search
export const SEARCH = '/pesquisa/:pesquisa';

//Pages - Events
export const EVENTS = '/eventos';
export const EVENT_DETAIL = '/eventos/:event_id';

//Pages - Live
export const LIVE = '/live/play/:live_id';

//Error
export const PERMISSION = '/indisponivel';
export const ERROR_404 = '*';
