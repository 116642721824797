import React from 'react';
import {d_article, m_article, } from 'assets';
import { BreadCrumb, Template } from 'components';
import { Link } from 'react-router-dom';
import { HOME } from 'routes/Constants';
import smoothscroll from 'smoothscroll-polyfill';
import CategoryArticles from './components/CategoryArticles';


smoothscroll.polyfill();

const CommentedArticles: React.FC = () => {
  return (
    <>
    <Template
        banner={{
          title: 'Artigos Comentados',
          imgDesktop: d_article,
          imgMobile: d_article,
        }}
        breadcrumb={
          <BreadCrumb
            crumbs={[
              <Link to={`${HOME}`}>HOME</Link>,
              <span>Artigos Comentados</span>,
            ]}
          />
        }
      > 
        {/*<h1>Categorias</h1>*/}
        <CategoryArticles/>
      </Template>
    </>
  );
};

export default CommentedArticles;