import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Wrapper } from './style';
import { play } from 'assets'
import { PlayerSambaVideos } from 'components';
import httpClient from 'http-client';

interface LiveStatus {
  status: string;
  uploadedBanner?: string | boolean;
  path: string;
  liveReference: string | null;
}

const EventBanner: React.FC<LiveStatus> = ({ status, uploadedBanner, path, liveReference }) => {
  const [player, setPlayer] = useState(false);
  const [reference, setReference] = useState('');
  const history = useHistory();

  const getReference = async () => {
    const content = await httpClient.get(`contents/${liveReference}`)
    setReference(content.data.reference);
  }

  useEffect(() => {
    if (status === 'over') {
      getReference();
    }
  }, [status])

  return (
    <Wrapper
      pointer={status === 'over' || status === 'live'}
      player={player}
      onClick={() => {
        if (status === 'over') {
          if (!player) {
            setPlayer(true);
          }
        } else if (status === 'live') {
          history.push(`${path}`);
        }
      }}
    >
      {player ? (
        <div className="player">
          <PlayerSambaVideos
            contentReference={reference}
            controlsToEnable={[
              'play',
              'pause',
              'quality',
              'fullscreen',
              'time',
              'volume',
              'seekbar',
            ]}
            getEventListeners={() => { }}
          />
        </div>
      ) : (
        <img src={`${uploadedBanner}`} className="event-image" alt="Evento" />
      )}
      {(!player && (status === 'live' || status === 'waiting-upload' || status === 'over')) && (
        <div className={`overlay ${status}`}>
          {status === 'waiting-upload' ? (
            <span className="waiting-message">Em breve o conteúdo será disponibilizado.</span>
          ) : (
            <div className={`controllers ${status}`}>
              {status === 'live' && <div className="live-indicator">AO VIVO</div>}
              <img className="play-icon" src={play} alt="" />
              <div className="play-button">{`ASSISTIR${status === 'live' ? ' A LIVE' : ''}`}</div>
            </div>
          )}
        </div>)}
    </Wrapper>
  );
}

export default EventBanner;
