import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { LADINGPAGE } from 'routes/Constants';
import { Template, BreadCrumb } from 'components';
import { m_faq, d_faq } from 'assets';
import Accordion from './Accordion';

const Wrapper = styled.div`
  max-width: 76%;
  width: 100%;

  @media only screen and (max-width: 800px) {
    max-width: initial;
  }
`;

const FAQ: React.FC = () => {
  return (
    <Template
      banner={{
        title: 'FAQ',
        imgDesktop: d_faq,
        imgMobile: m_faq,
      }}
      breadcrumb={
        <BreadCrumb
          crumbs={[<Link to={`${LADINGPAGE}`}>HOME</Link>, <span>FAQ</span>]}
        />
      }
      headerPage={{
        title: 'Perguntas Frequentes',
        subtitle:
          'Tem alguma dúvida? Não se preocupe, vamos tentar respondê-las.',
      }}
    >
      <Wrapper>
        <Accordion />
      </Wrapper>
    </Template>
  );
};

export default FAQ;
