import { useState } from 'react';

type useModalReturnType = [boolean, () => void, () => void];

export default function useModal(): useModalReturnType {
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return [modalOpen, openModal, closeModal];
}
