import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getInfoByType } from 'services/info';
import Info from 'services/info/models/info-interface';
import getErrorMessage from 'helpers/errorMessages';
import { getValue } from 'utils/storage';

type ReturnTypeUseContacts = [boolean, boolean, Info[]];

export const useContacts = (): ReturnTypeUseContacts => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [contacts, setContacts] = useState<Array<Info>>([]);

  useEffect(() => {
    const getContacts = async () => {
      try {
        setError(false);
        setLoading(true);
        const user = getValue<string>({ prop: 'exeltis-user' });
        let localContacts;

        if (user) {
          localContacts = await getInfoByType('contact_medic');
        } else {
          const token = 'Bearer ' + process.env.REACT_APP_UNLOGGED_TOKEN;
          localContacts = await getInfoByType('contact_medic', {
            Authorization: token,
          });
        }
        setContacts(localContacts);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        toast.error(errorMessage);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    getContacts();
  }, []);

  return [loading, error, contacts];
};

export default useContacts;
