import styled from 'styled-components';

export const SearchModalWrapper = styled.div`
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  margin-top: -21px;

  .container-icon {
    margin-right: 18px;
    margin-left: 5px;
    cursor: pointer;
  }

  .container-input {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    font-size: 16px;
    color: #25282b;

    input[type='text'] {
      border: none;
      background: transparent;
      outline: none;
      font-size: 16px;
      width: 100%;

      ::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: #25282b;
        font-size: 16px;
      }

      :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #25282b;
        opacity: 1;
        font-size: 16px;
      }

      ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #25282b;
        opacity: 1;
        font-size: 16px;
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #25282b;
        font-size: 16px;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #25282b;
        font-size: 16px;
      }

      ::placeholder {
        color: #25282b;
        font-size: 16px;
      }

      ::-webkit-autofill,
      ::-webkit-autofill:hover,
      ::-webkit-autofill:focus,
      ::-webkit-autofill,
      ::-webkit-autofill:hover,
      ::-webkit-autofill:focus,
      ::-webkit-autofill,
      ::-webkit-autofill:hover,
      ::-webkit-autofill:focus {
        border: 1px solid green;
        -webkit-text-fill-color: green;
        -webkit-box-shadow: 0 0 0px 1000px #000 inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    }
  }
`;
