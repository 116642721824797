import styled from 'styled-components';

const WrapperList = styled.section`
  display: flex;
  max-width: 1424px;
  margin: 0 auto;
  > div.summary p {
    font-size: var(--font-larger);
    color: var(--black-clear);
    font-weight: 700;
    margin-bottom: 32px;
  }
  ul {
    li {
      margin-bottom: 24px;
      font-size: var(--font-medium);
      color: var(--blue-medium);
      font-weight: 600;
      cursor: pointer;
      &.active{
        font-weight: 800;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 1100px;
  grid-gap: 20px;
  padding-left: 20px;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;

const A = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;

  @media only screen and (max-width: 1200px) {
    margin-left: 0;
    width: 100%;
  }
`;

export { WrapperList, Grid, A };
