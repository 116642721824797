import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HOME } from 'routes/Constants';
import { sendEmailContactUs } from 'services/faq';
import { validateEmail, validateName } from 'helpers/validation';
import { toast } from 'react-toastify';
import { maskPhone } from 'helpers/masks';
import { Input, Button, TextArea } from 'components';
import { FormContent } from './style';
import { getValue } from 'utils/storage';

const Form: React.FC = () => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [question, setQuestion] = useState<string>('');

  const [nameError, setNameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');
  const [questionError, setQuestionError] = useState<string>('');
  const history = useHistory();

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    switch (e.target.id) {
      case 'name-input':
        setName(e.target.value);
        break;

      case 'email-input':
        setEmail(e.target.value);
        break;

      case 'phone-input':
        setPhone(maskPhone(e.target.value));
        break;

        case 'subject-input':
        setSubject(e.target.value);
        break;

      case 'question-input':
        setQuestion(e.target.value);
        break;

      default:
        break;
    }
  };

  const handleChangeTextArea = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    switch (e.target.id) {
      case 'question-input':
        setQuestion(e.target.value);
        break;

      default:
        break;
    }
  };

  const handleSend = async (): Promise<void> => {
    if (!email) {
      setEmailError('Preencha corretamente este campo');
    }

    if (!name) {
      setNameError('Preencha corretamente este campo');
    }

    if (validateName(name) || name.length < 2) {
      setNameError(
        'O nome precisa ter pelo menos duas letras e nenhum caracter especial.',
      );
      return;
    }

    if (!phone) {
      setPhoneError('Preencha corretamente este campo');
    }

    if (name.includes(' ') === false) {
      setNameError('Informe um nome e sobrenome');
    }

    if (!question.length) {
      setQuestionError('Informe uma mensagem ');
      return;
    }

    if (
      phone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '')
        .length < 11
    ) {
      setPhoneError('Informe o seu celular com DDD');
      return;
    }

    if (!validateEmail(email) && email) {
      setEmailError('Informe um e-mail com formato válido');
      return;
    }

    if (
      !emailError &&
      !nameError &&
      !phoneError &&
      !questionError
    ) {
      const data = {
        email,
        name,
        phone: phone
          .replace('(', '')
          .replace(')', '')
          .replace('-', '')
          .replace(' ', ''),
        subject,
        text: question,
      };

      const user = getValue<string>({ prop: 'exeltis-user' });
      const token = 'Bearer ' + process.env.REACT_APP_UNLOGGED_TOKEN;

      await sendEmailContactUs(
        data,
        user ? undefined : { Authorization: token },
      )
        .then(() => {
          history.push(`${HOME}`);
          toast.success('Sucesso ao enviar a mensagem');
        })
        .catch(error => {
          toast.error(
            '⚠ Algo deu errado ao carregar essa solicitação. Tente novamente.',
          );
        });
    }
  };

  return (
    <>
      <FormContent>
        <h3>Entre em contato através do formulário.</h3>
        <div className="form-container">
          <div className="form-inputs">
            <Input
              label="Nome"
              type="text"
              id="name-input"
              value={name}
              onChange={handleChangeInput}
              onFocus={() => setNameError('')}
              required={true}
            >
              {nameError && <span>{nameError}</span>}
            </Input>
          </div>
          <div className="form-inputs">
            <Input
              label="E-mail"
              type="email"
              id="email-input"
              value={email}
              onChange={handleChangeInput}
              onFocus={() => setEmailError('')}
              required={true}
            >
              {emailError && <span>{emailError}</span>}
            </Input>
          </div>
        </div>
        <div className="form-container">
          <div className="form-inputs">
            <Input
              type="text"
              id="phone-input"
              value={phone}
              onChange={handleChangeInput}
              onFocus={() => setPhoneError('')}
              label="Celular"
              required={true}
            >
              {phoneError && <span>{phoneError}</span>}
            </Input>
          </div>
          <div className="form-inputs">
            <Input
              label="Assunto"
              type="text"
              id="subject-input"
              value={subject}
              onChange={handleChangeInput}
              required={false}
            >
            </Input>
          </div>
        </div>
        <div className="form-textarea">
          <TextArea
            label="Escreva sua dúvida"
            type="text"
            id="question-input"
            value={question}
            onChange={handleChangeTextArea}
            onFocus={() => setQuestionError('')}
            required={true}
          >
            {questionError && <span>{questionError}</span>}
          </TextArea>
        </div>
        <div className="button-send">
          <Button variant="DefaultDark" onClick={handleSend}>
            Enviar
          </Button>
        </div>
      </FormContent>
    </>
  );
};

export default Form;
