import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  span {
    color: var(--black-medium);
    font-size: 16px;
    line-height: 27px;
    margin-left: 10px;

    a {
      margin: auto 5px;
      color: var(--blue-medium);
      font-weight: 700;
    }
  }
`;

export const TitleDesktop = styled.h2`
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 22px;
  color: var(--blue-darkness);
  line-height: 40px;
  text-transform: uppercase;
  display: block;

  @media (max-width: 800px) {
    font-size: 2.3rem;
    text-transform: none;
    display: none;
  }
`;

export const TitleMobile = styled.h2`
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 22px;
  color: var(--blue-darkness);
  line-height: 40px;
  text-transform: uppercase;
  display: none;

  @media (max-width: 800px) {
    font-size: 2.3rem;
    display: block;

    p {
      font-size: 1.3rem;
      margin-top: 10px;
    }
  }

  @media (max-width: 350px) {
    font-size: 2.0rem;
    display: block;

    p {
      font-size: 1.2rem;
      margin-top: 10px;
      margin-top: 10px;
    }
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 100px;

  @media (max-width: 800px) {
    margin: 30px 0;
    width: 100%;
  }
`;
