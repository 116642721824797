import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  LOGIN,
  NEWS,
  PODCAST,
  SCHEDULE,
  PRODUCTS,
  LIST_ARTICLES_COMMENTEDS,
  LIST_ARTICLES_SCIENTIFIC,
  LIST_MONOGRAPHS,
  LADINGPAGE,
  HOME,
  PROFILE,
  SIGNUP,
  getCategoryRoute,
  EVENTS,
} from 'routes/Constants';
import { HeaderMobile, Sidebar, ButtonClose } from './style';
import { logotipo } from 'assets';
import { Icon, Modal } from 'components';
import { useAuth } from 'providers/auth/AuthProvider';
import { getCategories } from 'services/categories';
import { Category } from 'services/categories/models/category-interface';
import { getValue } from 'utils/storage';
import { useModal } from 'hooks';
import SearchContent from '../Logged/Search';
import { OutsideClick } from 'components';

interface IHeader {
  onClose?: () => void;
}

const Mobile: React.FC<IHeader> = ({ onClose }) => {
  const { isAuthenticated, signOut } = useAuth();
  const history = useHistory();
  const [showSidebar, setShowSidebar] = useState(false);
  const [showArticles, setShowArticles] = useState(false);
  const [showMoreInfo] = useState(false);
  const [categories, setCategories] = useState<Category[]>();

  const [modalOpen, openModal, closeModal] = useModal();

  const handleSidebar = () => setShowSidebar(!showSidebar);

  const handleLogout = (): void => {
    signOut();
    history.push(`${LADINGPAGE}`);
  };

  useEffect(() => {
    const getAllCategories = async () => {
      const user = getValue<string>({ prop: 'exeltis-user' });
      if (user) {
        const response = await getCategories();
        setCategories(response);
      }
    };
    getAllCategories();
  }, []);

  const DropdownArticles = () => setShowArticles(!showArticles);

  function renderArticles() {
    return (
      <>
        <li onClick={DropdownArticles}>
          {!showArticles ? (
            <div className={'dropdown-articles'}>
              Artigos{' '}
              <div className="dropdown-arrow">
                <Icon name="arrow_down" size={20} />
              </div>
            </div>
          ) : (
            <div className={'dropdown-articles'}>
              <div className="dropdown-arrow-reverse">
                <Icon name="arrow_down" size={20} />
              </div>
              Artigos{' '}
            </div>
          )}
          {showArticles && (
            <>
              <li>
                <Link
                  onClick={() => {
                    setShowSidebar(false);
                    if (onClose) onClose();
                  }}
                  to={`${LIST_ARTICLES_COMMENTEDS}`}
                >
                  Artigos Comentados
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setShowSidebar(false);
                    if (onClose) onClose();
                  }}
                  to={`${LIST_ARTICLES_SCIENTIFIC}`}
                >
                  Artigos Científicos
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setShowSidebar(false);
                    if (onClose) onClose();
                  }}
                  to={`${LIST_MONOGRAPHS}`}
                >
                  Monografias
                </Link>
              </li>
            </>
          )}
        </li>
      </>
    );
  }

  function renderAuth() {
    if (isAuthenticated) {
      return (
        <nav className="isLogged-style">
          <ul>
            {!showMoreInfo && (
              <>
                {!showArticles && (
                  <>
                    <li>
                      <Link
                        onClick={() => {
                          setShowSidebar(false);
                          if (onClose) onClose();
                        }}
                        to={`${PROFILE}`}
                      >
                        Meu Perfil
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowSidebar(false);
                          if (onClose) onClose();
                        }}
                        to={`${HOME}`}
                      >
                        Página Inicial
                      </Link>
                    </li>
                    {categories?.map((category, key) => (
                      <li key={`${category.title}-${key}`}>
                        <Link
                          to={getCategoryRoute(category.category_id)}
                          onClick={() => {
                            setShowSidebar(false);
                            if (onClose) onClose();
                          }}
                        >
                          {category.title}
                        </Link>
                      </li>
                    ))}
                  </>
                )}
                {renderArticles()}
              </>
            )}
            {!showArticles && (
              <>
                <li>
                  <Link
                    onClick={() => {
                      setShowSidebar(false);
                      if (onClose) onClose();
                    }}
                    to={`${NEWS}`}
                  >
                    Notícia
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      setShowSidebar(false);
                      if (onClose) onClose();
                    }}
                    to={`${PODCAST}`}
                  >
                    Podcast
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      setShowSidebar(false);
                      if (onClose) onClose();
                    }}
                    to={`${EVENTS}`}
                  >
                    Agenda
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      setShowSidebar(false);
                      if (onClose) onClose();
                    }}
                    to={`${PRODUCTS}`}
                  >
                    Produtos
                  </Link>
                </li>
                <li>
                  <div className="container-logout">
                    <div className="wrapper-logout">
                      <div className="content-logout">
                        <Icon name="SignOut" size={25} />
                        <span onClick={handleLogout}>Sair</span>
                      </div>
                    </div>
                  </div>
                </li>
              </>
            )}
          </ul>
        </nav>
      );
    } else {
      return (
        <nav className="isGuest-style">
          <ul>
            <li>
              <Link
                onClick={() => {
                  setShowSidebar(false);
                  if (onClose) onClose();
                }}
                to={`${LADINGPAGE}`}
              >
                Página Inicial
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  setShowSidebar(false);
                  if (onClose) onClose();
                }}
                to={`${LOGIN}`}
              >
                Login
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  setShowSidebar(false);
                  if (onClose) onClose();
                }}
                to={`${SIGNUP}`}
              >
                Inscreva-se
              </Link>
            </li>
          </ul>
        </nav>
      );
    }
  }

  return (
    <HeaderMobile>
      <Link to={`${ isAuthenticated ? HOME : LADINGPAGE}`}>
        <img className="brand" src={logotipo} alt="Exeltis ON" />
      </Link>
      <div className="navigation">
        <div className="SearchModal">
          <Icon name="search_glass" size={23} onClick={openModal} />
          <Modal
            width={{
              desktop: '80%',
              tablet: '80%',
            }}
            closeModal={closeModal}
            positionClose="In"
            IconTheme="light"
            modalOpen={modalOpen}
            position="Top"
          >
            <SearchContent onSearch={closeModal} />
          </Modal>
        </div>
        <div className="contentNav" onClick={handleSidebar}>
          {!showSidebar ? (
            <Icon name="toggle" size={33} />
          ) : (
            <div>
              <Icon name="close" size={20} />
            </div>
          )}
        </div>
        <Sidebar open={showSidebar}>
          <div className="overflow">
            <div className="sidebar-content">
              <OutsideClick
                handleClick={() => {
                  setShowSidebar(false);
                  if (onClose) onClose();
                }}
              >
                <div className="sidebar-wrapper-content">
                  <Link to={`${HOME}`} className="brand-sidebar">
                    <img className="brand" src={logotipo} alt="Exeltis ON" />
                  </Link>
                  {renderAuth()}
                  <ButtonClose
                    onClick={() => {
                      setShowSidebar(false);
                      if (onClose) onClose();
                    }}
                  >
                    <div className="container-btn-close">
                      <Icon name="close" size={20} />
                    </div>
                  </ButtonClose>
                </div>
              </OutsideClick>
            </div>
          </div>
        </Sidebar>
      </div>
    </HeaderMobile>
  );
};

export default Mobile;
