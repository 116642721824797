import styled from 'styled-components';
import { px2rem } from 'styles/globals';

interface IWrapper{
  background?: string
}

interface IRow{
  reverse?: boolean
}

const Wrapper = styled.section<IWrapper>`
  margin: 0 auto;
  padding: 20px;
  ${props => props.background ? `background: ${props.background};`  : '' }

  @media screen and (min-width: 998px){
    padding: ${px2rem(80)} ${px2rem(10)};
    margin-bottom: 80px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${px2rem(24)};
`;

const SectionTitle = styled.h1`
  font-weight: 800;
  font-size: 20px;
  @media screen and (min-width: 998px){
    font-size: var(--font-larger);
  }
`;

const Row = styled.div<IRow>`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 998px){
    justify-content: space-between;
    flex-direction: row;
    ${props => props.reverse ?  
      `
        flex-direction: row-reverse;
        >div:not(:last-of-type){
          margin-left: ${px2rem(25)};
        }
      ` 
      : `
        flex-direction: row;
        >div:not(:last-of-type){
          margin-right: ${px2rem(25)};
        }
      ` 
    }

  }
  @media screen and (max-width: 997px){
    align-items: center;
  }
`;
const Grid = styled.div`
  @media screen and (min-width: 998px){
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${px2rem(25)};
    >a{
      min-width: 338px;
    }
  }
  @media screen and (max-width: 997px){
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

const WrapperList = styled.section`
  display: flex;
  max-width: 1424px;
  margin: 0 auto;
  > div.summary p {
    font-size: var(--font-larger);
    color: var(--black-clear);
    font-weight: 700;
    margin-bottom: 32px;
  }
  ul {
    li {
      margin-bottom: 24px;
      font-size: var(--font-medium);
      color: var(--blue-medium);
      font-weight: 600;
      cursor: pointer;
      &.active{
        font-weight: 800;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const GridList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1100px;
  grid-gap: 20px;
  padding-left: 20px;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;

const A = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;

  @media only screen and (max-width: 1200px) {
    margin-left: 0;
    width: 100%;
  }
`;

const Related = styled.div`
  margin-top: 40px;
  h3{
    font-size: var(--font-larger);
    font-weight: 800;
    margin-bottom: 20px;
    color: var(--blue-darkness);
  }
`;


export{
  Wrapper,
  Header,
  SectionTitle,
  Row,
  Grid,
  GridList,
  WrapperList,
  A,
  Related
}