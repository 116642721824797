import {keyframes } from 'styled-components';

export const dropdown = keyframes`
	z-index: 10;
	0% {
		opacity: 0;
		transform: translate(0px, -30px);
	}
	100% {
		opacity: 1;
		transform: translate(0px, 0px);
	}
`;

export default dropdown;