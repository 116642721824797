import styled from 'styled-components';
import { px2rem } from 'styles/globals';

interface IContainer {
  avatar: string;
}

const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1%;
  gap: 8px;

  .avatar-wrapper {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    align-self: center;
    background-image: url(${props => props.avatar});
    background-size: cover;
  }

  .speaker-name {
    align-self: center;
    color: #fff;
    font-size: ${px2rem(16)};
    font-weight: 700;
    line-height: ${px2rem(21.79)};
  }

  @media screen and (max-width: 1280px) {

    .avatar-wrapper {
      width: 45px;
      height: 45px;
    }

    .speaker-name {
      font-size: ${px2rem(14)};
    }
  }
`;

export {
  Container
};
