import React from 'react';
import styled from 'styled-components';
import HeaderDesktop from './Desktop';

const IDesktop = styled.div`
  position: relative;
  z-index: 6;
  @media (min-width: 1201px) {
    display: block;
  }
  @media (max-width: 1200px) {
    display: none;
  }
`;

const Logged: React.FC = () => {
  return (
    <>
      <IDesktop>
        <HeaderDesktop />
      </IDesktop>
    </>
  );
};

export default Logged;
