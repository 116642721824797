import React from 'react'
import useOutsideClick from 'hooks/useOutsideClick'

interface Props {
    handleClick(): void
}

const OutsideClickContainer: React.FC<Props> = ({ children, handleClick }) => {
	const wrapperRef = React.useRef(null)
	useOutsideClick(wrapperRef, handleClick)
	return <div ref={wrapperRef}>{children}</div>
}

OutsideClickContainer.defaultProps = {}

export default OutsideClickContainer
