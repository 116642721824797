import React from 'react';
import {d_monografia, m_monografia, m_technology } from 'assets';
import { BreadCrumb, Template } from 'components';
import { Link } from 'react-router-dom';
import { HOME } from 'routes/Constants';
import smoothscroll from 'smoothscroll-polyfill';
import CategoryArticles from './components/CategoryArticles';

smoothscroll.polyfill();

const Monography: React.FC = () => {
  return (
    <>
    <Template
        banner={{
          title: 'Monografias',
          imgDesktop: d_monografia,
          imgMobile: m_monografia,
        }}
        breadcrumb={
          <BreadCrumb
            crumbs={[
              <Link to={`${HOME}`}>HOME</Link>,
              <span>Monografias</span>,
            ]}
          />
        }
      > 
      <CategoryArticles/>
      </Template>
    </>
  );
};

export default Monography;
