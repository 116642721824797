import React, { useEffect, useState } from 'react';
import { d_news, m_news } from 'assets';
import { BreadCrumb, Template, MessageError, LoadingBg, Comments, Icon } from 'components';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { LADINGPAGE, NEWS } from 'routes/Constants';
import Cards from '../Cards';
import { Container, Category } from './style';
import { getNew, getAllNews } from 'services/news';
import INews from 'services/news/models/news-interface';
import getErrorMessage from 'helpers/errorMessages';
import { toast } from 'react-toastify';
import formatDataFromString from 'helpers/date';
import { engagementRecord } from 'services/user';

const NewsPage: React.FC = () => {
  const [allNews, setAllNews] = useState<INews[]>([]);
  const [news, setNews] = useState<INews>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const { news_id } = useParams<{
    news_id: string;
  }>();

  useEffect(() => {
    const getNewsPage = async () => {
      try {
        setError(false);
        setLoading(true);
        const new_content = await getNew(news_id);
        await engagementRecord(news_id, 'NOTICIA')
        setNews(new_content);
      } catch (err) {
        console.log('err', err);
        const errorMessage = getErrorMessage(err);
        toast.error(errorMessage);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    getNewsPage();
  }, [news_id]);

  useEffect(() => {
    const getNews = async () => {
      try {
        const all_news = await getAllNews();
        setAllNews(all_news);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        toast.error(errorMessage);
      }
    };

    getNews();
  }, []);

  if (loading) return <LoadingBg />;
  if (!news)
    return (
      <MessageError content="Sem conteúdo no momento, retorne mais tarde." />
    );

  function renderTitle(title: string | undefined) {
    return title ?? '- -';
  }

  function renderCategory(category: string | undefined) {
    return category ?? '- -';
  }

  function renderContent(content: string | undefined) {
    return content ?? '- -';
  }

  function renderThumbnail(thumbnail: string | undefined) {
    return thumbnail ?? '- -';
  }

  function renderDescription(description: string | undefined) {
    return description ?? '- -';
  }

  const renderCardsAllNews = (items: INews[]) => {
    if (items.length === 0)
      return <MessageError content="Sem notícias no momento." />;

    return items
      .slice(0, 2)
      .map(item => (
        <Cards
          to={`/noticias/${item.news_id}`}
          key={item.news_id.toString()}
          img={item.thumbnail}
          title={item.title}
          subtitle={item.description}
        />
      ));
  };

  return (
    <Template
      banner={{
        title: 'Notícias',
        imgDesktop: d_news,
        imgMobile: d_news,
      }}
      breadcrumb={
        <BreadCrumb
          crumbs={[
            <Link to={`${LADINGPAGE}`}>HOME</Link>,
            <Link to={`${NEWS}`}>NOTÍCIAS</Link>,
            <strong>{renderTitle(news?.title)}.</strong>,
          ]}
        />
      }
    >
      <Container>
        <Category color={news?.category?.info?.color}>{news?.category?.title}</Category>
        <h2>{renderTitle(news?.title)}</h2>
        <h3>{renderDescription(news?.description)}</h3>
        <h4>De Exeltis ON{news?.location ? `, ${news?.location}` : null}</h4>
        <h4>{formatDataFromString(news.created_at)}</h4>
        <img
          src={renderThumbnail(news.thumbnail)}
          alt="imagem"
          className="principal-image"
        />
        <p
          className="text-news"
          dangerouslySetInnerHTML={{ __html: renderContent(news.content) }}
        />
        <h3 className="more-news">Mais notícias</h3>
        <div className="container-cards">{renderCardsAllNews(allNews)}</div>
      </Container>
      <Comments contentId={news_id}/>
    </Template>
  );
};

export default NewsPage;
