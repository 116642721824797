import { d_news, image_news, m_news, news_card } from 'assets';
import { ArticleCard, BreadCrumb, LinkButton, SpolightCard, Template } from 'components';
import { ContainerGlobal } from 'components/ContainerGlobal/style';
import { Grid, Row, SectionTitle, Header } from 'pages/Home/style';
import React from 'react';
import { Link } from 'react-router-dom';
import { LADINGPAGE, NEWS } from 'routes/Constants';
import Cards from './Cards';
import CategoryNews from './Components/CategoryNews';

const News: React.FC = () => {
  return (
    <Template
      banner={{
        title: 'Notícias',
        imgDesktop: d_news,
        imgMobile: d_news,
      }}
      breadcrumb={
        <BreadCrumb
          crumbs={[
            <Link to={`${LADINGPAGE}`}>HOME</Link>,
            <Link to={`${NEWS}`}>NOTÍCIAS</Link>,
          ]}
        />
      }
    >
      <CategoryNews/>
    </Template>
  );
};

export default News;
