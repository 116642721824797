import React from 'react';
import { Banner, ContainerGlobal } from 'components';
import { Container, Wrapper, HeaderPage, Title, Subtitle } from './style';

interface IHeaderPage {
  title: string;
  subtitle: string;
}

interface ITemplateWithBg {
  banner: {
    title: string;
    subtitle?: string;
    imgDesktop: string;
    imgMobile: string;
    color?: string;
  };
  breadcrumb: React.ReactElement;
  headerPage?: IHeaderPage;
}

const Template: React.FC<ITemplateWithBg> = ({
  banner,
  breadcrumb,
  children,
  headerPage,
}) => {
  return (
    <ContainerGlobal>
      <Container>
        <div className="content-template">
          <Banner
            title={banner.title}
            subtitle={banner.subtitle}
            imgDesktop={banner.imgDesktop}
            imgMobile={banner.imgMobile}
            theme={banner.color ? "" : "BlueDark"}
            color={banner.color}
          />
          <div className="container-breadcrumb">{breadcrumb}</div>
        </div>
        <Wrapper>
          <HeaderPage>
            <Title>{headerPage?.title}</Title>
            <Subtitle>{headerPage?.subtitle}</Subtitle>
          </HeaderPage>
          {children}
        </Wrapper>
      </Container>
    </ContainerGlobal>
  );
};

export default Template;
