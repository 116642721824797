import httpClient from 'http-client';
import CommentArticle from './models/commentArticle-interface';

const getCommentArticle = async (
  commented_article_id: string,
): Promise<CommentArticle> => {
  const comment_article = (
    await httpClient.get(`/commented-articles/${commented_article_id}`)
  ).data;

  return comment_article;
};

// const getAllCommentArticle = async (
//   filters?: object,
// ): Promise<CommentArticle[]> => {
//   const comment_article: CommentArticle[] = (
//     await httpClient.get('/commented-articles', {
//       params: filters,
//     })
//   ).data;

//   return comment_article;
// };

const getAllCommentArticle = async (
  filters: any = {},
): Promise<CommentArticle[]> => {
  const comment_article: CommentArticle[] = (
    await httpClient.get('/contents', {
      params: { is_library: true, ...filters },
    })
  ).data;

  return comment_article;
};

export { getCommentArticle, getAllCommentArticle };
