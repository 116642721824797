import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Back, BackText, Error, Input, LoadingBg } from 'components';
import { logotipo } from 'assets';
import * as Style from '../../style';
import { FormContainer, CodeInput, Subtitle, Description } from './style';
import { toast } from 'react-toastify';
import smoothscroll from 'smoothscroll-polyfill';
import {
  resendCode as resendVerifyCode,
  verifyCode as verifyCodeService
} from 'services/signup';
import { LADINGPAGE, LOGIN } from 'routes/Constants';
import { verifyUser } from 'services/user';

smoothscroll.polyfill();

const RecoverVerifyCode: React.FC = () => {
  const history = useHistory();
  const [verifyCode, setVerifyCode] = useState<string>('');
  const [showPasswordInput, setShowPasswordInput] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error] = useState<boolean>(false);

  const handleChangeInputCode = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ): void => {
    if (event.target.value === '') {
      const removeChar = verifyCode.slice(0, index);

      setVerifyCode(removeChar);
      return;
    }

    setVerifyCode(verifyCode + event.target.value);
    document.getElementById(`input-verify-code-number-${index + 1}`)?.focus();

    if (index === 5) {
      document.getElementById(`input-verify-code-number-${index}`)?.blur();
    }
  };

  const handleVerifyCodeSubmit = async (): Promise<void> => {
    if (!verifyCode) {
      toast.error('Preencha o código de verificação');
      return;
    }

    const data = {
      email,
      code: verifyCode,
      is_medic: true,
    };

    await verifyCodeService(data)
      .then(response => {
        if (response) {
          toast.success('Cadastro finalizado com sucesso!');
          history.push(`${LOGIN}`);
        }
      })
      .catch((err) => {
        toast.error(
          '⚠ Confira se o código de verificação está correto.',
        );
      });
  };

  const sendVerifyCodeToEmail = async (): Promise<void> => {
    if (!email || !email.replace(/[!@#$%¨&()_+=~^<>*|"]/g, '').trim()) {
      toast.error('Preencha o campo Email');
      return;
    }

    const resendVerifyCodeData = {
      email,
      is_medic: true,
    };

    const { email_exists } = await verifyUser(email);

    if (email_exists) {
      await resendVerifyCode(resendVerifyCodeData)
        .then(response => {
          if (response) {
            setShowPasswordInput(true);
          }
        })
        .catch(() => {
          toast.error('⚠ Email incorreto!');
        });
    } else {
      toast.error('⚠ Email incorreto!');
    }
  };

  if (loading) {
    return <LoadingBg />;
  }

  if (error) {
    return <Error type="LoadFail" />;
  }

  function renderHandlePasswordReset() {
    return (
      <>
        <Style.Title>Concluir Cadastro</Style.Title>
        <Subtitle>Código de verificação</Subtitle>
        <FormContainer>
          <CodeInput>
            {Array(6)
              .fill(6)
              .map((_, index) => (
                <input
                  key={index}
                  type="number"
                  name=""
                  id={`input-verify-code-number-${index}`}
                  value={verifyCode.charAt(index)}
                  onChange={event => handleChangeInputCode(event, index)}
                />
              ))}
          </CodeInput>
          <div className="container-btns">
            <Button variant="Default" onClick={handleVerifyCodeSubmit}>
              Enviar
            </Button>
          </div>
        </FormContainer>
      </>
    );
  }

  function renderSendVerifyCodeToEmail() {
    return (
      <>
        <Style.Title>Recuperar código de verificação</Style.Title>
        <Description>
          Insira seu email para receber o código de verificação. Verifique o
          código também na sua caixa de spam.
        </Description>
        <Style.Form>
          <Style.Label>
            <Input
              type="email"
              id="email-input"
              value={email}
              onChange={event => setEmail(event.target.value)}
              label="E-mail"
            />
            <div className="container-btns">
              <Button variant="Default" onClick={sendVerifyCodeToEmail}>
                Enviar
              </Button>
            </div>
          </Style.Label>
        </Style.Form>
      </>
    );
  }

  return (
    <Style.Wrapper>
      <Style.BackContainer>
        <Back />
      </Style.BackContainer>
      <Style.BackContainerMobile>
        <BackText title="Voltar" />
      </Style.BackContainerMobile>
      <Style.LogoContainer>
        <div className="img-dash-desktop pointer">
          <img
            src={logotipo}
            alt="Logo"
            onClick={() => {
              history.push(`${LADINGPAGE}`);
            }}
          />
        </div>
      </Style.LogoContainer>
      <Style.Container>
        <Style.Block>
          {showPasswordInput ? (
            <>{renderHandlePasswordReset()}</>
          ) : (
            <>{renderSendVerifyCodeToEmail()}</>
          )}
        </Style.Block>
      </Style.Container>
    </Style.Wrapper>
  );
};

export default RecoverVerifyCode;
