import styled from 'styled-components';
import { px2rem } from 'styles/globals';

const Container = styled.footer`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%; 
  height: ${px2rem(220)};
  background: var(--blue-medium);
  justify-content: space-around;
  
  .text, .speakers {
    display: flex;
    flex: 1;
    text-align: center;
    align-self: center;
    padding: 0 ${px2rem(54)};
    height: 100%;
    justify-content: center;
  }

  .speakers{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${px2rem(5)};
    overflow: auto;
  }
  
  .text {
    flex-direction: column;
    gap: 9px;
    .title, .subtitle {
      align-self: flex-start;
      color: #fff;
      font-weight: 700;
      text-align: left;
    }

    .title {
      font-size: ${px2rem(24)};
      line-height: ${px2rem(24)};
    }
    .subtitle {
      font-size: ${px2rem(34)};
      line-height: ${px2rem(36)};
    }
  }

  .speakers {
    justify-content: space-around;
    flex-wrap: wrap;
  }

  hr {
    align-self: center;
    height: 68%;
    width: 1px;
    background: var(--blue-lighter);
    border: none;
  }

  .shadow-overlay {
    position: absolute;
    height: 100%;
    width: ${px2rem(90)};
    background: linear-gradient(to left, var(--blue-darker) -91.14%, rgba(8, 29, 61, 0) 100%);
    right: 0;
  }

  @media screen and (max-width: 1280px) {
    .text {
      .title {
        font-size: ${px2rem(16)};
      }

      .subtitle {
        font-size: ${px2rem(23)};
      }
    }
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export {
  Container
};
