import { useCallback, Dispatch, useReducer } from 'react';
import { getAllProducts } from 'services/product';
import Product from 'services/product/models/product-interface';

type Action =
  | { type: 'load' }
  | { type: 'success'; payload: Product[] }
  | { type: 'error' };

type State = {
  products: Product[];
  isLoading: boolean;
  hasError: boolean;
};

const initialState: State = {
  products: [],
  isLoading: true,
  hasError: false,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'load':
      return { ...state, isLoading: true, hasError: false };
    case 'success':
      return {
        ...state,
        isLoading: false,
        hasError: false,
        products: action.payload,
      };

    case 'error':
      return { ...state, isLoading: false, hasError: true };

    default:
      return { ...state };
  }
}

const getProducts = async (dispatch: Dispatch<Action>) => {
  try {
    dispatch({ type: 'load' });

    const products = await getAllProducts();

    dispatch({ type: 'success', payload: products });
  } catch (err) {
    console.log('err:', err);
    dispatch({ type: 'error' });
  }
};

export function useProvideProduct() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    ...state,
    getProducts: useCallback(async () => {
      await getProducts(dispatch);
    }, []),
  };
}
