import { arrowSliderL, arrowSliderR } from 'assets';
import React, { HTMLAttributes, ReactNode } from 'react';
import { Content, SlickWrapper, Title } from './style';

interface ISlider extends HTMLAttributes<HTMLElement> {
  children?: ReactNode;
  settings?: any;
  faded?: boolean;
}

const SliderEvent = (props: ISlider) => {
  const { children, settings, faded, className } = props;

  const PrevArrow = (props: any) => {
    const { onClick } = props;

    return (
      <div onClick={onClick} className="sliderNav sliderNav--prev">
        <img src={arrowSliderR} alt="" />
      </div>
    );
  };
  const NextArrow = (props: any) => {
    const { onClick } = props;

    return (
      <div onClick={onClick} className="sliderNav sliderNav--next">
        <img src={arrowSliderL} alt="" />
      </div>
    );
  };

  return (
    <>
      <Content>
        <Title>Próximos eventos</Title>
      </Content>
      <SlickWrapper
        {...settings}
        initialSlide={1}
        faded={!!faded}
        nextArrow={<PrevArrow />}
        prevArrow={<NextArrow />}
        className={`sliderWrapper ${className}`}
      >
        {children}
      </SlickWrapper>
    </>
  )
    ;
};

export default SliderEvent;