import React, { useState, useEffect } from 'react';
import { SearchModalWrapper } from './style';
import { Icon } from 'components';
import { useHistory } from 'react-router-dom';

interface ISearchModal {
  initialSearchModal?: string;
  placeholder?: string;
  searchIcon?: boolean;
  onSearch(): void;
}

const SearchModal: React.FC<ISearchModal> = ({
  initialSearchModal,
  placeholder = 'Pesquise aqui',
  searchIcon = 'true',
  onSearch,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const history = useHistory();

  useEffect(() => {
    if (initialSearchModal) {
      setSearchValue(initialSearchModal);
    }
  }, [initialSearchModal]);

  const handleSearch = (): void => {
    history.push(`/pesquisa/${searchValue}`);
    onSearch();
  };

  return (
    <SearchModalWrapper>
      <div className="container-icon" onClick={handleSearch}>
        {searchIcon && <Icon name="search_icon" size={40} />}
      </div>
      <div className="container-input">
        <input
          id="search-input"
          type="text"
          placeholder={placeholder}
          value={searchValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setSearchValue(event.target.value)
          }
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
              handleSearch();
            }
          }}
        />
      </div>
    </SearchModalWrapper>
  );
};

export default SearchModal;
