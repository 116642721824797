import styled from 'styled-components';
import { px2rem } from 'styles/globals';

interface IHomeWrapper {
  background?: string;
}

interface IRow {
  reverse?: boolean;
}

const HomeWrapper = styled.section<IHomeWrapper>`
  margin: 0 auto;
  padding: 20px;
  ${props => (props.background ? `background: ${props.background};` : '')}

  @media screen and (min-width: 998px) {
    padding: ${px2rem(80)} ${px2rem(88)};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${px2rem(24)};
`;

const SectionTitle = styled.h1`
  font-weight: 800;
  font-size: 20px;
  @media screen and (min-width: 998px) {
    font-size: var(--font-larger);
  }
`;

const Row = styled.div<IRow>`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  @media screen and (min-width: 998px) {
    justify-content: space-between;
    flex-direction: row;
    gap: 15px;
    ${props => props.reverse ?  
      `
        flex-direction: row-reverse;
       
      ` 
      : `
        flex-direction: row;
        
      ` 
    }

  }
  @media screen and (max-width: 997px) {
    align-items: center;
  }
`;
const Grid = styled.div`
  @media screen and (min-width: 998px){
    width: 50%;
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: ${px2rem(25)};
  }
  @media screen and (max-width: 997px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
`;

export { HomeWrapper, Header, SectionTitle, Row, Grid };
