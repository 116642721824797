import styled from 'styled-components';

export const Container = styled.div`
  max-width: 75%;
  width: 100%;
  margin: 0 auto;

  h2 {
    font-size: 40px;
    line-height: 35px;
    color: var(--grey-darker);
    font-weight: 700;
  }

  h3 {
    font-size: 16px;
    margin-top: 24px;
    font-weight: 500;
    color: #555555;
  }

  p {
    margin-top: 47px;
    font-size: 20px;
    line-height: 30px;
    color: var(--grey-darker);
  }

  @media only screen and (max-width: 700px) {
    max-width: 88%;
    margin-bottom: 55px;

    h2 {
      font-size: 1.8rem;
      line-height: 40px;
    }

    h3 {
      font-size: 18px;
      margin: 16px 0;
      line-height: 33px;
    }

    p {
      font-size: 18px;
    }
  }
`;

export const MessageError = styled.h2`
  font-size: 25px;
  margin-top: 20px;
  line-height: 32px;
`;
