import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import TemplateCategories from 'components/Template/TemplateCategories';
import { BreadCrumb, Template } from 'components';
import { Link } from 'react-router-dom';
import { LADINGPAGE } from 'routes/Constants';
import {  d_video, m_video } from 'assets';
import CategoriesList from './components/CategoriesList';
import SpotContent from './components/SpotContent';

smoothscroll.polyfill();

const Video: React.FC = () => {
  return (
    <>
     <Template
        banner={{
          title: 'Video',
          imgDesktop: d_video,
          imgMobile: d_video,
        }}
        breadcrumb={
          <BreadCrumb
            crumbs={[
              <Link to={`${LADINGPAGE}`}>HOME</Link>,
              <span>Video</span>,
            ]}
          />
        }
      >
      <SpotContent/>
      <CategoriesList/>
     </Template>
    </>
  );
};

export default Video;
