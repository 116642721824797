import httpClient from 'http-client';
import IEvents from './models/events-interface';

const getAllEvents = async (filters?: object): Promise<IEvents[]> => {
  const events: IEvents[] = (
    await httpClient.get<IEvents[]>('/events', {
      params: filters,
    })
  ).data;
  return events;
};

const getEvents = async (event_id: string): Promise<IEvents> => {
  const events = (await httpClient.get(`/events/${event_id}`)).data;
  return events;
};

const getEventsByFilter = async (filter_type?: string, filter_date?: string): Promise<IEvents> => {
  const events = (await httpClient.get(`/events`, {
    params: {
      filter_date,
      filter_type
    }
  })).data;
  return events;
};

export { getAllEvents, getEvents, getEventsByFilter };
