import styled from 'styled-components';

export const Title = styled.h2`
  color: var(--blue-medium);
  font-size: 20px;
  font-weight: bold;
  margin: 30px 0;
`;

export const TitleComponents = styled.h3`
  color: var(--grey-darker);
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
`;
export const MessageError = styled.h2`
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 50px 10px;
  align-items: center;

  div {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
`;
