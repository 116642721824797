import { iconDelete, response } from 'assets';
import React, { useState } from 'react';
import { Comment } from 'services/comments/models/comment-interface';
import { DeleteButton, ResponseComment } from './style';
// import { Container } from './styles';
import { deleteCommentByCommentId } from 'services/comments';

interface IResponse{
  comment: Comment;
}
const Response: React.FC<IResponse> = ({comment}) => {
  const [deleteComment, setDeleteComment] = useState<String[]>([]);
  const user = JSON.parse(localStorage.getItem('exeltis-user') as string);
  const isAdmin = user?.roles?.includes('Admin');

  const handleDeleteComment = async (comment_id: string) => {
    setDeleteComment([comment_id, ...deleteComment]);
    await deleteCommentByCommentId(comment_id);
  };
  if(deleteComment.includes(comment?.comment_id)){
    return null;
  }
  return(
    <ResponseComment>
      <div>
        <img src={comment?.user_avatar_url} alt=""/>
        <strong>{comment?.username}</strong>
        {isAdmin && 
          <DeleteButton onClick={() =>
            handleDeleteComment(comment?.comment_id)
          }>
            <img src={iconDelete} />
          </DeleteButton>
        }
      </div>
      <p>{comment?.description}</p>
    </ResponseComment>
  );
}

export default Response;