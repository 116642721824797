import React from 'react';
import styled from 'styled-components';
import VerifyCodeContent from './Components';

const Container = styled.div`
  height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: white;
  z-index: 10;

  @media (min-width: 768px) and (max-width: 1024px) {
    top: auto;
    position: initial;
    height: auto;
    padding-bottom: 30px;
  }

  @media (max-width: 767px) {
    position: initial;
    height: auto;
    padding-bottom: 30px;
  }
`;

export const BackgroundHeader = styled.div`
  height: 153px;
  background: white;
  position: absolute;
  right: 0;
  top: -78px;
  left: 0;
`;

export const BackgroundFooter = styled.div`
  max-height: 512px;
  height: 100%;
  background: white;
  position: absolute;
  right: 0;
  left: 0;

  @media (min-width: 1025px) {
    display: none;
  }

  @media (max-width: 1024px) {
    max-height: 800px;
    display: block;
  }

  @media (max-width: 800px) {
    display: block;
    max-height: 512px;
  }
`;

const VerifyCodePage: React.FC = () => {
  return (
    <Container>
      <BackgroundHeader />
      <VerifyCodeContent />
      <BackgroundFooter />
    </Container>
  );
};

export default VerifyCodePage;
