import styled from 'styled-components';

export const BreadCrumbContainer = styled.div`

  & > :not(:first-child) {
    &::before {
      content: '>';
      margin: 0 10px;
      color: var(--blue-medium);
      font-size: 18px;
      font-weight: 500;
    }
  }
  a {
    font-size: 16px;
    color: var(--blue-medium);
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 900;
  }
  span {
    font-size: 16px;
    color: #362c2c;
    text-decoration: none;
  }
`;
