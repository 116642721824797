import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { LADINGPAGE } from 'routes/Constants';
import { d_technology, m_technology } from 'assets';
import { BreadCrumb, LoadingBg, Template } from 'components';
import {
  HighlightCarrousel,
  CommentedArticles,
  ScientificArticles,
  News,
} from './components';
import { useModal } from 'hooks';
import { getCategoryInfo } from 'services/categories';
import { Category } from 'services/categories/models/category-interface';
import SelectTags from './components/SelectTags';

const TemplateCategories: React.FC = () => {
  const [category, setCategory] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [selectedItem, setSelectedItem] = useState(null);

  const { categoryId } = useParams<{
    categoryId: string;
  }>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCategoryById = async () => {
    const category = await getCategoryInfo(categoryId);
    setCategory(category);
  };

  useEffect(() => {
    getCategoryById();
    setSelectedItem(null);
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, [categoryId]);
  return (
    <Template
      banner={{
        title: category ? category?.title : '',
        imgDesktop: category ? category?.thumbnail : '2',
        imgMobile: category ? category?.thumbnail : '',
        color: category?.info?.color,
      }}
      breadcrumb={
        <BreadCrumb
          crumbs={[
            <Link to={`${LADINGPAGE}`}>HOME</Link>,
            <span>{category?.title}</span>,
          ]}
        />
      }
    >
      <SelectTags
        selected={selectedItem}
        setSelectedItem={(value: any) => setSelectedItem(value)}
      />
      {isLoading ? (
        <LoadingBg />
      ) : (
        <>
          <HighlightCarrousel
            title="Video"
            category={category?.title}
            theme={category?.info?.color}
            type="VIDEO"
            tag={selectedItem}
          />
          <HighlightCarrousel
            title="Podcast"
            category={category?.title}
            theme={category?.info?.color}
            type="PODCAST"
            tag={selectedItem}
          />
          <CommentedArticles
            title="Artigos Comentados"
            type="ARTIGO_COMENTADO"
            tag={selectedItem}
          />
          <ScientificArticles
            title="Artigos Científicos"
            type="ARTIGO_CIENTIFICO"
            tag={selectedItem}
          />
          <News title="Monografia" type="MONOGRAFIA" tag={selectedItem} />
        </>
      )}
    </Template>
  );
};

export default TemplateCategories;
