import styled, { createGlobalStyle } from 'styled-components';
import { px2rem } from 'styles/globals';

const GlobalStyle = createGlobalStyle`
  #root > div > header, footer {
    display: none;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  .player-footer {
    display: flex;
    flex-direction: column;
    flex: 1;
    .video {
      flex: 1;
      background-color: #000;
      div, iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
  
  .back-button {
    position: absolute;
    background: var(--blue-darkness);
    top: 40px;
    left: 40px;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    border: none;
    transition: ease-in-out .2s;

    :hover {
      transform: scale(1.1);
      transition: ease-in-out .2s;
    }

  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;

    .back-button {
      top: 16px;
      left: 16px;
      transform: scale(.7);

      :hover {
        transform: scale(.75);
      }
    }
      
    .video {
      min-height: 40vw;
    }
  }

  @media screen and (max-width: 500px) {
    .video {
      min-height: 70vw;
    }
  }
`;

const NoVideo = styled.div`
  flex: 1;
  position: relative;
  img{
    display: block;
    width: 100%;
  }
  >div{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg,rgba(18,66,138,0) 0%,rgba(18,66,138,0.827432) 55.52%,rgba(18,66,138,0.98) 91.67%);
    p{
      font-size: ${px2rem(43)};
      color: #FFF;
      font-weight: 700;
      text-align: center;
      margin: 20px;
    }
    button{
      width: auto;
    }
  }
  @media screen and (max-width: 1024px){
    >div p{
      font-size: 24px;
      button{
        font-size: 16px;
      }
    }
  }
`;

export {
  GlobalStyle,
  Container,
  NoVideo
};
