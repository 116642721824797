import styled, { css } from 'styled-components';

interface IBannerStyled {
  theme: 'BlueDark' | 'BrownLight' | 'PinkMedium' | 'PinkDark';
}

export const BannerWrapper = styled.div<IBannerStyled>`
  @media screen and (min-width: 998px){
    min-height: 270px;
  }

  ${props =>
    props.theme === 'BlueDark' &&
    css`
      background-color: var(--blue-medium);
    `}

  ${props =>
    props.theme === 'BrownLight' &&
    css`
      background-color: var(--brown-lighter);
    `}

    ${props =>
    props.theme === 'PinkMedium' &&
    css`
      background-color: var(--pink-dark);
    `}

    ${props =>
    props.theme === 'PinkLight' &&
    css`
      background-color: var(--pink-dark);
    `}

    ${props =>
    props.theme === 'PinkDark' &&
    css`
      background-color: var(--pink-darker);
    `}

    ${props =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
  
  border-radius: 6px;

  @media only screen and (max-width: 877px) {
   border-radius: 0px;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  .content {
    max-width: 31%;
    margin-left: 40px;
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    color: white;
    white-space: pre;
  }

  h2 {
    font-size: 40px;
    font-weight: 500;
    text-transform: uppercase;
  }

  h5 {
    font-size: 16px;
    font-weight: 400;
  }

  p {
    margin-top: 20px;
    font-size: 18px;
    color: white;
    line-height: 26px;
    display: block;
  }

  .container-img {
    display: flex;
    img {
      height: 100%;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      max-width: 612px;

      @media only screen and (max-width: 877px) {
        height: 90px;
      }
    }
  }

  .imgBannerDesktop {
    display: block;
  }

  .imgBannerMobile {
    display: none;
  }

  @media only screen and (max-width: 1200px) {
    .content {
      display: block;
      height: unset;
    }

    h2 {
      font-size: 18px;
    }

    h5 {
      display: none;
    }
  }

  @media only screen and (max-width: 877px) {
    h2{
      white-space: pre-line;
    }
    p {
      display: none;
    }

    .imgBannerDesktop {
      display: none;
    }

    .imgBannerMobile {
      display: block;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  @media only screen and (max-width: 615px) {
    .content {
      margin-left: 20px;
    }

    h2 {
      font-size: 18px;
    }
  }

  @media only screen and (max-width: 322px) {
    h2 {
      line-height: 20px;
    }
  }
`;
