type PropTypes = 'exeltis-api-token' | 'exeltis-user';
interface ChangeHeader<T> {
  prop: PropTypes;
  value: T;
}

export const changeValue = <T>({ prop, value }: ChangeHeader<T>): void => {
  try {
    localStorage.setItem(prop, JSON.stringify(value));
  } catch (err) {
    console.log(err);
  }
};

export const purgeStorage = ({ prop }: { prop: PropTypes }): void => {
  try {
    localStorage.removeItem(prop);
  } catch (err) {
    console.log(err);
  }
};

export const getValue = <T>({ prop }: { prop: PropTypes }): T | undefined => {
  try {
    const value = localStorage.getItem(prop);
    if (value !== null) {
      return JSON.parse(value);
    }
  } catch (err) {
    console.log(err);
  }
};
