import styled, { keyframes } from 'styled-components';

const animate = keyframes`
	z-index: 10;
	0% {
		opacity: 0;
		transform: translate(0px, -30px);
	}
	100% {
		opacity: 1;
		transform: translate(0px, 0px);
	}
`;

export const List = styled.div`
  margin-bottom: 10px;
`;

export const TabTitle = styled.div`
  .accordion-title {
    font-weight: 600;
    cursor: pointer;
    padding-top: 20px;
    padding-bottom: 31px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      color: var(--grey-darker);
      font-size: 20px;
      margin-left: 10px;
      font-weight: 700;
    }

    &.open {
      border-bottom: 1px solid transparent;
    }

    &.close {
      border-bottom: 2px solid #cccfd1;
    }

    .close-img,
    .open-img {
      transform: rotate(-90deg);
      width: 28px;
      height: 28px;
      border: 2px solid black;
      border-radius: 50%;
      padding: 2px;
    }

    .open-img {
      transform: rotate(90deg);
    }
  }
`;

export const TextFaq = styled.div`
  .accordion-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;

    -webkit-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .accordion-item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  }

  .accordion-content {
    width: 100%;
    animation: ${animate} 0.6s;
    padding-bottom: 40px;

    .accordion-content-width {
      display: flex;
      justify-content: flex-start;

      div {
        padding-left: 2.6rem;

        @media only screen and (max-width: 700px) {
          max-width: 84%;
        }
      }
    }

    span {
      font-size: 18px;
      color: #555555;
      line-height: 30px;

      p {

      }

      a {
        font-style: italic;
      }
    }

    &.open {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }
  }
`;
