import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useModal } from 'hooks';
import {
  VAGINAL_INFECTIONS,
  OBSTETRICS,
  FAMILY_PLANNING,
  NEWS,
  PODCAST,
  SCHEDULE,
  PRODUCTS,
  HOME,
  LADINGPAGE,
  CATEGORY_TEMPLATE,
  getCategoryRoute,
  PROFILE,
  EVENTS,
} from 'routes/Constants';
import dataArticles from '../data/dataArticles';
import { HeaderDesktop } from './style';
import { logotipo } from 'assets';
import { Select, Icon, Modal } from 'components';
import SearchContent from './Search';
import { useAuth } from 'providers/auth/AuthProvider';
import { Category } from 'services/categories/models/category-interface';
import { getCategories } from 'services/categories';
import { position } from 'polished';
import { getValue } from 'utils/storage';

const Component: React.FC = () => {
  const { signOut } = useAuth();
  const [articles, setArticles] = useState(false);
  const [categories, setCategories] = useState<Category[]>();
  const [profile, setProfile] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const handleItemClick = (id: any) => {
    selectedItem === id ? setSelectedItem(null) : setSelectedItem(id);
    onClose();
  };
  const [modalOpen, openModal, closeModal] = useModal();
  const history = useHistory();

  useEffect(() => {
    const getAllCategories = async () => {
      const user = getValue<string>({ prop: 'exeltis-user' });
      if (user) {
        const response = await getCategories();
        setCategories(response);
      }
    };
    getAllCategories();
  }, []);

  const handleLogout = (): void => {
    signOut();
    history.push(`${LADINGPAGE}`);
  };

  const onClose = () => {
    setArticles(false);
    setProfile(false);
  };

  const showProfile = () => {
    setProfile(true);
  };

  const showArticles = () => {
    setArticles(true);
  };

  function renderArticles() {
    return (
      <>
        <button onClick={showArticles}>
          Artigos
          <div style={{ marginLeft: '10px', marginTop: '3px' }}>
            <Icon name="arrow_down" color="#12428A" size={25} />
          </div>
        </button>
        {renderArtigosItems()}
      </>
    );
  }

  function renderArtigosItems() {
    return (
      <>
        <div className="select-position-articles">
          <Select onClose={onClose} select={articles}>
            {dataArticles.map(item => (
              <Link
                key={item.id.toString()}
                id={item.id.toString()}
                to={item.link}
                onClick={e => {
                  handleItemClick(item.id);
                }}
                className={`dropdown-item-dot ${
                  item.id === selectedItem && 'selected'
                }`}
              >
                {item.label}
              </Link>
            ))}
          </Select>
        </div>
      </>
    );
  }

  function renderProfile() {
    return (
      <>
        <div className="profile-header" onClick={showProfile}>
          <Icon name="profile_icon" color="#12428A" size={27} />
        </div>
        <div className="select-prosition-profile">
          <Select onClose={onClose} select={profile}>
            <Link onClick={onClose} className="logoutStyle" to={`${PROFILE}`}>
              Editar Perfil
            </Link>
            <div className="container-logout">
              <Icon onClick={handleLogout} name="SignOut" size={25} />
              <span onClick={handleLogout}>Sair</span>
            </div>
          </Select>
        </div>
      </>
    );
  }

  return (
    <HeaderDesktop>
      <Link to={`${HOME}`}>
        <img className="brand" src={logotipo} alt="Exeltis ON" />
      </Link>
      <nav>
        <ul>
          {categories?.map((category, key) => (
            <li key={`${category.title}-${key}`}>
              <Link to={getCategoryRoute(category.category_id)}>
                {category.title}
              </Link>
            </li>
          ))}
          <li>{renderArticles()}</li>
          <li>
            <Link to={`${NEWS}`}>Notícias</Link>
          </li>
          <li>
            <Link to={`${PODCAST}`}>Podcast</Link>
          </li>
          {
            <li>
              <Link to={`${EVENTS}`}>Agenda</Link>
            </li>
          }
          <li>
            <Link to={`${PRODUCTS}`}>Produtos</Link>
          </li>
        </ul>
      </nav>
      <div>
        <Icon name="search_icon" size={35} onClick={openModal} />
        <Modal
          width={{
            desktop: '80%',
            tablet: '80%',
          }}
          closeModal={closeModal}
          positionClose="In"
          IconTheme="light"
          modalOpen={modalOpen}
          position="Top"
        >
          <SearchContent onSearch={closeModal} />
        </Modal>
      </div>
      <div style={{ marginLeft: '16px' }}>{renderProfile()}</div>
    </HeaderDesktop>
  );
};

export default Component;
