import React, { useEffect, useState } from 'react';
import { newspaper } from 'assets';
import { Section, Header } from '../../styles';
import ArticleSession from '../ArticleSession';
import { useParams } from 'react-router';
import { getContentByCategoryType, getContentByCategoryTypeTag } from 'services/categories';
import ITags from 'services/tags/models/tags-interface';

interface INews{
  type: string;
  title?: string;
  tag: ITags | null
}
const News: React.FC<INews> = ({ title, type, tag }) => {
  const [content, setContent] = useState<any>();
  const { categoryId } = useParams<{
    categoryId: string
  }>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getData = async () => {
    if(!tag){
      const contents = await getContentByCategoryType(categoryId, type);
      setContent(contents)
    }else{
      const contents = await getContentByCategoryTypeTag(categoryId, type, tag?.tag_id)
      setContent(contents)
    }
  }

  useEffect(() => {
    getData()
  }, [categoryId, tag])

  if(!content || content?.length <= 0)
    return null
  return(
    <Section>
      <Header>
        <img src={newspaper} alt=""/>
        <h3>{title} {content && `(${content?.length})`}</h3>
      </Header>
      <ArticleSession type={type} data={content} origin="artigos/monografias/listagem"/>
    </Section>
  );
}

export default News;