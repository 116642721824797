import React from 'react';
import { TabTitle, TextFaq, List } from './style';
import FAQ from 'services/faq/models/faq-interface';
import { Icon } from 'components';

type Props = {
  faqs: FAQ[];
  setFaqSelected: Function;
};

const AccordionUI: React.FC<Props> = ({ faqs, setFaqSelected }) => {
  const [isOpen, setOpen] = React.useState<String>('');

  function verifyIsOpen(id: string) {
    return id === isOpen;
  }
  return (
    <>
      {faqs.map((faq, index) => {
        return (
          <List key={index}>
            <TabTitle>
              <div
                className={`accordion-title ${
                  verifyIsOpen(String(faq.faq_id)) ? 'open' : 'close'
                }`}
                onClick={() => {
                  if (verifyIsOpen(String(faq.faq_id))) {
                    setOpen('');
                  } else {
                    setOpen(String(faq.faq_id));
                  }

                  setFaqSelected(faqs);
                }}
              >
                {verifyIsOpen(String(faq.faq_id)) ? (
                  <Icon name="circle_negative" size={28} />
                ) : (
                  <Icon name="plus_circle" size={28} />
                )}
                <span>{faq.question}</span>
              </div>
            </TabTitle>

            <TextFaq>
              {verifyIsOpen(String(faq.faq_id)) && (
                <div
                  className={`accordion-item ${
                    !verifyIsOpen(String(faq.faq_id)) ? 'collapsed' : ''
                  }`}
                >
                  <div
                    className={`accordion-content ${
                      verifyIsOpen(String(faq.faq_id)) ? 'open' : 'close'
                    }`}
                  >
                    <div className="accordion-content-width">
                      <div>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: faq.answer as string,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </TextFaq>
          </List>
        );
      })}
    </>
  );
};

export default AccordionUI;
