import httpClient from 'http-client';
import Product from './models/product-interface';

const getAllProducts = async (filters?: object): Promise<Product[]> => {
  const product: Product[] = (
    await httpClient.get('/products', {
      params: filters,
    })
  ).data;

  return product;
};

const getProduct = async (productId: string): Promise<Product> => {
  const product = (await httpClient.get(`/products/${productId}`)).data;

  return product;
};

export { getProduct, getAllProducts };
