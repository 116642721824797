import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { px2rem } from 'styles/globals';

interface IArticle {
  isActive?: boolean;
  color?: string;
}

const ExternalLink = styled.a`
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
  @media screen and (min-width: 998px) {
    max-width: ${px2rem(530)};
    margin: 0;
  }
`;

const ArticleLink = styled(Link)`
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
  @media screen and (min-width: 998px) {
    max-width: ${px2rem(530)};
    margin: 0;
  }
`;

const Wrapper = styled.div`
  position: relative;
  img.icon{
    position: absolute;
    align-self: center;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    width: 48px;
  }
  @media screen and (min-width: 998px){
    height: 160px;
    overflow: hidden;
  }
`;

const Article = styled.article<IArticle>`
  box-shadow: 0 1px 4px rgba(17, 17, 17, 0.14);
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease-in;
  img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
  background-color: #FFF;
  ${props =>
    props.isActive &&
    css`
      background-color: var(--blue-medium);
     >div{
       h1, p{
         color: var(--white);
        }
     }
    `}
    ${props =>
      props.color && !props.isActive &&
      css`
        background:
          ${props.color};
        >div{
          h1, p{
            color: var(--white);
            overflow: hidden; // Removendo barra de rolagem
            text-overflow: ellipsis; // Adicionando "..." ao final
            display: -webkit-box;
            -webkit-line-clamp: 2; // Quantidade de linhas
            -webkit-box-orient: vertical;
          }
        }
    `}
  @media screen and (min-width: 998px) {
    height: 100%;
    max-width: ${px2rem(530)};
    
  }
`;

const ArticleDescription = styled.div`
  padding: ${px2rem(24)};
`;

const ArticleTitle = styled.h1`
  font-size: var(--font-larger);
  font-weight: 400;
  letter-spacing: 0.18px;
  color: var(--blue-darker);
`;

const ArticleResume = styled.p`
  font-size: var(--font-normal);
  color: var(--grey-darker);
  letter-spacing: 0.25px;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  padding-bottom: 4px;
`;

export {
  ExternalLink,
  ArticleLink,
  Article,
  Wrapper,
  ArticleDescription,
  ArticleTitle,
  ArticleResume,
};
