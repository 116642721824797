import styled from 'styled-components';
import { subtract_bg } from 'assets';

export const VectorForm = styled.div`
  position: absolute;
  left: 0;
  z-index: 2;

  img {
    width: 100%;
    height: 100vh;
    transform: translatey(190px);
    position: relative;
    z-index: 3;

    @media only screen and (min-width: 700px) and (max-width: 1024px) {
      height: auto;
      transform: translatey(19px);
    }

    @media only screen and (max-width: 699px) {
      transform: translatey(72px);
    }
  }
`;

export const Container = styled.div`
  position: absolute;
  z-index: 5;
  padding-top: 187px;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f5f9fc;

  background-image: url(${subtract_bg});
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;

  @media only screen and (min-width: 1700px) {
    background-size: 100% 38%, contain, 0em;
  }

  @media only screen and (max-width: 600px) {
    padding-top: 65px;
  }
`;
export const Banner = styled.main`
  position: relative;
  z-index: 1;
  background-color: var(--blue-light);
  height: 400px;
  width: auto;
  border-radius: 6px;
  margin: 0 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    color: white;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
  }

  @media only screen and (max-width: 800px) {
    margin: 40px 0;
  }
`;

export const About = styled.section`
  margin-top: 50px;
  position: relative;
  z-index: 1;
  margin: 40px 40px;
  background: #e7f1ffa3;
  border-radius: 6px;

  .about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 40px;
  }

  .samba-video {
    height: auto;
    margin-right: 50px;
  }

  .video-unavailable {
    background-color: var(--blue-light);
    max-width: 450px;
    width: 100%;
    height: 300px;
    margin-right: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
      color: white;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  .about-content-info {
    max-width: 600px;
  }

  h2 {
    font-size: 28px;
    font-weight: 700;
    color: var(--blue-light);
  }

  img {
    width: 130px;
    height: auto;
    margin-left: 2px;
  }

  p {
    line-height: 38px;
    color: var(--black-lighter);
    margin-top: 20px;
    font-size: 20px;

    strong {
      font-weight: 700;
    }
  }

  br {
    display: none;
  }

  .containerButton {
    max-width: 400px;
    margin-top: 30px;
  }

  @media only screen and (max-width: 1024px) {
    margin: 0px;
    border-radius: 0;

    .samba-video {
      margin-right: 0px;

      iframe {
        max-width: calc(10px + 100%);
      }
    }

    .video-unavailable {
      margin-right: 0;
    }

    .about-container {
      flex-direction: column;
      align-items: center;
      padding: 20px;
    }

    div {
      margin-bottom: 10px;

      h2 {
        margin-bottom: 20px;
      }

      p {
        max-width: 600px;
        margin-bottom: 30px;
      }

      br {
        display: block;
      }

      .containerButton {
        display: flex;
        max-width: inherit;
      }
    }
  }
`;

export const Features = styled.section`
  position: relative;
  z-index: 6;
  margin-top: -10px;
  padding: 40px 40px;

  .features-container {
    display: flex;
    justify-content: center;
    max-width: 1057px;
    margin: 0 auto;
    align-items: center;
  }

  .title {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
  }

  h2 {
    line-height: 62px;
    max-width: 457px;
    font-size: 46px;
    font-weight: 300;
    color: var(--black-lighter);

    strong {
      font-weight: 700;
    }
  }

  .features-content {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .feature-content-wrapper {
    display: flex;
    align-items: center;
    padding-right: 70px;
    @media only screen and (max-width: 540px){
      padding-right: 0;
    }

  }

  .feature-item {
    margin: 40px;
  }

  .container-btn {
    margin-top: 60px;
    max-width: 417px;
    position: relative;
    z-index: 10;
  }

  .signup-text {
    display: none;
  }

  .container-btn-mobile {
    display: none;
  }

  @media only screen and (max-width: 800px) {
    h2 {
      line-height: 40px;
      max-width: 457px;
      font-size: 29px;
      font-weight: 300;
      color: var(--black-lighter);
      margin-bottom: 30px;

      strong {
        font-weight: 700;
      }
    }
    padding: 40px 20px;

    .features-container {
      flex-direction: column;
      align-items: center;
    }

    .container-btn {
      display: none;
    }

    .signup-text {
      display: block;
      color: var(--blue-medium);
      font-size: 20px;
      line-height: 30px;
      font-weight: 700;
      text-align: center;
      margin: 30px 0;
    }

    .container-btn-mobile {
      display: flex;
      max-width: inherit;
      width: 100%;
    }
  }
`;

export const FooterContainer = styled.div`
  background-color: #f5f9fc;
  margin-top: -50px;
  padding-top: 60px;
  padding-bottom: 60px;

  @media only screen and (max-width: 800px) {
    margin-top: initial;
    padding-top: initial;
    padding-bottom: 0;
  }
`;
