const meses = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];



function formatDataFromString(stringData: string | undefined): string {
  if (!stringData) return '';

  const data = new Date(stringData);

  const dia = adicionaZero(data.getDate());
  const mes = meses[data.getMonth()];
  const ano = data.getFullYear();

  const horas = adicionaZero(data.getHours());
  const minutes = adicionaZero(data.getMinutes());
  return `${dia} de ${mes} de ${ano} às ${horas}:${minutes}`;
}

function adicionaZero(numero: number) {
  if (numero <= 9) return '0' + numero;
  else return numero;
}

export function convertTime(stringTime: string | undefined): string {
  if (!stringTime) return '';

  const time = new Date(stringTime);

  const horas = adicionaZero(time.getHours());
  const minutes = adicionaZero(time.getMinutes());

  return `${horas}:${minutes}`;
}

export default formatDataFromString;
