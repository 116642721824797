import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { px2rem } from 'styles/globals';

interface IArticle {
  isActive?: boolean;
  color?: string;
}

const ExternalLink = styled.a`
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
  @media screen and (min-width: 998px) {
    max-width: ${px2rem(343)};
    margin: 0;
  }
`;

const ArticleLink = styled(Link)`
  text-decoration: none;
  display: block;
  width: 100%;
  margin-bottom: 16px;
  @media screen and (min-width: 998px) {
    max-width: ${px2rem(343)};
    margin: 0;
  }
`;

const Article = styled.article<IArticle>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0 1px 4px rgba(17, 17, 17, 0.14);
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease-in;
  img {
    display: block;
    width: 100%;
    height: 124px;
  }
  background-color: #FFF;
  ${props =>
    props.isActive &&
    css`
      background-color: ${props.color};
      > div {
        h1,
        p {
          color: var(--white);
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    `}
  @media screen and (min-width: 998px) {
    height: 100%;
    max-width: ${px2rem(343)};
  }
`;

const ArticleDescription = styled.div`
  padding: ${px2rem(24)};
`;

const ArticleTitle = styled.h1`
  font-size: var(--font-larger);
  font-weight: 400;
  letter-spacing: 0.18px;
  color: var(--blue-darker);
`;

const ArticleResume = styled.p`
  font-size: var(--font-normal);
  color: var(--grey-darker);
  letter-spacing: 0.25px;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Wrapper = styled.div`
  position: relative;
  @media screen and (min-width: 998px){
    height: 105px;
    overflow: hidden;
    
  }
`;

export {
  ArticleLink,
  Article,
  ArticleDescription,
  ArticleTitle,
  ArticleResume,
  Wrapper,
  ExternalLink
};
