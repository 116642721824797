import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, Link } from 'react-router-dom';
import { validateEmail, checkPassword, validateName } from 'helpers/validation';
import { maskPhone, maskOnlyNumber } from 'helpers/masks';
import { Input, Button, Back, BackText } from 'components';
import { logotipo } from 'assets';
import * as Style from '../../style';
import {
  LADINGPAGE,
  LOGIN,
  PRIVACY_POLICY_SIGNUP, SIGNUP_EXPORTED_USER,
  RECOVER_VERIFY_CODE, VERIFY_CODE
} from 'routes/Constants';
import { data as states } from './data';
import { verifyUser } from 'services/user';
import { resendCode } from 'services/signup';

const Signup: React.FC = () => {
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [crm, setCRM] = useState<string>('');
  const [crm_uf, setCRMSelected] = useState<string>('');

  const [crmError, setCRMError] = useState<string>();
  const [crm_ufError, setCRM_ufError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');
  const [repeatPasswordError, setRepeatPasswordError] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');

  const handleSignup = async (): Promise<void> => {
    if (!email) {
      setEmailError('Preencha corretamente este campo');
    }

    if (!name) {
      setNameError('Preencha corretamente este campo');
    }

    if (!crm_uf) {
      setCRM_ufError('Selecione o UF do seu CRM');
    }

    if (!crm) {
      setCRMError('Preencha corretamente este campo');
    }

    if (!phone) {
      setPhoneError('Preencha corretamente este campo');
    }

    if (!password) {
      setPasswordError('Preencha corretamente este campo');
    }

    if (!repeatPassword) {
      setRepeatPasswordError('Preencha corretamente este campo');
      return;
    }

    if (validateName(name) || name.length < 2) {
      setNameError(
        'O nome precisa ter pelo menos duas letras e nenhum caracter especial.',
      );
      return;
    }

    if (crm.length < 7) {
      setCRMError('O crm precisa ter 7 números.');
      return;
    }

    if (password !== repeatPassword) {
      setPasswordError('As senhas não se correspondem, informe senhas iguais.');
      setRepeatPasswordError(
        'As senhas não se correspondem, informe senhas iguais.',
      );
      return;
    }

    if (password === repeatPassword && !checkPassword(password)) {
      setPasswordError(
        'A senha precisa conter no mínimo 8 caracteres, incluindo letras maíusculas, minúsculas e números',
      );
      return;
    }

    if (
      phone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '')
        .length < 11
    ) {
      setPhoneError('O número de telefone está inválido.');
      return;
    }

    if (!validateEmail(email) && email) {
      setEmailError('Informe um e-mail com formato válido');
      return;
    }



    if (
      !crmError &&
      !crm_ufError &&
      crm_uf &&
      !emailError &&
      !nameError &&
      !passwordError &&
      !repeatPasswordError &&
      !phoneError
    ) {
      try {
        const { crm_exists, email_exists, is_exported, is_confirmed, had_logged } = await verifyUser(email, crm, crm_uf);
        if (!crm_exists && !email_exists) {
          const data = {
            email,
            password,
            name,
            crm,
            crm_uf,
            telephone: phone
              .replace('(', '')
              .replace(')', '')
              .replace('-', '')
              .replace(' ', ''),
          };
          history.push(`${PRIVACY_POLICY_SIGNUP}`, {
            data,
            type: 'signup',
          });
        } else {
          if (email_exists && is_exported && !is_confirmed && !had_logged) {
            const data = {
              email,
            };
            history.push(`${SIGNUP_EXPORTED_USER}`, {
              data,
              type: 'signup_registration' &&
                toast.warning('⚠ O seu email consta em nossa base de dados como usuário convidado pelo administrador, por favor, adicione uma nova senha no campo a seguir e prossiga com o fluxo de cadastro.'),
            });
            return
          }
          if (email_exists && is_exported && !is_confirmed && had_logged) {
            await resendCode({ email, is_medic: true }).then(() => {
              toast.warn(
                'Verifique seu email para receber um código de segurança',
                { delay: 4000, hideProgressBar: false });
              history.push(`${VERIFY_CODE}`, {
                email,
                type: 'signup',
              });
            })
          }
          if (crm_exists) {
            setCRMError('Este CRM já foi cadastrado');
          }
          if (email_exists) {
            setEmailError('Este e-mail já foi cadastrado');
          }
        }
      } catch (err) {
        toast.error('⚠ Houve um erro ao fazer cadastro.');
      }


    }
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    switch (e.target.id) {
      case 'name-input':
        setName(e.target.value);
        break;

      case 'email-input':
        setEmail(e.target.value);
        break;

      case 'crm-input':
        setCRM(maskOnlyNumber(e.target.value));
        break;

      case 'phone-input':
        setPhone(maskPhone(e.target.value));
        break;

      case 'password-input':
        setPassword(e.target.value);
        break;

      case 'repeat-password-input':
        setRepeatPassword(e.target.value);
        break;

      default:
        break;
    }
  };

  const handleChangeSelect = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    switch (e.target.id) {
      case 'crm-selected':
        setCRMSelected(e.target.value);
        break;

      default:
        break;
    }
  };

  return (
    <Style.Wrapper>
      <Style.BackContainer>
        <Back />
      </Style.BackContainer>
      <Style.BackContainerMobile>
        <BackText title="Voltar" />
      </Style.BackContainerMobile>
      <Style.ContainerLogoGlobal>
        <Style.LogoContainer>
          <div className="img-dash-desktop pointer">
            <img
              src={logotipo}
              alt="Logo"
              onClick={() => {
                history.push(`${LADINGPAGE}`);
              }}
            />
          </div>
        </Style.LogoContainer>
      </Style.ContainerLogoGlobal>

      <Style.Container>
        <Style.Block>
          <Style.Form>
            <Style.Title
              style={{ textTransform: 'uppercase', marginBottom: 0 }}
            >
              Cadastro
            </Style.Title>
            <Style.DescriptionMobile>
              Bem-vindo(a) à <strong>Exeltis On</strong>, a plataforma
              médica da <strong>Exeltis</strong>.
            </Style.DescriptionMobile>
            <Style.Label>
              <Input
                type="text"
                id="name-input"
                value={name}
                onChange={handleChangeInput}
                onFocus={() => setNameError('')}
                label="Nome"
              >
                {nameError && <span>{nameError}</span>}
              </Input>

              <Input
                type="email"
                id="email-input"
                value={email}
                onChange={handleChangeInput}
                onFocus={() => setEmailError('')}
                label="E-mail"
              >
                {emailError && <span>{emailError}</span>}
              </Input>

              <Style.ContainerCRM>
                <div className="select-crm">
                  <h3>UF do CRM</h3>
                  <Style.Select
                    id="crm-selected"
                    onChange={handleChangeSelect}
                    onFocus={() => setCRM_ufError('')}
                  >
                    <option className="option-desktop">
                      Selecione o UF do CRM
                    </option>
                    {states.map(({ value, label }) => (
                      <option value={value} key={value}>
                        {label}
                      </option>
                    ))}
                  </Style.Select>
                  {crm_ufError && (
                    <span className="error-styled">{crm_ufError}</span>
                  )}
                </div>
                <div className="input-crm">
                  <Input
                    type="text"
                    id="crm-input"
                    value={crm}
                    maxlength={7}
                    onChange={handleChangeInput}
                    onFocus={() => setCRMError('')}
                    label="CRM"
                  >
                    {crmError && <span>{crmError}</span>}
                  </Input>
                </div>
              </Style.ContainerCRM>

              <div className="container-input-phone">
                <Input
                  type="text"
                  id="phone-input"
                  value={phone}
                  onChange={handleChangeInput}
                  onFocus={() => setPhoneError('')}
                  label="Celular"
                >
                  {phoneError && <span>{phoneError}</span>}
                </Input>
              </div>

              <Input
                type="password"
                id="password-input"
                value={password}
                onChange={handleChangeInput}
                onFocus={() => setPasswordError('')}
                label="Senha"
                alertMessage="A senha precisa conter no mínimo 8 caracteres, incluindo letras maiúsculas, minúsculas e números"
              >
                {passwordError && <span>{passwordError}</span>}
              </Input>
              <Input
                type="password"
                id="repeat-password-input"
                value={repeatPassword}
                onChange={handleChangeInput}
                onFocus={() => setRepeatPasswordError('')}
                label="Confirmar senha"
              >
                {repeatPasswordError && <span>{repeatPasswordError}</span>}
              </Input>
              <div className="content-wrapper">
                <div className="container-btn" style={{ margin: 0 }}>
                  <div>
                    <Button variant="DefaultDark" onClick={handleSignup}>
                      Cadastrar
                    </Button>
                  </div>
                </div>
                <div className="container-link">
                  <Link
                    className="signup-navigation"
                    to={`${RECOVER_VERIFY_CODE}`}
                  >
                    Já tem um código de verificação ou não recebeu o código?
                  </Link>
                </div>
                <div className="container-link">
                  <Link className="signup-navigation" to={`${LOGIN}`}>Já tem uma conta?</Link>
                </div>
              </div>

              <Style.LoginContainerMobile>
                <div
                  className="content-login-link"
                  style={{ marginTop: '17px' }}
                >
                </div>
              </Style.LoginContainerMobile>
            </Style.Label>
          </Style.Form>
        </Style.Block>
      </Style.Container>
    </Style.Wrapper>
  );
};

export default Signup;
