function convertState(state: string) {
  var data = state;

  switch (state) {
    case 'Acre':
      data = 'AC';
      break;
    case 'Alagoas':
      data = 'AL';
      break;
    case 'Amazonas':
      data = 'AM';
      break;
    case 'Amapa':
      data = 'AP';
      break;
    case 'Bahia':
      data = 'BA';
      break;
    case 'Ceara':
      data = 'CE';
      break;
    case 'Distrito Federal':
      data = 'DF';
      break;
    case 'Espirito Santo':
      data = 'ES';
      break;
    case 'Goias':
      data = 'GO';
      break;
    case 'Maranhao':
      data = 'MA';
      break;
    case 'Minas Gerais':
      data = 'MG';
      break;
    case 'Mato Grosso do Sul':
      data = 'MS';
      break;
    case 'Mato Grosso':
      data = 'MT';
      break;
    case 'Para':
      data = 'PA';
      break;
    case 'Paraiba':
      data = 'PB';
      break;
    case 'Pernambuco':
      data = 'PE';
      break;
    case 'Piaui':
      data = 'PI';
      break;
    case 'Parana':
      data = 'PR';
      break;
    case 'Rio de Janeiro':
      data = 'RJ';
      break;
    case 'Rio Grande do Norte':
      data = 'RN';
      break;
    case 'Rondonia':
      data = 'RO';
      break;
    case 'Roraima':
      data = 'RR';
      break;
    case 'Rio Grande do Sul':
      data = 'RS';
      break;
    case 'Santa Catarina':
      data = 'SC';
      break;
    case 'Sergipe':
      data = 'SE';
      break;
    case 'São Paulo':
      data = 'SP';
      break;
    case 'Tocantins':
      data = 'TO';
      break;
  }

  return data;
}

export default convertState;
