
import {useState, useCallback} from 'react';
import { getValue, changeValue, purgeStorage } from 'utils/storage';
import { login, LoginData } from 'services/login';
import { User } from 'services/user/models/user-interface';

const getUserFromLocalStorage = () => getValue<User>({prop: 'exeltis-user'}) ?? null;
const getAuthenticatedFromStorage = () =>  !!getValue<User>({prop: 'exeltis-user'});
const getTokenFromLocalStorage = () => getValue<string>({prop: 'exeltis-api-token'}) ?? '';


export function useProvideAuth() {

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(getAuthenticatedFromStorage);
    const [user, setUser] = useState<User | null>(getUserFromLocalStorage);
    const [token, setToken] = useState<string>(getTokenFromLocalStorage);

    const signIn = useCallback(async (data: LoginData) => {
      const {api_token, user: userResponse} = await login(data);
      changeValue<string>({prop: 'exeltis-api-token',  value: api_token});
      changeValue<User>({prop: 'exeltis-user',  value: userResponse});
      setUser(userResponse);
      setToken(api_token);
      setIsAuthenticated(true);
      return userResponse
    }, []);

    const signOut = useCallback(() => {
      purgeStorage({prop: 'exeltis-user'});
      purgeStorage({prop: 'exeltis-api-token'});
      setUser(null);
      setToken('');
      setIsAuthenticated(false);
    }, []);

    return {isAuthenticated, user, token, signIn, signOut}

}
