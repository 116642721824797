import styled from 'styled-components';

export const FormContent = styled.form`
  background: white;
  border-radius: 4px;
  padding: 55px;
  -webkit-box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);
  -moz-box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);
  box-shadow: -1px 1px 10px 0px rgba(179, 179, 179, 0.45);
  max-width: 600px;
  width: 100%;

  h3 {
    font-size: 33px;
    color: var(--blue-medium);
    line-height: 48px;
    font-weight: 700;
    max-width: 600px;
    padding-bottom: 40px;
  }

  .form-container {
    display: flex;
    justify-content: space-between;
  }

  .form-inputs {
    width: 100%;

    :last-child {
      margin-left: 20px;
    }
  }

  .button-send {
    width: 165px;
    margin-left: auto;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    width: 90%;
    padding: 25px;

    .form-container {
      display: flex;
      flex-wrap: wrap;
    }

    .form-inputs {
      :last-child {
        margin-left: 0px;
      }
    }

    h3 {
      font-size: 26px;
    }

    .button-send {
      width: 100%;
    }
  }
`;
