import { ArticleCard, LinkButton, Loading, SpolightCard } from 'components';
import { ContainerGlobal } from 'components/ContainerGlobal/style';
import LoadingUI from 'components/Loading';
import React, { useEffect, useState } from 'react';
import { getCategories } from 'services/categories';
import Category from 'services/categories/models/category-interface';
import { getInfoContent } from 'services/info';
import { Wrapper, Header, SectionTitle, Row, Grid } from '../style';
import ArticleList from './ArticlesList';

const CategoriesList: React.FC = () => {
  const [categories, setCategories] = useState<Array<Category>>();
  const getData = async () => {
    const categories = await getCategories();
    setCategories(categories);
  }

  useEffect(() => {
    getData()
  },[])

  return(
    <>
    {categories?.map((category: Category, key: number) => {
        const item = (key + 1) % 2 == 0;
        return(
          <Wrapper key={`news-${key}`}>
            <ArticleList title={category.title} reverse={item} theme={category?.info?.color} categoryId={category?.category_id} />
          </Wrapper>
        )
    })}
  </>
  )
}

export default CategoriesList;