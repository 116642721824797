import React from 'react';
import IProduct from 'services/product/models/product-interface';
import { Link } from 'react-router-dom';
import { BreadCrumb, Template } from 'components';
import { m_products, d_products } from 'assets';
import { HOME, PRODUCTS } from 'routes/Constants';
import { Info, ButtonOutline } from '../../Components/components';
import { Section, Title, Description } from './style';

type Props = {
  product: IProduct;
};

const Supplement: React.FC<Props> = ({ product }) => {
  return (
    <Template
      banner={{
        title: 'Produtos',
        imgDesktop: d_products,
        imgMobile: d_products,
      }}
      breadcrumb={
        <BreadCrumb
          crumbs={[
            <Link to={`${HOME}`}>HOME</Link>,
            <Link to={`${PRODUCTS}`}>PRODUTOS</Link>,
            <span>Suplementos</span>,
          ]}
        />
      }
    >
      <Section>
        <div className="supplement-thumbs">
          <Title>{product.title}</Title>
          <Description>{product.subtitle}</Description>
          <img src={product.thumbnail} alt="Medicamento" />
          {product.info.nutritional_information && (
            <img
              className="nutricional_information"
              src={product.info.nutritional_information}
              alt="Tabela nutricional"
            />
          )}
        </div>
        <div className="supplement-content">
          <Title>{product.title}</Title>
          <Description className="subtitle">{product.subtitle}</Description>
          <Description>{product.description}</Description>
          {product?.info?.specialist_link && (
            <div className="medicine-info-btn">
              <ButtonOutline href={`${product.info.specialist_link}`} target="_blank">
                Fale com um representante
              </ButtonOutline>
            </div>
          )}

          
          <div className="supplement-recommendations">
            {product.info.presentation && (
              <div className="medicine-info">
                <Info
                  icon={{ name: 'atom_blue_light', color: 'black', size: 30 }}
                  title="APRESENTAÇÃO"
                  description={`${product.info.presentation}`}
                />
              </div>
            )}
            {product.info.active_principle && (
              <div className="supplement-info">
                <Info
                  icon={{ name: 'medicine', color: 'black', size: 30 }}
                  title="Princípio Ativo"
                  description={`${product.info.active_principle}`}
                />
              </div>
            )}
            {product.info.usage_recomendations && (
              <div className="supplement-info">
                <Info
                  icon={{ name: 'medicine', color: 'black', size: 30 }}
                  title="recomendações de uso"
                  description={product.info.usage_recomendations}
                />
              </div>
            )}
            {product.info.conservation_care && (
              <div className="supplement-info">
                <Info
                  icon={{ name: 'thermometer', color: 'black', size: 30 }}
                  title="Cuidados de conservação"
                  description={product.info.conservation_care}
                />
              </div>
            )}
            
            <div className="supplement-more-info">
              {product.alerts && (
                <p>
                  <strong>ALÉRGICOS:{''}</strong>
                  {product.alerts}
                </p>
              )}
            </div>
          </div>
        </div>
      </Section>
    </Template>
  );
};

export default Supplement;
