import React, { useEffect, useState } from 'react';
import { d_profile, m_profile, avatar, edit } from 'assets';
import { BreadCrumb, Template } from 'components';
import { Link } from 'react-router-dom';
import { LADINGPAGE } from 'routes/Constants';
import { changeUserAvatar, getUserDetails } from 'services/user';
import { useModal } from 'hooks';
import { Container } from './style';
import { Button, Modal } from 'components';
import EditPassword from './EditPassword';
import EditProfile from './EditProfile';
import getErrorMessage from 'helpers/errorMessages';
import { toast } from 'react-toastify';
import { User } from '../../services/user/models/user-interface';
import { maskPhone } from 'helpers/masks';


type Prop = {
  reload: () => void;
};

const ProfilePage: React.FC<Prop> = ({ reload }) => {
  const [user, setUser] = useState<User>();
  const [modal, setModal] = useState<boolean>(false);
  
  const [isModalPasswordOpen, openModalPassword, closeModalPassword] =
  useModal();
  const [isModalProfileOpen, openModalProfile, closeModalProfile] = useModal();
  
  const closeModal = () => {
    closeModalProfile()
    closeModalPassword()
    setModal(!modal)
  }

  const handleAvatarChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    event.preventDefault();
    if (!event.target.files) return;

    let file = event.target.files[0];

    const formData = new FormData();
    formData.append('file', file);

    await changeUserAvatar(formData).then(response => {
      if (response) {
        localStorage.setItem('exeltis-user', JSON.stringify(response));
        closeModal()
      }
    });
  };

  useEffect(() => {
    const usersData = async () => {
      try {
        const result = await getUserDetails();
        setUser(result);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        toast.error(errorMessage);
      }
    };
    usersData()
  }, [modal]);

  if (!user) return <h1>fazer refetch</h1>;

  return (
    <Template
      banner={{
        title: 'Meu perfil',
        imgDesktop: d_profile,
        imgMobile: m_profile,
      }}
      breadcrumb={
        <BreadCrumb
          crumbs={[
            <Link to={`${LADINGPAGE}`}>HOME</Link>,
            <span>Meu perfil</span>,
          ]}
        />
      }
    >
      <Container>
        <div className="container-header">
          <img
            src={!!user?.info?.avatar ? user.info.avatar : avatar}
            alt="imagem"
            className="img-container"
          />
          <div className="wrapper-header">
            <h2>{user.name}</h2>
            <p>
              <span>CRM: </span>
              {user.info.crm}
            </p>
          </div>
          <>
            <img
              src={edit}
              alt="icon"
              className="icon"
              onClick={() => {
                document.getElementById('input-file-user-avatar')?.click();
              }}
            />
            <input
              type="file"
              id="input-file-user-avatar"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleAvatarChange(event)
              }
            />
          </>
        </div>
        <div className="container-info">
          <h3>Nome completo</h3>
          <p>{user.name}</p>
          <h3>E-mail</h3>
          <p>{user.email}</p>
          <div className="container-crm">
            <div>
              <h3>UF do CRM</h3>
              <p>{user.info.crm_uf}</p>
            </div>
            <div>
              <h3>CRM</h3>
              <p>{user.info.crm}</p>
            </div>
          </div>
          <h3 className="phone">Celular</h3>
          <p>{maskPhone(user.info.telephone)}</p>
        </div>
        <Button variant="DefaultDark" onClick={openModalProfile}>
          Editar dados
        </Button>
        <div className="security">
          <h2>Segurança</h2>
          <h3>Senha</h3>
          <p>123456</p>
          <Button variant="DefaultDark" onClick={openModalPassword}>
            Alterar senha
          </Button>
        </div>
        <Modal
          closeModal={closeModalProfile}
          positionClose="In"
          IconTheme="dark"
          modalOpen={isModalProfileOpen}
          width={{ desktop: '700px', tablet: '650px' }}
        >
          <EditProfile
            user={user}
            onClose={() => closeModal()}
          />
        </Modal>
        <Modal
          closeModal={closeModalPassword}
          positionClose="In"
          IconTheme="dark"
          modalOpen={isModalPasswordOpen}
          width={{ desktop: '700px', tablet: '650px' }}
        >
          <EditPassword
            user={user}
            closeModal={closeModal}
          />
        </Modal>
      </Container>
    </Template>
  );
};

export default ProfilePage;
