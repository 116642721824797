import React from 'react';
import styled from 'styled-components';
import Info from 'services/info/models/info-interface';
import {email, deviceMobile, mapTrifold} from 'assets/index'

export interface IFeature {
  contact: Info;
}

const Component = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 50px 0;

  img {
    max-width: 96px;
    margin-right: 10px;
  }

  .feature-content-wrapper {
    display: flex;
    align-items: flex-start;
  }

  .title {
    font-size: 23px;
    line-height: 37px;
    font-weight: 600;
    margin-top: 11px;
  }

  .text {
    font-size: 17px;
    color: #828282;
    line-height: 28px;
    margin-top: 6px;
  }

  @media only screen and (max-width: 768px) {
    padding: 10px 0;
    margin: 0;

    img {
      max-width: 77px;
      width: 100%;
      height: 77px;
    }
  }
`;

function getDefaultContactsInfo(contact: Info) {
  const subtitle = contact.info.subtitle ?? '-';
  const icon = (subtitle: string) => {
    if(subtitle === 'E-mail') return email
    if(subtitle === 'SAC') return deviceMobile
    if(subtitle === 'Endereços') return mapTrifold
  }
  const text = contact?.text ?? '-';

  return { subtitle, icon, text };
}

const Feature: React.FC<IFeature> = ({ contact }) => {
  const { subtitle, icon, text } = getDefaultContactsInfo(contact);
  return (
    <Component>
      <div className="feature-content-wrapper">
        <img className="icon" src={icon(text)} alt="imagem" />
        <div>
          <h3 className="title">{text}</h3>
          <div className="text">{subtitle}</div>
        </div>
      </div>
    </Component>
  );
};

export default Feature;
